import * as actionTypes from "./actionTypes";
import * as fileService from "./service";

const log = require("loglevel").getLogger("FileActions");
log.setLevel("debug");

export const uploadFileToS3 = (file_data, url) => {
    return async (dispatch) => {
        log.debug("FileActions -> uploadFileToS3 -> dispatch -> Enter");
        await fileService.uploadFileToS3(file_data, url);
        await dispatch({
            type: actionTypes.UPLOAD_FILE,
            data: file_data,
        });
    };
};

export const getPresignedUploadUrl = async (fileName, fileType) => {
    log.debug("FileActions -> getPresignedUploadUrl -> Enter");
    const presignedUrl = await fileService.getPresignedUploadUrl(fileName, fileType);
    return presignedUrl;
};

export const getPresignedDownloadUrl = async (fileName) => {
    log.debug("FileActions -> getPresignedDownloadUrl -> Enter");
    const presignedUrl = await fileService.getPresignedDownloadUrl(fileName);
    return presignedUrl;
};

export const deleteFileFromS3 = (fileName) => {
    return async (dispatch) => {
        log.debug("FileActions -> deleteFileFromS3 -> dispatch -> Enter");
        await fileService.deleteFileFromS3(fileName);
        await dispatch({
            type: actionTypes.DELETE_FILE,
            data: { fileName },
        });
    };
};

/* export const listUserFiles = () => {
    log.debug("FileActions -> listUserFiles -> Enter");
    return async (dispatch) => {
        const files = await fileService.listUserFiles();
        await dispatch({
            type: actionTypes.GET_FILES,
            data: files
        });
    }
} */
