import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Icon from "components/Icon/Icon";
import PageSelect from "components/PageSelect";
import ProgressBar from "components/ProgressBar";
import Text from "components/Text";
import { CAMPAIGN_TYPE, LeadStatus } from "utils/constants";
import { NewCampaign } from "../../NewCampaign";
import SourceLead from "./SourceLead";
import "./SourceList.css";
import { LoadingOutlined } from "@ant-design/icons";
import * as campaignActions from "../../../../redux/Campaign/reducer";
import { useParams } from "react-router-dom";
import { Spin } from "antd";

const SourceList = ({ setShowSourceListModal, relevantLeads }) => {
    const user = useSelector((state) => state.user.user);
    const [currentPage, setCurrentPage] = useState(0);
    const [leadsByPage, setLeadsByPage] = useState(undefined);
    const [chunkSize, setChunkSize] = useState(5);
    const [pageButtons, setPageButtons] = useState([]);
    // calculate progress
    const foundMagicMoment = relevantLeads.filter((lead) => lead.status === LeadStatus.Active && lead.origin === "your list");
    const monitoring = relevantLeads.filter((lead) => lead.status === LeadStatus.Monitoring && lead.origin === "your list");
    const [loadingRelevantLeads, setLoadingRelevantLeads] = useState(false);
    const [progressBar, setProgressBar] = useState(0);
    const dispatch = useDispatch();
    // get campaign id from url
    const campaign = useSelector((state) => state.campaign.campaigns);
    const { id } = useParams();
    // filters
    const [filterFoundMagicMoment, setFilterFoundMagicMoment] = useState(0);
    const [filterByKeyword, setFilterByKeyword] = useState("");

    useEffect(() => {
        async function fetchData() {
            dispatch(campaignActions.getCampaignMonitoredById({ id, setLoadingRelevantLeads }));
        }
        fetchData();
    }, []);

    // progress bar
    useEffect(() => {
        setProgressBar(Math.round((foundMagicMoment.length / relevantLeads.length) * 100));
    }, [campaign]);

    useEffect(() => {
        setLeadsByPage(undefined);
        const sortedRelevantLeads = sortRelevantLeads();
        const chunks = splitToChunks(sortedRelevantLeads, chunkSize);
        setLeadsByPage(chunks);
    }, [relevantLeads]);

    useEffect(() => {
        if (leadsByPage) {
            setPageButtons(Array.from(Array(leadsByPage.length).keys()).map((n) => n + 1));
        }
    }, [leadsByPage]);

    const splitToChunks = (arr, chunkSize) => {
        const chunks = [];
        const chunkSizeInt = parseInt(chunkSize);
        for (let i = 0; i < arr.length; i += chunkSizeInt) {
            chunks.push(arr.slice(i, i + chunkSizeInt));
        }
        return chunks;
    };

    // cahnge pages when chunk size changes
    useEffect(() => {
        if (leadsByPage) {
            const chunks = splitToChunks(relevantLeads, chunkSize);
            setCurrentPage(0);
            setLeadsByPage(chunks);
        }
    }, [chunkSize]);

    //filtering
    useEffect(() => {
        if (filterFoundMagicMoment === 0 && relevantLeads.length > 0) {
            const chunks = splitToChunks(relevantLeads, chunkSize);
            setCurrentPage(0);
            setLeadsByPage(chunks);
        } else if (filterFoundMagicMoment === 1 && foundMagicMoment.length > 0) {
            const chunks = splitToChunks(foundMagicMoment, chunkSize);
            setCurrentPage(0);
            setLeadsByPage(chunks);
        } else if (filterFoundMagicMoment === 2 && monitoring.length > 0) {
            const chunks = splitToChunks(monitoring, chunkSize);
            setCurrentPage(0);
            setLeadsByPage(chunks);
        }

        if (typeof filterFoundMagicMoment === "string") {
            console.log("filterFoundMagicMoment", filterFoundMagicMoment);
            if (filterFoundMagicMoment === "sort-date-first") {
                const sorted = relevantLeads.sort((a, b) => {
                    // compare dates of format yyyy-mm-dd
                    const dateA = new Date(a.createdAt);
                    const dateB = new Date(b.createdAt);
                    return dateA - dateB;
                });
                const chunks = splitToChunks(sorted, chunkSize);
                setCurrentPage(0);
                setLeadsByPage(chunks);
                return;
            } else if (filterFoundMagicMoment === "sort-date-last") {
                const sorted = relevantLeads.sort((a, b) => {
                    // compare dates of format yyyy-mm-dd
                    const dateA = new Date(a.createdAt);
                    const dateB = new Date(b.createdAt);
                    return dateB - dateA;
                });
                const chunks = splitToChunks(sorted, chunkSize);
                setCurrentPage(0);
                setLeadsByPage(chunks);
                return;
            }
            const filtered = relevantLeads.filter(
                (lead) =>
                    lead &&
                    lead.user_data &&
                    lead.user_data.company_name &&
                    lead.user_data.company_name.toLowerCase().includes(filterFoundMagicMoment.toLowerCase())
            );
            if (filtered.length > 0) {
                const chunks = splitToChunks(filtered, chunkSize);
                setCurrentPage(0);
                setLeadsByPage(chunks);
            } else {
                setLeadsByPage(undefined);
            }
        }
    }, [filterFoundMagicMoment]);

    //filtering by keyword
    useEffect(() => {
        if (filterByKeyword.length > 0) {
            const filtered = relevantLeads.filter(
                (lead) =>
                    (lead.user_data &&
                        lead.user_data.company_name &&
                        lead.user_data.company_name.toLowerCase().includes(filterByKeyword.toLowerCase())) ||
                    (lead.name && lead.name.toLowerCase().includes(filterByKeyword.toLowerCase())) ||
                    (lead.email && lead.email.toLowerCase().includes(filterByKeyword.toLowerCase())) ||
                    (lead.title && lead.title.toLowerCase().includes(filterByKeyword.toLowerCase())) ||
                    (lead.createdAt && lead.createdAt.toLowerCase().includes(filterByKeyword.toLowerCase()))
            );
            if (filtered.length > 0) {
                const chunks = splitToChunks(filtered, chunkSize);
                setCurrentPage(0);
                setLeadsByPage(chunks);
            } else {
                setLeadsByPage(undefined);
            }
        } else {
            const chunks = splitToChunks(relevantLeads, chunkSize);
            setCurrentPage(0);
            setLeadsByPage(chunks);
        }
    }, [filterByKeyword]);

    const handleModalClose = () => {
        setShowSourceListModal(false);
    };

    const sortRelevantLeads = () => {
        const sorted = relevantLeads.sort((a, b) => {
            // if name is an empty string put it at the end
            if (a.name === "") {
                return 1;
            }
            if (b.name === "") {
                return -1;
            }

            // if name is not undefined, sort by name
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return sorted;
    };

    if (loadingRelevantLeads) {
        return <LoadingSourceList handleModalClose={handleModalClose} />;
    }

    if (relevantLeads.length > 0) {
        return (
            <>
                <div>
                    <div className="source-list-background">
                        <div className="source-list-container">
                            <div className="source-list-header">
                                <div className="source-list-header-title">
                                    <h2>{user.name && user.name.split(" ")[0]}'s magic moments Source list</h2>
                                </div>
                                <div className="found-magic-moments-container">
                                    <label className="source-list-header-label">{`Found magic moments: ${foundMagicMoment.length}/${
                                        monitoring.length + foundMagicMoment.length
                                    }`}</label>
                                    <ProgressBar progress={progressBar} />
                                </div>
                                <div>
                                    <NewCampaign version={"upload more"} />
                                </div>
                                <div className="close-source-list-button-container">
                                    <button className="close-source-list-button" onClick={handleModalClose}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path
                                                d="M6.00006 4.7274L10.4551 0.2724L11.7277 1.545L7.27266 6L11.7277 10.455L10.4551 11.7276L6.00006 7.2726L1.54506 11.7276L0.272461 10.455L4.72746 6L0.272461 1.545L1.54506 0.2724L6.00006 4.7274Z"
                                                fill="black"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="filters-container">
                                <input onChange={(e) => setFilterByKeyword(e.target.value)} placeholder="Filter by keywords" />
                                <input onChange={(e) => setFilterFoundMagicMoment(e.target.value)} placeholder="Filter by company" />
                                <select
                                    className="source-list-chunk-size-select"
                                    onChange={(e) => setFilterFoundMagicMoment(parseInt(e.target.value))}
                                >
                                    <option value={0}>All</option>
                                    <option value={1}>Found sales trigger</option>
                                    <option value={2}>Monitoring</option>
                                </select>
                                <select className="source-list-chunk-size-select" onChange={(e) => setFilterFoundMagicMoment(e.target.value)}>
                                    <option value={null}>Filter by Created</option>
                                    <option value={"sort-date-first"}>Created First</option>
                                    <option value={"sort-date-last"}>Created Last</option>
                                </select>
                            </div>

                            <div className="source-lead-row">
                                <div className={`source-lead-row-item title`}>
                                    <Text className="source-lead-row-item title">Name</Text>
                                </div>
                                <div className={`source-lead-row-item title`}>
                                    <Text className="source-lead-row-item title">Title</Text>
                                </div>
                                <div className={`source-lead-row-item title`}>
                                    <Text className="source-lead-row-item title">Company</Text>
                                </div>
                                <div className={`source-lead-row-item title`}>
                                    <Text className="source-lead-row-item title">Email</Text>
                                </div>
                                <div className={`source-lead-row-item title`}>
                                    <Text className="source-lead-row-item title">Uploaded at</Text>
                                </div>
                                <div className={`source-lead-row-item title`}>
                                    <Text className="source-lead-row-item title">Action</Text>
                                </div>
                            </div>
                            {leadsByPage && leadsByPage[currentPage]?.map((lead) => <SourceLead lead={lead} key={lead.id} />)}
                            <footer className="source-list-footer">
                                <div>
                                    <label className="source-list-chunk-size-label">Leads per page:</label>
                                    <select className="source-list-chunk-size-select" onChange={(e) => setChunkSize(e.target.value)}>
                                        <option value={5}>5</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                                <div className="page-select">
                                    <PageSelect setPage={setCurrentPage} buttons={pageButtons} />
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return <EmptySourceList user={user} handleModalClose={handleModalClose} />;
    }
};

const EmptySourceList = ({ user, handleModalClose }) => {
    return (
        <>
            <div>
                <div className="source-list-background">
                    <div className="source-list-container">
                        <div className="source-list-header">
                            <div className="source-list-header-title">
                                <h2>{user.name && user.name.split(" ")[0]}'s magic moments Source list</h2>
                            </div>
                            <div className="found-magic-moments-container"></div>
                            <div className="close-source-list-button-container">
                                <button className="close-source-list-button" onClick={handleModalClose}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path
                                            d="M6.00006 4.7274L10.4551 0.2724L11.7277 1.545L7.27266 6L11.7277 10.455L10.4551 11.7276L6.00006 7.2726L1.54506 11.7276L0.272461 10.455L4.72746 6L0.272461 1.545L1.54506 0.2724L6.00006 4.7274Z"
                                            fill="black"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="empty-source-list">
                            <Icon type={CAMPAIGN_TYPE.AUTO_DISCOVERY} />
                            <p className="samplead-body  text-center">
                                This is your Magic moments source list Upload a list of prospects and <br />
                                Samplead will monitor their activity daily and let you know once it find out the <br />
                                perfect moment to reach out
                            </p>

                            <NewCampaign version={"upload more"} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const LoadingSourceList = ({ handleModalClose }) => {
    return (
        <>
            <div>
                <div className="source-list-background">
                    <div className="source-list-container">
                        <div className="close-source-list-button-container">
                            <button className="close-source-list-button" onClick={handleModalClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <path
                                        d="M6.00006 4.7274L10.4551 0.2724L11.7277 1.545L7.27266 6L11.7277 10.455L10.4551 11.7276L6.00006 7.2726L1.54506 11.7276L0.272461 10.455L4.72746 6L0.272461 1.545L1.54506 0.2724L6.00006 4.7274Z"
                                        fill="black"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            className="source-list-header"
                        >
                            <LoadingOutlined
                                style={{
                                    fontSize: "30px",
                                    color: "#a53aff",
                                }}
                            />
                        </div>
                        <div className="empty-source-list"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SourceList;
