import { Tag } from "antd";
import ColorHash from 'color-hash'

const colorHash = new ColorHash({lightness: 0.5, saturation: 0.5});

const BadgeLink = ({ badge }) => {

    const badgeColor = colorHash.hex(badge.name);

    const addUrlPrefix = (url) => {
        if (url.startsWith("www")) {
            return "https://" + url;
        }
        return url;
    };

    return (
        <Tag color={badgeColor} onClick={() => window.open(addUrlPrefix(badge.url))}>
            <div>{badge.name}</div>
        </Tag>
    );
};

export default BadgeLink;
