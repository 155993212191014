import React, { useState } from "react";
import "./Tooltip.css";

export const TOOLTIP_POSITION = {
    TOP: "top",
    TOP_LEFT: "top-left",
    TOP_RIGHT: "top-right",
    BOTTOM: "bottom",
    LEFT: "left",
    RIGHT: "right",
};

const Tooltip = ({ icon, message, position, children }) => {
    const [displayTooltip, setDisplayTooltip] = useState(false);

    return (
        <div
            className={`tooltip ${icon || ""}`}
            onMouseOver={() => {
                // logger('onMouseOver');
                setDisplayTooltip(true);
            }}
            onMouseOut={() => {
                // logger('onMouseOut');
                setDisplayTooltip(false);
            }}
        >
            {displayTooltip && (
                <div className={`tooltip-bubble tooltip-${position}`}>
                    {icon && <div className={`${icon}`} />}
                    <div className="tooltip-message">{message}</div>
                </div>
            )}
            {children}
        </div>
    );
};

export default Tooltip;
