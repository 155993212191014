const path = "redux/Sequence";
export const LOAD_SEQUENCE = `${path}/LOAD_SEQUENCE`;
export const RESET_SEQUENCE = `${path}/RESET_SEQUENCE`;
export const GENERATE_SEQUENCE = `${path}/GENERATE_SEQUENCE`;
export const ADD_STEP = `${path}/ADD_STEP`;
export const REMOVE_STEP = `${path}/REMOVE_STEP`;
export const UPDATE_STEP = `${path}/UPDATE_STEP`;
export const CHANGE_STEPS_TO_UPDATE = `${path}/CHANGE_STEPS_TO_UPDATE`;
export const CHANGE_SUBJECT = `${path}/CHANGE_SUBJECT`;
export const CHANGE_MODIFY_TO = `${path}/CHANGE_MODIFY_TO`;
export const CHANGE_FREE_TEXT = `${path}/CHANGE_FREE_TEXT`;
export const REPHRASE_STEP = `${path}/REPHRASE_STEP`;
export const TOGGLE_SHOW_STEP = `${path}/TOGGLE_SHOW_STEP`;
export const TOGGLE_SHOW_ALL_STEPS = `${path}/TOGGLE_SHOW_ALL_STEPS`;
export const SET_CURRENTLY_EDITING = `${path}/SET_CURRENTLY_EDITING`;
export const SET_CURSOR_POSITION = `${path}/SET_CURSOR_POSITION`;
export const SET_STEPS = `${path}/SET_STEPS`;
export const SAVE_SEQUENCE = `${path}/SAVE_SEQUENCE`;
export const LOAD_GENERATED_SEQUENCE = `${path}LOAD_GENERATED_SEQUENCE`;
export const SET_UNSAVED_CHANGES = `${path}/SET_UNSAVED_CHANGES`;
export const SET_STEP_TIME_DELAY = `${path}/SET_STEP_TIME_DELAY`;
export const GET_REPHRASED_STEP = `${path}/GET_REPHRASED_STEP`;
export const CHANGE_MESSAGE_INDEX_FOR_STEP = `${path}/CHANGE_MESSAGE_INDEX_FOR_STEP`;
export const REPHRASE_WITH_CUSTOM_TEXT = `${path}/REPHRASE_WITH_CUSTOM_TEXT`;
export const SET_EMAIL_OR_LINKEDIN = `${path}/SET_EMAIL_OR_LINKEDIN`;
