import { createSlice } from "@reduxjs/toolkit";
import store from "redux/store";

const initialState = {
    noOutreachMailboxes: false,
};

const noOutreachMailboxSlice = createSlice({
    name: "noOutreachMailbox",
    initialState: initialState,
    reducers: {
        setNoOutreachMailboxes: (state, action) => {
            state.noOutreachMailboxes = action.payload;
        }
    }
});

export const { setNoOutreachMailboxes } = noOutreachMailboxSlice.actions;
export default noOutreachMailboxSlice.reducer;