import "./ExportConfirmation.css";
import Button, { BUTTON_VARIANT } from "components/Button/Button";
import { CSVLink } from "react-csv";
import Modal from "../Modal";

const csvHeaders = [
    { label: "profile_link", key: "url" },
    { label: "first_name", key: "first_name" },
    { label: "last_name", key: "last_name" },
    { label: "job_title", key: "title" },
    { label: "company_name", key: "company_name" },
    { label: "Company Headcount", key: "company_headcount" },
    { label: "Company HQ", key: "company_location" },
    { label: "Industry", key: "industry" },
    { label: "Badges Links", key: "badges_links" },
    { label: "Badges", key: "badges" },
    { label: "Phone", key: "phone" },
    { label: "email", key: "email" },
    { label: "Tailored Message", key: "message" },
    { label: "Value Proposition", key: "message_2" },
    { label: "samplead_id", key: "id" },
    { label: "campaign_id", key: "campaign_id" },
];

const ExportConfirmation = ({ leads, campaignName, visible, onClose, onDownload }) => {
    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            console.log(e);
            return false;
        }
        return true;
    }

    const csvData = leads.map((lead) => {
        const parts = lead?.name?.split(" ");
        // const parsedUserData = typeof lead.user_data === "object" ||
        // console.log(lead.user_data);

        //  lead.user_data && isJsonString(lead.user_data) ? JSON.parse(lead.user_data) : null;
        // let company_data = lead.summary && isJsonString(lead.summary) ? JSON.parse(lead.summary) : null;
        let company_data = lead?.summary;

        if (company_data === null && lead.user_data !== null && isJsonString(lead.user_data)) {
            console.log("Take from user data");
            const user_data = JSON.parse(lead.user_data);
            const company_data_details = "company_data" in user_data ? user_data.company_data : null;
            company_data = {
                company_name: user_data?.company_name,
                company_headcount: company_data_details?.company_size?.company_size_on_linkedin,
                industry: company_data_details?.industry,
                company_location: company_data_details?.hq?.country,
            };
        }

        // backwards comparability for emiliy's campaign
        //remove when campaign is over
        if (lead?.company_data) {
            company_data = lead?.company_data;
        }
        // console.log(company_data);

        if (lead)
            return {
                first_name: parts[0] || "",
                last_name: parts.length > 1 ? parts?.slice(1)?.join(" ") : "",
                title: lead.title,
                message: lead.message || "",
                message_2: lead.message_2 || "",
                url: lead.url,
                badges: lead?.badges?.map((badge) => badge.name).toString(),
                badges_links: lead?.badges?.map((badge) => badge.url).toString(),
                phone: "phone" in lead ? lead.phone : null,
                email: "email" in lead ? lead.email : null,

                company_name: lead?.user_data?.company_name,
                company_headcount: lead?.user_data?.company_data?.company_size_on_linkedin,
                company_location: lead.user_data?.company_data?.hq?.country,
                industry: lead?.user_data?.company_data?.industry,
                id: lead.id,
                campaign_id: lead.campaignId,
            };
    });

    const filename = (campaignName || "exported").replace(/[^a-z0-9]/gi, "");

    return (
        <Modal isModalOpen={visible} closeModal={onClose}>
            <div className="export-confirmation">
                <div className="export-confirmation__content">
                    <h2 className={"samplead-body--large"}>List exported</h2>
                    <p className={"samplead-body text-center break-line"}>
                        {`Your list was exported! you may download it and start creating
                            Hot leads!`}
                    </p>
                    <Button variant={BUTTON_VARIANT.PRIMARY}>
                        <CSVLink onClick={() => onDownload(leads)} data={csvData} headers={csvHeaders} filename={`${filename}.csv`} target="_blank">
                            Download list
                        </CSVLink>
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ExportConfirmation;
