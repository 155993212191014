import React, { useEffect, useState } from "react";

import Button, { BUTTON_VARIANT } from "components/Button/Button";
import ProgressBar from "components/ProgressBar";
import "./innerComponents.css";

export const CampaignProgressBar = ({ progress, uploadErrorMessage, setUploadErrorMessage, setIsCreatingCampaign }) => {
    const [currentProgress, setProgress] = useState(0);

    const numberOfLeads = progress;
    const timePerLead = 3;
    const totalTime = 3 * progress;
    const minutes = Math.floor(totalTime / 60);
    const timeMessage = minutes > 1 ? ` around ${minutes} minutes...` : `less than a minute...`;
    const progressByNum = 1 / numberOfLeads - (1 / numberOfLeads) * 0.1;

    useEffect(() => {
        const intervalId = setInterval(() => {
            setProgress((x) => (x < 100 ? x + progressByNum : x));
        }, 10);

        return () => clearInterval(intervalId);
    }, []);

    const handleModalClose = () => {
        setIsCreatingCampaign(false);
        setUploadErrorMessage("");
    };

    return (
        <>
            {uploadErrorMessage.length === 0 ? (
                <main className={`campaigns__main creating-lead-auto mini-progress-bar`}>
                    <div className={`campaigns__main-content creating-lead-auto`}>
                        <div className="progress-bar-label">We are creating your list! it should take {timeMessage}</div>
                        <ProgressBar progress={currentProgress} />
                    </div>
                </main>
            ) : (
                <UploadErrorMessageModal uploadErrorMessage={uploadErrorMessage} handleModalClose={handleModalClose} />
            )}
        </>
    );
};

const UploadErrorMessageModal = ({ uploadErrorMessage, handleModalClose }) => {
    return (
        <div className="campaigns__main creating-lead-auto mini-progress-bar">
            <h3>Upload Failed</h3>
            <h4 className="samplead-body text-center">{uploadErrorMessage}</h4>
            <Button onClick={() => handleModalClose()} variant={BUTTON_VARIANT.PRIMARY}>
                Ok
            </Button>
        </div>
    );
};
