const path = "redux/User";
export const USER_LOGGED_IN = `${path}/USER_LOGGED_IN`;
export const USER_LOGGED_OUT = `${path}/USER_LOGGED_OUT`;
export const CLEAR_LAST_PATH = `${path}/CLEAR_LAST_PATH`;
export const SET_USERS = `${path}/SET_USERS`;
export const SET_ACCOUNT_USERS = `${path}/SET_ACCOUNT_USERS`;
export const UPDATE_ACCOUNT_USER = `${path}/UPDATE_ACCOUNT_USER`;
export const LOADING_ACCOUNT_USER_UPDATE = `${path}/LOADING_ACCOUNT_USER_UPDATE`;
export const SET_USER_SUBSCRIBED = `${path}/SET_USER_SUBSCRIBED`;
export const SET_NEW_USER_TO_OLD = `${path}/SET_NEW_USER_TO_OLD`;
