import * as actionTypes from "./actionTypes";
import * as userService from "./service";
import { removeStorageItem, setStorageItem } from "utils/storageUtils";
import { setAccessToken } from "utils/auth";
import { resetCampaigns } from "redux/Expandi/reducer";

import env from "config/site.config";

const log = require("loglevel").getLogger("UserActions");
log.setLevel("debug");

export const signIn = (credentials) => {
    return async (dispatch) => {
        log.debug("UserActions -> signIn -> Enter");
        try {
            const { user } = await userService.signIn(credentials);
            setAccessToken(user.token, user.admin ? user.token : null);
            setStorageItem(env.REACT_APP_STORAGE_SIGNED_USER_KEY, user, true);
            if (user.admin) setStorageItem(env.REACT_APP_STORAGE_SIGNED_ADMIN_KEY, user, true);

            await dispatch({
                type: actionTypes.USER_LOGGED_IN,
                data: { user: user, adminUser: user.admin ? user : null },
            });
        } catch (e) {
            console.log("everything went bad");
            console.log(e);
        }
    };
};

export const signOut = () => {
    return async (dispatch) => {
        log.debug("UserActions -> signOut -> Enter");

        setAccessToken(null);
        removeStorageItem(env.REACT_APP_STORAGE_SIGNED_USER_KEY);
        removeStorageItem(env.REACT_APP_STORAGE_SIGNED_ADMIN_KEY);

        await dispatch({
            type: actionTypes.USER_LOGGED_OUT,
        });
    };
};

export const clearLastpath = () => {
    return {
        type: actionTypes.CLEAR_LAST_PATH,
    };
};

export const setLoggedInUser = (user, adminUser = "") => {
    return async (dispatch, getState) => {
        log.debug("UserActions -> setLoggedInUser -> Enter");
        setAccessToken(user.token, adminUser ? adminUser.token : null);
        setStorageItem(env.REACT_APP_STORAGE_SIGNED_USER_KEY, user, true);
        if (adminUser) setStorageItem(env.REACT_APP_STORAGE_SIGNED_ADMIN_KEY, adminUser, true);
        dispatch(resetCampaigns());

        await dispatch({
            type: actionTypes.USER_LOGGED_IN,
            data: { user, adminUser },
        });
    };
};

export const getUserStats = (sd, ed, callback) => {
    return async (dispatch) => {
        log.debug("UserActions -> getUserStats -> Enter");
        const userStats = await userService.getUserStats(sd, ed);
        callback(userStats);
    };
};

export const setUsers = (users) => {
    return {
        type: actionTypes.SET_USERS,
        data: users,
    };
};

export const getAccountUsers = (callback = () => {}) => {
    return async (dispatch) => {
        const users = await userService.getAccountUsers();

        await dispatch({
            type: actionTypes.SET_ACCOUNT_USERS,
            data: users,
        });
        callback();
    };
};

export const updateAccountFilteredUser = (filteredOwnerId, isShown, callback = () => {}) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.LOADING_ACCOUNT_USER_UPDATE,
            data: { filteredOwnerId },
        });
        const response = await userService.updateAccountFilteredUser(filteredOwnerId, isShown);

        await dispatch({
            type: actionTypes.UPDATE_ACCOUNT_USER,
            data: { filteredOwnerId, isShown },
        });
        callback();
    };
};

export const cancelUserSubscription = (callback) => {
    return async (dispatch) => {
        log.debug("UserActions -> cancelUserSubscription -> Enter");
        const res = await userService.cancelUserSubscription();
        callback(res);
    };
};

export const setUserSubscribed = () => {
    return {
        type: actionTypes.SET_USER_SUBSCRIBED,
    };
};

export const setNewUserToOld = () => {
    return {
        type: actionTypes.SET_NEW_USER_TO_OLD,
    };
};
