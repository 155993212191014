import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { CAMPAIGN_TYPE, DEFAULT_CAMPAIGN_TITLE, PATH_ALL_CAMPAIGNS } from "utils/constants";
import Icon from "components/Icon/Icon";
import { NewSuggestionsButton } from "./NewSuggestionsButton";
import { CampaignTitle } from "./CampaignTitle";
import Button, { BUTTON_VARIANT } from "components/Button/Button";
import { NewCampaign } from "../NewCampaign";

const CampaignHeader = (props) => {
    const { campaign, onTitleUpdate, onSuggestionsClick, suggestedLeadsCount, leadsCount, setShowSourceListModal } = props;
    const isNewCampaign = campaign.id == null;

    const navigate = useNavigate();

    return (
        <header className="campaigns__header">
            <div className={"campaigns__header-top"}>
                {/* <Link to={PATH_ALL_CAMPAIGNS} className="samplead-body--bold">{`< Back to all campaigns`}</Link> */}
            </div>

            <div className={"campaigns__header-bottom"}>
                <div className={"campaigns__header-bottom-left"}>
                    <Icon type={campaign.type || "empty"} />
                    <CampaignTitle
                        editable={isNewCampaign ? true : false}
                        editMode={isNewCampaign}
                        title={campaign.title || DEFAULT_CAMPAIGN_TITLE}
                        onTitleUpdate={onTitleUpdate}
                    />
                    {leadsCount && <h5 className="campaigns__header-leads-count samplead-menu-header">{leadsCount}</h5>}
                </div>
                {!isNewCampaign && (
                    <div className={"campaigns__header-bottom-right"}>
                        {campaign.type === CAMPAIGN_TYPE.AUTO_DISCOVERY && campaign?.id !== "all" && (
                            <Button variant={BUTTON_VARIANT.LINK} onClick={() => setShowSourceListModal(true)}>
                                View source list
                            </Button>
                        )}
                        <NewCampaign version={"upload more"} />
                        {onSuggestionsClick && campaign.type === CAMPAIGN_TYPE.AUTO_DISCOVERY && (
                            <NewSuggestionsButton suggestedLeadsCount={suggestedLeadsCount} onClick={onSuggestionsClick} />
                        )}
                    </div>
                )}
            </div>
        </header>
    );
};

export default CampaignHeader;
