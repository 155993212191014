import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as repliesActions from "../../redux/Replies/actions.js";
import { LeadList } from "routes/Campaign/inner-components/LeadList.jsx";
import { Card, Empty } from "antd";
import Tabs from "components/Tabs/Tabs.jsx";
import "./Replies.css";
import { BarLoader } from "react-spinners";
import { BsCheckAll, BsCheck } from "react-icons/bs";
import { setTabReplies } from "redux/Replies/reducer.js";

const Replies = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const tabsCaptions = ["New Replies", "Interested", "Booked", "Old Replies"];
    const replies = useSelector((state) => state.replies); // Add the correct path to replies in your state

    const tabReplies = useSelector((state) => state.replies.tabReplies); // Add the correct path to tabReplies in your state
    const focusedLead = useSelector((state) => state.replies.focusedLead); // Add the correct path to selectedLead in your state

    useEffect(() => {
        dispatch(repliesActions.fetchReplies());
    }, [dispatch]);

    useEffect(() => {
        if (replies?.replies) {
            console.log("setting leads");
            console.log(replies);
        }
    }, [replies]);

    const onTabChange = (index) => {
        console.log("index", index);
        if (!replies?.replies) return;
        console.log("replies", replies);
        setActiveTab(index);

        const newReplies = replies?.replies?.filter((leadObj) => leadObj?.repliesData?.messages[0]?.send_by_id === null);
        const instrested = replies?.replies?.filter((leadObj) => leadObj?.repliesData?.status === 3);
        const booked = replies?.replies?.filter((leadObj) => leadObj?.repliesData?.status === 4);
        const oldReplies = replies?.replies?.filter((leadObj) => leadObj?.repliesData?.messages[0]?.send_by_id !== null);

        switch (index) {
            case 0:
                console.log("new", newReplies);
                dispatch(setTabReplies(newReplies));
                break;
            case 1:
                console.log("instrested", instrested);
                dispatch(setTabReplies(instrested));
                break;
            case 2:
                console.log("booked", booked);
                dispatch(setTabReplies(booked));
                break;
            case 3:
                console.log("old", oldReplies);
                dispatch(setTabReplies(oldReplies));
                break;
            default:
                break;
        }
    };

    return (
        <div>
            {replies?.loading ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100vh",
                        color: "#a53aff",
                        fontWeight: "bold",
                    }}
                >
                    <h3>Loading Replies</h3>
                    <div style={{ margin: "20px" }}>
                        <BarLoader color={"#a53aff"} loading={true} />
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Tabs activeTabIndex={activeTab} captions={tabsCaptions} onActiveTabChange={onTabChange}>
                        <div></div>
                    </Tabs>
                    {tabReplies && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    // push to top
                                    position: "sticky",
                                    top: "0",
                                }}
                            >
                                <LeadList
                                    key={activeTab}
                                    leads={tabReplies}
                                    campaignId={null} // Dummy campaign ID
                                    type="dummyType" // Dummy type
                                    onRephraseLead={() => {}} // Dummy function for onRephraseLead
                                    onRemoveLead={() => {}} // Dummy function for onRemoveLead
                                    onRestoreLead={() => {}} // Dummy function for onRestoreLead
                                    onExpandCollapse={() => {}} // Dummy function for onExpandCollapse
                                    onEditLeadMessage={() => {}} // Dummy function for onEditLeadMessage
                                    emptyListComponent={EmptyData} // Dummy EmptyListComponent
                                    exported={false} // Dummy value for exported
                                    setSpecificExports={() => {}} // Dummy function for setSpecificExports
                                    specificExports={[]} // Empty array for specificExports
                                    isActiveExported={false} // Dummy value for isActiveExported
                                    isLoadingCampaign={false} // Dummy value for isLoadingCampaign
                                />
                            </div>
                            <div>{tabReplies?.length > 0 && focusedLead && <Messenger messages={focusedLead?.repliesData?.messages} />}</div>
                        </div>
                    )}
                </div>
            )}
            {/*  display messenger for clicked lead */}
        </div>
    );
};

const Messenger = ({ messages }) => {
    const focusedLead = useSelector((state) => state.replies.focusedLead); // Add the correct path to selectedLead in your state
    return (
        <Card className="messenger" title={`Chat with ${focusedLead?.name}`}>
            {messages
                ?.slice()
                .reverse()
                .map((messageObj, i) => {
                    return <Message key={i} messageObj={messageObj} />;
                })}
        </Card>
    );
};

const Message = ({ messageObj }) => {
    const focusedLead = useSelector((state) => state.replies.focusedLead); // Add the correct path to selectedLead in your state
    const messageBody = messageObj?.body;
    const timeSent = messageObj?.created;
    const status = messageObj?.status;
    const receivedTime = messageObj?.received_datetime;
    const sender = messageObj?.send_by_id;
    const sentBy = sender === null ? "Contact" : "You";

    return (
        <div className={`message ${sentBy === "You" ? "user-message" : "contact-message"}`}>
            <div className="message-header">
                {<div>{sentBy === "You" ? "" : focusedLead?.name}</div>}
                <div>{new Date(timeSent).toLocaleTimeString()}</div>
            </div>
            {messageBody && <div className="message-body">{messageBody}</div>}
            {sentBy === "You" && (
                <div className="message-status">
                    {receivedTime ? (
                        <div
                            className="message-status"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "end",
                                alignItems: "center",
                            }}
                        >
                            <BsCheckAll size={"2em"} style={{ color: "#ffffff" }} />
                            <div>{new Date(receivedTime).toLocaleTimeString()}</div>
                        </div>
                    ) : (
                        <div
                            className="message-status"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "end",
                                alignItems: "center",
                            }}
                        >
                            <BsCheck size={"2em"} style={{ color: "#ffffff" }} />
                            <div>Sent</div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const EmptyData = () => {
    return (
        <div style={{ margin: "20px" }}>
            <Card bordered={false}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
            </Card>
        </div>
    );
};

export default Replies;
