import axios from "axios";
import env from "config/site.config";

const log = require("loglevel").getLogger("GeneralService");
log.setLevel("debug");

axios.interceptors.request.use((request) => {
    log.info("Starting Request", request.url);
    return request;
});

axios.interceptors.response.use((response) => {
    // console.log('Response:', response)
    return response;
});

export const getSuggestions = async (route, data) => {
    log.debug("GeneralService -> getSuggestions -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/${route}/autocomplete`, data)
        .then(async (response) => {
            const res = response.data;
            log.debug("GeneralService -> getSuggestions -> response: " + JSON.stringify(res));
            return res.data;
        })
        .catch((response) => {
            log.debug("GeneralService -> getSuggestions -> error: ");
            log.debug(response);
            return null;
        });
};
