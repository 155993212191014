import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";

import { DISABLE_ERRORS } from "utils/constants";
import NavButtons from "components/NavButtons/NavButtons";
import * as fileActions from "redux/File/actions";
import { ALLOWED_FILE_TYPES } from "utils/constants";

const MarketingMaterialsPage = ({ handleNavClick }) => {
    const dispatch = useDispatch();
    let containerRef = useRef(null);
    const fileData = useSelector((state) => state.file.files);

    const [errorMsg, setErrorMsg] = useState("");
    const [files, setFiles] = useState(useSelector((state) => state.file.files));
    const [signedUploadUrl, setSignedUploadUrl] = useState([]);

    // when settings in redux store updates, set userData to the new store values
    useEffect(() => {
        setFiles(fileData);
    }, [fileData]);

    const handleChange = async (file) => {
        try {
            const file_data = Array.from(file);

            let new_signedUploadUrl = await fileActions.getPresignedUploadUrl(file_data[0].name, file_data[0].type);
            setSignedUploadUrl([...signedUploadUrl, new_signedUploadUrl]);

            // PUT file to S3
            dispatch(fileActions.uploadFileToS3(file_data[0], new_signedUploadUrl));
            setFiles([...files, ...Array.from(file)]);
        } catch (error) {
            console.log(error);
            setErrorMsg("Error uploading file");
        }
    };

    const handleDeleteFile = async (file_name) => {
        console.log("handleDeleteFile", file_name);
        // prompt user to confirm delete
        if (window.confirm("Are you sure you want to delete this file?")) {
            try {
                dispatch(fileActions.deleteFileFromS3(file_name));
                setFiles(files.filter((file) => file.Key !== file_name));
            } catch (error) {
                console.log(error);
            }
        } else {
            return;
        }
    };

    const next_clicked = (action) => {
        setErrorMsg(false);
        let hasError = false;
        // validate input
        if (action === "next") {
            if (files.length === 0) {
                setErrorMsg("Please upload a file");
                hasError = true;
            }
        }

        if (hasError && !DISABLE_ERRORS) return;
        let response_obj = [
            {
                key: "file",
                value: files,
            },
        ];
        handleNavClick(action, response_obj);
    };

    console.log(files);
    return (
        <>
            <div className="form-container-file" ref={containerRef}>
                <div className="input-header">
                    <div className="input-header-text">
                        <p>Marketing Materials</p>
                    </div>
                </div>
                <div className={`subheader centered`}>
                    <div className="subheader-text">
                        <p>Specific marketing materials to the product you are promoting</p>
                    </div>
                </div>
                <FileUploader
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    types={ALLOWED_FILE_TYPES}
                    label="Click to browse or drag and drop your files"
                    classes="file-uploader-box"
                />
                <div>
                    {files ? (
                        <div className="uploaded-file-list-container">
                            {files.map((file, index) => {
                                return (
                                    <>
                                        <p key={index} className="uploaded-files">
                                            {file.name}
                                            &nbsp;&nbsp;
                                            <span className="delete-file-button" onClick={() => handleDeleteFile(file.name)}>
                                                X
                                            </span>
                                        </p>
                                    </>
                                );
                            })}
                        </div>
                    ) : (
                        "no files uploaded yet"
                    )}
                </div>
                <div className="input-error-msg">{errorMsg && <div className="error-msg">{errorMsg}</div>}</div>
            </div>
            <NavButtons handleNavClick={next_clicked} />
        </>
    );
};

export default MarketingMaterialsPage;
