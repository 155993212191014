import React from "react";
import "./Tabs.css";

export const TabButton = ({ isActive, onClick, children }) => (
    <button className={isActive ? "active" : ""} onClick={onClick}>
        {children}
    </button>
);

export const TabButtonsContainer = ({ children }) => {
    return (
        <div className="tabs__container">
            <div className="tab-buttons">{children}</div>
            <div className="tab-divider" />
        </div>
    );
};

const Tabs = ({ activeTabIndex, onActiveTabChange, captions, children }) => {
    // const activeTab = tabs[activeTabIndex];

    return (
        <div style={{ minHeight: "70vh" }}>
            <TabButtonsContainer>
                {captions.map((caption, index) => (
                    <TabButton key={index} isActive={activeTabIndex === index} onClick={() => onActiveTabChange(index)}>
                        {caption}
                    </TabButton>
                ))}
            </TabButtonsContainer>
            {children[activeTabIndex]}
        </div>
    );
};

export const Tab = ({ children }) => <>{children}</>;

// class Tabs extends React.Component {
//   handleChangeTab = (tab) => {
//     this.props.setActiveTab(tab);
//   };

//   render() {
//     const content = React.Children.toArray(this.props.children)[
//       this.props.activeTab
//     ];

//     const buttons = React.Children.map(
//       this.props.children,
//       (x) => x.props.label
//     );

//     console.log(buttons);
//     return (
//       <div>
//         <TabButtons
//           activeTab={this.state.activeTab}
//           buttons={buttons}
//           changeTab={this.handleChangeTab}
//         />
//         <div>{content}</div>
//       </div>
//     );
//   }
// }

export default Tabs;
