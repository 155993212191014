import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import ErrorBoundary from "utils/ErrorBoundary";
import { formatDate } from "utils/utils";
import DashboardStatistics from "./inner-components/DashboardStatistics";
import DashboardMain from "./inner-components/DashboardMain";
import DashboardHeader from "./inner-components/DashboardHeader";
import * as userActions from "redux/User/actions";
import * as campaignActions from "redux/Campaign/reducer";
import * as campaignApi from "redux/Campaign/api";
import Modal from "components/Modal";
import Button from "components/Button/Button";
import { PATH_ALL_CAMPAIGNS, PATH_ONBOARDING_PAGE } from "utils/constants";
import { isNullOrEmptyArray } from "utils/general";
import { getStorageItem } from "utils/storageUtils";
import Spinner from "components/Spinner";
import "./Dashboard.css";
import env from "config/site.config";

function Dashboard() {
    const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(true);
    const [isLoadingStatistics, setIsLoadingStatistics] = useState(true);
    const [userStatistics, setUserStatistics] = useState({});
    const user = useSelector((state) => state.user.user);
    const campaignStore = useSelector((state) => state.campaign);
    const lastPath = useSelector((state) => state.user.lastPath);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    let params = new URLSearchParams(location.search); // query string has onboarding=complete after onboarding is completed
    const [isOnboardingCompleteModalOpen, setIsOnboardingCompleteModalOpen] = useState(params.get("onboarding") === "complete");

    const fetchUserStatistics = async (sd, ed) => {
        try {
            dispatch(
                userActions.getUserStats(sd, ed, (userStats) => {
                    setUserStatistics(userStats);
                    console.log("userStatistics: ", userStats);
                })
            );
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (lastPath && lastPath !== location.pathname) {
            console.log("lastPath in dashboard", lastPath);
            dispatch(userActions.clearLastpath());
            navigate(lastPath);
            return;
        }

        setIsLoadingCampaigns(true);
        dispatch(campaignActions.getCampaigns()).then(() => {
            setIsLoadingCampaigns(false);
        });

        const dates = {
            startDate: formatDate(new Date(new Date().setDate(new Date().getDate() - 7))),
            endDate: formatDate(new Date()),
        };
        dispatch(
            userActions.getUserStats(dates.startDate, dates.endDate, (userStats) => {
                setUserStatistics(userStats);
                console.log("userStatistics: ", userStats);
            })
        ).then(() => {
            setIsLoadingStatistics(false);
        });
    }, [lastPath, location.pathname, user]);

    const deleteCampaign = async (campaignId) => {
        console.log(campaignId);
        campaignApi.deleteCampaign(campaignId).then((res) => {
            dispatch(campaignActions.getCampaigns());
        });
    };

    const handleDateRangeSelected = async (dateRange) => {
        const dates = {
            startDate: formatDate(dateRange.startDate),
            endDate: formatDate(dateRange.endDate),
        };
        await fetchUserStatistics(dates.startDate, dates.endDate);
    };

    const gotoDiscovery = (id) => {
        navigate(`${PATH_ALL_CAMPAIGNS}/${id}`);
    };

    const discoveryCampaign = campaignStore.campaigns ? campaignStore.campaigns.find((c) => c.type === "auto-discovery") : null;

    return (
        <ErrorBoundary>
            <Modal isModalOpen={isOnboardingCompleteModalOpen} onClose={() => setIsOnboardingCompleteModalOpen(false)}>
                <div className="onboarding-complete-modal">
                    <h2>Welcome to Samplead!</h2>
                    <p>
                        Our AI is discovering new prospect suggestions for you. You can change your search definitions in the settings page at any
                        moment.
                    </p>
                    <Button variant="primary" onClick={() => setIsOnboardingCompleteModalOpen(false)}>
                        Let's Start
                    </Button>
                </div>
            </Modal>
            <div className={"dashboard__wrapper"}>
                <div className={"dashboard__container"}>
                    <div className={"dashboard-content"}>
                        {discoveryCampaign && discoveryCampaign.leadCount > 0 && (
                            <div className={"dashboard-content-prospects-title"} onClick={() => gotoDiscovery(discoveryCampaign.id)}>
                                <div className={"dashboard-content-prospects-title-left samplead-body"}>
                                    You have {discoveryCampaign.leadCount} new prospects!
                                </div>
                                <div className={"dashboard-content-prospects-title-right samplead-body"}>Watch now</div>
                            </div>
                        )}

                        <DashboardHeader userName={user.name} />

                        {!isLoadingCampaigns ? (
                            <DashboardMain campaigns={campaignStore.campaigns} handleDeleteCampaign={deleteCampaign} />
                        ) : (
                            <Spinner />
                        )}
                    </div>
                    {!isLoadingStatistics ? (
                        <DashboardStatistics
                            isEmptyState={isNullOrEmptyArray(campaignStore.campaigns)}
                            userStatistics={userStatistics}
                            onDateRangeSelected={handleDateRangeSelected}
                        />
                    ) : (
                        <Spinner contWidth="311px" />
                    )}
                </div>
            </div>
        </ErrorBoundary>
    );
}

export default Dashboard;
