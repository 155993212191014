import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";

import Icon from "components/Icon/Icon";
import useStyles from "./styles";
import * as sequenceActions from "redux/Sequence/actions";

const SelectInput = ({ label, mode, state, setState, selectElement }) => {
    const [showOptions, setShowOptions] = useState(false);
    const dispatch = useDispatch();
    const selectRef = useRef();
    const styles = useStyles();
    const onClick = (e) => {
        if (mode === "single") {
            setState(
                Object.keys(state).reduce((acc, key) => {
                    return { ...acc, [key]: key === e.target.name };
                }, {})
            );
            setShowOptions(false);
        }
        if (mode === "multiple") {
            const getNewState = () => {
                const newState = { ...state, [e.target.name]: e.target.checked };
                const allKeys = Object.keys(newState);
                if ("All" in newState) {
                    if (e.target.name !== "All") {
                        newState.All = false;
                        if (allKeys.every((key) => newState[key] || key === "All")) {
                            return allKeys.reduce((acc, key) => {
                                return { ...acc, [key]: key === "All" };
                            }, {});
                        }
                    } else {
                        if (newState.All) {
                            return allKeys.reduce((acc, key) => {
                                return { ...acc, [key]: key === "All" };
                            }, {});
                        }
                    }
                }

                if (allKeys.every((key) => !newState[key])) {
                    return { ...newState, [allKeys[0]]: true };
                }
                return newState;
            };
            setState(getNewState());
        }
    };
    const getSelectName = () => {
        if (mode === "single") {
            return Object.keys(state).find((key) => state[key]);
        }
        if (mode === "multiple") {
            return Object.keys(state)
                .filter((key) => state[key])
                .join(", ");
        }
    };
    const handleBlur = (e) => {
        if (!selectRef.current.contains(e.relatedTarget)) {
            setShowOptions(false);
        }
    };

    return (
        <div style={styles.root}>
            {label && <label>{label}</label>}
            <div style={styles.select}>
                {selectElement ? (
                    <div onClick={() => setShowOptions(!showOptions)}>{selectElement}</div>
                ) : (
                    <div onClick={() => setShowOptions(!showOptions)} style={styles.selectInput}>
                        <div style={{ paddingRight: "12px" }}>{getSelectName()}</div>
                        <Icon type={showOptions ? "up_arrow" : "down_arrow"} style={{ float: "right" }} />
                    </div>
                )}
                {showOptions && (
                    <div style={styles.selectOptions} onBlur={handleBlur} ref={selectRef}>
                        {Object.entries(state).map(([key, value], i) => {
                            let editableText = "";
                            if (key.includes("Free text: ")) {
                                editableText = key.split("Free text: ")[1];
                                key = "Free text: ";
                            }
                            return (
                                <div key={i}>
                                    <div style={styles.selectOption}>
                                        <input type="checkbox" id={key} name={key + editableText} value={key} onChange={onClick} checked={value} />
                                        <label htmlFor={key}>{key}</label>
                                        {editableText && (
                                            <div style={{ display: "flex" }}>
                                                <p
                                                    contentEditable={true}
                                                    suppressContentEditableWarning={true}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                            e.target.blur();
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        dispatch(sequenceActions.changeFreeText(e.target.textContent));
                                                    }}
                                                    style={{ color: "#757575", padding: "0 3px" }}
                                                >
                                                    {editableText}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    {i + 1 !== Object.keys(state).length && <div style={styles.selectOptionDivider} />}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectInput;
