import axios from "axios";
import env from "config/site.config";

const log = require("loglevel").getLogger("CampaignService");
log.setLevel("error");

export const getCampaigns = async () => {
    log.debug("CampaignService -> getCampaigns -> Enter");

    const url = `${env.REACT_APP_BACKEND_URL}/campaign/get`;
    return axios
        .post(url, {})
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> getCampaigns -> response: " + JSON.stringify(res));
            return res.campaigns;
        })
        .catch((response) => {
            log.debug("CampaignService -> getCampaigns -> error: ");
            log.debug(response);
            return null;
        });
};

export const createManualCampaign = async (name, leads, callback) => {
    log.debug("CampaignService -> createManualCampaign -> Enter");

    const data = {
        name,
        leads,
    };

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/manual/create`, data)
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> createManualCampaign -> response: " + JSON.stringify(res));
            console.log("CampaignService then", res);
            callback(res);
            return res;
        })
        .catch((response) => {
            log.debug("CampaignService -> createManualCampaign -> error: ");
            log.debug(response);
            return null;
        })
        .finally(() => {});
};

export const createDiscoveryCampaign = async (name, callback) => {
    log.debug("CampaignService -> createDiscoveryCampaign -> Enter");

    const data = {
        name,
    };

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/discovery/create`, data)
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> createDiscoveryCampaign -> response: " + JSON.stringify(res));
            callback(res);
            return res;
        })
        .catch((response) => {
            log.debug("CampaignService -> createDiscoveryCampaign -> error: ");
            log.debug(response);
            return response;
        });
};

export const updateCampaignLeads = async (campaignId, leads, callback) => {
    log.debug("CampaignService -> updateCampaignLeads -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/update`, { campaignId, leads })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> updateCampaignLeads -> response: " + JSON.stringify(res));
            callback(res);
            return res;
        })
        .catch((response) => {
            log.debug("CampaignService -> updateCampaignLeads -> error: ");
            log.debug(response);
            return null;
        })
        .finally(() => {});
};

export const getCampaignById = async (campaignId, pageNumber, pageSize, searchValue, searchKey, currentTab, sortKey, sortOrder) => {
    log.debug("CampaignService -> getCampaignById -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/getById`, {
            campaignId,
            pageNumber,
            pageSize,
            searchValue,
            searchKey,
            currentTab,
            sortKey,
            sortOrder,
        })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> getCampaignById -> response: " + JSON.stringify(res));
            return { campaign: res.campaign, tabCounts: res.tabCounts };
        })
        .catch((response) => {
            log.debug("CampaignService -> getCampaignById -> error: ");
            log.debug(response);
            return null;
        });
};

export const getAllMagicMoments = async (
    campaignId,
    pageNumber,
    pageSize,
    searchValue,
    searchKey,
    currentTab,
    sortKey,
    sortOrder,
    filterByCampaignId
) => {
    log.debug("CampaignService -> getAllMagicMoments -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/getAllMagicMoments`, {
            campaignId,
            pageNumber,
            pageSize,
            searchValue,
            searchKey,
            currentTab,
            sortKey,
            sortOrder,
            filterByCampaignId,
        })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> getAllMagicMoments -> response: " + JSON.stringify(res));
            return { campaign: res.campaign, tabCounts: res.tabCounts };
        })
        .catch((response) => {
            log.debug("CampaignService -> getAllMagicMoments -> error: ");
            log.debug(response);
            return null;
        });
};

export const getCampaignMonitoredById = async (campaignId) => {
    log.debug("CampaignService -> getCampaignMonitoredById -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/getMonitoredById`, { campaignId })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> getCampaignMonitoredById -> response: " + JSON.stringify(res));
            return res.campaign;
        })
        .catch((response) => {
            log.debug("CampaignService -> getCampaignMonitoredById -> error: ");
            log.debug(response);
            return null;
        });
};

export const getCampaignUnseenById = async (campaignId) => {
    log.debug("CampaignService -> getCampaignUnseenById -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/getUnseenById`, { campaignId })
        .then(async (response) => {
            const res = response.data;
            console.log("unseen", res);
            log.debug("CampaignService -> getCampaignUnseenById -> response: " + JSON.stringify(res));
            return { leads: res.leads, id: campaignId };
        })
        .catch((response) => {
            log.debug("CampaignService -> getCampaignUnseenById -> error: ");
            log.debug(response);
            return null;
        });
};

export const getAllMagicMomentsUnseen = async (campaignId) => {
    log.debug("CampaignService -> getAllMagicMomentsUnseen -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/getAllMagicMomentsUnseen`, { campaignId })
        .then(async (response) => {
            const res = response.data;
            console.log("unseen", res);

            log.debug("CampaignService -> getAllMagicMomentsUnseen -> response: " + JSON.stringify(res));
            return { leads: res.leads, id: campaignId };
        })
        .catch((response) => {
            log.debug("CampaignService -> getAllMagicMomentsUnseen -> error: ");
            log.debug(response);
            return null;
        });
};

export const renameCampaign = (campaign_id, name) => {
    log.debug("CampaignService -> renameCampaign -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/rename`, { campaign_id, name })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> renameCampaign -> response: " + JSON.stringify(res));
            return { status: "ok" };
        })
        .catch((response) => {
            log.debug("CampaignService -> renameCampaign -> error: ");
            log.debug(response);
            return { status: "error" };
        });
};

export const rephraseLead = async (leadId) => {
    log.debug("CampaignService -> rephraseLead -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/lead/rephrase`, { leadId })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> rephraseLead -> response: " + JSON.stringify(res.data));
            return res.lead;
        })
        .catch((response) => {
            log.debug("CampaignService -> rephraseLead -> error: ");
            log.debug(response);
            return null;
        });
};

export const rephraseLeadLambda = async (data) => {
    log.debug("CampaignService -> rephraseLeadLambda -> Enter");
    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/lead/lambda/rephrase`, { data })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> rephraseLead -> response: " + JSON.stringify(res.data));
            return res.lead;
        })
        .catch((response) => {
            log.debug("CampaignService -> rephraseLead -> error: ");
            log.debug(response);
            return null;
        });
};

export const markLeadsAsRead = async (leadIds) => {
    log.debug("CampaignService -> markLeadsAsRead -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/leads/read`, { ids: leadIds })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> markLeadsAsRead -> response: " + JSON.stringify(res));
            return;
        })
        .catch((response) => {
            log.debug("CampaignService -> markLeadsAsRead -> error: ");
            log.debug(response);
            return null;
        });
};

export const editLeadMessage = async (campaignId, leadId, message) => {
    log.debug("CampaignService -> editLeadMessage -> Enter");

    const data = {
        campaignId,
        leadId,
        message,
    };

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/lead/message/edit`, data)
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> editLeadMessage -> response: " + JSON.stringify(res));
            return res.lead;
        })
        .catch((response) => {
            log.debug("CampaignService -> editLeadMessage -> error: ");
            log.debug(response);
            return null;
        });
};

export const editLeadValuePropositionMessage = async (campaignId, leadId, message) => {
    log.debug("CampaignService -> editLeadValuePropositionMessage -> Enter");

    const data = {
        campaignId,
        leadId,
        message,
    };

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/lead/message_2/edit`, data)
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> editLeadValuePropositionMessage -> response: " + JSON.stringify(res));
            return res.lead;
        })
        .catch((response) => {
            log.debug("CampaignService -> editLeadValuePropositionMessage -> error: ");
            log.debug(response);
            return null;
        });
};

export const changeLeadStatus = async (leadId, status) => {
    log.debug("CampaignService -> changeLeadStatus -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/lead/status/update `, { leadId, status })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> changeLeadStatus -> response: " + JSON.stringify(res));
            return res.lead;
        })
        .catch((response) => {
            log.debug("CampaignService -> changeLeadStatus -> error: ");
            log.debug(response);
            return null;
        });
};

export const denyLead = async (leadId, reasons) => {
    log.debug("CampaignService -> denyLead -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/lead/deny`, { leadId, reasons })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> denyLead -> response: " + JSON.stringify(res));
            return res.lead;
        })
        .catch((response) => {
            log.debug("CampaignService -> denyLead -> error: ");
            log.debug(response);
            return null;
        });
};

export const exportLeads = async (leadIds) => {
    log.debug("CampaignService -> exportLeads -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/lead/export`, { leadIds })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> exportLeads -> response: " + JSON.stringify(res));
        })
        .catch((response) => {
            log.debug("CampaignService -> exportLeads -> error: ");
            log.debug(response);
            return null;
        });
};

export const removeLeads = async (leadIds) => {
    log.debug("CampaignService -> removeLeads -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/lead/remove_multiple`, { leadIds })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> removeLeads -> response: " + JSON.stringify(res));
        })
        .catch((response) => {
            log.debug("CampaignService -> removeLeads -> error: ");
            log.debug(response);
            return null;
        });
};

export const restoreLeads = async (leadIds) => {
    log.debug("CampaignService -> restoreLeads -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/lead/restore_multiple`, { leadIds })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> restoreLeads -> response: " + JSON.stringify(res));
        })
        .catch((response) => {
            log.debug("CampaignService -> restoreLeads -> error: ");
            log.debug(response);
            return null;
        });
};

export const deleteCampaign = async (campaignId, callback = () => {}) => {
    log.debug("CampaignService -> deleteCampaign -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/delete`, { campaignId })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> deleteCampaign -> response: " + JSON.stringify(res));
            callback(res);
        })
        .catch((response) => {
            log.debug("CampaignService -> deleteCampaign -> error: ");
            log.debug(response);
            return null;
        })
        .finally(() => {});
};

export const exportExpandi = async (leads, callback = () => {}) => {
    log.debug("CampaignService -> exportExpandi -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/expandi/export`, {
            leads: leads.map((lead) => {
                const first_name = lead?.user_data?.first_name || lead?.name?.split(" ")[0] || "";
                const last_name = lead?.user_data?.last_name || lead?.name?.split(" ").slice(1).join(" ") || "";
                const company_name = lead?.user_data?.company_name || "";
                const job_title = lead?.user_data?.title || lead?.title || "";
                const profile_link = lead?.user_data?.user_url || lead?.url || "";
                const image_link = lead?.user_data?.image || "";
                const email = lead?.email || "";
                const name = lead?.name || "";
                const tailored_message = lead?.message || "";
                return {
                    first_name,
                    last_name,
                    company_name,
                    job_title,
                    profile_link,
                    image_link,
                    email,
                    name,
                    "Tailored Message": tailored_message,
                };
            }),
        })
        .then((res) => {
            console.log(res);
            callback();
        })
        .catch((err) => {
            console.log(err);
        });
};

export const changeActive = async (campaignId, active) => {
    log.debug("CampaignService -> changeActive -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/campaign/changeActive`, { campaignId, active })
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> changeActive -> response: " + JSON.stringify(res));
            return res;
        })
        .catch((response) => {
            log.debug("CampaignService -> changeActive -> error: ");
            log.debug(response);
            return null;
        });
};
