import React from "react";
import { Oval } from "react-loader-spinner";

const Spinner = ({ width = "80", contWidth = "100%", contHeight = "100%", color = "#7B31C6", secondaryColor = "#0372BE" }) => {
    return (
        <div style={{ width: contWidth, height: contHeight, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Oval color={color} secondaryColor={secondaryColor} strokeWidth={4} width={width} />
        </div>
    );
};

export default Spinner;
