import React from "react";

import { LINKEDIN_STATE, queryToObject } from "utils/auth";

const LoginCallback = () => {
    if (window.location.search) {
        console.log(window.location.search);
        const params = queryToObject(window.location.search);

        if (params.state === LINKEDIN_STATE && window.opener) {
            window.opener.postMessage(params);
        }
    }

    return <div />;
};

export default LoginCallback;
