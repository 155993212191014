export const getStorageItem = (key, isObject) => {
    const value = localStorage.getItem(key);
    return isObject ? JSON.parse(value) : value;
};

export const setStorageItem = (key, value, isObject) => {
    localStorage.setItem(key, isObject ? JSON.stringify(value) : value);
};

export const removeStorageItem = (key) => {
    localStorage.removeItem(key);
};
