import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

import userReducer from "redux/User/reducer";
import campaignReducer from "redux/Campaign/reducer";
import settingsReducer from "redux/Settings/reducer";
import fileReducer from "redux/File/reducer";
import snackbarReducer from "redux/Snackbar/reducer";
import sequenceReducer from "redux/Sequence/reducer";
import noMailboxReducer from "redux/NoOutreachMailbox/reducer";
import expandiReducer from "redux/Expandi/reducer";
import repliesReducer from "redux/Replies/reducer";

import env from "config/site.config";

const reducer = combineReducers({
    user: userReducer,
    campaign: campaignReducer,
    settings: settingsReducer,
    file: fileReducer,
    snackbar: snackbarReducer,
    sequence: sequenceReducer,
    noMailbox: noMailboxReducer,
    expandi: expandiReducer,
    replies: repliesReducer,
});

const middleWares = [];
if (env.REACT_APP_ENV_NAME !== "production") {
    middleWares.push(logger);
}
const preLoadedState = {};

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middleWares),
    devTools: env.REACT_APP_ENV_NAME !== "production",
    preloadedState: preLoadedState,
});

export default store;
