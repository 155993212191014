import { useSelector } from "react-redux";

import env from "config/site.config";

import "./Statistics.css";

const Statistics = () => {
    const user = useSelector((state) => state.user.user);

    return (
        <iframe
            title="Statistics"
            width="1600"
            height="1100"
            src={`${env.STATISTICS_API_URL}/?token=${user.token}&aggregate=True`}
            style={{ border: 0 }}
            allowFullScreen
        ></iframe>
    );
};
export default Statistics;
