import * as actionTypes from "./actionTypes";

export const toggleSnackbarOpen = ({ message = "", timeout = 3000, variant = "" }) => ({
    type: actionTypes.TOGGLE_SNACKBAR_OPEN,
    message,
    timeout,
    variant,
});

export const toggleSnackbarClose = () => ({
    type: actionTypes.TOGGLE_SNACKBAR_CLOSE,
});
