import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import avatar from "assets/images/avatar.png";
import { logger } from "utils/utils";
import { PATH_HOME, PATH_ONBOARDING_PAGE, PATH_PRODUCT_OPTIONS } from "utils/constants";
import KebabMenuButton from "../Button/KebabMenuButton";
import * as userActions from "redux/User/actions";
import HelpDialog from "../HelpDialog";
import "./Header.css";
import env from "config/site.config";
import Button, { BUTTON_VARIANT } from "components/Button/Button";
import MirrorModeModal from "./MirrorModeModal";
import AccountUsersModal from "./AccountUsersModal";

const Header = () => {
    const onboardingCompleted = useSelector((state) => state.settings.onboarding_complete);
    const user = useSelector((state) => state.user.user);
    const mirrorMode = useSelector((state) => state.user.mirrorMode);
    const admin = useSelector((state) => state.user.adminUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showHelp, setShowHelp] = useState(false);
    const [showMirroringModal, setShowMirroringModal] = useState(false);
    const [showAccountUsersModal, setShowAccountUsersModal] = useState(false);

    const handleHelpClick = () => {
        logger("Help clicked in header");
        setShowHelp(true);
    };

    const onSignOut = () => {
        dispatch(userActions.signOut());
    };

    const onIntegrationsClick = () => {
        // navigate to integrations page
        navigate("/integrations");
    };

    // if the location is /onboarding, load different header class
    const isOnboarding = window.location.pathname === PATH_ONBOARDING_PAGE;
    const header_class = isOnboarding ? "no-header" : "header";

    const menuItems = [
        { label: "Sign Out", onClick: onSignOut },
        { label: "Integrations", onClick: onIntegrationsClick },
    ];

    if (admin?.admin) menuItems.push({ label: "User Mirroring", onClick: () => setShowMirroringModal(true) });
    if (user?.role === "2") menuItems.push({ label: "Account Users", onClick: () => setShowAccountUsersModal(true) });

    return (
        <nav className={header_class}>
            <div className="nav-container">
                <Link to={PATH_HOME} className="nav-logo">
                    {" "}
                </Link>
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    {env.REACT_APP_ENV_NAME.toLowerCase() !== "production" && (
                        <div style={{ color: "red" }}>{env.REACT_APP_ENV_NAME.toUpperCase()}</div>
                    )}
                    {mirrorMode && <div style={{ color: "red" }}>Mirror Mode</div>}
                </div>

                {user && (
                    <ul className="nav-menu">
                        {onboardingCompleted === 0 && (
                            <Button
                                variant={BUTTON_VARIANT.PRIMARY}
                                onClick={() => {
                                    navigate(PATH_ONBOARDING_PAGE);
                                }}
                                style={{ marginRight: "10px" }}
                            >
                                Finish Onboarding
                            </Button>
                        )}
                        <NavLink className="nav-item nav-item--settings" to="/settings" />

                        <li className="nav-item nav-item--help" onClick={handleHelpClick}>
                            <HelpDialog showHelp={showHelp} setShowHelp={setShowHelp} />
                        </li>
                        <li className="nav-item nav-item--divider" />

                        <li className="nav-item nav-item--avatar">
                            <img src={user.image_url ? user.image_url : avatar} alt="Avatar" />
                        </li>

                        <li className="nav-item nav-item--avatar">
                            <KebabMenuButton trigger={<FontAwesomeIcon icon={faChevronDown} size="sm" />} menuItems={menuItems} />
                        </li>
                    </ul>
                )}
            </div>
            <MirrorModeModal isModalOpen={showMirroringModal} closeModal={() => setShowMirroringModal(false)}></MirrorModeModal>
            <AccountUsersModal isModalOpen={showAccountUsersModal} closeModal={() => setShowAccountUsersModal(false)}></AccountUsersModal>
        </nav>
    );
};

export default Header;
