import React from "react";

import ErrorBoundary from "utils/ErrorBoundary";
import SequenceHeader from "./SequenceComponents/SequenceHeader";
import SequenceOptions from "./SequenceComponents/SequenceOptions";
import SequenceContent from "./SequenceComponents/SequenceContent";
import useStyles from "./styles";

const Sequence = () => {
    const styles = useStyles();
    return (
        <ErrorBoundary>
            <div style={styles.root}>
                <SequenceHeader />
                <SequenceOptions />
                <SequenceContent />
            </div>
        </ErrorBoundary>
    );
};

export default Sequence;
