import React from "react";
import "./Modal.scss";

export const MODAL_TYPE = {
    MANUAL_DISCOVERY: "manual-discovery",
    EXPORT: "export",
    SUGGESTIONS: "suggestions",
    SUGGESTIONS_DENIED: "suggestions-denied",
};

const Modal = ({ isModalOpen, closeModal, children, className, style }) => {
    return (
        <div className={(isModalOpen ? "modal-container" : "modal-container modal--closed") + ` ${className ? className : ""}`} style={style}>
            <div className="modal-overlay" onClick={closeModal} />

            {isModalOpen && <div className={"modal-content"}>{children}</div>}
        </div>
    );
};

export default Modal;
