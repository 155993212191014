import * as actionTypes from "./actionTypes";
import { getStorageItem, removeStorageItem } from "utils/storageUtils";

const log = require("loglevel").getLogger("userReducer");
log.setLevel("debug");

const initialState = JSON.stringify({
    user: null,
    lastPath: null,
    adminUser: null,
    mirrorMode: false,
    users: [],
    accountUsers: [],
});

function userReducer(state = JSON.parse(initialState), action) {
    switch (action.type) {
        case actionTypes.USER_LOGGED_IN: {
            const { user, adminUser } = action.data;
            const lastPath = getStorageItem("lastPath");
            console.log("lastPath in reducer", lastPath);

            if (lastPath) {
                removeStorageItem("lastPath");
            }
            let mirrorMode = false;
            if (user && adminUser && adminUser.email !== user.email) {
                mirrorMode = true;
            }
            return {
                ...state,
                user: user,
                adminUser: adminUser,
                mirrorMode: mirrorMode,
                lastPath,
            };
        }
        case actionTypes.CLEAR_LAST_PATH: {
            return {
                ...state,
                lastPath: null,
            };
        }
        case actionTypes.USER_LOGGED_OUT: {
            return {
                ...state,
                user: null,
                lastPath: null,
            };
        }
        case actionTypes.SET_USERS: {
            return {
                ...state,
                users: action.data,
            };
        }
        case actionTypes.SET_ACCOUNT_USERS: {
            return {
                ...state,
                accountUsers: action.data,
            };
        }
        case actionTypes.UPDATE_ACCOUNT_USER: {
            const { filteredOwnerId, isShown } = action.data;
            return {
                ...state,
                accountUsers: state.accountUsers.map((user) => {
                    if (user.owner_id === filteredOwnerId) {
                        return {
                            ...user,
                            is_shown: isShown,
                            is_loading: false,
                        };
                    }
                    return user;
                }),
            };
        }
        case actionTypes.LOADING_ACCOUNT_USER_UPDATE: {
            const { filteredOwnerId } = action.data;
            return {
                ...state,
                accountUsers: state.accountUsers.map((user) => {
                    if (user.owner_id === filteredOwnerId) {
                        return {
                            ...user,
                            is_loading: true,
                        };
                    }
                    return user;
                }),
            };
        }
        case actionTypes.SET_USER_SUBSCRIBED: {
            return {
                ...state,
                user: {
                    ...state.user,
                    active_subscription: true,
                    is_new_user: false,
                },
            };
        }
        case actionTypes.SET_NEW_USER_TO_OLD: {
            return {
                ...state,
                user: {
                    ...state.user,
                    is_new_user: false,
                },
            };
        }
        default:
            return state;
    }
}

export default userReducer;
