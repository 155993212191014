import axios from "axios";
import "./SignUp.css";
import { useState } from "react";

const SignUp = ({ signInWithLinkedin, setSignUp, handleChangeToCredentials, handleCreateNewAccount }) => {
    const handleChangeToLogin = () => {
        setSignUp(false);
    };

    return (
        <div className="signup-cont">
            <div>Sign Up to</div>
            <div className="nav-logo"></div>
            <button className="linkedin_btn" onClick={signInWithLinkedin}>
                <div>
                    <img src="https://img.icons8.com/color/48/000000/linkedin.png" width="30px" alt="linkedin" />
                    <h4>Sign up with Linkedin</h4>
                </div>
            </button>
            <div style={{ marginTop: "10px" }}>or use your email</div>
            <label>Email</label>
            <input onChange={(e) => handleChangeToCredentials("email", e.target.value)} className="signup_input" placeholder="Email" />
            <label>Password</label>
            <input onChange={(e) => handleChangeToCredentials("password", e.target.value)} className="signup_input" placeholder="Password" />
            <div className="forgot-pass">Forgot password?</div>
            <button onClick={handleCreateNewAccount} className="create_btn">
                Create an Account
            </button>
            <div className="checkbox-cont">
                <input style={{ marginRight: "5px" }} type="checkbox" />
                <p className="login-text">I agree to the Terms of Service and Privacy Policy</p>
            </div>
            <div className="is-member-box">
                <p className="login-text">Already a member? </p>
                <p className="login-text" style={{ color: "#A54CFF" }} onClick={handleChangeToLogin}>
                    Log in
                </p>
            </div>
        </div>
    );
};

export default SignUp;
