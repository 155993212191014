import axios from "axios";
import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { useParams } from "react-router-dom";
import env from "config/site.config";
import { LeadListMinifyed } from "./LeadListMinifyed";
import * as d3sc from "d3-scale-chromatic";

import "./ClusterTitles.css";
import { Card, Empty, Skeleton, Statistic } from "antd";
import { DotChartOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";

const ClusterTitles = ({ campaignId, weaviateClass }) => {
    const [clusterTitles, setClusterTitles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [leads, setLeads] = useState([]);
    const [totalLeadsLength, setTotalLeadsLength] = useState(0);

    const fetchClusterTitles = async () => {
        if (!campaignId && !clusterTitles) return;

        try {
            const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/campaign/clustering-data`, {
                campaignId,
                weaviateClass,
            });
            if (data?.data?.data.length === 0) return console.log("No data to display");
            console.log("data", data?.data?.data);
            if (!data?.data?.data) {
                setClusterTitles([]);
                return console.log("No data to display");
            }

            setClusterTitles(data?.data?.data);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log(" Getting clusters for campaignId", campaignId);
        fetchClusterTitles();
    }, [campaignId]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <Error />;
    }

    return (
        <div
            className="cluster-titles"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <BubbleChart
                totalLeadsLength={totalLeadsLength}
                leads={leads}
                setTotalLeadsLength={setTotalLeadsLength}
                clusterTitles={clusterTitles}
                setLeads={setLeads}
                campaignId={campaignId}
                weaviateClass={weaviateClass}
            />
        </div>
    );
};

const EmptyData = () => {
    return (
        <div style={{ margin: "20px" }}>
            <Card bordered={false}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Click on a Cluster to view it's prospects" />
            </Card>
        </div>
    );
};

const Loading = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                marginTop: "auto",
                height: "50vh",
            }}
        >
            <Skeleton.Node active>
                <DotChartOutlined style={{ fontSize: 40, color: "#bfbfbf" }} />
            </Skeleton.Node>
            <Skeleton active />
        </div>
    );
};

const Error = () => {
    return <div>There was an error</div>;
};

const BubbleChart = ({ clusterTitles, setLeads, campaignId, totalLeadsLength, leads, setTotalLeadsLength, weaviateClass }) => {
    const [leadsInnerClusterTitles, setLeadsInnerClusterTitles] = useState([]);
    const [topTitles, setTopTitles] = useState([]);
    const [noData, setNoData] = useState(false);
    const zoomRef = useRef();
    const svgRef = useRef();

    const handleCircleClick = (titles) => {
        console.log("titles", titles);
        setLeadsInnerClusterTitles(titles);
        setTotalLeadsLength(titles?.length);
    };

    const calculateTopTitles = (data) => {
        console.log("calculateTopTitles", data);
        const clusterSizes = data?.map((d) => {
            return { size: d.cluster_size, title: d.group_titles };
        });

        // get biggest 4 or less
        const sortedClusterSizes = clusterSizes.sort((a, b) => b.size - a.size);
        const topTitles = sortedClusterSizes.slice(0, 3);
        console.log("topTitles", topTitles);
        setTopTitles(topTitles);
    };

    useEffect(() => {
        if (!clusterTitles || clusterTitles.length === 0) {
            setNoData(true);
            return;
        }
        const data = JSON.parse(clusterTitles);

        calculateTopTitles(data);

        const container = d3.select("#myContainer");
        container.selectAll("svg").remove();
        const zoom = d3.zoom().on("zoom", function (event) {
            svg.attr("transform", event.transform);
        });

        // Get dynamic width and height based on window size
        const width = window.innerWidth;
        const height = window.innerHeight;

        const centerX = width / 3;
        const centerY = height / 2;

        const svg = container
            .attr("width", width)
            .attr("height", height)
            .append("svg")
            .attr("width", "auto")
            .attr("height", "auto")
            .call(zoom)
            .append("g");

        zoomRef.current = zoom;
        svgRef.current = svg;

        const maxClusterSize = Math.max(...data.map((d) => d.cluster_size));
        const radiusScale = d3.scaleSqrt().domain([0, maxClusterSize]).range([20, 100]);
        const fontSizeScale = d3.scaleSqrt().domain([0, maxClusterSize]).range([5, 20]); // Adjust range as needed
        const defaultColor = "#0D0887";
        // create defs selection
        const defs = svg.append("defs");

        // Define color array
        const colors = ["#0D0887", "#41049D", "#6A00A8", "#8F0DA6", "#B12A90", "#CB4678", "#E16462", "#F1844F", "#FDAE39", "#FDCF4B"].reverse();
        // Create color scale
        const colorScale = d3
            .scaleLinear()
            .domain([0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3].concat(d3.range(0.3, 1, 0.7 / (colors.length - 7))))
            .range(colors)
            .interpolate(d3.interpolateRgb);

        const getColor = (ratio, i) => {
            console.log("getColor -> ratio:", ratio);
            // limit ratio between 0 and 1
            ratio = Math.max(0, Math.min(1, 1 - ratio)); // invert the ratio here

            // create gradient id
            const gradientId = `gradient${i}`;

            // create radial gradient
            const gradient = defs.append("radialGradient").attr("id", gradientId);

            // create gradient stops
            gradient
                .selectAll("stop")
                .data(colors)
                .enter()
                .append("stop")
                .attr("offset", (d, i) => i / (colors.length - 1))
                .attr("stop-color", (d, i) => colorScale((ratio * i) / (colors.length - 1)));

            return `url(#${gradientId})`;
        };

        svg.append("defs")
            .append("filter")
            .attr("id", "shadow")
            .append("feDropShadow")
            .attr("dx", 1) // The x-offset of the drop shadow.
            .attr("dy", 1) // The y-offset of the drop shadow.
            .attr("stdDeviation", 1); // The blur radius of the drop shadow.

        const circles = svg
            .selectAll("circle")
            .data(data)
            .join("circle")
            .attr("r", (d) => radiusScale(d.cluster_size))
            .attr("fill", (d, i) => (d.cluster_total_reply_ratio ? getColor(d.cluster_total_reply_ratio, i) : defaultColor))
            .style("filter", "url(#shadow)");

        const circlesByIndex = circles.nodes();

        let tooltip = d3
            .select("body")
            .append("div")
            .style("position", "absolute")
            .style("visibility", "hidden")
            .style("background", "#fff")
            .style("border", "1px solid #f0f0f0")
            .style("padding", "2px 5px")
            .style("border-radius", "2px")
            .style("box-shadow", "0 2px 8px rgba(0,0,0,0.15)")
            .style("font-size", "12px")
            .style("color", "#595959");

        const foreignObjects = svg
            .selectAll("foreignObject")
            .data(data)
            .join("foreignObject")
            .attr("width", (d) => 2 * radiusScale(d.cluster_size))
            .attr("height", (d) => 2 * radiusScale(d.cluster_size))
            .html((d) => `<div class='circle-text' style='font-size: ${fontSizeScale(d.cluster_size)}px;'>${d.group_titles}</div>`)
            .attr("cursor", "pointer")
            .on("click", (event, d) => {
                handleCircleClick(d.clean_title.filter((title) => title !== ""));
            })
            .on("mouseover", function (event, d) {
                d3.select(this).style("cursor", "pointer");
                // scale up the circle not the foreign object
                d3.select(circlesByIndex[d.index])
                    .transition()
                    .duration(100)
                    .attr("r", (d) => radiusScale(d.cluster_size) + 5);
                // show only 3 decimal points
                return tooltip
                    .style("visibility", "visible")
                    .text(`Ratio: ${d.cluster_total_reply_ratio ? d.cluster_total_reply_ratio?.toFixed(3) : "N/A" || "N/A"}`);
            })
            .on("mousemove", function (event) {
                return tooltip.style("top", event.pageY - 20 + "px").style("left", event.pageX + 20 + "px");
            })
            .on("mouseout", function (event, d) {
                d3.select(this).style("cursor", "default");
                d3.select(circlesByIndex[d.index])
                    .transition()
                    .duration(100)
                    .attr("r", (d) => radiusScale(d.cluster_size));
                return tooltip.style("visibility", "hidden");
            });

        const simulation = d3
            .forceSimulation(data)
            .force(
                "collide",
                d3
                    .forceCollide()
                    .radius((d) => radiusScale(d.cluster_size) + 5)
                    .strength(1)
            )
            .force("center", d3.forceCenter(centerX, centerY).strength(0.1)) // Increase centering force strength
            .force("x", d3.forceX(centerX).strength(0.01)) // Decrease x force strength
            .force("y", d3.forceY(centerY).strength(0.01)) // Decrease y force strength
            .on("tick", ticked);

        // Animation: Zoom and focus on circles
        // Animation: Zoom and focus on circles
        svg.transition().duration(1000).call(zoom.scaleBy, 1); // Increase the scale factor to zoom in more

        function ticked() {
            circles
                .attr("cx", (d) => Math.max(radiusScale(d.cluster_size), Math.min(width - radiusScale(d.cluster_size), d.x)))
                .attr("cy", (d) => Math.max(radiusScale(d.cluster_size), Math.min(height - radiusScale(d.cluster_size), d.y)));

            foreignObjects
                .attr("x", (d) => Math.max(0, Math.min(width - 2 * radiusScale(d.cluster_size), d.x - radiusScale(d.cluster_size))))
                .attr("y", (d) => Math.max(0, Math.min(height - 2 * radiusScale(d.cluster_size), d.y - radiusScale(d.cluster_size))));
        }
    }, [clusterTitles]);

    if (noData)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <EmptyDataClustering />
            </div>
        );

    return (
        <div
            style={{
                width: "80vw",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                }}
            >
                <div
                    id="myContainer"
                    style={{
                        background: "white",
                        borderRadius: "25px",
                        height: "100%",
                        backgroundSize: "50px 50px",
                        // backgroundColor: "red",
                        width: "100%",
                        height: "70vh",
                    }}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px",
                        marginLeft: "20px",
                    }}
                >
                    <h1>Top Segments</h1>
                    {topTitles.map((stat) => (
                        <Card
                            bordered={false}
                            style={{
                                width: "200px",
                                height: "100px",
                            }}
                        >
                            <Statistic
                                style={{
                                    display: "flex",
                                }}
                                formatter="number"
                                title={stat.title}
                                value={stat.size}
                            />
                        </Card>
                    ))}
                </div>
            </div>
            <div
                style={{
                    width: "100%",
                }}
            >
                {
                    <LeadListMinifyed
                        emptyListComponent={EmptyData}
                        campaignId={campaignId}
                        leads={leads}
                        type={"auto-discovery"}
                        isActiveExported={false}
                        totalLeads={totalLeadsLength}
                        isLoadingCampaign={true}
                        leadsInnerClusterTitles={leadsInnerClusterTitles}
                        setLeads={setLeads}
                        weaviateClass={weaviateClass}
                    />
                }
            </div>
        </div>
    );
};

const EmptyDataClustering = () => {
    return (
        <div>
            <Card bordered={false}>
                <Empty description={"Data will dynamically appear here as you Approve/Deny prospects"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Card>
        </div>
    );
};

export default ClusterTitles;
