import React, { useState } from "react";
import { useSelector } from "react-redux";

import { FormInput } from "components/Form/Form";
import NavButtons from "components/NavButtons/NavButtons";
import RadioButton from "components/RadioButton";
import { DISABLE_ERRORS } from "utils/constants";
import "../onboarding.css";
import { Switch } from "antd";

const TargetTitlesPage = ({ handleNavClick, input_limit }) => {
    const settings = useSelector((state) => state.settings);
    const initial_input = settings.target_titles.length === 0 ? ["", "", ""] : settings.target_titles;
    const [inputValue, setInputValue] = useState(initial_input);
    const [excludedTitles, setExcludedTitles] = useState(settings.excluded_titles === [] ? [""] : settings.excluded_titles);
    const [excludedTitlesDisabled, setExcludedTitlesDisabled] = useState(settings.excluded_titles.filter((item) => item !== "").length === 0);
    const [errorMsg, setErrorMsg] = useState("");

    const update_input_value = (e, index, value, setFn) => {
        let new_arr = [...value];
        new_arr[index] = e;
        setFn(new_arr);
    };

    const next_clicked = (action) => {
        setErrorMsg(false);
        let hasError = false;
        // validate input
        console.log(
            inputValue,
            inputValue.every((val) => val === "")
        );
        if (action === "next") {
            if (inputValue.length === 0 || inputValue.every((val) => val === "")) {
                setErrorMsg("Please enter at least 1 value");
                hasError = true;
            }
        }

        if (hasError && !DISABLE_ERRORS) return;

        // create response obj to send to update function
        let response_obj = [];
        if (inputValue.length > 0) {
            response_obj.push({
                key: "target_titles",
                value: inputValue.map((item) => item.trim()).filter((item) => item !== "" && item !== null),
            });
        }
        if (excludedTitles.length > 0 && !excludedTitlesDisabled) {
            response_obj.push({
                key: "excluded_titles",
                value: excludedTitles.map((item) => item.trim()).filter((item) => item !== "" && item !== null),
            });
        } else if (excludedTitlesDisabled || excludedTitles.length === 0) {
            response_obj.push({
                key: "excluded_titles",
                value: [],
            });
        }
        // end create response obj

        handleNavClick(action, response_obj, "array");
    };

    const addExtraField = (value, setFn) => {
        setErrorMsg(false);
        if (value.length < input_limit) {
            setFn([...value, ""]);
        }
    };

    return (
        <>
            <div className="form-container">
                <div className="input-header mb-6">
                    <div className="input-header-text">
                        <p>Target Titles</p>
                    </div>
                </div>
                {inputValue.map((value, index) => {
                    return (
                        <div className="centered" key={index}>
                            <div className="input-bar centered">
                                <FormInput
                                    type="text"
                                    inputValue={inputValue[index]}
                                    inputName="target_titles"
                                    placeholder="VP of Technology"
                                    onChange={(e) => update_input_value(e, index, inputValue, setInputValue)}
                                />
                            </div>
                        </div>
                    );
                })}
                {inputValue.length < input_limit && (
                    <div className="add-custom-option">
                        <p className="add-custom-option-text link" onClick={() => addExtraField(inputValue, setInputValue)}>
                            {" "}
                            + add another title
                        </p>
                    </div>
                )}

                <div className="full-length-container mt-4">
                    <div className="radio-and-input-container low-padding auto-grow extra-wide mt-4">
                        <label className="align-center weight-200">
                            Would you like to exclude any titles? <br />
                        </label>
                        <div className="flex justify-center items-center m-4">
                            <Switch
                                checked={!excludedTitlesDisabled}
                                onChange={() => setExcludedTitlesDisabled(!excludedTitlesDisabled)}
                                style={{
                                    backgroundColor: excludedTitlesDisabled ? "#d9d9d9" : "#1890ff",
                                }}
                            >
                                {excludedTitlesDisabled ? "No" : "Yes"}
                            </Switch>
                        </div>
                        {excludedTitles.map((value, index) => {
                            return (
                                <div className="centered" key={index}>
                                    <div className="input-bar centered">
                                        <FormInput
                                            type="text"
                                            inputValue={excludedTitles[index]}
                                            inputName="excluded_titles"
                                            placeholder="Consultants"
                                            onChange={(e) => update_input_value(e, index, excludedTitles, setExcludedTitles)}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {!excludedTitlesDisabled && excludedTitles.length < input_limit && (
                    <div className="add-custom-option">
                        <p className="add-custom-option-text link" onClick={() => addExtraField(excludedTitles, setExcludedTitles)}>
                            {" "}
                            + add another title
                        </p>
                    </div>
                )}

                <div className="input-error-msg">{errorMsg && <div className="error-msg">{errorMsg}</div>}</div>
            </div>
            <NavButtons handleNavClick={next_clicked} />
        </>
    );
};

export default TargetTitlesPage;
