import env from "config/site.config";

export const PATH_HOME = "/";
export const PATH_ALL_CAMPAIGNS = "/campaigns";
export const PATH_MAGIC_MOMENTS = "/magic_moments";
export const PATH_NEW_CAMPAIGN = "/campaigns/new";
export const PATH_CAMPAIGN_PAGE = "/campaigns/*";
export const PATH_SETTINGS_PAGE = "/settings";
export const PATH_ONBOARDING_PAGE = "/onboarding";
export const PATH_DASHBOARD = "/dashboard";
export const PATH_STATISTICS = "/statistics";
export const PATH_SEQUENCE_PAGE = "/sequence";
export const PATH_INTEGRATIONS = "/integrations/*";
export const PATH_INTEGRATE_OUTREACH = "/integrations/outreach";
export const PATH_INTEGRATE_OUTREACH_PARAM = "/integrations/outreach/:code";
export const PATH_INTEGRATE_ZAPIER = "/integrations/zapier";
export const PATH_INTEGRATE_EXPANDI = "/integrations/expandi";
export const PATH_INTEGRATE_WE_CONNECT = "/integrations/we-connect";
export const PATH_INTEGRATE_SALESFORCE = "/integrations/salesforce";
export const PATH_INTEGRATE_SALESFORCE_PARAM = "/integrations/salesforce/:code";
export const PATH_LOGIN = "/login/";
export const PATH_INTEGRATE_SAMPLEAD_AUTOMATION = "/integrations/samplead-automation";
export const PATH_PRODUCT_OPTIONS = "/product-options";
export const PATH_OAUTH2_GRANT = "/oauth2/grant";
export const PATH_EMBEDDED_STATISTICS = "/embedded-statistics";
export const PATH_CLUSTERING_TITLES = "/clustering-titles/:campaignId";
export const PATH_SIGNUP = "/signup";
export const PATH_LOGIN_CALLBACK = "/login/callback";
export const PATH_LOGOUT = "/logout";
export const PATH_CODE_VERIFY = "/codeverify";
export const PATH_STRIPE_CHECKOUT = "/checkout/:checkoutId";
export const PATH_ACCOUNT_SETTING = "/settings/account";
export const PATH_TO_REPLIES = "/replies";
export const PATH_TO_SAMPLEAD_AUTOMATION_SITE = "https://app.samplead.co/";
export const PATH_TO_EXPANDI_SITE = "https://app.expandi.io/";

const constants = {
    GLOBAL_ACTION_INIT_STATE: "GLOBAL_ACTION_INIT_STATE",

    STORAGE_SIGNED_USER_KEY: "samplead-frontend-user-data",

    MENU_OPEN_CATEGORIES: "menu-open-categories",

    TOAST_TYPE_INFO: "toast_info",
    TOAST_TYPE_SUCCESS: "toast_success",
    TOAST_TYPE_WARN: "toast_warn",
    TOAST_TYPE_ERROR: "toast_error",

    MOBILE_MAX_WIDTH: 767,
    TABLET_MAX_WIDTH: 1024,
    LAPTOP_MAX_WIDTH: 1280,
};

export const BLANK_IMAGE = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

export default constants;

export const CAMPAIGN_TYPE = {
    AUTO_DISCOVERY: "auto-discovery",
    MANUAL_DISCOVERY: "manual",
    EMPTY: "empty",
};
export const CSV_FILE_TYPE = "text/csv";
export const EXCEL_FILE_TYPE = "application/vnd.ms-excel, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const DEFAULT_CAMPAIGN_TITLE = "Untitled Campaign";

export const ENTITY_NBSP = String.fromCharCode(160); //'\u00A0';

export const ENTITY_TIMES = String.fromCharCode(10006); //'\u00D7';String.fromCharCode(10006)

export const LEAD_TYPE = {
    ACTIVE: "active",
    REMOVED: "removed",
    // EXPORTED: 'exported',
};

export const LeadStatus = {
    Unseen: 1,
    Active: 2,
    Removed: 3,
    Monitoring: 4,
};

export const DISABLE_ERRORS = true;

export const PAYWALL_LINK = (email, token, ver) => {
    console.table({ email, token });
    const link =
        env.REACT_APP_ENV_NAME === "production"
            ? "//buy.stripe.com/8wMeWKfFU8wB5gIfYY"
            : env.REACT_APP_ENV_NAME === "locaL"
            ? "//buy.stripe.com/test_8wMbJJ1Ch6jX7n2288"
            : "//buy.stripe.com/test_cN2299cgV4bPazebIJ";

    const links = {
        starterMonthly: "https://buy.stripe.com/dR67ui79o7sxeRi3ce",
        proMonthly: "https://buy.stripe.com/8wMeWKfFU8wB5gIfYY",
        enterpriseMonthly: "",
        starterYearly: "https://buy.stripe.com/5kAcOC65k4gl7oQ4gk",
        proYearly: "https://buy.stripe.com/dR69CqeBQ8wBeRi3cf",
        enterpriseYearly: "",
    };

    return `${links[ver]}?prefilled_email=${email}&client_reference_id=${token}`;
};

export const ALLOWED_FILE_TYPES = ["JPEG", "PNG", "GIF", "PDF", "DOCX", "TXT", "PPTX"];
