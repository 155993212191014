import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import NavButtons from "components/NavButtons/NavButtons";
import { validate_email } from "utils/general";
import { DISABLE_ERRORS } from "utils/constants";
import Autocomplete from "components/Autocomplete";
import TextareaAutosize from "react-textarea-autosize";

import "../onboarding.css";

const SingleInputTemplate = ({
    inputHeader,
    inputKey,
    handleNavClick,
    type = "text",
    subheaderText = null,
    autocomplete = false,
    textarea = false,
    placeholder = "",
}) => {
    const settings = useSelector((state) => state.settings);
    const [inputValue, setInputValue] = useState(settings[inputKey] ? settings[inputKey] : "");
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        setInputValue(settings[inputKey] ? settings[inputKey] : "");
    }, [settings]);

    const update_input_value = (value, index = 0) => {
        console.log("update_input_value", value, index);
        setInputValue(value);
    };

    const handleOnChange = (e) => {
        setInputValue(e.target.value);
    };

    const next_clicked = (action) => {
        console.log("next clicked input_value", inputValue);
        setErrorMsg("");
        let hasError = false;
        // validate input
        if (action === "next") {
            if (!inputValue || inputValue.length === 0) {
                setErrorMsg("Please enter a value");
                hasError = true;
            }

            if (type === "email") {
                if (!validate_email(inputValue)) {
                    setErrorMsg("Please enter a valid email address");
                    hasError = true;
                }
            }
        }

        if (hasError && !DISABLE_ERRORS) return;
        let response_obj = [
            {
                key: inputKey,
                value: inputValue,
            },
        ];
        handleNavClick(action, response_obj, "single");
    };

    const handleEnterKey = (e) => {};

    let input_component;
    if (autocomplete) {
        input_component = (
            <Autocomplete
                inputKey={inputKey}
                isFocused={true}
                inputValue={inputValue}
                setInputValue={update_input_value}
                handleEnterKey={next_clicked}
                className="input-box-text wide"
            />
        );
    } else if (textarea) {
        input_component = (
            <TextareaAutosize
                autoFocus
                onKeyDown={(e) => handleEnterKey(e)}
                type={type}
                name={inputKey}
                className="input-box-text"
                value={inputValue}
                placeholder={placeholder || inputHeader}
                onChange={(e) => handleOnChange(e)}
            />
        );
    } else {
        input_component = (
            <input
                autoFocus
                onKeyDown={(e) => handleEnterKey(e)}
                type={type}
                name={inputKey}
                className="input-box-text"
                value={inputValue}
                placeholder={placeholder || inputHeader}
                onChange={(e) => handleOnChange(e)}
            />
        );
    }

    return (
        <>
            <div className="form-container">
                <div className="input-header">
                    <div className="input-header-text">
                        <p>{inputHeader}</p>
                    </div>
                </div>
                {subheaderText && (
                    <div className={`subheader centered weight-200`}>
                        <div className="subheader-text">
                            <p>{subheaderText}</p>
                        </div>
                    </div>
                )}
                <div className="input-caption-container">{input_component}</div>
                <div className="input-error-msg">{errorMsg && <div className="error-msg">{errorMsg}</div>}</div>
            </div>
            <NavButtons key={inputKey} handleNavClick={next_clicked} />
        </>
    );
};

export default SingleInputTemplate;
