import React from "react";
import { useSelector } from "react-redux";
import Button, { BUTTON_VARIANT } from "components/Button/Button";

import "./NavButtons.css";

const NavButtons = ({ handleNavClick, firstPage = false }) => {
    const { full_name, company_name, work_email, company_website } = useSelector((state) => state.settings);
    const canSkip = full_name && company_name && work_email && company_website;
    return (
        <div className="navigation-container">
            <div style={{ display: "flex", gap: "10px" }}>
                {canSkip && (
                    <Button variant={BUTTON_VARIANT.PRIMARY_WHITE} onClick={() => handleNavClick("skip_onboarding")}>
                        Skip All
                    </Button>
                )}
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
                {!firstPage && (
                    <Button
                        variant="primary"
                        onClick={() => {
                            handleNavClick("previous");
                        }}
                    >
                        Previous
                    </Button>
                )}
                <Button variant="primary-white" onClick={() => handleNavClick("next")}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export default NavButtons;
