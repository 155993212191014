import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";

import { PATH_HOME } from "utils/constants";
import Button, { BUTTON_VARIANT } from "components/Button/Button";
import sampleadLogo from "assets/images/logo.png";
import salesforceFullLogo from "assets/images/integrations/salesforce-integration-image.png";
import salesforceLogo from "assets/images/integrations/salesforce-integration-image.png";
import infoPhoto from "assets/images/integrations/salesforce-info-photo.png";
import { SideArrow } from "components/DropdownMenu";
import Modal from "components/Modal";
import "./blacklist.css";
import { handleSalesforceRefreshToken } from "routes/Campaign/CampaignContent/tabs/integrationFunctions";
import env from "config/site.config";
import Spinner from "components/Spinner";

const IntegrateSalesforce = () => {
    const [installed, setInstalled] = useState(false);
    const [confirmPopUp, setConfirmPopUp] = useState(false);
    const [uninstallPopUp, setUninstallPopUp] = useState(false);
    const [updateBlacklistPopup, setUpdateBlacklistPopup] = useState(false);
    const user = useSelector((state) => state.user.user);

    useEffect(() => {
        const access_token = localStorage.getItem("salesforce_access_token");
        const refresh_token = localStorage.getItem("salesforce_refresh_token");
        const access_token_expire = localStorage.getItem("salesforce_access_token_expire");

        if (access_token && refresh_token) {
            setInstalled(true);
            if (access_token_expire) {
                if (Math.floor(Date.now() / 1000) > access_token_expire) {
                    //expired
                    const res = axios.post(`${env.REACT_APP_BACKEND_URL}/salesforce/refresh_token`, {
                        salesforce_refresh_token: refresh_token,
                    });
                    res.then((response) => {
                        console.log(response);
                        localStorage.setItem("salesforce_access_token", response.data.access_token);
                        localStorage.setItem("salesforce_access_token_expire", response.data.access_token_expire);
                    });
                }
            }
        }

        setInstalled(false);
    }, []);

    useEffect(() => {
        console.log("installed", installed);
    }, [installed]);

    useEffect(() => {
        const getIntegrations = async () => {
            try {
                const url = `${env.REACT_APP_BACKEND_URL}/settings/get_integrations`;
                const { data } = await axios.post(url, user);
                if (!data.message && data.settings) {
                    // outreach integration is 0 or 1
                    if (data.settings.salesforce_integration) {
                        setInstalled(true);
                    } else {
                        setInstalled(false);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        getIntegrations();
    }, []);

    const handleInstallClick = () => {
        setConfirmPopUp(true);
    };

    function timeConverter(UNIX_timestamp) {
        if (!UNIX_timestamp) return;
        var a = new Date(UNIX_timestamp * 1000);
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var time = date + " " + month + " " + year + " " + hour + ":" + min;
        return time;
    }

    const checkRefreshTokenExpire = () => {
        const refresh_token_expire = Math.floor(+localStorage.getItem("salesforce_access_token_expire") + 7 * 24 * 60 * 60); //two weeks

        console.log(refresh_token_expire);
        console.log(Math.floor(Date.now() / 1000));
        if (refresh_token_expire < Math.floor(Date.now() / 1000)) {
            return true;
            //expired or null
        } else {
            return false;
        }
    };

    return (
        <div className="all-integrations-content-container">
            {confirmPopUp && <IntegrateSalesforceConfirm setConfirmPopUp={setConfirmPopUp} installed={installed} />}
            <div className="integrations-container">
                <Link to={PATH_HOME} style={{ textDecoration: "none" }} className="samplead-body--bold">
                    {`< Back to Home`}
                </Link>
                <div className="integrations-header">
                    <img src={salesforceLogo} alt="Salesforce" className="integrations-image" />
                    <h1 style={{ fontWeight: "700", fontSize: "40px" }}>Salesforce</h1>
                </div>
                <div>Do you need an introduction? really??</div>
            </div>

            <div className="integrations-main-content-container">
                <div className="integrations-main-content">
                    {/* <p>
                        Generate more pipeline and win more deals with less effort. The Salesforce Sales Execution Platform helps you grow sales,
                        consolidate tools, and lower sales execution risk.
                    </p> */}
                    <img src={infoPhoto} alt="info" />
                    {/* <p>
                        Installing this FREE integration will add an option to export your approved prospects and messages directly in to your
                        outreach.io sequence
                    </p> */}
                </div>
                <div className="integrations-side-actions">
                    <button
                        className={installed && checkRefreshTokenExpire() ? "failed-install-button" : installed ? "installed" : "install-button"}
                        onClick={handleInstallClick}
                    >
                        {installed && checkRefreshTokenExpire() ? "Try again" : installed ? "Connected" : "Connect"}
                    </button>
                    <ul>
                        <li>
                            {installed ? (
                                <h4>
                                    Installed by {user.email} on {timeConverter(localStorage.getItem("salesforce_access_token_expire"))}
                                </h4>
                            ) : (
                                <p>Next: Confirm authorization and complete installation</p>
                            )}
                            {installed && checkRefreshTokenExpire() && (
                                <span>
                                    <span>
                                        <div className="info-box">
                                            <InfoSVG />
                                            <p>
                                                We failed to authenticate your Salesforce – Please{" "}
                                                <a className="link-to-outreach" href="https://accounts.outreach.io/users/sign_in">
                                                    click Here
                                                </a>{" "}
                                                to sign in to your Salesforce account and then try connecting again.
                                            </p>
                                        </div>
                                    </span>
                                    <div className="external-button-wrapper">
                                        <button onClick={handleInstallClick} className="external-app-settings-button">
                                            External App Settings
                                        </button>
                                        <SideArrow />
                                    </div>
                                </span>
                            )}
                        </li>
                        <li>
                            <p>Categories</p>
                            <p>
                                <label>SalesForce.com</label>
                            </p>
                        </li>
                        <li>
                            <p>Developers links</p>
                            <div>Samplead & SFDC</div>
                        </li>
                        {installed && (
                            <>
                                <div className="uninstall-container">
                                    <Button onClick={() => setUninstallPopUp(true)} variant={BUTTON_VARIANT.SECONDARY} className="uninstall">
                                        Disconnect
                                    </Button>
                                    <p>Requires confirmation</p>
                                    {uninstallPopUp && <UninstallModal setUninstallPopUp={setUninstallPopUp} user={user} />}
                                </div>
                                <div>
                                    <Button onClick={() => setUpdateBlacklistPopup(true)} variant={BUTTON_VARIANT.PRIMARY}>
                                        Update Blacklist
                                    </Button>
                                    <UpdateBlacklistModal
                                        isModalOpen={updateBlacklistPopup}
                                        closeModal={() => {
                                            setUpdateBlacklistPopup(false);
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const IntegrateSalesforceConfirm = ({ setConfirmPopUp, installed }) => {
    const [externalPopup, setExternalPopup] = useState(null);
    const [error, setError] = useState("");
    const [domainName, setDomainName] = useState("");
    const [gotCode, setGotCode] = useState(false);

    const user = useSelector((state) => state.user.user);

    console.log(installed);

    useEffect(() => {
        if (!externalPopup) {
            return;
        }

        const timer = setInterval(() => {
            if (!externalPopup) {
                timer && clearInterval(timer);
                return;
            }
            const currentUrl = externalPopup.location.href;
            if (!currentUrl) {
                return;
            }
            const searchParams = new URL(currentUrl).searchParams;
            const code = searchParams.get("code");
            if (code && !gotCode) {
                setGotCode(true);
                console.log("got code");
                console.log(code);
                externalPopup.close();
                getAuthToken(code, normalizeDomainName(domainName));
            } else {
                console.log("no code");
            }
        }, 50);
    }, [externalPopup]);

    const handleOutsideClick = () => {
        setConfirmPopUp(false);
    };

    const getAuthToken = async (code, domainName) => {
        try {
            const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/salesforce/token`, { code, domainName });
            console.log(data);
            if (data.access_token) {
                // issued_at + 2 hours
                const expireTime = +data.issued_at / 1000 + 7200;
                localStorage.setItem("salesforce_access_token", data.access_token);
                localStorage.setItem("salesforce_refresh_token", data.refresh_token);
                localStorage.setItem("salesforce_access_token_expire", expireTime);

                window.location.reload(false);
            } else {
                setError("Something went wrong. Please try again.");
                console.log(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const connectClick = async (e) => {
        if (!domainName) {
            setError("Please enter your domain name");
            return;
        }
        const left = window.screenX + (window.outerWidth - 500) / 2;
        const top = window.screenY + (window.outerHeight - 400) / 2.5;
        const title = `WINDOW TITLE`;
        const normalizedDomainName = normalizeDomainName(domainName);
        const url = env.REACT_APP_SALESFORCE_AUTH_URL(normalizedDomainName);
        const popup = window.open(url, title, `popup=${"true"},width=${500},height=${400},left=${left},top=${top}`);
        setExternalPopup(popup);
    };

    return (
        <>
            <div className="confirm-popup-outside" onClick={handleOutsideClick}></div>
            <div className="pop-up">
                <div className="joined-logos">
                    <img src={sampleadLogo} alt="Samplead" className="samplead-image" />
                    <p>+</p>
                    <img src={salesforceFullLogo} alt="Salesforce" className="integrations-image" />
                </div>
                <div className="info-container">
                    <h4>Thank you for adding Salesforce to Samplead</h4>
                    <p>Your users can now export Samplead's personalized messages directly to SFDC-.</p>
                    <h4 className="p-0">This integration will give Samplead access to the following Salesforce data:</h4>
                </div>
                <ul className="access-point-list">
                    {["Create account", "Create contact", "Update contact", "Update blacklist", "Recognize Salesforce triggers"].map(
                        (accessPoint) => {
                            return (
                                <li className="access-point" key={accessPoint}>
                                    <CheckmarkSVG />
                                    <p>{accessPoint}</p>
                                </li>
                            );
                        }
                    )}
                </ul>
                <div className="flex items-center pl-5 gap-1">
                    <label htmlFor="domain-name">Domain name:</label>
                    <input
                        type="text"
                        id="domain-name"
                        placeholder="Enter your domain name"
                        className="rounded-md border-2 p-2 w-2/3"
                        value={domainName}
                        onChange={(e) => {
                            setDomainName(e.target.value);
                        }}
                    />
                </div>
                <div className="buttons">
                    <button onClick={connectClick} className={installed ? "retry-integration" : "install-btn"}>
                        {installed ? "Try again" : "Confirm"}
                    </button>
                    <button onClick={() => setConfirmPopUp(false)} className="cancel-btn">
                        Cancel
                    </button>
                </div>
                {error !== "" && <p className="error">{error}</p>}
            </div>
        </>
    );
};

const CheckmarkSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
                d="M7.00016 13.6667C3.31816 13.6667 0.333496 10.682 0.333496 7C0.333496 3.318 3.31816 0.333332 7.00016 0.333332C10.6822 0.333332 13.6668 3.318 13.6668 7C13.6668 10.682 10.6822 13.6667 7.00016 13.6667ZM7.00016 12.3333C8.41465 12.3333 9.7712 11.7714 10.7714 10.7712C11.7716 9.77104 12.3335 8.41449 12.3335 7C12.3335 5.58551 11.7716 4.22896 10.7714 3.22876C9.7712 2.22857 8.41465 1.66667 7.00016 1.66667C5.58567 1.66667 4.22912 2.22857 3.22893 3.22876C2.22873 4.22896 1.66683 5.58551 1.66683 7C1.66683 8.41449 2.22873 9.77104 3.22893 10.7712C4.22912 11.7714 5.58567 12.3333 7.00016 12.3333ZM6.3355 9.66667L3.50683 6.838L4.4495 5.89533L6.3355 7.78133L10.1062 4.01L11.0495 4.95266L6.3355 9.66667Z"
                fill="#2ED47A"
            />
            <path
                d="M7.00016 13.6667C3.31816 13.6667 0.333496 10.682 0.333496 7C0.333496 3.318 3.31816 0.333332 7.00016 0.333332C10.6822 0.333332 13.6668 3.318 13.6668 7C13.6668 10.682 10.6822 13.6667 7.00016 13.6667ZM7.00016 12.3333C8.41465 12.3333 9.7712 11.7714 10.7714 10.7712C11.7716 9.77104 12.3335 8.41449 12.3335 7C12.3335 5.58551 11.7716 4.22896 10.7714 3.22876C9.7712 2.22857 8.41465 1.66667 7.00016 1.66667C5.58567 1.66667 4.22912 2.22857 3.22893 3.22876C2.22873 4.22896 1.66683 5.58551 1.66683 7C1.66683 8.41449 2.22873 9.77104 3.22893 10.7712C4.22912 11.7714 5.58567 12.3333 7.00016 12.3333ZM6.3355 9.66667L3.50683 6.838L4.4495 5.89533L6.3355 7.78133L10.1062 4.01L11.0495 4.95266L6.3355 9.66667Z"
                fill="#007D39"
            />
        </svg>
    );
};
const InfoSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 5H11V7H9V5ZM9 9H11V15H9V9Z"
                fill="#7A7A7A"
            />
        </svg>
    );
};

const UninstallModal = ({ user, setUninstallPopUp }) => {
    const uninstallClick = async () => {
        try {
            const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/settings/update/single`, [
                {
                    key: "salesforce_integration",
                    value: 0,
                    user: user,
                },
            ]);
            console.log(data);
            if (data.status === "ok") {
                localStorage.removeItem("salesforce_access_token");
                localStorage.removeItem("salesforce_refresh_token");
                localStorage.removeItem("salesforce_access_token_expire");
            }
        } catch (error) {
            console.log(error);
        }
        setUninstallPopUp(false);
        window.location.reload(false);
    };

    return (
        <>
            <div className="confirm-popup-outside"></div>
            <div className="pop-up">
                <div className="joined-logos">
                    <img src={sampleadLogo} alt="Samplead" className="samplead-image" />
                    <p>+</p>
                    <img src={salesforceFullLogo} alt="Salesforce" className="integrations-image" />
                </div>
                <div className="info-container">
                    <h4>This integration currently permits access to the following Salesforce data:</h4>
                </div>
                <ul className="access-point-list">
                    {["Create account", "Create contact", "Update contact", "Update blacklist", "Recognize Salesforce triggers"].map(
                        (accessPoint) => {
                            return (
                                <li className="access-point" key={accessPoint}>
                                    <CheckmarkSVG />
                                    <p>{accessPoint}</p>
                                </li>
                            );
                        }
                    )}
                    <div className="buttons">
                        <button className="uninstall-button" onClick={uninstallClick}>
                            Confirm uninstall
                        </button>
                        <button onClick={() => setUninstallPopUp(false)} className="cancel-btn">
                            Cancel
                        </button>
                    </div>
                </ul>
            </div>
        </>
    );
};

const UpdateBlacklistModal = (props) => {
    const [statusList, setStatusList] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState({});
    const [blacklistFlag, setBlacklistFlag] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (statusList.length > 0) return;
        async function handleGetStatuses() {
            const refreshToken = localStorage.getItem("salesforce_refresh_token");
            await handleSalesforceRefreshToken(refreshToken);
            const salesforce_access_token = localStorage.getItem("salesforce_access_token");
            try {
                const res = await axios.post(`${env.REACT_APP_BACKEND_URL}/salesforce/get_account_fields`, {
                    salesforce_access_token,
                });
                console.log(res);
                if (res.data.success) {
                    res.data.accountStatuses && setStatusList(res.data.accountStatuses);
                    if (res.data.blacklistSettings) {
                        const blacklistSettings = res.data.blacklistSettings;
                        const blacklistSettingsObj = {};
                        blacklistSettings.blacklist_parameters.forEach((status) => {
                            blacklistSettingsObj[status] = true;
                        });
                        setSelectedStatuses(blacklistSettingsObj);
                        setBlacklistFlag(blacklistSettings.blacklist_flag);
                    }
                } else {
                    setStatusList([]);
                }
            } catch (error) {
                console.log(error);
            }
        }
        handleGetStatuses();
    }, [props.isModalOpen]);

    const updateBlacklist = async (blacklist_parameters, blacklist_flag) => {
        const salesforce_access_token = localStorage.getItem("salesforce_access_token");
        try {
            const res = await axios.post(`${env.REACT_APP_BACKEND_URL}/salesforce/update_blacklist`, {
                salesforce_access_token,
                blacklist_parameters,
                blacklist_flag,
            });
            console.log(res);
            props.closeModal();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal {...props}>
            <div className="blacklist-modal-header">
                <h4>Update Blacklist</h4>
            </div>

            {statusList?.length ? (
                <>
                    <div className="blacklist-modal-body">
                        <Button
                            variant={blacklistFlag ? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY}
                            onClick={() => {
                                setBlacklistFlag(true);
                            }}
                        >
                            Blacklist Selected Statuses
                        </Button>
                        <Button
                            variant={!blacklistFlag ? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY}
                            onClick={() => {
                                setBlacklistFlag(false);
                            }}
                        >
                            Whitelist Selected Statuses
                        </Button>
                        <div className="blacklist-modal-body-list">
                            {statusList.map((status) => {
                                return (
                                    <div className="blacklist-modal-body-list-item" key={status}>
                                        <input
                                            type="checkbox"
                                            id={status}
                                            name={status}
                                            value={status}
                                            checked={selectedStatuses[status]}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedStatuses((prev) => {
                                                        return { ...prev, [e.target.value]: true };
                                                    });
                                                } else {
                                                    setSelectedStatuses((prev) => {
                                                        const { [e.target.value]: _, ...rest } = prev;
                                                        return rest;
                                                    });
                                                }
                                            }}
                                        />
                                        <label htmlFor={status}>{status}</label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className="blacklist-modal-footer">
                        <Button
                            onClick={async () => {
                                setLoading(true);
                                await updateBlacklist(Object.keys(selectedStatuses), blacklistFlag);
                                setLoading(false);
                            }}
                            variant={BUTTON_VARIANT.PRIMARY}
                            disabled={loading}
                        >
                            {loading ? <Spinner contHeight="30px" width="30px" /> : "Update"}
                        </Button>

                        <Button onClick={props.closeModal} variant={BUTTON_VARIANT.DANGER}>
                            Cancel
                        </Button>
                    </div>
                </>
            ) : (
                <Spinner />
            )}
        </Modal>
    );
};

export default IntegrateSalesforce;

function normalizeDomainName(domainName) {
    domainName = domainName.replace(/\.com\/.*/, ".com");
    if (domainName.includes("https://")) {
        domainName = domainName.replace("https://", "");
    }
    if (domainName.includes(".my.salesforce.com")) {
        domainName = domainName.replace(".my.salesforce.com", "");
    }
    if (domainName.includes(".lightning.force.com")) {
        domainName = domainName.replace(".lightning.force.com", "");
    }
    domainName = domainName.replace(/^\/+|\/+$/g, "");
    return domainName;
}
