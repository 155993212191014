import React, { useState, useRef } from "react";
import moment from "moment/moment";
import { useEffect } from "react";

const HotnessIcon = ({ lead }) => {
    const [show, setShow] = useState(false);
    const [style, setStyle] = useState({});

    if(!lead) return null;

    const handleMouseOver = () => {
        setShow(true);
    };

    const handleMouseOut = () => {
        setShow(false);
    };

    const ExplainFireMessage = ({ fireType }) => {
        const elementRef = useRef(null);

        const getOffset = (el) => {
            // get right and bottom offset
            const rect = el.getBoundingClientRect();

            return rect.right - window.innerWidth > 0 ? rect.right - window.innerWidth : 0;
        };

        useEffect(() => {
            if (!elementRef.current || Object.keys(style).length) {
                return;
            }
            setStyle({ left: `-${getOffset(elementRef.current)}px` });
        }, [elementRef]);

        const getFireMessage = (fireType) => {
            console.log(fireType);
            switch (fireType) {
                case "high":
                    return "The prospect is HOT. Reach out today to increase your chances of a reply";
                case "mid":
                    return "The prospect is still hot - but it's getting colder... To increase your chance of a response, reach out today!";
                case "low":
                    return "We will delete this prospect shortly, the system automatically deletes prospects When they get too cold. Reach out before it's gone.";
                default:
                    return "This is a Indicator regarding the lead's hotness";
            }
        };

        return (
            <>
                <div ref={elementRef} className="message-on-hover" style={style}>
                    {getFireMessage(fireType)}
                </div>
                <div className="chat-bubble"></div>
            </>
        );
    };

    const dayDiff = moment(new Date()).diff(lead.updatedAt, "d");
    const icon = dayDiff < 7 ? "high" : dayDiff < 14 ? "mid" : "low";

    return (
        <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut} className="hotness-icon-container">
            <div style={{ width: "25px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={`../../images/${icon}.png`} style={{ height: "26px" }} alt="" />
            </div>
            {show && <ExplainFireMessage fireType={icon} />}
        </div>
    );
};

export default HotnessIcon;
