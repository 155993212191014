import React from "react";

import Modal from "components/Modal";
import SuggestionsList from "components/SuggestionsList";

export const SuggestedLeadsMenu = ({ campaignId, isOpen, onClose, suggestedLeads, onApproveLead, onDenyLead }) => (
    <Modal isModalOpen={isOpen} closeModal={onClose} className="suggestion-list-modal">
        <SuggestionsList
            campaignId={campaignId}
            suggestedLeads={suggestedLeads}
            onCloseClick={onClose}
            onApproveLead={onApproveLead}
            onDenyLead={onDenyLead}
        />
    </Modal>
);
