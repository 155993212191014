import React from "react";
import { useSelector } from "react-redux";

import env from "config/site.config.js";

function Stats({ campaign }) {
    const user = useSelector((state) => state.user.user);

    return (
        <iframe
            src={`${env.STATISTICS_API_URL}/?token=${user.token}&campaign_id=${campaign.id}`}
            width={"1400"}
            height={"600"}
            title="statistics"
        ></iframe>
    );
}

export default Stats;
