import axios from "axios";
import env from "config/site.config";

const log = require("loglevel").getLogger("CampaignService");
log.setLevel("error");

export const getNewReplies = async () => {
    log.debug("CampaignService -> getCampaigns -> Enter");

    const url = `${env.REACT_APP_BACKEND_URL}/replies/get-new`;
    return axios
        .post(url, {})
        .then(async (response) => {
            const res = response.data;
            log.debug("CampaignService -> getCampaigns -> response: " + JSON.stringify(res));
            return res?.data;
        })
        .catch((response) => {
            log.debug("CampaignService -> getCampaigns -> error: ");
            log.debug(response);
            return null;
        });
};
