import React, { useState, useEffect } from "react";
import ReactDropdown from "react-dropdown";
import Button from "../Button/Button";
import { CheckCircleFilled } from "@ant-design/icons";

import "./ManualDiscoveryTable.scss";

const ManualDiscoveryTable = ({ csvData, onColumnsConfirm, mappedObjSaved, setMappedObjSaved, setError, clearCSV }) => {
    const [data, setData] = useState({});
    const [placeHolder, setPlaceholder] = useState(0);
    const [random, setRandom] = useState(0);
    const [validMapped, setValidMapped] = useState(true);
    const [randomMapped, setRandomMapped] = useState(0);

    useEffect(() => {
        let mappedData = {};
        csvData.fields.forEach((field) => {
            Object.assign(mappedData, { [field]: field });
        });
        setData(mappedData);
        setRandom(Math.floor(Math.random() * csvData.data.length));
        mappedObjSaved && setRandomMapped(Math.floor(Math.random() * mappedObjSaved.length));
    }, [mappedObjSaved, csvData]);

    const handleSelectChange = (field) => {
        const reMapped = [];
        csvData.data.forEach((lead, idx) => {
            Object.keys(lead).map((column) => {
                if (column === field) {
                    let leadProperty = lead[column].toString();

                    if (
                        typeof leadProperty === "string" &&
                        !leadProperty.includes("linkedin.com/company/") &&
                        (leadProperty.includes("www") || leadProperty.includes("linkedin"))
                    ) {
                        if (!leadProperty.includes("linkedin.com/company/")) {
                            reMapped.push({
                                url: leadProperty,
                                columnName: Object.keys(lead).find((column) => lead[column] === leadProperty),
                            });
                        }
                    }
                }
            });
        });
        if (reMapped.length > 0) {
            setValidMapped(true);
            setMappedObjSaved(reMapped);
        } else {
            setValidMapped(false);
            setError("No Links in Selected Column");
        }
    };

    function handleSelectChangeEmail(field) {
        const mappedObjSavedCopy = [...mappedObjSaved];

        csvData.data.forEach((lead, idx) => {
            Object.keys(lead).map((column) => {
                console.log(mappedObjSavedCopy[idx] !== undefined && mappedObjSavedCopy[idx].url);
                if (column === field) {
                    let leadProperty = lead[column].toString();
                    const current_url = mappedObjSavedCopy[idx] !== undefined && mappedObjSavedCopy[idx].url;
                    console.log(leadProperty);
                    if (mappedObjSavedCopy[idx] && typeof leadProperty === "string" && leadProperty !== current_url) {
                        mappedObjSavedCopy[idx].email = leadProperty;
                    } 
                }
            });
        });
        setMappedObjSaved(mappedObjSavedCopy);
    }

    function handleSelectChangField(field, key) {
        const mappedObjSavedCopy = [...mappedObjSaved];

        csvData.data.forEach((lead, idx) => {
            Object.keys(lead).map((column) => {
                console.log(mappedObjSavedCopy[idx] !== undefined && mappedObjSavedCopy[idx].url);
                if (column === field) {
                    let leadProperty = lead[column].toString();
                    const current_url = mappedObjSavedCopy[idx] !== undefined && mappedObjSavedCopy[idx].url;
                    console.log(leadProperty);
                    if (mappedObjSavedCopy[idx] && typeof leadProperty === "string" && leadProperty !== current_url) {
                        mappedObjSavedCopy[idx][key] = leadProperty;
                    } 
                }
            });
        });
        setMappedObjSaved(mappedObjSavedCopy);
    }

    const handleClick = () => {
        if (validMapped) {
            let valid = true;

            mappedObjSaved.forEach((lead) => {
                if (!lead.url || !lead.title || !lead.company || !lead.name) {
                    valid = false;
                }
            });

            if (!valid) {
                setError("All Leads Must Have a URL ,Name, Title, and Company");
                return;
            }

            onColumnsConfirm(data);
        } else {
            setError("Please Select a Column with Valid Links");
        }
    };
    return (
        <>
            <div>
                <div className="samplead-body--large" key="1">
                    <p>
                        Found <strong>URLs</strong> in Column labeled{" "}
                        <strong>{mappedObjSaved !== null && mappedObjSaved[randomMapped] && mappedObjSaved[randomMapped].columnName}</strong>
                    </p>
                    <p>Would you like to select a different one?</p>
                </div>
                <ReactDropdown
                    className="dropdown"
                    options={[...csvData.fields]}
                    onChange={(e) => handleSelectChange(e.value)}
                    // value={field}
                    placeholder={(mappedObjSaved && mappedObjSaved[randomMapped] && mappedObjSaved[randomMapped].columnName) || "Choose Column"}
                />
                <div className="samplead-body--large" key="3">
                    <br />
                    <p>
                        Select a column for <strong>Name</strong>
                    </p>
                </div>
                <ReactDropdown
                    className="dropdown"
                    options={[...csvData.fields]}
                    onChange={(e) => handleSelectChangField(e.value, "name")}
                    // value={field}
                    placeholder={(mappedObjSaved && mappedObjSaved[randomMapped] && mappedObjSaved[randomMapped].columnName) || "Choose Column"}
                />
                <div className="samplead-body--large" key="3">
                    <br />
                    <p>
                        Select a column for <strong>Title</strong>
                    </p>
                </div>
                <ReactDropdown
                    className="dropdown"
                    options={[...csvData.fields]}
                    onChange={(e) => handleSelectChangField(e.value, "title")}
                    // value={field}
                    placeholder={(mappedObjSaved && mappedObjSaved[randomMapped] && mappedObjSaved[randomMapped].columnName) || "Choose Column"}
                />
                <div className="samplead-body--large" key="3">
                    <br />
                    <p>
                        Select a column for <strong>Company</strong>
                    </p>
                </div>
                <ReactDropdown
                    className="dropdown"
                    options={[...csvData.fields]}
                    onChange={(e) => handleSelectChangField(e.value, "company")}
                    // value={field}
                    placeholder={(mappedObjSaved && mappedObjSaved[randomMapped] && mappedObjSaved[randomMapped].columnName) || "Choose Column"}
                />
                <div className="samplead-body--large" key="2">
                    <br />
                    <p>
                        Select a column for <strong>Emails</strong>
                    </p>
                </div>
                <ReactDropdown
                    className="dropdown"
                    options={[...csvData.fields]}
                    onChange={(e) => handleSelectChangeEmail(e.value)}
                    // value={field}
                    placeholder={(mappedObjSaved && mappedObjSaved[randomMapped] && mappedObjSaved[randomMapped].columnName) || "Choose Column"}
                />
            </div>
            <table>
                <thead>
                    <tr>
                        <th className="samplead-body--large" key="1">
                            Column label{" "}
                        </th>
                        <th className="samplead-body--large" key="2">
                            Example Value
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {mappedObjSaved &&
                        mappedObjSaved.map((obj, idx) => {
                            if (idx === randomMapped) {
                                return (
                                    <tr className="samplead-body" key={idx}>
                                        <td>url</td>
                                        <td>
                                            {obj.url}
                                            <CheckCircleFilled style={{ color: "#52c41a", margin: "3px" }} />
                                        </td>
                                    </tr>
                                );
                            }
                        })}
                </tbody>
                <tbody>
                    {mappedObjSaved &&
                        mappedObjSaved.map((obj, idx) => {
                            if (idx === randomMapped && obj.company) {
                                return (
                                    <tr className="samplead-body" key={idx}>
                                        <td>company</td>
                                        <td>
                                            {obj?.company}
                                            <CheckCircleFilled style={{ color: "#52c41a", margin: "3px" }} />
                                        </td>
                                    </tr>
                                );
                            }
                        })}
                </tbody>
                <tbody>
                    {mappedObjSaved &&
                        mappedObjSaved.map((obj, idx) => {
                            if (idx === randomMapped && obj.name) {
                                return (
                                    <tr className="samplead-body" key={idx}>
                                        <td>name</td>
                                        <td>
                                            {obj?.name}
                                            <CheckCircleFilled style={{ color: "#52c41a", margin: "3px" }} />
                                        </td>
                                    </tr>
                                );
                            }
                        })}
                </tbody>
                <tbody>
                    {mappedObjSaved &&
                        mappedObjSaved.map((obj, idx) => {
                            if (idx === randomMapped && obj.email) {
                                return (
                                    <tr className="samplead-body" key={idx}>
                                        <td>email</td>
                                        <td>
                                            {obj.email}
                                            <CheckCircleFilled style={{ color: "#52c41a", margin: "3px" }} />
                                        </td>
                                    </tr>
                                );
                            }
                        })}
                </tbody>
            </table>
            <div className="csv_buttons">
                <Button variant="secondary" onClick={handleClick}>
                    Send CSV for processing{" "}
                </Button>
                <Button variant="secondary" onClick={clearCSV}>
                    Clear CSV
                </Button>
            </div>
        </>
    );
};

ManualDiscoveryTable.propTypes = {};

export default ManualDiscoveryTable;
