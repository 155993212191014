import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";

import Text from "components/Text";
import { changeLeadStatus } from "redux/Campaign/reducer";
import { LeadStatus } from "utils/constants";
import "./SourceLead.css";

const LazyLeadImageComponent = lazy(() => import("components/LeadImageComponent"));

const SourceLead = ({ lead }) => {
    const dispatch = useDispatch();
    const found = lead.status === LeadStatus.Active && lead.origin === "your list" ? "source-lead-row-found" : "";
    const leadCompany =
        lead && lead.user_data && lead.user_data.company_name ? lead.user_data.company_name : lead.company_name ? lead.company_name : "";

    const removeLead = () => {
        console.log("remove lead");
        dispatch(changeLeadStatus({ leadId: lead.id, status: LeadStatus.Removed }));
    };

    useEffect(() => {
        console.log("lead", lead);
        console.log("lead.name", lead.name);
    }, [lead]);

    return (
        <>
            <div className={`source-lead-row ${found}`}>
                <div className={`source-lead-row-item`}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <LazyLeadImageComponent lead={lead} />
                    </Suspense>
                    <Text className="source-lead-row-item">{lead?.name?.length > 15 ? lead.name.substring(0, 15) + "..." : lead.name}</Text>
                </div>
                <div className={`source-lead-row-item`}>
                    <Text className="source-lead-row-item">
                        {lead.title && lead.title.length > 15 ? lead.title.substring(0, 20) + "..." : lead.title}
                    </Text>
                </div>
                <div className={`source-lead-row-item`}>
                    <Text className="source-lead-row-item">{leadCompany.length > 15 ? leadCompany.substring(0, 20) + "..." : leadCompany}</Text>
                </div>
                <div className={`source-lead-row-item`}>
                    <Text className="source-lead-row-item">{lead.email}</Text>
                </div>
                <div className={`source-lead-row-item`}>
                    <Text className="source-lead-row-item">{lead.createdAt.split("T")[0]}</Text>
                </div>
                <div className={`source-lead-row-item`}>
                    <button className="source-lead-row-delete" onClick={() => removeLead()}>
                        Delete
                    </button>
                </div>
            </div>
        </>
    );
};

export default SourceLead;
