import * as actionTypes from "./actionTypes";
import * as sequenceService from "./service";

const log = require("loglevel").getLogger("CampaignActions");
log.setLevel("debug");

export const getSequence = () => {
    return async (dispatch) => {
        const steps = await sequenceService.getSequence();
        await dispatch({
            type: actionTypes.LOAD_SEQUENCE,
            data: { steps },
        });
    };
};

export const generateSequence = (callback) => {
    return async (dispatch, getState) => {
        const { originalSteps, generatedSteps, stepsToUpdate, subject, modifyTo } = getState().sequence;
        const subjectValue = Object.keys(subject)
            .find((key) => subject[key])
            .toLowerCase()
            .replaceAll(" ", "_");
        let modifyToValue = Object.keys(modifyTo)
            .find((key) => modifyTo[key])
            .toLowerCase()
            .replaceAll(" ", "_");
        let freeText = "";
        if (modifyToValue.startsWith("free_text:")) {
            [modifyToValue, freeText] = modifyToValue.split(":_");
        }
        const generatedStepsUpdated = await sequenceService.generateSequence(
            originalSteps,
            generatedSteps,
            stepsToUpdate,
            subjectValue,
            modifyToValue,
            freeText
        );
        await dispatch({
            type: actionTypes.GENERATE_SEQUENCE,
            data: { generatedSteps: generatedStepsUpdated },
        });
        callback();
    };
};

export const addStep = () => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.ADD_STEP,
        });
    };
};

export const removeStep = () => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.REMOVE_STEP,
        });
    };
};

export const updateStep = (stepId, { subject, message, messageIndex }, original) => {
    return async (dispatch, getState) => {
        const sequence = getState().sequence[original ? "originalSteps" : "generatedSteps"];
        let previousSubject, previousMessage;

        if (sequence && sequence[stepId]) {
            previousSubject = sequence[stepId].subject;
            previousMessage = sequence[stepId].message;
        } else {
            console.error(`Step with id ${stepId} does not exist in the sequence.`);
            // Handle the error appropriately here, for example by returning early
            return;
        }

        subject = subject !== undefined && subject !== null ? subject : previousSubject;
        message = message !== undefined && message !== null ? message : previousMessage;

        await dispatch({
            type: actionTypes.UPDATE_STEP,
            data: { stepId, subject, message, original, messageIndex },
        });
    };
};

export const changeStepsToUpdate = (stepsToUpdate) => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.CHANGE_STEPS_TO_UPDATE,
            data: { stepsToUpdate },
        });
    };
};

export const changeSubject = (subject) => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.CHANGE_SUBJECT,
            data: { subject },
        });
    };
};

export const changeModifyTo = (modifyTo) => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.CHANGE_MODIFY_TO,
            data: { modifyTo },
        });
    };
};

export const rephraseStep = (stepId, callback) => {
    console.log("stepId", stepId);
    return async (dispatch, getState) => {
        const { originalSteps, subject, modifyTo } = getState().sequence;
        const subjectValue = Object.keys(subject)
            .find((key) => subject[key])
            .toLowerCase()
            .replaceAll(" ", "_");
        const modifyToValue = Object.keys(modifyTo)
            .find((key) => modifyTo[key])
            .toLowerCase()
            .replaceAll(" ", "_");
        try {
            const response = await sequenceService.getRephrasedStep(originalSteps, subjectValue, modifyToValue, stepId);
            const newStep = response.data;
            await dispatch({
                type: actionTypes.REPHRASE_STEP,
                data: { stepId, newStep },
            });
        } catch (error) {
            log.error(error);
        }
        callback();
    };
};

export const changeFreeText = (freeText) => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.CHANGE_FREE_TEXT,
            data: { freeText },
        });
    };
};

export const toggleShowStep = (stepId, original) => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.TOGGLE_SHOW_STEP,
            data: { stepId, original },
        });
    };
};

export const toggleShowAllSteps = (original, toShow) => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.TOGGLE_SHOW_ALL_STEPS,
            data: { original, toShow },
        });
    };
};

export const setCurrentlyEditing = (currentlyEditing) => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.SET_CURRENTLY_EDITING,
            data: { currentlyEditing },
        });
    };
};

export const setCursorPosition = (position) => {
    return {
        type: actionTypes.SET_CURSOR_POSITION,
        data: position,
    };
};

export const saveSequence = (steps, callback) => {
    console.log("steps", steps);
    return async (dispatch, getState) => {
        try {
            await sequenceService.saveSequence(steps);
            await dispatch({
                type: actionTypes.SAVE_SEQUENCE,
            });
            callback();
        } catch (error) {
            log.error(error);
        }
    };
};

export const getSequenceByCampaignId = ({ id, callback }) => {
    const campaignId = id;
    return async (dispatch) => {
        try {
            const response = await sequenceService.getSequenceByCampaignId(campaignId);

            if (!response || !response.data) {
                log.error("Response or data from response is null or undefined");
                return;
            }

            const steps = response.data.steps;

            if (!steps) {
                log.error("No steps in the response data");
                return;
            }

            const savedSteps = await dispatch({
                type: actionTypes.LOAD_GENERATED_SEQUENCE,
                data: { steps },
            });

            return savedSteps;
        } catch (error) {
            log.error(error);
        } finally {
            callback();
        }
    };
};

export const setSteps = (steps) => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.SET_STEPS,
            data: { steps },
        });
    };
};

export const loadGeneratedSteps = (campaignId) => {
    return async (dispatch) => {
        const steps = await sequenceService.getSequenceByCampaignId(campaignId);
        await dispatch({
            type: actionTypes.LOAD_GENERATED_SEQUENCE,
            data: { steps },
        });
    };
};

export const setUnsavedChanges = (unsavedChanges) => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.SET_UNSAVED_CHANGES,
            data: { unsavedChanges },
        });
    };
};

export const setStepTimeDelay = ({ step, timeDelay }) => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.SET_STEP_TIME_DELAY,
            data: { stepId: step, timeDelay },
        });
    };
};

export const getRephrasedStep = ({ step, campaignId, callback, currentMessage }) => {
    return async (dispatch, getState) => {
        try {
            const response = await sequenceService.getRephrasedStep(step, campaignId, currentMessage);
            const newStep = response.data;
            await dispatch({
                type: actionTypes.GET_REPHRASED_STEP,
                data: { stepId: step, newStep },
            });
        } catch (error) {
            log.error(error);
        } finally {
            callback();
        }
    };
};

export const changeMessageIndex = (data) => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.CHANGE_MESSAGE_INDEX_FOR_STEP,
            data,
        });
    };
};

export const rephraseWithCustomText = ({ step, campaignId, customText, currentMessage, callback }) => {
    return async (dispatch) => {
        try {
            const response = await sequenceService.rephraseWithCustomText(step, campaignId, customText, currentMessage);
            const newStep = response?.data?.rephrasedStep;
            await dispatch({
                type: actionTypes.REPHRASE_WITH_CUSTOM_TEXT,
                data: { id: step, campaignId, newStep },
            });
        } catch (error) {
            log.error(error);
        } finally {
            callback();
        }
    };
};

export const setEmailOrLinkedIn = (emailOrLinkedIn) => {
    return async (dispatch) => {
        await dispatch({
            type: actionTypes.SET_EMAIL_OR_LINKEDIN,
            data: { emailOrLinkedIn },
        });
    };
};
