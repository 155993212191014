import React, { useState } from "react";
import { useSelector } from "react-redux";

import { FormInput } from "components/Form/Form";
import RadioButton from "components/RadioButton";
import NavButtons from "components/NavButtons/NavButtons";
import { remove_or_add_from_array } from "utils/general";
import { DISABLE_ERRORS } from "utils/constants";
import { Switch } from "antd";
import "../onboarding.css";

const GeoLocationsPage = ({ handleNavClick, options, checkboxWidth, columns }) => {
    const settings = useSelector((state) => state.settings);

    const column_class = columns ? `checkbox-col-${columns}` : "";
    const checkbox_width = checkboxWidth ? `checkbox-w-${checkboxWidth}` : "";

    const [geoLocations, setGeoLocations] = useState(settings.geographical_targets); // for checkboxes

    const [includeSpecificTerritories, setIncludeSpecificTerritories] = useState(settings.specific_territories.length > 0);
    const [specificTerritories, setSpecificTerritories] = useState(settings.specific_territories.join(", "));

    const [includeExcludedCities, setIncludeExcludedCities] = useState(settings.excluded_cities.length > 0);
    const [excludedCities, setExcludedCities] = useState(settings.excluded_cities.join(", "));

    const [errorMsg, setErrorMsg] = useState("");

    const update_input_value = (e) => {
        // if inside inputValue, remove it
        setGeoLocations(remove_or_add_from_array(geoLocations, e.target.value));
    };

    const next_clicked = (action) => {
        setErrorMsg(false);
        let hasError = false;
        // validate input
        if (action === "next") {
            if (geoLocations.length === 0) {
                setErrorMsg("Please select at least one option");
                hasError = true;
            }
        }
        if (hasError && !DISABLE_ERRORS) return;

        // create response obj to send to update function
        let response_obj = [];
        if (geoLocations.length > 0) {
            response_obj.push({
                key: "geographical_targets",
                value: geoLocations,
            });
        }
        if (includeSpecificTerritories && specificTerritories.length > 0) {
            response_obj.push({
                key: "specific_territories",
                value: specificTerritories
                    .split(",")
                    .map((item) => item.trim())
                    .filter((item) => item !== "" && item !== null),
            });
        } else if (!includeSpecificTerritories) {
            response_obj.push({
                key: "specific_territories",
                value: [],
            });
        }
        if (includeExcludedCities && excludedCities.length > 0) {
            response_obj.push({
                key: "excluded_cities",
                value: excludedCities
                    .split(",")
                    .map((item) => item.trim())
                    .filter((item) => item !== "" && item !== null),
            });
        } else if (!includeExcludedCities) {
            response_obj.push({
                key: "excluded_cities",
                value: [],
            });
        }
        // end create response obj
        handleNavClick(action, response_obj, "array");
    };

    return (
        <>
            <div className="form-container">
                <div className="input-header">
                    <div className="input-header-text">
                        <p>Geographic locations you are aiming at:</p>
                    </div>
                </div>
                <div className="checkbox-wrapper">
                    <div className={`checkbox-container ${checkbox_width}`}>
                        {options.map((option, i) => {
                            return (
                                <div key={i} className={`checkbox-input  ${column_class}`}>
                                    <div className="checkbox-input-wrapper">
                                        <input
                                            checked={geoLocations.includes(option)}
                                            type="checkbox"
                                            id={option}
                                            name={option}
                                            value={option}
                                            onClick={(e) => update_input_value(e)}
                                        />
                                        <label htmlFor={option}>{option}</label>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="input-error-msg">{errorMsg && <div className="error-msg">{errorMsg}</div>}</div>

                {/* Questions below checkbox inputs */}
                <div className="bottom-question-container">
                    <div className="radio-and-input-container right-question">
                        <div>
                            <Switch
                                checked={!includeSpecificTerritories}
                                onChange={() => setIncludeSpecificTerritories(!includeSpecificTerritories)}
                                style={{
                                    backgroundColor: includeSpecificTerritories ? "#d9d9d9" : "#1890ff",
                                }}
                            ></Switch>
                            <label className="ml-5">Do you have a specific territory?</label>
                        </div>
                        {!includeSpecificTerritories && (
                            <FormInput
                                inputValue={specificTerritories}
                                inputDisabled={includeSpecificTerritories}
                                type="text"
                                placeholder="City name"
                                onChange={(e) => setSpecificTerritories(e)}
                            />
                        )}
                    </div>
                    <div className="radio-and-input-container left-question">
                        <div>
                            <Switch
                                checked={!includeExcludedCities}
                                onChange={() => setIncludeExcludedCities(!includeExcludedCities)}
                                style={{
                                    backgroundColor: includeExcludedCities ? "#d9d9d9" : "#1890ff",
                                }}
                            >
                                {includeExcludedCities ? "No" : "Yes"}
                            </Switch>
                            <label className="ml-5">Do you want to exclude any cities?</label>
                        </div>
                        {!includeExcludedCities && (
                            <FormInput
                                inputValue={excludedCities}
                                inputDisabled={includeExcludedCities}
                                type="text"
                                placeholder="City name"
                                onChange={(e) => {
                                    setExcludedCities(e);
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <NavButtons handleNavClick={next_clicked} />
        </>
    );
};

export default GeoLocationsPage;
