import React from "react";
import { useState } from "react";
import "./PricingBox.css";
import { PAYWALL_LINK } from "utils/constants";
import { useSelector } from "react-redux";
import { Button, Modal, Input, Result } from "antd";
const { TextArea } = Input;

const PopularBadge = () => (
    <div
        style={{
            marginLeft: "auto",
            padding: "4px 14px",
            borderRadius: "30px",
            backgroundColor: "#2ed47a",
            color: "#fff",
            fontSize: "14px",
        }}
    >
        <div>Popular</div>
    </div>
);

function PricingBox({ topIcon, title, price, description, features, paymentLink, callToAction, showYearly, popularBadge }) {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState("");
    const [showResult, setShowResult] = useState(false);
    const user = useSelector((state) => state.user);
    const settings = useSelector((state) => state.settings);
    const [userMessage, setUserMessage] = useState(`
    Hi Samplead Team,

    This is ${settings?.full_name} from ${settings?.company_name}, 
    I would Love to know more about the ${title} plan.

    Thanks
    `);
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = async () => {
        setConfirmLoading(true);
        // async function to send email to sales team
        try {
            const response = await paymentLink(userMessage);
            console.log("response", response);
            if (response?.status === 200) {
                setModalText("Thank you for contacting us. We will get back to you shortly.");
            }
            // setOpen(false);
            setShowResult(true);
            setConfirmLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    const handleCancel = () => {
        console.log("Clicked cancel button");
        setOpen(false);
    };

    return (
        <div className="pricing-box pricing-1-box pricing-box-card">
            <Modal
                title="Contact us!"
                open={open}
                onOk={showResult ? () => setOpen(false) : handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    style: {
                        backgroundColor: "#a53aff",
                        color: "#fff",
                        border: "none",
                    },
                    value: "Send",
                }}
                confirmLoading={confirmLoading}
            >
                <p
                    style={{
                        marginBottom: "10px",
                    }}
                >
                    Leave us a message:
                </p>
                <TextArea style={{ minHeight: "200px" }} defaultValue={userMessage} onChange={setUserMessage} />
                {showResult && <Result status="success" title="Successfully sent your message" />}
            </Modal>
            <div className="pricing-box-plan-wrapper">
                <div className="header-pricing">
                    <img src={topIcon || ""} loading="lazy" alt="" className="icon-pricing" />
                    <div className="pricing-plan-text pricing-1-plan-text">{title}</div>
                    {popularBadge && <PopularBadge />}
                </div>
                <div className="pricing-plan-note-text">{description}</div>
                <div className="pricing-plan-price">
                    ${price}
                    <span className="pricing-plan-small-text">{showYearly ? "/year" : "/month"}</span>
                </div>
                <div>Our starter plan give you a taste of the cool features of Samplead</div>
            </div>
            <div className="pricing-plan-content-wrapper">
                {features?.map((benefit, index) => (
                    <div className="pricing-plan-content-item" key={index}>
                        <img
                            src="https://uploads-ssl.webflow.com/63e9fc56fd643fb7bc8bfe21/63ee5ca363ceed8c926a39fb_green-tick.png"
                            loading="lazy"
                            alt=""
                            className="pricing-plan-tick-icon"
                        />
                        <div className="pricing-plan-content-text">{benefit}</div>
                    </div>
                ))}
            </div>

            <a
                onClick={
                    //if payment link is a function, open modal
                    typeof paymentLink === "function"
                        ? () => {
                              showModal();
                          }
                        : null
                }
                href={paymentLink}
                style={{ minWidth: "320px" }}
                className="separator-button-2 pricing w-inline-block"
            >
                <div>{callToAction}</div>
            </a>
        </div>
    );
}

export default PricingBox;
