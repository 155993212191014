import React, { createRef, useLayoutEffect, useState } from "react";

import EditableText from "components/EditableText";

export const CampaignTitle = (props) => {
    const titleRef = createRef();
    const { editMode, title, onTitleUpdate, editable } = props;
    const [showMoreTitle, setShowMoreTitle] = useState(false);

    useLayoutEffect(() => {
        if (titleRef.current) {
            setShowMoreTitle(titleRef.current.offsetWidth < titleRef.current.scrollWidth);
        }
    }, [titleRef]);

    const handleTitleChange = (title) => {
        if (editable) {
            onTitleUpdate(title);
        }
    };

    return (
        <EditableText
            editable={editable}
            refValue={titleRef}
            initValue={title}
            updateValue={handleTitleChange}
            isEditing={editMode}
            editClassName="campaigns__header-title samplead-menu-header"
            showTooltip={showMoreTitle}
        />
    );
};
