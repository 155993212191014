import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as repliesApi from "./api";
import logger from "redux-logger";

const initialState = {
    replies: [],
    tabReplies: [],
    loading: false,
    error: null,
    focusedLead: null,
};

export const getNewReplies = createAsyncThunk("replies/getNewReplies", async () => {
    const response = await repliesApi.getNewReplies();
    return response;
});

const repliesSlice = createSlice({
    name: "replies",
    initialState,
    reducers: {
        setFocusedLead: (state, action) => {
            state.focusedLead = action.payload;
        },
        setTabReplies: (state, action) => {
            state.tabReplies = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNewReplies.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNewReplies.fulfilled, (state, action) => {
                console.log("action.payload", action.payload);
                state.replies = action.payload;
                state.loading = false;
            })
            .addCase(getNewReplies.rejected, (state, action) => {
                state.error = action.error;
                state.loading = false;
            });
    },
});

export const { setFocusedLead, setTabReplies } = repliesSlice.actions;

export default repliesSlice.reducer;
