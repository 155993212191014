import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import * as campaignActions from "redux/Campaign/reducer";
import Tabs from "components/Tabs/Tabs";
import { LeadStatus, PATH_DASHBOARD } from "utils/constants";
import CampaignHeader from "../inner-components/CampaignHeader";
import { SuggestedLeadsMenu } from "../inner-components/SuggestedLeadsMenu";
import { ActiveLeads } from "./tabs/ActiveLeads";
import { RemovedLeads } from "./tabs/RemovedLeads";
import Popup from "components/Popup";
import { BUTTON_VARIANT } from "components/Button/Button";
import SourceList from "./SourceList";
import Steps from "./tabs/Steps2";
import Stats from "./tabs/Stats";

export const CampaignContent = () => {
    const [isLoadingCampaign, setIsLoadingCampaign] = useState(true);
    const [isSuggestedLeadsMenuOpen, setIsSuggestionMenuOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [checkTokens, setCheckTokens] = useState(0);
    const [showSourceListModal, setShowSourceListModal] = useState(false);

    const updated = useSelector((state) => state.campaign.lastUpdated);
    const campaign = useSelector((state) => (state.campaign.campaigns ? state.campaign.campaigns : [])).find((c) => c.id === id);
    const tabCounts = useSelector((state) => state.campaign.tabCounts);

    useEffect(() => {
        let currentTab;
        switch (activeTab) {
            case 0:
                currentTab = "active";
                break;
            case 1:
                currentTab = "exported";
                break;
            case 2:
                currentTab = "removed";
                break;
            case 3:
                currentTab = "steps";
                break;
            case 4:
                currentTab = "stats";
                break;
            default:
                currentTab = "active";
        }

        dispatch(
            campaignActions.updatePaginationAndSearch({
                campaignId: id,
                currentTab: currentTab,
            })
        );
    }, [activeTab]);

    useEffect(() => {
        console.log("checking campaign");
        console.log(campaign);
        if (!campaign) return;

        const timeout = checkForCampaignUpdate();
        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [campaign]);

    useEffect(() => {
        const load = async () => {
            if (id) {
                console.log("(REDUX DEBUG) LOAD CAMPAIGN");
                dispatch(
                    campaignActions.getCampaignById({
                        campaignId: id,
                        pageNumber: 1,
                        pageSize: 10,
                        searchValue: "",
                        searchKey: "",
                        currentTab: "active",
                    })
                );
                dispatch(
                    campaignActions.getCampaignUnseenById({
                        id: id,
                    })
                );
                setIsLoadingCampaign(false);
            }
        };
        load();
    }, [id]);

    if (!campaign) {
        navigate(`${PATH_DASHBOARD}`);
        return <div />;
    }

    const rephraseLead = async (lead) => {
        dispatch(campaignActions.rephraseLead(lead.id));
    };

    const editLeadMessage = (lead) => {
        dispatch(campaignActions.editLeadMessage(lead));
    };

    const exportLeads = (leadIds) => {
        dispatch(campaignActions.exportLeads({ campaignId: id, leadIds: leadIds }));
    };

    const removeLeads = (leadIds) => {
        dispatch(campaignActions.removeLeads({ campaignId: id, leadIds: leadIds }));
    };

    const restoreLeads = (leadIds) => {
        dispatch(campaignActions.restoreLeads({ campaignId: id, leadIds: leadIds }));
    };

    const changeLeadStatus = (lead, status) => {
        dispatch(campaignActions.changeLeadStatus({ leadId: lead.id, status: status }));
    };

    const loadCampaign = (id) => {
        console.log("(REDUX DEBUG)  checkForCampaignUpdate");

        dispatch(
            campaignActions.getCampaignById({ campaignId: id, pageNumber: 1, pageSize: 10, searchValue: "", searchKey: "", currentTab: "active" })
        );
        dispatch(
            campaignActions.getCampaignUnseenById({
                id: id,
            })
        );
    };

    if (isLoadingCampaign) {
        return "Loading campaign...";
    }

    const approveLead = async (lead) => {
        dispatch(campaignActions.changeLeadStatus({ leadId: lead.id, status: LeadStatus.Active }));
    };

    const denyLead = async (lead, reasons) => {
        dispatch(campaignActions.denyLead({ leadId: lead.id, reasons: reasons.join(",") }));
        // console.log("DENY: ", lead);
    };

    const onTabChange = (index) => {
        setActiveTab(index);
        dispatch(campaignActions.clearExpandedLeadIds());
    };

    const onTitleUpdate = (name) => {
        dispatch(campaignActions.renameCampaign({ campaignId: id, name: name }));
    };

    const onToggleRows = (expandCollapseAction, leads) => {
        if (expandCollapseAction === "Expand All") {
            const unreadLeadIds = leads.filter((l) => l.unread === 1).map((l) => l.id);

            if (unreadLeadIds.length > 0) {
                dispatch(campaignActions.markLeadsAsRead({ campaignId: id, leadIds: unreadLeadIds }));
            }

            dispatch(campaignActions.updateExpandedLeadIds(leads.map((l) => l.id)));
        } else {
            dispatch(campaignActions.updateExpandedLeadIds([]));
        }
    };

    //
    function checkForCampaignUpdate() {
        console.log(campaign);
        if (campaign) {
            const amountOfLeads = campaign.leads.length;
            let doneGeneratingMessages = campaign.leads.filter((lead) => lead.message !== null || lead.messageStatus === 4);
            console.log(amountOfLeads);
            console.log(doneGeneratingMessages.length);

            if (doneGeneratingMessages.length !== amountOfLeads) {
                return setTimeout(async () => {
                    loadCampaign(campaign.id);
                    setCheckTokens(checkTokens + 1);
                    console.log(checkTokens);
                }, 30000);
            } else {
                console.log("done.");
            }
        }
    }
    //

    const suggestedLeads = campaign.leads ? campaign.leads.filter((c) => c.status === LeadStatus.Unseen) : [];
    const activeLeads = campaign.leads ? campaign.leads.filter((lead) => lead.status === LeadStatus.Active && !lead.exported) : [];
    const exportedLeads = campaign.leads ? campaign.leads.filter((lead) => lead.status === LeadStatus.Active && lead.exported) : [];
    const removedLeads = campaign.leads ? campaign.leads.filter((x) => x.status === LeadStatus.Removed) : [];

    const tabsCaptions = [
        `Active Leads (${tabCounts?.["active"] || 0})`,
        `Exported Leads (${tabCounts?.["exported"] || 0})`,
        `Removed Leads (${tabCounts?.["removed"] || 0})`,
        `Steps`,
        `Stats`,
    ];

    const reasons = [
        { type: "bad_territory", title: "Not in my defined territory" },
        { type: "bad_title", title: "Good company, but not a good title " },
        { type: "bad_company", title: "Good title, company not a fit" },
        { type: "bad_title_bad_company", title: "Not a good title and company not a fit" },
        { type: "competitor", title: "Competitor" },
        { type: "ecosystem", title: "Customer Vendor Ecosystem" },
    ];

    return (
        <div key={updated}>
            <Popup
                title="Deny Reason"
                onClose={() => setShowPopup(false)}
                visible={showPopup}
                buttons={[{ title: "Ok", type: BUTTON_VARIANT.PRIMARY, action: () => setShowPopup(false) }]}
            >
                <div>
                    {reasons.map((reason, i) => {
                        return (
                            <div key={i} style={{ paddingTop: "5px" }}>
                                <input type="radio" name="deny_reason" value={reason.type} />
                                <label htmlFor={reason.type}>&nbsp;&nbsp;&nbsp;{reason.title}.</label>
                            </div>
                        );
                    })}
                </div>
            </Popup>
            <CampaignHeader
                campaign={campaign}
                onTitleUpdate={onTitleUpdate}
                suggestedLeadsCount={suggestedLeads.length}
                setShowSourceListModal={setShowSourceListModal}
                onSuggestionsClick={() => setIsSuggestionMenuOpen(true)}
            />
            {campaign.type === "auto-discovery" && showSourceListModal && (
                <SourceList
                    setShowSourceListModal={setShowSourceListModal}
                    relevantLeads={campaign.leads.filter((lead) => lead.origin !== "suggested" && lead.status !== LeadStatus.Removed)}
                />
            )}
            <Tabs activeTabIndex={activeTab} captions={tabsCaptions} onActiveTabChange={onTabChange}>
                <ActiveLeads
                    campaign={campaign}
                    isActiveExported={false}
                    relevantLeads={activeLeads?.reverse()}
                    onUpdate={loadCampaign}
                    onRephrase={rephraseLead}
                    onToggleRows={onToggleRows}
                    onEditMessage={editLeadMessage}
                    onChangeStatus={changeLeadStatus}
                    onExport={exportLeads}
                    onRemove={removeLeads}
                    isLoadingCampaign={isLoadingCampaign}
                />

                <ActiveLeads
                    campaign={campaign}
                    isActiveExported={true}
                    relevantLeads={exportedLeads?.reverse()}
                    onUpdate={loadCampaign}
                    onRephrase={rephraseLead}
                    onToggleRows={onToggleRows}
                    onEditMessage={editLeadMessage}
                    onChangeStatus={changeLeadStatus}
                    onExport={exportLeads}
                    onRemove={removeLeads}
                />

                <ActiveLeads //removed leads
                    campaign={campaign}
                    isActiveExported={true}
                    relevantLeads={removedLeads?.reverse()}
                    onUpdate={loadCampaign}
                    onRephrase={rephraseLead}
                    onToggleRows={onToggleRows}
                    onEditMessage={editLeadMessage}
                    onChangeStatus={changeLeadStatus}
                    onExport={exportLeads}
                    onRemove={restoreLeads}
                    removedLeads={true}
                />

                <Steps campaign={campaign} />
                <Stats campaign={campaign} />
            </Tabs>

            <SuggestedLeadsMenu
                isOpen={isSuggestedLeadsMenuOpen}
                suggestedLeads={suggestedLeads}
                onApproveLead={approveLead}
                onDenyLead={denyLead}
                onClose={() => setIsSuggestionMenuOpen(false)}
            />
        </div>
    );
};
