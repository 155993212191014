import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Oauth2Grant.css";

import env from "config/site.config";

// create a oauth2 approve page
const Oauth2Grant = () => {
    const [searchParams, setSearchParams] = useState(null);
    const [clientInfo, setClientInfo] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams) {
            setSearchParams(urlParams);
        }

        const getClientInfo = async () => {
            try {
                const response = await axios.get(`${env.REACT_APP_BACKEND_URL}/oauth/client_info`, {
                    params: urlParams,
                });

                setClientInfo(response.data);
            } catch (error) {
                console.log("error", error);
            }
        };
        getClientInfo();
    }, []);

    if (searchParams && clientInfo) {
        return (
            <div className="oauth2-page-container">
                <h1 className="oauth2-title">Authorize {clientInfo.name}</h1>
                <img className="oauth2-client-image" src={clientInfo.image} alt={clientInfo.name} />
                <AcceptOrDeny searchParams={searchParams} />
            </div>
        );
    } else if (searchParams) {
        return (
            <div className="oauth2-page-container">
                <div className="oauth2-loading">Loading client information...</div>
            </div>
        );
    } else {
        return (
            <div className="oauth2-page-container">
                <div className="oauth2-error-message">Missing client_id or redirect_uri in the URL</div>
            </div>
        );
    }
};

const AcceptOrDeny = ({ searchParams }) => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleAccept = async () => {
        setIsLoading(true);

        try {
            const response = await axios.post(`${env.REACT_APP_BACKEND_URL}/oauth/authorize`, null, {
                params: searchParams,
            });

            console.log("response", response);

            if (response.status === 200) {
                const { code, redirectUri, state } = response.data;
                window.location.href = `${redirectUri}?code=${code}&state=${state}`;
            } else {
                alert("Authorization failed");
            }
        } catch (error) {
            alert("Authorization failed");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeny = async () => {
        setIsLoading(true);

        navigate("/");
    };

    return (
        <div className="oauth2-container">
            <h3 className="oauth2-header">Do you want to authorize this application?</h3>
            <button className="oauth2-btn oauth2-btn-accept" onClick={handleAccept} disabled={isLoading}>
                Accept
            </button>
            <button className="oauth2-btn oauth2-btn-deny" onClick={handleDeny} disabled={isLoading}>
                Deny
            </button>
        </div>
    );
};

export default Oauth2Grant;
