import * as actionTypes from "./actionTypes";

const initialState = {
    toggleSnackbar: false,
    snackbarMessage: null,
    snackbarTimeout: 3000,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.TOGGLE_SNACKBAR_OPEN: {
            return {
                ...state,
                toggleSnackbar: true,
                snackbarMessage: action.message,
                snackbarTimeout: action.timeout || 3000,
                snackbarVariant: action.variant,
            };
        }

        case actionTypes.TOGGLE_SNACKBAR_CLOSE: {
            return {
                ...state,
                toggleSnackbar: false,
                snackbarMessage: null,
                snackbarTimeout: 3000,
                snackbarVariant: "",
            };
        }

        default: {
            return state;
        }
    }
}
