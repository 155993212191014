import axios from "axios";
import env from "config/site.config";

const log = require("loglevel").getLogger("SettingsService");
log.setLevel("error");

export const getSettings = async () => {
    log.debug("SettingsService -> getSettings -> Enter");

    const url = `${env.REACT_APP_BACKEND_URL}/settings/get`;
    return axios
        .post(url, {})
        .then(async (response) => {
            const res = response.data;
            log.debug("SettingsService -> getSettings -> response: " + JSON.stringify(res));
            return res.settings;
        })
        .catch((response) => {
            log.debug("SettingsService -> getSettings -> error: ");
            log.debug(response);
            return null;
        });
};

export const editSingleSetting = async (settings_obj) => {
    log.debug("SettingsService -> editSingleSetting -> Enter");
    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/settings/update/single`, settings_obj)
        .then(async (response) => {
            const res = response.data;
            log.debug("SettingsService -> editSingleSetting -> response: " + JSON.stringify(res));
            return res;
        })
        .catch((response) => {
            log.debug("SettingsService -> editSingleSetting -> error: ");
            log.debug(response);
            return null;
        });
};

export const editArraySetting = async (settings_obj) => {
    log.debug("SettingsService -> editArraySetting -> Enter");
    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/settings/update/array`, settings_obj)
        .then(async (response) => {
            const res = response.data;
            log.debug("SettingsService -> editArraySetting -> response: " + JSON.stringify(res));
            return res;
        })
        .catch((response) => {
            log.debug("SettingsService -> editArraySetting -> error: ");
            log.debug(response);
            return null;
        });
};

export const editMultipleSettings = async (settings_obj) => {
    log.debug("SettingsService -> editMultipleSettings -> Enter");
    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/settings/update/multiple`, settings_obj)
        .then(async (response) => {
            const res = response.data;
            log.debug("SettingsService -> editMultipleSettings -> response: " + JSON.stringify(res));
            return res;
        })
        .catch((response) => {
            log.debug("SettingsService -> editMultipleSettings -> error: ");
            log.debug(response);
            return null;
        });
};

export const sendBoardingSummary = async (settings_obj) => {
    log.debug("SettingsService -> sendBoardingSummary -> Enter");
    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/settings/update/summary`, settings_obj)
        .then(async (response) => {
            const res = response.data;
            log.debug("SettingsService -> sendBoardingSummary -> response: " + JSON.stringify(res));
            return res;
        })
        .catch((response) => {
            log.debug("SettingsService -> sendBoardingSummary -> error: ");
            log.debug(response);
            return null;
        });
};
