import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import {
    PATH_HOME,
    PATH_INTEGRATE_EXPANDI,
    PATH_INTEGRATE_OUTREACH,
    PATH_INTEGRATE_SALESFORCE,
    PATH_INTEGRATE_SAMPLEAD_AUTOMATION,
    PATH_INTEGRATE_ZAPIER,
} from "utils/constants";
import { TabButton, TabButtonsContainer } from "components/Tabs/Tabs";
import comingSoonImage from "assets/images/integrations/coming-soon.png";
import expandiImage from "assets/images/integrations/expandi-integration-image.png";
import outreachImage from "assets/images/integrations/outreach-integration-image.png";
import salesforceImage from "assets/images/integrations/salesforce-integration-image.png";
import zapierImage from "assets/images/integrations/zapier-integration-image.png";
import SampleadIcon from "assets/images/integrations/samplead-logo.png";
import env from "config/site.config";
import "./integrations.css";
import "./style.css";

const IntegrationCard = ({ integration }) => {
    const { title, description, image, isIntegrated, installed } = integration;

    return (
        <div className={`integration-card ${!isIntegrated && "coming-soon"}`}>
            {!isIntegrated && <img src={`${comingSoonImage}`} className="coming-soon-badge" alt="coming-soon-badge" />}
            {installed && <div className="installed-badge">Installed</div>}
            <div className="integration-card-image">
                <img src={`${image}`} alt="integration" />
            </div>
            <h2 className="integration-card-title">{title}</h2>
            <p>{description}</p>
        </div>
    );
};

const Integrations = () => {
    const [activeTab, setActiveTab] = useState("All Integrations");
    const [filteredIntegration, setFilteredIntegration] = useState([]);
    const [installedArray, setInstalledArray] = useState([]);
    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate();

    useEffect(() => {
        const getIntegrations = async () => {
            try {
                const url = `${env.REACT_APP_BACKEND_URL}/settings/get_integrations`;
                const { data } = await axios.post(url, user);
                if (!data.message && data.settings) {
                    // outreach integration is 0 or 1
                    const installed = [];
                    if (data.settings.outreach_integration) {
                        installed.push("outreach");
                    }
                    if (data.settings.expandi_integration) {
                        installed.push("expandi");
                    }
                    if (data.settings.salesforce_integration) {
                        installed.push("salesforce");
                    }
                    if (data.settings.samplead_automation_integration) {
                        installed.push("samplead-automation");
                    }
                    if (data.settings.zapier_integration) {
                        installed.push("zapier");
                    }
                    setInstalledArray(installed);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getIntegrations();
    }, []);

    const integrations = [
        {
            title: "Outreach",
            description: "The Outreach Sales Execution Platform makes your entire team more efficient.",
            image: outreachImage,
            isIntegrated: true,
            installed: installedArray.includes("outreach") ? true : false,
        },
        {
            title: "Expandi",
            description: "Linkedin automation without any risk.",
            image: expandiImage,
            isIntegrated: true,
            installed: installedArray.includes("expandi") ? true : false,
        },
        {
            title: "Salesforce",
            description: "Do you need an introduction? really??",
            image: salesforceImage,
            isIntegrated: true,
            installed: installedArray.includes("salesforce") ? true : false,
        },
        {
            title: "Samplead Linkedin Automation",
            description: "Semleasly send peronalised messages.",
            image: SampleadIcon,
            isIntegrated: true,
            installed: installedArray.includes("samplead-automation") ? true : false,
        },
        {
            title: "Zapier",
            description: "integrate the web applications you use and automate workflows.",
            image: zapierImage,
            isIntegrated: true,
            installed: installedArray.includes("zapier") ? true : false,
        },
    ];

    useEffect(() => {
        setFilteredIntegration(integrations);
    }, [installedArray]);

    useEffect(() => {
        if (activeTab === "All Integrations") {
            setFilteredIntegration(integrations);
            // setFilteredIntegration(integrations.filter(integration => integration.installed === false));
        } else if (activeTab === "Installed integrations") {
            setFilteredIntegration(integrations.filter((integration) => integration.installed === true));
        }
    }, [activeTab]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const NoIntegrations = () => {
        return (
            <div className="no-integrations">
                <h3>No Installed integrations found</h3>
                <p>There are no integrations available in this section.</p>
            </div>
        );
    };

    const handleIntegrationClick = (integration) => {
        const integrationName = integration.title.toLowerCase();

        if (integration.isIntegrated === true) {
            switch (integrationName) {
                case "outreach":
                    navigate(PATH_INTEGRATE_OUTREACH);
                    break;
                case "expandi":
                    navigate(PATH_INTEGRATE_EXPANDI);
                    break;
                case "salesforce":
                    navigate(PATH_INTEGRATE_SALESFORCE);
                    break;
                case "samplead linkedin automation":
                    navigate(PATH_INTEGRATE_SAMPLEAD_AUTOMATION);
                    break;
                case "zapier":
                    navigate(PATH_INTEGRATE_ZAPIER);
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <div>
            <div className="header-outreach">
                <Link to={PATH_HOME} style={{ textDecoration: "none" }} className="samplead-body--bold">{`< Back to Home`}</Link>
                <h1>Integrations</h1>
                <p>Explore Samplead integrations to improve your company's workflow </p>
            </div>

            <div className="samplead-integrations-main-container">
                <div>
                    <TabButtonsContainer>
                        <TabButton onClick={(e) => handleTabClick(e.target.innerText)} isActive={activeTab === "All Integrations" && true}>
                            All Integrations
                        </TabButton>
                        <TabButton onClick={(e) => handleTabClick(e.target.innerText)} isActive={activeTab === "Installed integrations" && true}>
                            Installed integrations
                        </TabButton>
                    </TabButtonsContainer>
                </div>
                <div className="samplead-integrations-cards-container">
                    {filteredIntegration.length === 0 ? (
                        <NoIntegrations />
                    ) : (
                        filteredIntegration.map((integration) => {
                            return (
                                <div className="integration-card-outer" key={integration.title} onClick={() => handleIntegrationClick(integration)}>
                                    <IntegrationCard integration={integration} />
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
        </div>
    );
};

export default Integrations;
