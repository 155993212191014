import Header from "components/Header";
import PricingBox from "components/PricingBox";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { PAYWALL_LINK } from "utils/constants";

import "./ProductOptions.css";
import axios from "axios";
import env from "config/site.config";

const ProductOptions = ({}) => {
    const [showYearly, setShowYearly] = useState(false);
    const user = useSelector((state) => state.user.user);
    const { email, token } = user;

    useEffect(() => {
        console.log(`%cProduct Options Page`, "background-color: blue; color");
        localStorage.setItem("cameFromPaywall", true);
    }, []);

    const handleContactSalesTeam = async (userMessage) => {
        console.log("sendEmailToSalesTeam", userMessage);
        try {
            const response = await axios.post(`${env.REACT_APP_BACKEND_URL}/user/contact_sales`, {
                user: user,
                message: userMessage,
            });
            console.log("response", response);
            return response;
        } catch (error) {
            console.log("error", error);
            return error;
        }
    };

    const pricingPlans = [
        {
            topIcon: "https://uploads-ssl.webflow.com/63e9fc56fd643fb7bc8bfe21/63ee5b44010f403ee41a3810_starter.png",
            name: "Starter",
            description: "Perfect plan to get started!",
            priceText: "99",
            priceTextYearly: "1010",
            features: [
                "100-150 time based suggested prospects each month",
                "250 monitored prospects",
                "250 instant personalizations",
                "Statistics",
                "Integrations",
            ],
            paymentLink: showYearly ? PAYWALL_LINK(email, token, "starterYearly") : PAYWALL_LINK(email, token, "starterMonthly"),
            callToAction: "Try Samplead now 🚀",
        },
        {
            topIcon: "https://uploads-ssl.webflow.com/63e9fc56fd643fb7bc8bfe21/63ee5b4494b5cc7bdf52f53c_pro.png",
            name: "Pro",
            popularBadge: true,
            description: "Perfect plan for professionals!",
            priceText: "250",
            priceTextYearly: "2550",
            features: [
                "200-300 time based suggested prospects each month",
                "500 monitored prospects",
                "500 instant personalizations",
                "Statistics",
                "Integrations",
            ],
            paymentLink: showYearly ? PAYWALL_LINK(email, token, "proYearly") : PAYWALL_LINK(email, token, "proMonthly"),
            callToAction: "Try Samplead now 🚀",
        },
        {
            topIcon: "https://uploads-ssl.webflow.com/63e9fc56fd643fb7bc8bfe21/63ee5b44fa8523362b76010a_done%20for%20you.png",
            name: "Done for you",
            description: "Best suits for great company!",
            priceText: "1500",
            priceTextYearly: "15300",
            features: [
                "Everything in Pro Plan",
                "AI content creation",
                "A/B testing",
                "LinkedIn automation",
                "Email sequencing",
                "Dedicated account manager",
            ],
            // gere we want to send an email to the sales team
            paymentLink: (e) => handleContactSalesTeam(e),
            callToAction: "Contact Us 😎",
        },
    ];

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "50px 0" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "20px",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                                gap: "20px",
                                width: "100%",
                                maxWidth: "500px",
                                margin: "0 auto",
                            }}
                        >
                            <div style={{ fontSize: "16px", fontWeight: 700, cursor: "pointer" }} onClick={() => setShowYearly(false)}>
                                Monthly
                            </div>
                            <div
                                className={`toggle-button ${showYearly ? "toggle-on" : ""}`}
                                style={{ position: "relative", width: "60px", height: "30px", cursor: "pointer" }}
                                onClick={() => setShowYearly(!showYearly)}
                            >
                                <div className="toggle-knob" />
                                <div className="toggle-circle" />
                            </div>
                            <div style={{ fontSize: "16px", fontWeight: 700, cursor: "pointer" }} onClick={() => setShowYearly(true)}>
                                Yearly
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                <div style={{ fontSize: "14px", fontWeight: 600 }}>Save Up To 15%</div>
                                <img
                                    src="https://uploads-ssl.webflow.com/63e9fc56fd643fb7bc8bfe21/63ee5e6bb7392cf83933005a_arrow-discount.svg"
                                    loading="lazy"
                                    alt=""
                                    style={{ width: "14px", height: "14px" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                            gap: "20px",
                            margin: "100px",
                        }}
                    >
                        {pricingPlans.map((plan, index) => (
                            <PricingBox
                                key={index}
                                topIcon={plan.topIcon}
                                title={plan.name}
                                price={showYearly ? plan.priceTextYearly : plan.priceText}
                                description={plan.description}
                                features={plan.features}
                                paymentLink={plan.paymentLink}
                                callToAction={plan.callToAction}
                                popularBadge={plan.popularBadge}
                                showYearly={showYearly}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductOptions;
