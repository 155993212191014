import React, { useState } from "react";
import { Link } from "react-router-dom";

import Button, { BUTTON_VARIANT } from "components/Button/Button";
import { PATH_DASHBOARD } from "utils/constants";
import Icon from "components/Icon/Icon";
import Modal from "components/Modal";
import { CampaignTitle } from "../../Campaign/inner-components/CampaignTitle";
import SelectInput from "./SelectInput";

const SequenceModal = ({ title, text, visible, onClose }) => {
    return (
        <Modal isModalOpen={visible} closeModal={onClose}>
            <div className="export-confirmation">
                <div className="export-confirmation__content">
                    <h2 className={"samplead-body--large"}>{title}</h2>
                    <p className={"samplead-body text-center break-line"}>{text}</p>
                    <Button variant={BUTTON_VARIANT.PRIMARY} onClick={onClose}>
                        Ok
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

const SequenceHeader = () => {
    const [sequence, setSequence] = useState({
        "Seq 1": false,
        "Seq 2": false,
        "Seq 3": false,
    });
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [linkedinActive, setLinkedinActive] = useState(false);
    const [emailActive, setEMailActive] = useState(false);
    const onClose = () => {
        setVisible(false);
    };

    return (
        <header className="campaigns__header">
            <div className={"campaigns__header-top"}>
                <Link to={PATH_DASHBOARD} className="samplead-body--bold">{`< Back to all Projects`}</Link>
            </div>

            <div className={"campaigns__header-bottom"}>
                <div className={"campaigns__header-bottom-left"}>
                    <Icon type={"sequence"} />
                    <CampaignTitle editable={true} title={"Sequence name"} />
                    <Icon
                        type={"linkedin" + (linkedinActive ? "_active" : "")}
                        style={{ marginLeft: "20px", cursor: "pointer" }}
                        onClick={() => {
                            setLinkedinActive((prev) => !prev);
                        }}
                    />
                    <Icon
                        type={"email" + (emailActive ? "_active" : "")}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setEMailActive((prev) => !prev);
                        }}
                    />
                </div>
                <div className={"campaigns__header-bottom-right"}>
                    <SelectInput
                        mode={"single"}
                        state={sequence}
                        setState={setSequence}
                        selectElement={<Button variant={BUTTON_VARIANT.SECONDARY}>Saved sequences</Button>}
                    />

                    <Button
                        variant={BUTTON_VARIANT.SECONDARY}
                        onClick={() => {
                            setVisible(true);
                            setTitle("Sequence Saved");
                            setText("Your sequence was saved you can re edit it by clicking on saved sequences");
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        variant={BUTTON_VARIANT.PRIMARY}
                        onClick={() => {
                            setVisible(true);
                            setTitle("Sequence Exported");
                            setText("Your sequence was exported let's get those leads flowing");
                        }}
                    >
                        Export
                    </Button>
                </div>
            </div>
            <SequenceModal visible={visible} onClose={onClose} title={title} text={text} />
        </header>
    );
};

export default SequenceHeader;
