import React from "react";
import {
    PATH_DASHBOARD,
    PATH_MAGIC_MOMENTS,
    PATH_EMBEDDED_STATISTICS,
    PATH_ONBOARDING_PAGE,
    PATH_PRODUCT_OPTIONS,
    PATH_SEQUENCE_PAGE,
    PATH_STATISTICS,
    PATH_TO_REPLIES,
    PATH_TO_SAMPLEAD_AUTOMATION_SITE,
} from "utils/constants";
import { NavLink, useLocation, Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import "./Sidebar.css";
import useScript from "./customHook";

const capitalizeStringSentence = (str) => {
    return str
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

const links = [
    {
        path: PATH_DASHBOARD,
        className: "dashboard",
        target: "_self",
    },
    {
        path: PATH_MAGIC_MOMENTS,
        className: "magic_moments",
        target: "_self",
    },
    // {
    //     path: PATH_TO_REPLIES,
    //     className: "replies",
    //     target: "_self",
    // },
    {
        path: PATH_STATISTICS,
        className: "statistics",
        target: "_self",
    },
    {
        path: PATH_EMBEDDED_STATISTICS,
        className: "embedded",
        target: "_self",
    },
    {
        path: PATH_TO_SAMPLEAD_AUTOMATION_SITE,
        className: "samplead_automation",
        target: "_blank",
    },
];

/**
 * @param side - 'left' or 'right'
 */
const Sidebar = ({ side }) => {
    useScript("//code.tidio.co/fbfiaebm30fwrsffxdlzdjpnbcskb7qh.js");
    useScript("https://www.googletagmanager.com/gtag/js?id=G-Z7SJYTQ6QB");

    let location = useLocation();

    // if the location is /onboarding, load different sidebar class
    const isOnboarding = location.pathname === PATH_ONBOARDING_PAGE || location.pathname === PATH_PRODUCT_OPTIONS;
    const sidebar_class = isOnboarding ? "no-sidebar" : "sidebar";

    return (
        <nav className={`${sidebar_class} ${side}`}>
            <div className="sidebar-container">
                {links.map(({ path, className, target }) => {
                    if (target === "_blank") {
                        return (
                            <a
                                key={path}
                                href={path}
                                className={`sidebar__link ${className}`}
                                target={target}
                                data-tooltip-id={className}
                                data-tooltip-content={capitalizeStringSentence(className)}
                            >
                                <Tooltip id={className} variant="light" className="tooltip-style" offset={-8} />
                            </a>
                        );
                    }
                    return (
                        <NavLink
                            key={path}
                            to={path}
                            className={`sidebar__link ${className}`}
                            data-tooltip-id={className}
                            data-tooltip-content={capitalizeStringSentence(className)}
                        >
                            <Tooltip id={className} variant="light" className="tooltip-style" offset={-8} />
                        </NavLink>
                    );
                })}
            </div>
        </nav>
    );
};

export default Sidebar;
