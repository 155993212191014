import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

import Icon from "components/Icon/Icon";
import Button, { BUTTON_VARIANT } from "components/Button/Button";
import * as sequenceActions from "redux/Sequence/actions";
import useStyles from "routes/Sequence/styles";
import Spinner from "components/Spinner";
import TextArea from "antd/es/input/TextArea";
import { set } from "date-fns";
import { Select, Switch, Modal, Tooltip, Segmented, Card, Input } from "antd";
import { LockFilled, UnlockFilled } from "@ant-design/icons";
import { BarLoader } from "react-spinners";
import * as campaignActions from "redux/Campaign/reducer";

import FriendlyIcon from "../../../../assets/svgs/friendly.svg";
import CasualIcon from "../../../../assets/svgs/casual.svg";
import FormalIcon from "../../../../assets/svgs/formal.svg";
import OriginalIcon from "../../../../assets/svgs/original.svg";
import OurChoiseIcon from "../../../../assets/svgs/our_choice.svg";
import CustomIcon from "../../../../assets/svgs/custom.svg";

import { ReactComponent as LinkedinOff } from "../../../../assets/icons/linkedinOff.svg";
import { ReactComponent as LinkedinOn } from "../../../../assets/icons/linkedinOn.svg";
import { ReactComponent as EmailOff } from "../../../../assets/icons/emailOff.svg";
import { ReactComponent as EmailOn } from "../../../../assets/icons/emailOn.svg";

const { Option } = Select;

const timeDelayOptions = [
    { value: "immediately", label: "Immediately" },
    { value: "15", label: "15 minutes after" },
    { value: "30", label: "30 minutes after" },
    { value: "45", label: "45 minutes after" },
    { value: "60", label: "1 hour after" },
    { value: "240", label: "4 hours after" },
    { value: "5d", label: "5 days" },
    { value: "480", label: "8 hours after" },
    { value: "720", label: "12 hours after" },
    { value: "1d", label: "1 day" },
    { value: "2d", label: "2 days" },
    { value: "3d", label: "3 days" },
    { value: "4d", label: "4 days" },
    { value: "6d", label: "6 days" },
    { value: "7d", label: "7 days" },
    { value: "8d", label: "8 days" },
    { value: "9d", label: "9 days" },
    { value: "10d", label: "10 days" },
    { value: "1w", label: "1 week" },
    { value: "2w", label: "2 weeks" },
];

const LeadMessage = ({ value = "", messageSubject, minRows, maxLength, step, original, campaignActivated }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const textAreaRef = useRef(null);

    const currentMessage = useSelector((state) => {
        if (state.sequence.generatedSteps[step] && messageSubject in state.sequence.generatedSteps[step]) {
            const messageIndex = state.sequence.generatedSteps[step].messageIndex || 0;
            if (Array.isArray(state.sequence.generatedSteps[step][messageSubject]) && messageIndex >= 0) {
                return state.sequence.generatedSteps[step][messageSubject][messageIndex];
            } else {
                return state.sequence.generatedSteps[step][messageSubject];
            }
        } else {
            return ""; // or some default value
        }
    });

    const messageIndex = useSelector((state) => {
        if (state.sequence.generatedSteps[step] && messageSubject in state.sequence.generatedSteps[step]) {
            return state.sequence.generatedSteps[step].messageIndex || 0;
        } else {
            return 0; // or some default value
        }
    });

    const handleFocus = () => {
        dispatch(sequenceActions.setCurrentlyEditing(step));
        // set unsaved changes to true
    };

    const handleSelect = (e) => {
        dispatch(sequenceActions.setCursorPosition(e.target.selectionStart));
    };

    console.log("currentMessage", currentMessage);
    console.log("messageIndex", messageIndex);

    return (
        <TextareaAutosize
            type={"text"}
            ref={textAreaRef}
            style={{ ...classes.sequenceStep }}
            minRows={minRows}
            onChange={(e) => {
                if (messageSubject === "message") {
                    dispatch(sequenceActions.updateStep(step, { [messageSubject]: e.target.value, messageIndex: messageIndex }, original));
                    dispatch(sequenceActions.setUnsavedChanges(true));
                } else if (messageSubject === "subject") {
                    dispatch(sequenceActions.updateStep(step, { [messageSubject]: e.target.value }, original));
                    dispatch(sequenceActions.setUnsavedChanges(true));
                }
            }}
            onFocus={handleFocus}
            onSelect={handleSelect}
            value={currentMessage}
            disabled={campaignActivated}
            maxLength={maxLength}
            placeholder={messageSubject.charAt(0).toUpperCase() + messageSubject.slice(1)}
        />
    );
};

const SequenceStep = ({ step, subject, message, children, original, buttonCallback, campaignId, campaignActivated }) => {
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);

    const sequence = useSelector((state) => state.sequence);
    const currentMessage = sequence.generatedSteps ? sequence.generatedSteps[step]?.message : undefined;
    const emailOrLinkedIn = useSelector((state) => state.sequence?.emailOrLinkedIn);

    // showRephraseOptions is either currentMessage is array or not
    const [showRephraseOptions, setShowRephraseOptions] = useState(false);

    useEffect(() => {
        if (Array.isArray(currentMessage) && currentMessage.length > 1) {
            setShowRephraseOptions(true);
        } else {
            setShowRephraseOptions(false);
        }
    }, [currentMessage]);

    const currentSteps = sequence.generatedSteps || {};
    console.log("currentSteps", currentSteps);
    const currentIndex = sequence.generatedSteps[step]?.messageIndex || 0;

    const show = useSelector((state) => {
        if (original) {
            return state.sequence.show.originalSteps[step];
        } else {
            return state.sequence.show.generatedSteps[step];
        }
    });
    const dispatch = useDispatch();
    const setShow = () => {
        dispatch(sequenceActions.toggleShowStep(step, original));
    };
    const classes = useStyles();

    const handleRephraseClick = () => {
        // if message is not an array get rephrase options
        setLoading(true);
        dispatch(sequenceActions.getRephrasedStep({ step, campaignId, currentMessage: currentMessage, callback: () => setLoading(false) }));
    };

    const handleRephraseOptionClick = (index) => {
        // change message index

        const data = {
            step: step,
            index: index,
        };

        dispatch(sequenceActions.changeMessageIndex(data));
    };

    const handleSetTimeDelay = (value) => {
        dispatch(sequenceActions.setStepTimeDelay({ step: step, campaignId: campaignId, timeDelay: value }));
        // set unsaved changes to true
        dispatch(sequenceActions.setUnsavedChanges(true));
    };

    const handleCustomPrompt = (value) => {
        setLoading(true);
        // handle custom prompt
        console.log("custom prompt", value);

        //save current message
        dispatch(sequenceActions.updateStep(step, { message: currentMessage }));

        if (!value || value === "") return;
        // setLoading(true);
        dispatch(
            sequenceActions.rephraseWithCustomText({
                step: step,
                campaignId: campaignId,
                currentMessage: currentMessage,
                customText: value,
                callback: () => setLoading(false),
            })
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "8px", backgroundColor: "#F2F2FF", padding: "16px", borderRadius: "8px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }} onClick={() => setShow((prev) => !prev)}>
                <div style={classes.sequenceStepSubjects}>Step {step}</div>
                <Icon type={show ? "up_arrow" : "down_arrow"} style={{ float: "right", cursor: "pointer" }} />
            </div>
            {show && (
                <>
                    <Select
                        disabled={campaignActivated}
                        defaultValue={currentSteps[step]?.timeDelay ? currentSteps[step]?.timeDelay : null}
                        placeholder="Time Delay"
                        onChange={handleSetTimeDelay}
                    >
                        {timeDelayOptions.map((option) => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                    {emailOrLinkedIn === "email" && (
                        <LeadMessage
                            campaignActivated={campaignActivated}
                            value={subject}
                            messageSubject="subject"
                            minRows="1"
                            maxLength={100}
                            step={step}
                            original={original}
                            placeholder="Subject"
                        />
                    )}
                    <LeadMessage
                        campaignActivated={campaignActivated}
                        value={message}
                        messageSubject="message"
                        minRows="10"
                        maxLength={2200}
                        step={step}
                        original={original}
                    />

                    {buttonCallback && (
                        <Button
                            variant={BUTTON_VARIANT.SECONDARY}
                            borderless={true}
                            isDisabled={loading}
                            style={{
                                right: "0",
                                top: "20px",
                                position: "absolute",
                            }}
                            onClick={(e) => {
                                setLoading(true);
                                buttonCallback(() => setLoading(false));
                            }}
                        >
                            {loading ? <Spinner width="30" /> : "Rephrase"}
                        </Button>
                    )}
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            marginLeft: "auto",
                        }}
                    >
                        {/* <Button onClick={handleBackToOriginalClick} variant={BUTTON_VARIANT.PRIMARY_WHITE}>
                            Back to original
                        </Button> */}
                    </div>

                    {loading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100px",
                            }}
                        >
                            <BarLoader color={"#a53aff"} loading={loading} />
                        </div>
                    ) : (
                        showRephraseOptions &&
                        !campaignActivated && (
                            <>
                                <Segmented
                                    onChange={(e) => {
                                        handleRephraseOptionClick(e);
                                        // set unsaved changes to true
                                        dispatch(sequenceActions.setUnsavedChanges(true));
                                    }}
                                    block
                                    defaultValue={currentIndex}
                                    options={[
                                        {
                                            value: 0,
                                            icon: (
                                                <Tooltip title="Original">
                                                    <img className="rephrase-emoji" src={OriginalIcon} alt="Original" />
                                                </Tooltip>
                                            ),
                                        },

                                        {
                                            value: 1,
                                            icon: (
                                                <Tooltip title="Formal">
                                                    <img className="rephrase-emoji" src={FormalIcon} alt="Formal" />
                                                </Tooltip>
                                            ),
                                        },
                                        {
                                            value: 2,
                                            icon: (
                                                <Tooltip title="Casual">
                                                    <img className="rephrase-emoji" src={CasualIcon} alt="Casual" />
                                                </Tooltip>
                                            ),
                                        },
                                        {
                                            value: 3,
                                            icon: (
                                                <Tooltip title="Friendly">
                                                    <img className="rephrase-emoji" src={FriendlyIcon} alt="Friendly" />
                                                </Tooltip>
                                            ),
                                        },
                                        // {
                                        //     value: 4,
                                        //     icon: (
                                        //         <Tooltip title="✨Our choice✨">
                                        //             <img className="rephrase-emoji" src={OurChoiseIcon} alt="Original" />
                                        //         </Tooltip>
                                        //     ),
                                        // },
                                        {
                                            value: 4,
                                            icon: (
                                                <Tooltip title="Custom">
                                                    <img className="rephrase-emoji" src={CustomIcon} alt="Original" />
                                                </Tooltip>
                                            ),
                                        },
                                    ]}
                                />

                                <p
                                    style={{ marginTop: "10px", height: "auto" }}
                                    suppressContentEditableWarning={true}
                                    // onBlur={handleChange}
                                    className={`samplead-body lrbc--message ${ready !== false ? "ready--message" : ""}`}
                                ></p>
                                {currentIndex === 4 && (
                                    <>
                                        <div>
                                            <TextArea
                                                onKeyDown={
                                                    // if enter is pressed
                                                    (e) => {
                                                        if (e.key === "Enter") {
                                                            console.log("enter pressed");
                                                            handleCustomPrompt(e.target.value);
                                                        }
                                                    }
                                                }
                                                autoSize={{ minRows: 1, maxRows: 3 }}
                                                placeholder={`Or write your own rephrasing instructions.\nPress enter to rephrase.`}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        )
                    )}
                    {!campaignActivated && (
                        <Button onClick={handleRephraseClick} variant={BUTTON_VARIANT.SECONDARY}>
                            Rephrase
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};

const FullSequence = ({ steps, title, original, campaignId, campaign }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const empty = useSelector((state) => state.sequence.empty);
    const unsavedChanges = useSelector((state) => state.sequence?.unsavedChanges);
    const emailOrLinkedIn = useSelector((state) => state.sequence?.emailOrLinkedIn);

    const [inputValue, setInputValue] = useState("");
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);

    const [modalLoading, setModalLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const showModal = () => {
        setModalOpen(true);
    };

    const handleModalOk = () => {
        setModalLoading(true);
        setTimeout(() => {
            setModalLoading(false);
            setModalOpen(false);
        }, 3000);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        // get sequence by campaign id
        if (campaignId) {
            dispatch(sequenceActions.getSequenceByCampaignId({ id: campaignId, callback: () => setLoading(false) }));
            // Set loading state to false after fetching the sequence
        } else {
            console.error("campaignId is null or undefined");
        }
    }, [campaignId, dispatch]);

    useEffect(() => {
        console.log("inputValue", inputValue);
        console.log("okButtonDisabled", okButtonDisabled);
        if (inputValue === "lock" || inputValue === "unlock") {
            setOkButtonDisabled(false);
        }
    }, [inputValue]);

    const handleSave = () => {
        console.log("saving", steps);
        console.log("campaign", campaignId);
        dispatch(sequenceActions.saveSequence({ steps: steps, campaignId: campaignId }, () => {}));
        // set unsaved changes to false
        dispatch(sequenceActions.setUnsavedChanges(false));
    };

    const handleChangeCampaignActiveStatus = () => {
        setInputValue("");
        setOkButtonDisabled(true);
        dispatch(campaignActions.changeActive({ campaignId }));
    };

    const handleChangeLinkedinOrEmail = (value) => {
        console.log("emailOrLinkedin", emailOrLinkedIn);
        dispatch(sequenceActions.setEmailOrLinkedIn(value));
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    useEffect(() => {
        if (inputValue === "lock" || inputValue === "unlock") {
            setOkButtonDisabled(false);
        } else {
            setOkButtonDisabled(true);
        }
    }, [inputValue]);

    const handleToggle = () => {
        setModalOpen(true);
    };

    const setModalVisible = (visible) => {
        setModalOpen(visible);
    };

    const modalTitle = campaign?.activated ? "You are about to unlock your content" : "You are about to lock your content";

    const modalContent = campaign?.activated
        ? "Changing the content before the campaign sent 100 messages or more affects Samplead’s ability to accurately A/B test and get insights to improve your results."
        : "Changing the content before the campaign sent 100 messages or more affects Samplead’s ability to accurately A/B test and get insights to improve your results.";

    if (loading)
        return (
            <div
                style={{
                    width: "40%",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "100px",
                }}
            >
                <BarLoader color={"#a53aff"} loading={loading} />
            </div>
        );

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "50px", lastChild: { gap: "0px" }, width: "40%" }}>
            <Modal open={modalOpen} title={modalTitle} onCancel={() => setModalVisible(false)} footer={null} centered maskClosable width={500}>
                <p>{modalContent}</p>
                <h3 style={{ marginTop: "10px" }}>
                    Are you sure you want to continue?
                    <br /> type <b>{campaign?.activated ? "unlock" : "lock"}</b> to confirm
                </h3>
                <Input
                    style={{ width: "80%", marginTop: "20px" }}
                    placeholder={`Type "${campaign?.activated ? "unlock" : "lock"}" to confirm`}
                    onChange={handleInputChange}
                />
                <div style={{ display: "flex", justifyContent: "flex-end", gap: "5px", marginTop: "20px" }}>
                    <Button variant={BUTTON_VARIANT.PRIMARY_WHITE} onClick={() => setModalVisible(false)} type="default">
                        Continue editing
                    </Button>
                    <Button
                        variant={BUTTON_VARIANT.PRIMARY}
                        isDisabled={okButtonDisabled}
                        onClick={() => {
                            setModalVisible(false);
                            handleChangeCampaignActiveStatus();
                        }}
                        type="primary"
                    >
                        {campaign?.activated ? "Yes, I want to unlock" : "Yes, I want to lock"}
                    </Button>
                </div>
            </Modal>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1 style={classes.sequenceStepTitle}>{title}</h1>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        margin: "10px",
                    }}
                >
                    <div style={{ position: "relative", display: "inline-block" }}>
                        <Button
                            onClick={handleSave}
                            variant={BUTTON_VARIANT.PRIMARY}
                            isDisabled={!unsavedChanges}
                            style={{
                                position: "absolute",
                                transition: "width 0.5s",
                                width: unsavedChanges ? "160px" : "80px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {unsavedChanges ? "Save Changes" : "Saved"}
                        </Button>
                    </div>
                    <div style={{ position: "relative", display: "inline-block" }}>{/* Your button */}</div>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                        <div onClick={() => handleChangeLinkedinOrEmail("linkedin")} style={{ cursor: "pointer" }}>
                            {emailOrLinkedIn === "linkedin" ? <LinkedinOn /> : <LinkedinOff />}
                        </div>
                        <div onClick={() => handleChangeLinkedinOrEmail("email")} style={{ cursor: "pointer" }}>
                            {emailOrLinkedIn === "email" ? <EmailOn /> : <EmailOff />}
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "10px" }}>
                            <Switch
                                size={200}
                                checked={campaign?.activated}
                                onChange={handleToggle}
                                checkedChildren={null}
                                unCheckedChildren={null}
                                style={{ backgroundColor: campaign?.activated ? "#a53aff" : "#dadada" }}
                            />
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}>
                            {campaign?.activated ? (
                                <LockFilled style={{ fontSize: "30px", color: "#black" }} />
                            ) : (
                                <UnlockFilled style={{ fontSize: "30px", color: "black" }} />
                            )}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", gap: "10px" }}></div>
            </div>
            {original || !empty ? (
                <>
                    {Object.entries(steps).map(([step, { subject, message }], index) => {
                        return (
                            <SequenceStep
                                campaignId={campaignId}
                                campaignActivated={campaign?.activated}
                                step={step}
                                subject={""}
                                message={""}
                                original={""}
                                key={index}
                                buttonCallback={
                                    original
                                        ? null
                                        : (callback) => {
                                              dispatch(sequenceActions.rephraseStep(step, callback));
                                          }
                                }
                            ></SequenceStep>
                        );
                    })}
                    {original && !campaign?.activated && (
                        <div>
                            <Button
                                variant={BUTTON_VARIANT.SECONDARY}
                                borderless={true}
                                style={{ float: "left" }}
                                onClick={(e) => {
                                    dispatch(sequenceActions.addStep());
                                }}
                            >
                                Add step
                            </Button>
                            <Button
                                variant={BUTTON_VARIANT.SECONDARY}
                                borderless={true}
                                style={{ float: "right" }}
                                onClick={(e) => {
                                    dispatch(sequenceActions.removeStep());
                                }}
                            >
                                Remove step
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div style={{ marginTop: "30px" }}>
                        <div>Start creating AI powered sequences:</div>
                        <div>1. Name your sequence</div>
                        <div>2. Choose if it is an email or a linkedin sequence</div>
                        <div>3. Upload your version of the sequence steps</div>
                        <div>4. Generate by using the drop downs</div>
                        <Icon
                            type={"linkedin"}
                            style={{ marginTop: "15px", cursor: "pointer" }}
                            onClick={() => {
                                dispatch(sequenceActions.getSequence());
                            }}
                        />
                    </div>
                </>
            )}
            <div />
        </div>
    );
};

const SequenceContent = ({ campaign }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sequenceActions.getSequence());
    }, []);

    const originalSteps = useSelector((state) => state.sequence.originalSteps);
    const generatedSteps = useSelector((state) => state.sequence.generatedSteps);
    const unsavedChanges = useSelector((state) => state.sequence?.unsavedChanges);

    return (
        <div
            style={{
                minWidth: "1100px",
                display: "flex",
                gap: "70px",
                height: "100%",
                width: "100%",
                marginTop: "20px",
            }}
        >
            <FullSequence campaign={campaign} campaignId={campaign?.id} steps={generatedSteps} original={true} />
            {/* <div style={{ minHeight: "100%", border: "1px solid #CDCDCD", display: "block" }}></div> */}
            <StepsActions campaign={campaign} />
        </div>
    );
};

const StepsActions = ({ currentlyEditing, campaign }) => {
    const placeholderButtons = [
        { value: "{first_name}", label: "{first_name}" },
        { value: "{last_name}", label: "{last_name}" },
        { value: "{company_name}", label: "{company_name}" },
        { value: "{job_title}", label: "{job_title}" },
        { value: "{Tailored message}", label: "Tailored message" },
        { value: "{value_prop}", label: "Value prop" },
    ];

    const dispatch = useDispatch();
    const current = useSelector((state) => state.sequence?.currentlyEditing);
    const message = useSelector((state) => state.sequence?.generatedSteps?.[current]?.message);
    const cursorPosition = useSelector((state) => state.sequence?.cursorPosition);
    const steps = useSelector((state) => state.sequence?.generatedSteps);
    const campaignId = campaign?.id;

    const messageIndex = useSelector((state) => state.sequence?.generatedSteps?.[current]?.messageIndex || 0);

    console.log("editing", current);
    console.log("activated", campaign?.activated);

    const addPlaceHolderToStep = (placeholder, step) => {
        // const newMessage = message.slice(0, cursorPosition) + placeholder + message.slice(cursorPosition)

        let newMessage = "";

        if (Array.isArray(message)) {
            newMessage = [...message];
            newMessage[messageIndex] = message[messageIndex].slice(0, cursorPosition) + placeholder + message[messageIndex].slice(cursorPosition);
        } else {
            newMessage = message.slice(0, cursorPosition) + placeholder + message.slice(cursorPosition);
        }

        dispatch(sequenceActions.updateStep(current, { message: newMessage }));
        // set unsaved changes to true
        dispatch(sequenceActions.setUnsavedChanges(true));
    };

    if (campaign?.activated) {
        return (
            <div>
                <Card title="Campaign is activated">
                    <p>Unlock the campaign to edit the sequence</p>
                </Card>
            </div>
        );
    }

    // actions are a save butun and placeholder adder which is buttons for each placeholder
    return (
        <div
            style={{
                backgroundColor: "#fbfbff",
                height: "fit-content",
                padding: "20px",
                borderRadius: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
            }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "10px",
                }}
            >
                <Card
                    title={
                        <h3
                            style={{
                                color: "#a53aff",
                                fontWeight: "bold",
                                fontSize: "16px",
                            }}
                        >
                            Add Placeholder
                        </h3>
                    }
                >
                    {placeholderButtons.map((button) => (
                        <Button
                            key={button.value}
                            onClick={() => addPlaceHolderToStep(button.value, currentlyEditing)}
                            variant={BUTTON_VARIANT.PRIMARY_WHITE}
                        >
                            {button.label}
                        </Button>
                    ))}
                </Card>
            </div>
        </div>
    );
};

export default SequenceContent;
