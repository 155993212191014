import React, { useEffect, useState } from "react";
import { FormInput } from "../Form/Form";
import Button, { BUTTON_VARIANT } from "../Button/Button";
import DropzoneComponent from "../DropzoneComponent";
import { CAMPAIGN_TYPE, ENTITY_NBSP, EXCEL_FILE_TYPE } from "utils/constants";
import isURL from "validator/lib/isURL";
import ManualDiscoveryTable from "../ManualDiscoveryTable";
import Papa from "papaparse";
import Modal from "../Modal";
import { setCsvLengthForProgress } from "redux/Campaign/reducer";

import "./ManualDiscoveryForm.css";
import ButtonGroup from "../ButtonGroup";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const Instructions = ({ body, caption }) => {
    return (
        <div className="manual-discovery__content--instruction">
            <p className={"samplead-body"}>{body}</p>
            <p className={"samplead-caption"}>{caption}</p>
        </div>
    );
};

const Buffer = () => {
    return <div className="manual-discovery__content--buffer">{ENTITY_NBSP}</div>;
};

const ManualDiscoveryForm = ({ setUploadedFile, maximized, onCampaignCreate, onCampaignUpdate }) => {
    const [url, setUrl] = useState("");
    const [isValidUrl, setIsValidUrl] = useState(false);
    const [csvData, setCsvData] = useState(null);
    const [isEmails, setIsEmail] = useState(true);
    const [mappedObjSaved, setMappedObjSaved] = useState(null);
    const [error, setError] = useState("");
    const [acceptedFileName, setAcceptedFileName] = useState("");
    const existingCampaignID = useParams().id;
    const dispatch = useDispatch();

    const handleUrlChange = (value, isValid) => {
        setUrl(value);
        setIsValidUrl(isValid && value);
    };

    useEffect(() => {
        if (csvData !== null) {
            typeChecker(csvData);
        }
    }, [csvData]);

    const clearCSV = () => {
        setCsvData(null);
    };

    const onColumnsConfirm = (columnsIdentified) => {
        if (mappedObjSaved !== null) {
            existingCampaignID
                ? onCampaignUpdate(existingCampaignID, mappedObjSaved)
                : onCampaignCreate(CAMPAIGN_TYPE.MANUAL_DISCOVERY, mappedObjSaved);
        }
    };

    const typeChecker = (csvData) => {
        let mapped = [];
        csvData.data.forEach((lead, idx) => {
            const mappedObj = {};
            Object.keys(lead).forEach((column) => {
                let leadProperty = lead[column].toString();
                if (
                    (typeof leadProperty === "string" && !leadProperty.includes("linkedin.com/company/") && leadProperty.includes("www")) ||
                    leadProperty.includes("linkedin")
                ) {
                    if (!leadProperty.includes("linkedin.com/company/")) {
                        mappedObj.url = leadProperty;
                        mappedObj.found = "url";
                    }
                } else if (typeof leadProperty === "string" && leadProperty.match(/^\S+@\S+\.\S+$/)) {
                    console.log("email");
                    mappedObj.email = leadProperty;
                    mappedObj.found = mappedObj.found || "email";

                    console.log("mappedObj.found", mappedObj.found);
                } else if (typeof leadProperty === "string" && leadProperty.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im)) {
                    mappedObj.phone = leadProperty;
                }
            });
            mappedObj.found && mapped.push(mappedObj);
        });

        mapped = mapped.map((obj) => {
            obj.email_format = isEmails;
            return obj;
        });

        if (mapped.find((obj) => obj.found)) {
            setMappedObjSaved(mapped);
        } else {
            setCsvData(null);
            setError("No URLs were found in the entire CSV");
        }
    };

    // useEffect(() => {
    //     if (csvData !== null) {
    //         if (isEmails) {
    //             setCsvData((prev) =>
    //                 prev.data.map((person) => {
    //                     return (person.email_format = true);
    //                 })
    //             );
    //         } else {
    //             setCsvData((prev) =>
    //                 prev.data.map((person) => {
    //                     return (person.email_format = false);
    //                 })
    //             );
    //         }
    //     }
    // }, [isEmails]);

    return (
        <div className="manual-discovery__container">
            <div className="manual-discovery__content">
                <h3 className="samplead-body--large">
                    {" "}
                    Where would you use these messages?
                    <div>
                        <ButtonGroup buttons={["LinkedIn", "Email"]} setIsEmail={setIsEmail} />
                    </div>
                </h3>

                {maximized && (
                    <>
                        <Buffer />

                        <Instructions
                            body={"Upload leads from Sales Navigator"}
                            caption={"Enter a link of search you contacted on Linkedin Sales Navigator here:"}
                        />
                        <form className={"manual-discovery__form"}>
                            <FormInput
                                type={"text"}
                                inputName={"exampleInputText"}
                                placeholder={"Enter your link here"}
                                validator={{
                                    validatorFn: isURL,
                                    errorMessage: "Invalid link",
                                }}
                                inputValue={url}
                                onChange={handleUrlChange}
                            />
                            <Button variant={BUTTON_VARIANT.PRIMARY} isDisabled={!isValidUrl}>
                                Upload
                            </Button>
                        </form>
                        <Buffer />
                        <div className={"samplead-divider--horizontal"} />
                        <Buffer />
                    </>
                )}
                {csvData === null ? (
                    <Instructions
                        body={maximized ? "Or upload csv file here:" : "Upload a csv file here:"}
                        caption={"Upload a pre-prepared lead list as a csv file here:"}
                    />
                ) : (
                    <Instructions
                        body={maximized ? `${acceptedFileName}` : `${acceptedFileName}`}
                        caption={"Or use a different CSV file by clicking Clear CSV:"}
                    />
                )}
                {csvData === null && (
                    <DropzoneComponent
                        boxText={"Click to browse or \ndrag and drop your files"}
                        acceptedFileType={EXCEL_FILE_TYPE}
                        handleFileDrop={(acceptedFiles) => {
                            Papa.parse(acceptedFiles[0], {
                                header: true,
                                complete: (results) => {
                                    let csvData = {
                                        data: results.data,
                                        fields: results.meta.fields,
                                    };
                                    setAcceptedFileName(acceptedFiles[0].name);

                                    if (isEmails) {
                                        csvData.data = csvData.data.map((person) => {
                                            person.email_format = true;
                                            return person;
                                        });
                                    } else {
                                        csvData.data = csvData.data.map((person) => {
                                            person.email_format = false;
                                            return person;
                                        });
                                    }
                                    csvData.fields.push("email_format");
                                    console.log(csvData.data.length);
                                    dispatch(setCsvLengthForProgress(csvData.data.length));
                                    setCsvData(csvData);
                                },
                            });
                        }}
                    />
                )}
                {csvData && (
                    <ManualDiscoveryTable
                        csvData={csvData}
                        onColumnsConfirm={onColumnsConfirm}
                        mappedObjSaved={mappedObjSaved}
                        setMappedObjSaved={setMappedObjSaved}
                        setError={setError}
                        clearCSV={clearCSV}
                    />
                )}
                {error && (
                    <Modal isModalOpen={error.length > 0} closeModal={() => setError("")}>
                        <div className="error-content">
                            <p>{error}</p>
                        </div>
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default ManualDiscoveryForm;
