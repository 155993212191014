import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as BoltIcon } from "assets/icons/bolt.svg";
import axios from "axios";

import React, { useState, useEffect, useRef, useMemo } from "react";
import { CSSTransition } from "react-transition-group";
import { BarLoader } from "react-spinners";

import "./index.css";
import { getSequenceByName } from "routes/Campaign/CampaignContent/tabs/integrationFunctions";
import { useDispatch, useSelector } from "react-redux";
import csv_icon from "assets/images/icons/csv-icon.png";
import outreach_icon from "assets/images/icons/outreach-icon.png";
import expandi_icon from "assets/images/icons/expandi-icon.png";
import samplead_automation_icon from "assets/images/icons/samplead_automation_logo_small.png";
import salesforce_icon from "assets/images/icons/salesforce-icon.png";
import zapier_icon from "assets/images/icons/zapier-icon.png";
import * as expandiActions from "redux/Expandi/reducer";
import Spinner from "components/Spinner";
import { toggleSnackbarOpen } from "redux/Snackbar/actions";
import env from "config/site.config";
import Select from "react-select";

function DropdownMenu({
    handleSelectedOption,
    outreachSequences,
    setOutreachSequences,
    outreachSpinner,
    handleSelectedSequence,
    handleSelectedExpandiCampaign,
    handleSalesforceExport,
    handleZapierExport,
}) {
    const dispatch = useDispatch();
    const [activeMenu, setActiveMenu] = useState("main");
    const [menuHeight, setMenuHeight] = useState(null);
    const [showModal, setShowModal] = useState({ outreach: false, expandi: false, samplead: false, salesforce: false, zapier: false });
    const [filteredSequencesList, setFilteredSequencesList] = useState([]);
    const dropdownRef = useRef(null);
    const sampleadAutomationIntegration = useSelector((state) => !!state?.settings?.samplead_automation_integration);
    const expandiIntegration = useSelector((state) => !!state?.settings?.expandi_integration);
    const salesforceIntegration = useSelector((state) => !!state?.settings?.salesforce_integration);
    const zapierIntegration = useSelector((state) => !!state?.settings?.zapier_integration);
    const outreachIntegration = useSelector((state) => state.settings.outreach_integration);
    const outreachMailbox = useSelector((state) => state.settings.outreach_mailbox);
    const noMailbox = outreachIntegration === 1 && outreachMailbox === 0;
    const options = [{ label: "CSV", icon: csv_icon }];
    if (sampleadAutomationIntegration) options.push({ label: "Samplead Automation", icon: samplead_automation_icon });
    if (expandiIntegration) options.push({ label: "Expandi", icon: expandi_icon });
    if (salesforceIntegration) options.push({ label: "Salesforce", icon: salesforce_icon });
    if (outreachIntegration && !noMailbox) options.push({ label: "Outreach", icon: outreach_icon });
    if (zapierIntegration) options.push({ label: "Zapier", icon: zapier_icon });

    useEffect(() => {
        console.log(outreachSequences);
        setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
        dispatch(expandiActions.getExpandiUsers());
    }, []);

    useEffect(() => {
        setFilteredSequencesList(outreachSequences);
    }, [outreachSequences]);

    function calcHeight(el) {
        const height = el.offsetHeight;
        setMenuHeight(height);
    }

    function changeModal(modalToToggle) {
        setShowModal((prev) => {
            for (let key in prev) {
                if (key === modalToToggle) {
                    prev[key] = !prev[key];
                } else {
                    prev[key] = false;
                }
            }
            return { ...prev };
        });
    }
    const handleDropdownClick = (gotoMenu) => {
        switch (gotoMenu) {
            case "CSV":
                handleSelectedOption("CSV");
                break;
            case "Outreach":
                changeModal("outreach");
                break;
            case "Expandi":
                changeModal("expandi");
                break;
            case "Samplead Automation":
                changeModal("samplead");
                break;
            case "Salesforce":
                changeModal("salesforce");
                break;
            case "Zapier":
                changeModal("zapier");
                break;
            default:
                break;
        }
    };

    const handleSearchClick = async (value, whatToFilter) => {
        try {
            const accessToken = localStorage.getItem("outreach_access_token");
            const searchedResult = await getSequenceByName(accessToken, value);
            console.log(searchedResult);
            if (searchedResult.data.length > 0) {
                setFilteredSequencesList(searchedResult.data);
            } else {
                setFilteredSequencesList([]);
            }
        } catch (error) {}
    };

    function DropdownItem({ children, leftIcon, rightIcon, goToMenu, sequence, onClick, ...props }) {
        const [loading, setLoading] = useState(false);

        return (
            <div
                className="menu-item"
                onClick={() => (goToMenu ? handleDropdownClick(goToMenu) : sequence ? handleSelectedSequence(sequence) : onClick(setLoading))}
            >
                {["Outreach", "CSV", "Expandi", "Samplead Automation", "Salesforce", "Zapier"].includes(goToMenu) ? (
                    <>
                        {goToMenu === "Samplead Automation" ? (
                            <img style={{ height: "100%" }} alt="" src={leftIcon} />
                        ) : (
                            <img className="icon-button" alt="" src={leftIcon} />
                        )}
                    </>
                ) : (
                    <span className="icon-left">{leftIcon}</span>
                )}
                {loading ? <Spinner width="30px" /> : children}
                <span className="icon-right">{rightIcon}</span>
                {outreachSpinner && goToMenu === "Outreach" && (
                    <div className="bottom-spinner">
                        <BarLoader color="#5951ff" width={200} />
                    </div>
                )}
            </div>
        );
    }

    const ExpandiExport = ({ whitelabel }) => {
        const [filter, setFilter] = useState("");
        const campaigns = useSelector((state) =>
            state?.expandi?.[expandiActions.getCampaignKey(whitelabel)].filter((campaign) => {
                if (!campaign.id) return false;
                if (filter === "") return true;
                return campaign.campaign_name.toLowerCase().includes(filter.toLowerCase());
            })
        );
        const users = useSelector((state) => state?.expandi?.[expandiActions.getUsersKey(whitelabel)]);
        const [selectedUserId, setSelectedUserId] = useState(Object.keys(users)[0]);

        const defaultUser = useMemo(() => {
            if (Object.keys(users).length === 0)
                return {
                    value: {},
                    label: "No users found",
                };
            return {
                value: Object.keys(users)[0],
                label: Object.values(users)[0].username,
            };
        }, [users]);

        useEffect(() => {
            if (Object.keys(users).length === 0) {
                dispatch(expandiActions.getExpandiUsers(whitelabel));
                return;
            }
        }, []);

        useEffect(() => {
            if (Object.keys(users).length === 0) {
                return;
            }
            dispatch(expandiActions.getExpandiCampaigns({ whitelabel, expandiUserId: Object.keys(users)[0] }));
        }, [users]);

        return (
            <div className="menu-outreach">
                <div
                    style={{
                        position: "sticky",
                        width: "100%",
                        top: 0,
                        zIndex: 99,
                        backgroundColor: "white",
                    }}
                >
                    <h2 className="expandi-menu-title">Export to Campaign:</h2>
                    <div className="searchbox-integration">
                        <div className="integration-search">
                            <label>
                                {" "}
                                <MagnifyingGlass />{" "}
                            </label>
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Filter by campaign name"
                                onChange={(e) => {
                                    setFilter(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <Select
                        className="select-integration m-2"
                        placeholder="Select user"
                        defaultValue={defaultUser}
                        options={Object.entries(users).map(([id, user]) => {
                            return { value: id, label: user.username };
                        })}
                        onChange={(e) => {
                            dispatch(expandiActions.getExpandiCampaigns({ whitelabel, expandiUserId: e.value }));
                            setSelectedUserId(e.value);
                        }}
                    />
                </div>
                {campaigns?.length > 0 ? (
                    campaigns.map((campaign, index) => {
                        return (
                            <DropdownItem
                                onClick={(setLoading) => {
                                    setLoading(true);
                                    handleSelectedExpandiCampaign(campaign.id, selectedUserId, whitelabel, () => setLoading(false));
                                }}
                                key={index}
                                leftIcon={<SequenceSVG />}
                            >
                                {campaign.campaign_name}
                            </DropdownItem>
                        );
                    })
                ) : (
                    <DropdownItem goToMenu="main" leftIcon={<BoltIcon />}>
                        No campaigns found
                    </DropdownItem>
                )}
            </div>
        );
    };

    const OutreachExport = () => {
        return (
            <div className="menu-outreach">
                <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
                    <h2>Export to Sequence:</h2>
                </DropdownItem>
                <div className="searchbox-integration">
                    <div className="integration-search">
                        <label>
                            {" "}
                            <MagnifyingGlass />{" "}
                        </label>
                        <input
                            className="search-input"
                            type="text"
                            placeholder="Please type the exact name"
                            onChange={(e) => handleSearchClick(e.target.value, "outreach")}
                        />
                    </div>
                </div>
                {outreachSequences.length > 0 ? (
                    filteredSequencesList.map((sequence, index) => {
                        return (
                            <DropdownItem onClick={() => handleSelectedSequence(sequence)} key={index} leftIcon={<SequenceSVG />} sequence={sequence}>
                                {sequence.attributes.name}
                            </DropdownItem>
                        );
                    })
                ) : (
                    <DropdownItem goToMenu="main" leftIcon={<BoltIcon />}>
                        Couldn't get sequences, make sure you are integrated with Outreach.
                    </DropdownItem>
                )}
            </div>
        );
    };

    const SalesforceExport = () => {
        const [loading, setLoading] = useState(true);
        const [campaigns, setCampaigns] = useState([]);

        async function getSalesforceCampaigns(accessToken) {
            try {
                const response = await axios.post(`${env.REACT_APP_BACKEND_URL}/salesforce/get_campaigns`, {
                    salesforce_access_token: accessToken,
                });
                return response.data;
            } catch (error) {
                console.log(error);
            }
        }
        const getSalesforceData = async () => {
            setLoading(true);
            const accessToken = localStorage.getItem("salesforce_access_token");
            try {
                const salesforceData = await getSalesforceCampaigns(accessToken);
                console.log("salesforceData", salesforceData);
                if (salesforceData.success) setCampaigns(salesforceData?.campaigns);
                else {
                    throw new Error("Couldn't get Salesforce campaigns, make sure you are integrated with Salesforce.");
                }
            } catch (error) {
                dispatch(
                    toggleSnackbarOpen({
                        variant: "error",
                        message: "Couldn't get Salesforce campaigns, make sure you are integrated with Salesforce.",
                    })
                );
            }
            setLoading(false);
        };

        useEffect(() => {
            getSalesforceData();
        }, []);

        // create a antd modal
        return (
            <div className="menu-outreach">
                <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
                    <h2>Export to Salesforce:</h2>
                </DropdownItem>
                <div className="searchbox-integration">
                    <div className="integration-search">
                        <label>
                            {" "}
                            <MagnifyingGlass />{" "}
                        </label>
                        <input className="search-input" type="text" placeholder="Please type the exact name" />
                    </div>
                </div>
                {loading ? (
                    <div className="bottom-spinner">
                        <BarLoader color="#5951ff" width={150} />
                    </div>
                ) : campaigns.length > 0 ? (
                    <>
                        <DropdownItem
                            onClick={async (setComponentLoading) => {
                                setComponentLoading(true);
                                await handleSalesforceExport();
                                setComponentLoading(false);
                            }}
                            leftIcon={<SequenceSVG />}
                        >
                            Without Campaign
                        </DropdownItem>
                        {campaigns.map((campaign, index) => {
                            return (
                                <DropdownItem
                                    onClick={async (setComponentLoading) => {
                                        setComponentLoading(true);
                                        await handleSalesforceExport(campaign.Id);
                                        setComponentLoading(false);
                                    }}
                                    key={index}
                                    leftIcon={<SequenceSVG />}
                                >
                                    {campaign.Name}
                                </DropdownItem>
                            );
                        })}
                    </>
                ) : (
                    <DropdownItem goToMenu="main" leftIcon={<BoltIcon />}>
                        Couldn't get campaigns, make sure you are integrated with Salesforce.
                    </DropdownItem>
                )}
            </div>
        );
    };

    const ZapierExport = () => {
        return (
            <div className="menu-outreach">
                <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
                    <h2>Export to Zapier:</h2>
                </DropdownItem>
                <div className="searchbox-integration">
                    <div className="integration-search">
                        <label>
                            {" "}
                            <MagnifyingGlass />{" "}
                        </label>
                        <input className="search-input" type="text" placeholder="Please type the exact name" />
                    </div>
                </div>
                <DropdownItem
                    onClick={async (setComponentLoading) => {
                        setComponentLoading(true);
                        await handleZapierExport();
                        setComponentLoading(false);
                    }}
                    leftIcon={<SequenceSVG />}
                >
                    Push to Zapier
                </DropdownItem>
            </div>
        );
    };

    return (
        <div className="all-menus">
            <div className="dropdown-menu" style={{ height: menuHeight }} ref={dropdownRef}>
                <CSSTransition in={activeMenu === "main"} timeout={500} classNames="menu-primary" unmountOnExit onEnter={calcHeight}>
                    <div className="menu">
                        {options.map((option, index) => {
                            return (
                                <DropdownItem
                                    onClick={() => handleDropdownClick(option.label)}
                                    key={index}
                                    leftIcon={option.icon}
                                    rightIcon={<SideArrow />}
                                    goToMenu={option.label}
                                >
                                    {option.label !== "Samplead Automation" && option.label}
                                </DropdownItem>
                            );
                        })}
                    </div>
                </CSSTransition>
            </div>
            {showModal.samplead && <ExpandiExport whitelabel={true} />}
            {showModal.expandi && <ExpandiExport whitelabel={false} />}
            {showModal.outreach && <OutreachExport />}
            {showModal.salesforce && <SalesforceExport />}
            {showModal.zapier && <ZapierExport />}
            {/* </CSSTransition> */}
        </div>
    );
}

const SequenceSVG = () => {
    return (
        <div className="sequence-svg">
            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V7L11 1Z"
                    stroke="#7A7A7A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M11 1V7H17" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13 12H5" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13 16H5" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 8H6H5" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    );
};

const MagnifyingGlass = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M12.5233 11.4628L15.7355 14.6743L14.6743 15.7355L11.4628 12.5233C10.2678 13.4812 8.7815 14.0022 7.25 14C3.524 14 0.5 10.976 0.5 7.25C0.5 3.524 3.524 0.5 7.25 0.5C10.976 0.5 14 3.524 14 7.25C14.0022 8.7815 13.4812 10.2678 12.5233 11.4628ZM11.0188 10.9063C11.9706 9.92741 12.5022 8.61532 12.5 7.25C12.5 4.349 10.1503 2 7.25 2C4.349 2 2 4.349 2 7.25C2 10.1503 4.349 12.5 7.25 12.5C8.61532 12.5022 9.92741 11.9706 10.9063 11.0188L11.0188 10.9063V10.9063Z"
                fill="#535353"
            />
        </svg>
    );
};

export const SideArrow = () => {
    return (
        <svg className="side-arrow-svg" xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
            <path d="M3.879 4.99995L0.166504 1.28745L1.227 0.226949L6 4.99995L1.227 9.77295L0.166504 8.71245L3.879 4.99995Z" fill="#6e6e6e" />
        </svg>
    );
};

export default DropdownMenu;
