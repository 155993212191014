import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ca } from "date-fns/locale";
import { LeadStatus } from "utils/constants";

import * as campaignsApi from "./api";

const initialState = {
    campaigns: [
        {
            id: "all",
            title: "Magic Moments",
            type: "auto-discovery",
            leads: [],
            sortOrder: "desc",
            activated: true,
            campaignFilterId: null,
        },
    ],
    lastUpdated: new Date().toJSON(),
    expandedLeadIds: [],
    userTmpImages: {},
    csvLengthForProgress: 0,
    campaignPaginationAndSearch: {},
    isLoadingSuggestions: false,
};

const campaignSlice = createSlice({
    name: "campaign",
    initialState: initialState,
    reducers: {
        setCampaigns: (state, action) => {
            const { campaigns } = state;
            const newCampaigns = action.payload;

            newCampaigns.forEach((newCampaign) => {
                const { id, leads, searchResultCount, pageNumber, pageSize } = newCampaign;

                const index = campaigns.findIndex((c) => c.id === id);

                if (index > -1) {
                    const unseenLeads = campaigns[index].leads.filter((l) => l.status === 1);

                    // If we have a page and page size, update leads starting from the appropriate index
                    if (pageNumber && pageSize) {
                        const offset = (pageNumber - 1) * pageSize;
                        for (let i = 0; i < leads.length; i++) {
                            campaigns[index].leads[i + offset] = leads[i];
                        }
                    } else {
                        campaigns[index].leads = leads;
                    }
                    campaigns[index].leads = [...campaigns[index].leads, ...unseenLeads];
                    campaigns[index].searchResultCount = searchResultCount;
                } else {
                    campaigns.push(newCampaign);
                }
            });

            state.campaigns = campaigns;
        },
        setCampaign: (state, action) => {
            const { campaigns } = state;
            const campaign = action.payload;
            const { id, leads, searchResultCount, pageNumber, pageSize } = campaign;

            const index = campaigns.findIndex((c) => c.id === id);

            if (index > -1) {
                const unseenLeads = campaigns[index].leads.filter((l) => l.status === 1);

                // If we have a page and page size, update leads starting from the appropriate index
                if (pageNumber && pageSize) {
                    const offset = (pageNumber - 1) * pageSize;
                    for (let i = 0; i < leads.length; i++) {
                        campaigns[index].leads[i + offset] = leads[i];
                    }
                } else {
                    campaigns[index].leads = leads;
                }
                campaigns[index].leads = [...campaigns[index].leads, ...unseenLeads];
                campaigns[index].searchResultCount = searchResultCount;
            } else {
                campaigns.push(campaign);
            }

            state.campaigns = campaigns;
        },
        updatePaginationAndSearch: (state, action) => {
            const { campaignId, pageNumber, pageSize, searchValue, searchKey, currentTab, sortKey, sortOrder } = action.payload;
            state.campaignPaginationAndSearch[campaignId] = {
                pageNumber,
                pageSize,
                searchValue,
                searchKey,
                currentTab,
                sortKey,
                sortOrder,
            };
        },

        setTabCounts: (state, action) => {
            state.tabCounts = action.payload;
        },
        addLeadsToCampaign: (state, action) => {
            const { campaigns } = state;
            const { id, leads } = action.payload;

            console.log("addLeadsToCampaign", id, leads);

            const campaignIndex = campaigns.findIndex((c) => c.id === id);

            if (campaignIndex > -1) {
                campaigns[campaignIndex].leads = [
                    ...campaigns[campaignIndex].leads?.filter((l) => {
                        return !leads.find((lead) => lead.id === l.id);
                    }),
                    ...leads,
                ];
            }

            state.campaigns = campaigns;
        },
        setCsvLengthForProgress: (state, action) => {
            state.csvLengthForProgress = action.payload;
        },
        updateCampaignLead: (state, action) => {
            const { campaigns } = state;
            const updatedLead = action.payload;

            const campaignIndex = campaigns.findIndex((c) => c.id === updatedLead.campaignId);
            const allCampaignIndex = campaigns.findIndex((c) => c.id === "all");

            if (campaignIndex > -1) {
                const leadIndex = campaigns[campaignIndex].leads.findIndex((l) => l.id === updatedLead.id);

                if (leadIndex > -1) {
                    campaigns[campaignIndex].leads[leadIndex] = updatedLead;
                }
            }

            if (allCampaignIndex > -1) {
                const allLeadIndex = campaigns[allCampaignIndex].leads.findIndex((l) => l.id === updatedLead.id);

                if (allLeadIndex > -1) {
                    campaigns[allCampaignIndex].leads[allLeadIndex] = updatedLead;
                } else {
                    campaigns[allCampaignIndex].leads.push(updatedLead);
                }
            }

            state.campaigns = campaigns;
        },

        updateCampaignLeads: (state, action) => {
            const { campaigns } = state;
            const { campaignId, leads } = action.payload;
            const leadsObj = {};

            leads.forEach((lead) => {
                leadsObj[lead.id] = lead;
            });

            const campaignIndex = campaigns.findIndex((c) => c.id === campaignId);

            if (campaignIndex > -1) {
                campaigns[campaignIndex].leads = campaigns[campaignIndex].leads.map((lead) => {
                    if (leadsObj[lead.id]) {
                        return leadsObj[lead.id];
                    } else {
                        return lead;
                    }
                });
            }
        },
        getExpandedLeadIds: (state, action) => {
            return state.expandedLeadIds;
        },
        updateExpandedLeadIds: (state, action) => {
            state.expandedLeadIds = action.payload;
        },
        clearExpandedLeadIds: (state, action) => {
            state.expandedLeadIds = [];
        },
        addTmpUserImageUrl: (state, action) => {
            const { userTmpImages } = state;
            userTmpImages[action.payload.userId] = action.payload.url;
            state.userTmpImages = userTmpImages;
        },
        orderCampaigns: (state, action) => {
            const { campaigns } = state;
            state.campaigns = campaigns
                .map((campaign) => {
                    if (campaign.type === "auto-discovery") campaign.order = 1;
                    else campaign.order = 0;
                    return campaign;
                })
                .sort((a, b) => a.order - b.order);
        },
        setLead: (state, action) => {
            const { campaigns } = state;
            const { lead } = action.payload;

            const campaignIndex = campaigns.findIndex((c) => c.id === lead.campaignId);
            const allCampaignIndex = campaigns.findIndex((c) => c.id === "all");

            if (campaignIndex > -1) {
                const leadIndex = campaigns[campaignIndex].leads.findIndex((l) => l.id === lead.id);

                if (leadIndex > -1) {
                    campaigns[campaignIndex].leads[leadIndex] = lead;
                }
            }

            if (allCampaignIndex > -1) {
                const allLeadIndex = campaigns[allCampaignIndex].leads.findIndex((l) => l.id === lead.id);

                if (allLeadIndex > -1) {
                    campaigns[allCampaignIndex].leads[allLeadIndex] = lead;
                } else {
                    campaigns[allCampaignIndex].leads.push(lead);
                }
            }

            state.campaigns = campaigns;
            state.lastUpdated = new Date().toJSON();
        },
        normalizeLeadsProperties: (state, action) => {
            const { campaigns } = state;
            const { campaignId, leadIds, properties } = action.payload;
            if (!campaigns?.length || !campaignId || !leadIds?.length || !properties?.length) return;

            const campaign = campaigns.find((c) => c.id === campaignId);

            if (campaign) {
                campaign.leads = campaign.leads.map((lead) => {
                    if (leadIds.includes(lead.id)) {
                        properties.forEach((property) => {
                            if (!lead[property]) {
                                lead[property] = " ";
                            }
                        });
                    }
                    return lead;
                });
            }

            state.campaigns = campaigns;
        },
        setCampaignUpdated: (state, action) => {
            state.lastUpdated = new Date().toJSON();
        },
        changeActive: (state, action) => {
            const { campaigns } = state;
            const { campaignId, activated } = action.payload;

            const campaignIndex = campaigns.findIndex((c) => c.id === campaignId);

            if (campaignIndex > -1) {
                campaigns[campaignIndex].activated = activated;
            }

            state.campaigns = campaigns;
        },
        setCampaignFilterId: (state, action) => {
            // got payload {type: 'campaign/setCampaignFilterId', payload: '103f06f6-ff90-492e-aa34-6c4c93e9e1b1'}

            // change campaignFilterId of campaign with id: 'all'
            const { campaigns } = state;

            const campaignIndex = campaigns.findIndex((c) => c.id === "all");

            if (campaignIndex > -1) {
                campaigns[campaignIndex].campaignFilterId = action.payload;
            }

            state.campaigns = campaigns;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(renameCampaign.fulfilled, (state, action) => {
            const { campaigns } = state;

            const index = campaigns.findIndex((c) => c.campaignId === action.payload.campaignId);

            if (index > -1) {
                campaigns[index].name = action.payload.data;
            }

            return {
                ...state,
                campaigns,
            };
        });
    },
});

const getCampaigns = createAsyncThunk("campaign/getCampaigns", async (_, thunkAPI) => {
    const payload = await campaignsApi.getCampaigns();
    const { dispatch } = thunkAPI;
    if (!payload) return;
    dispatch(campaignSlice.actions.setCampaigns(payload));
});

const renameCampaign = createAsyncThunk("campaign/renameCampaign", async ({ campaignId, name }, thunkAPI) => {
    const payload = await campaignsApi.renameCampaign(campaignId, name);
    return payload;
});

const rephraseLead = createAsyncThunk("campaign/rephraseLead", async (leadId, thunkAPI) => {
    const payload = await campaignsApi.rephraseLead(leadId);
    const { dispatch } = thunkAPI;
    dispatch(campaignSlice.actions.updateCampaignLead(payload));
});

const rephraseLeadLambda = createAsyncThunk("campaign/rephraseLeadLambda", async (data, thunkAPI) => {
    const { leadId, setLoadingMessages } = data;
    setLoadingMessages(true);
    console.log("rephraseLeadLambda", data);
    const payload = await campaignsApi.rephraseLeadLambda(data);
    const { dispatch } = thunkAPI;
    dispatch(campaignSlice.actions.updateCampaignLead(payload));
    setLoadingMessages(false);
});

const markLeadsAsRead = createAsyncThunk("campaign/markLeadsAsRead", async ({ campaignId, leadIds }, thunkAPI) => {
    await campaignsApi.markLeadsAsRead(leadIds);
    const { dispatch, getState } = thunkAPI;
    const campaign = getState().campaign.campaigns[campaignId];
    const leadIdsSet = new Set(leadIds);
    const leads = campaign.leads.filter((lead) => leadIdsSet.has(lead.id)).map((lead) => ({ ...lead, read: true }));

    dispatch(campaignSlice.actions.updateCampaignLeads({ campaignId, leads }));
});

const editLeadMessage = createAsyncThunk("campaign/editLeadMessage", async (lead, thunkAPI) => {
    const payload = await campaignsApi.editLeadMessage(lead.campaignId, lead.id, lead.message);
    const { dispatch } = thunkAPI;
    dispatch(campaignSlice.actions.updateCampaignLead(payload));
});

const editLeadValuePropositionMessage = createAsyncThunk("campaign/editLeadValuePropositionMessage", async ({ lead, message }, thunkAPI) => {
    console.log("editLeadValuePropositionMessage", lead);
    const payload = await campaignsApi.editLeadValuePropositionMessage(lead.campaignId, lead.id, message);
    const { dispatch } = thunkAPI;
    dispatch(campaignSlice.actions.updateCampaignLead(payload));
});

const denyLead = createAsyncThunk("campaign/denyLead", async ({ leadId, reasons }, thunkAPI) => {
    const payload = await campaignsApi.denyLead(leadId, reasons);
    const { dispatch } = thunkAPI;
    dispatch(campaignSlice.actions.updateCampaignLead(payload));
});

const changeLeadStatus = createAsyncThunk("campaign/changeLeadStatus", async ({ leadId, status }, thunkAPI) => {
    const payload = await campaignsApi.changeLeadStatus(leadId, status);
    const { dispatch } = thunkAPI;
    dispatch(campaignSlice.actions.updateCampaignLead(payload));
});

const getCampaignById = createAsyncThunk(
    "campaign/getCampaignById",
    async ({ campaignId, pageNumber, pageSize, searchValue, searchKey, currentTab, sortKey, sortOrder, callback }, thunkAPI) => {
        const { campaign, tabCounts } = await campaignsApi.getCampaignById(
            campaignId,
            pageNumber,
            pageSize,
            searchValue,
            searchKey,
            currentTab,
            sortKey,
            sortOrder
        );
        if (campaign && tabCounts) {
            const { dispatch } = thunkAPI;
            dispatch(campaignSlice.actions.setCampaign(campaign));
            dispatch(campaignSlice.actions.setTabCounts(tabCounts));
            if (callback) {
                callback();
            }
        }
    }
);

const getAllMagicMoments = createAsyncThunk(
    "campaign/getAllMagicMoments",
    async ({ campaignId, pageNumber, pageSize, searchValue, searchKey, currentTab, sortKey, sortOrder, filterByCampaignId, callback }, thunkAPI) => {
        const { campaign, tabCounts } = await campaignsApi.getAllMagicMoments(
            campaignId,
            pageNumber,
            pageSize,
            searchValue,
            searchKey,
            currentTab,
            sortKey,
            sortOrder,
            filterByCampaignId
        );
        console.log("getAllMagicMoments", campaign, tabCounts);
        if (campaign && tabCounts) {
            const { dispatch } = thunkAPI;
            dispatch(campaignSlice.actions.setCampaign(campaign));
            dispatch(campaignSlice.actions.setTabCounts(tabCounts));
            if (callback) {
                callback();
            }
        }
    }
);

const getAllMagicMomentsUnseen = createAsyncThunk("campaign/getAllMagicMomentsUnseen", async (obj, thunkAPI) => {
    const id = "all";
    const payload = await campaignsApi.getAllMagicMomentsUnseen(id);

    console.log("payload", payload);

    if (payload) {
        const { dispatch } = thunkAPI;
        console.log("getAllMagicMomentsUnseen", payload);

        dispatch(campaignSlice.actions.addLeadsToCampaign(payload));
    }
});

const getCampaignMonitoredById = createAsyncThunk("campaign/getCampaignMonitoredById", async (obj, thunkAPI) => {
    const { id, setLoadingRelevantLeads } = obj;
    setLoadingRelevantLeads(true);
    const payload = await campaignsApi.getCampaignMonitoredById(id);
    setLoadingRelevantLeads(false);

    if (payload) {
        const { dispatch } = thunkAPI;
        console.log("getCampaignMonitoredById", payload);
        dispatch(campaignSlice.actions.addLeadsToCampaign(payload));
    }
});

const getCampaignUnseenById = createAsyncThunk("campaign/getCampaignUnseenById", async (obj, thunkAPI) => {
    const { id } = obj;
    const payload = await campaignsApi.getCampaignUnseenById(id);

    console.log("payload", payload);

    if (payload) {
        const { dispatch } = thunkAPI;
        console.log("getCampaignUnseenById", payload);
        dispatch(campaignSlice.actions.addLeadsToCampaign(payload));
    }
});

const exportLeads = createAsyncThunk("campaign/exportLeads", async ({ campaignId, leadIds, callback = () => {} }, thunkAPI) => {
    await campaignsApi.exportLeads(leadIds);
    callback();
    const { dispatch, getState } = thunkAPI;
    const parameters = { ...getState().campaign?.campaignPaginationAndSearch?.[campaignId], campaignId };
    console.log("parameters", parameters);
    parameters && (await dispatch(getCampaignById(parameters)));
});

const removeLeads = createAsyncThunk("campaign/removeLeads", async ({ campaignId, leadIds }, thunkAPI) => {
    await campaignsApi.removeLeads(leadIds);
    const { dispatch, getState } = thunkAPI;
    const parameters = { ...getState().campaign?.campaignPaginationAndSearch?.[campaignId], campaignId };
    parameters && (await dispatch(getCampaignById(parameters)));
});

const restoreLeads = createAsyncThunk("campaign/restoreLeads", async ({ campaignId, leadIds }, thunkAPI) => {
    await campaignsApi.restoreLeads(leadIds);
    const { dispatch, getState } = thunkAPI;
    const parameters = { ...getState().campaign?.campaignPaginationAndSearch?.[campaignId], campaignId };
    parameters && (await dispatch(getCampaignById(parameters)));
});

const changeActive = createAsyncThunk("campaign/changeActive", async ({ campaignId, active }, thunkAPI) => {
    const res = await campaignsApi.changeActive(campaignId, active);
    const isActive = res?.activated;
    const { dispatch } = thunkAPI;
    dispatch(campaignSlice.actions.changeActive({ campaignId, activated: isActive }));

    return isActive;
});

const createDiscoveryCampaign = createAsyncThunk("campaign/createDiscoveryCampaign", async ({ name, callback }, thunkAPI) => {
    const payload = await campaignsApi.createDiscoveryCampaign(name, callback);
    const { dispatch } = thunkAPI;
    console.log("createDiscoveryCampaign", payload);
    callback();
});

export const {
    setCampaigns,
    setCampaign,
    updatePaginationAndSearch,
    setCsvLengthForProgress,
    updateCampaignLead,
    updateCampaignLeads,
    getExpandedLeadIds,
    updateExpandedLeadIds,
    clearExpandedLeadIds,
    addTmpUserImageUrl,
    orderCampaigns,
    setLead,
    setLeads,
    normalizeLeadsProperties,
    setCampaignUpdated,
    addLeadsToCampaign,
    setCampaignFilterId,
} = campaignSlice.actions;

export {
    getCampaigns,
    rephraseLead,
    rephraseLeadLambda,
    renameCampaign,
    markLeadsAsRead,
    editLeadMessage,
    editLeadValuePropositionMessage,
    denyLead,
    changeLeadStatus,
    exportLeads,
    removeLeads,
    restoreLeads,
    getCampaignById,
    getCampaignMonitoredById,
    getCampaignUnseenById,
    getAllMagicMomentsUnseen,
    getAllMagicMoments,
    changeActive,
    createDiscoveryCampaign,
};
export default campaignSlice.reducer;
