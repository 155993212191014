const useStyles = () => ({
    root: {
        display: "flex",
        gap: "10px",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
    },
    select: {
        position: "relative",
        minWidth: "100px",
    },
    selectInput: {
        border: "1px solid #DADADA",
        borderRadius: "5px",
        padding: "7px 12px",
        display: "flex",
        justifyContent: "space-between",
    },
    selectOptions: {
        backgroundColor: "#FFFFFF",
        position: "absolute",
        boxShadow: "0px 20px 35px rgba(87, 87, 87, 0.15)",
        border: "1px solid #F2F2FF",
        borderRadius: "11px",
        padding: "12px 30px 12px 14px",
        zIndex: "1000",
    },
    selectOption: {
        display: "flex",
        gap: "10px",
        whiteSpace: "nowrap",
    },
    selectOptionDivider: {
        width: "100%",
        height: "1px",
        backgroundColor: "#F2F2FF",
        margin: "8px 0px",
    },
});

export default useStyles;
