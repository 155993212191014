const env = {
    REACT_APP_ENV_NAME: "dev",
    REACT_APP_BACKEND_URL: "https://dev-console.app.samplead.co",
    REACT_APP_STORAGE_SIGNED_USER_KEY: "dev-samplead-user-data",
    REACT_APP_STORAGE_SIGNED_ADMIN_KEY: "dev-samplead-admin-data",
    REACT_APP_LINKED_IN_SIGN_OUT_URL: "https://linkedin.com/m/logout",
    REACT_APP_LINKED_IN_CALLBACK_URL: "https://dev.app.samplead.co/login/callback",
    REACT_APP_GOOGLE_ANALYTICS_ID: "G-HN7WK519XH",
    REACT_APP_OUTREACH_AUTH_URL:
        "https://api.outreach.io/oauth/authorize?client_id=xXZ22D5jh8WdPgCL0wR2Ndyw7fm4wfQvBXGrzi8hV4Y&redirect_uri=https://dev.app.samplead.co&response_type=code&scope=prospects.all+sequences.all+sequenceStates.all+mailboxes.read+accounts.all",
    REACT_APP_SALESFORCE_AUTH_URL: (domainName) => {
        return `https://${domainName}.my.salesforce.com/services/oauth2/authorize?client_id=3MVG9IXUyidRC0l232EssIh8tWSoFkpBJtJ8IxPxgtHEUDVylhEDVDbjp1hWrsZNwyzEieax6TIyUv9qLRujv&redirect_uri=https://dev.app.samplead.co/integrations/salesforce/&response_type=code`;
    },
    STATISTICS_API_URL: "https://statistics-dashboard.samplead.co",
};

export default env;
