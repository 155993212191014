import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import {
    PATH_ALL_CAMPAIGNS,
    PATH_CAMPAIGN_PAGE,
    PATH_DASHBOARD,
    PATH_HOME,
    PATH_LOGIN,
    PATH_SEQUENCE_PAGE,
    PATH_LOGIN_CALLBACK,
    PATH_SETTINGS_PAGE,
    PATH_ONBOARDING_PAGE,
    PATH_STATISTICS,
    PATH_INTEGRATIONS,
    PATH_INTEGRATE_OUTREACH,
    PATH_INTEGRATE_EXPANDI,
    PATH_INTEGRATE_ZAPIER,
    PATH_INTEGRATE_WE_CONNECT,
    PATH_INTEGRATE_OUTREACH_PARAM,
    PATH_INTEGRATE_SAMPLEAD_AUTOMATION,
    PATH_INTEGRATE_SALESFORCE,
    PATH_INTEGRATE_SALESFORCE_PARAM,
    PATH_ACCOUNT_SETTING,
    PATH_PRODUCT_OPTIONS,
    PATH_OAUTH2_GRANT,
    PATH_CLUSTERING_TITLES,
    PATH_TO_REPLIES,
    PATH_MAGIC_MOMENTS,
} from "utils/constants";
import Login from "./Login";
import LoginCallback from "./LoginCallback";
import Dashboard from "./Dashboard";
import AllCampaigns from "./AllCampaigns";
import Statistics from "./Statistics/Statistics";
import Campaign from "./Campaign";
import Settings from "./Settings";
import Onboarding from "./Onboarding";
import Sequence from "./Sequence";
import Integrations from "./Integrations/Integrations";
import IntegrateZapier from "./Integrations/IntegrateZapier";
import IntegrateOutreach from "./Integrations/IntegrateOutreach";
import IntegrateExpandi from "./Integrations/IntegrateExpandi";
import IntegrateSalesforce from "./Integrations/IntegrateSalesforce";
import { setStorageItem } from "utils/storageUtils";
import AccountSettings from "./Settings/AccountSettings";
import ProductOptions from "./ProcessCheckout/ProductOptions";
import Oauth2Grant from "./OauthGrant";
import ClusterTitles from "./ClusterTitles";
import { PATH_EMBEDDED_STATISTICS } from "utils/constants";
import EmbeddedStatistics from "./EmbeddedStatistics";
import Replies from "./Replies";
import MagicMoments from "./MagicMoments";

const AppAuthRoutes = () => (
    <Routes>
        <Route path={PATH_LOGIN} element={<Login />} />
        <Route path={PATH_LOGIN_CALLBACK} element={<LoginCallback />} />
        <Route path="*" element={<Navigate to={PATH_LOGIN} />} />
    </Routes>
);

const AppPublicRoutes = () => {
    return (
        <Routes>
            <Route path={PATH_ONBOARDING_PAGE} element={<Onboarding />} />
            <Route path={PATH_PRODUCT_OPTIONS} element={<ProductOptions />} />
            <Route path="*" element={<Navigate to={PATH_ONBOARDING_PAGE} />} />
        </Routes>
    );
};

const AppPrivateRoutes = () => {
    return (
        <Routes>
            <Route path={PATH_HOME} element={<Navigate to={PATH_DASHBOARD} />} index />
            <Route path={PATH_DASHBOARD} element={<Dashboard />} />
            <Route path={PATH_MAGIC_MOMENTS} element={<MagicMoments />} />
            <Route path={PATH_STATISTICS} element={<Statistics />} />
            <Route path={PATH_EMBEDDED_STATISTICS} element={<EmbeddedStatistics />} />
            <Route path={PATH_CLUSTERING_TITLES} element={<ClusterTitles />} />
            <Route path={PATH_CAMPAIGN_PAGE} element={<Campaign />} />
            <Route path={PATH_SETTINGS_PAGE} element={<Settings />} />
            <Route path={PATH_TO_REPLIES} element={<Replies />} />
            <Route path={PATH_ACCOUNT_SETTING} element={<AccountSettings />} />
            <Route path={PATH_INTEGRATIONS} element={<Integrations />} />
            <Route path={PATH_SEQUENCE_PAGE} element={<Sequence />} />
            <Route path={PATH_INTEGRATE_ZAPIER} element={<IntegrateZapier />} />
            <Route path={PATH_INTEGRATE_OUTREACH} element={<IntegrateOutreach />} />
            <Route path={PATH_INTEGRATE_OUTREACH_PARAM} element={<IntegrateOutreach />} />
            <Route path={PATH_INTEGRATE_EXPANDI} element={<IntegrateExpandi whitelabelFlag={false} />} />
            <Route path={PATH_INTEGRATE_SAMPLEAD_AUTOMATION} element={<IntegrateExpandi whitelabelFlag={true} />} />
            <Route path={PATH_INTEGRATE_SALESFORCE} element={<IntegrateSalesforce />} />
            <Route path={PATH_INTEGRATE_SALESFORCE_PARAM} element={<IntegrateSalesforce />} />
            <Route path={PATH_INTEGRATE_WE_CONNECT} element={<Integrations />} />
            <Route path={PATH_ONBOARDING_PAGE} element={<Onboarding />} />
            <Route path={PATH_OAUTH2_GRANT} element={<Oauth2Grant />} />
            <Route path="*" element={<Navigate to={PATH_DASHBOARD} />} />
        </Routes>
    );
};

const AppRoutes = () => {
    const location = useLocation();
    const user = useSelector((state) => state.user.user);

    if (!user) {
        console.log("path in app routes", location.pathname + location.search);
        if (!location.pathname.includes("/login/")) {
            // save last path only if it is not a login route
            setStorageItem("lastPath", location.pathname + location.search);
        }
        return <AppAuthRoutes />;
    }

    if (user.admin) {
        return <AppPrivateRoutes />;
    }

    if (!user.active_subscription) {
        return <AppPublicRoutes />;
    }

    return <AppPrivateRoutes />;
};

export default AppRoutes;
