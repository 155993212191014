import { Tag } from "antd";

const OriginChip = ({ origin, badgesVersion }) => {
    if (origin === "suggested") {
        return (
            <Tag color="#a54cff" className="origin-chip">
                <div>{"SUGGESTED"}</div>
            </Tag>
        );
    }
    if (origin === "your list") {
        return (
            <Tag color="#ffd405" className="origin-chip">
                {"YOUR LIST"}
            </Tag>
        );
    } else {
        return null;
    }
};

export default OriginChip;
