import React, { useState, useEffect } from "react";
import NavButtons from "components/NavButtons/NavButtons";
import "../progressBar.css";

const CompanyWebsiteLoader = ({ handleNavClick }) => {
    const [progress, setProgress] = useState(0);
    const [isAnimationActive, setIsAnimationActive] = useState(false);

    const duration = 10;

    const onAnimationEnd = () => {
        setIsAnimationActive(true);
        setTimeout(() => setIsAnimationActive(false), 3000); // Reset after 3 seconds
        handleNavClick();
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => prevProgress + 100 / duration);
        }, 100);

        return () => clearInterval(interval);
    }, [duration]);

    useEffect(() => {
        if (progress >= 100) {
            setProgress(100);
            onAnimationEnd();
        }
    }, [progress, onAnimationEnd]);

    return (
        <>
            <div className="flex justify-center items-center h-full">
                <div className="flex flex-col justify-center items-center gap-5">
                    <h1>Searching for your company's website...</h1>
                    <div className="progress-container">
                        <div className="progress-bar" style={{ width: `${progress}%` }} />
                    </div>
                </div>
                {/* {isAnimationActive && (
                    <div className="confetti-container">
                        {[...Array(50)].map((_, index) => (
                            <div
                                key={index}
                                className="confetti"
                                style={{
                                    "--i": index,
                                    "--animation-duration": `${Math.random() * 3 + 0.5}s`,
                                    top: `${Math.random() * 100}%`,
                                    left: `${Math.random() * 100}%`,
                                }}
                            />
                        ))}
                    </div>
                )} */}
            </div>
        </>
    );
};
export default CompanyWebsiteLoader;
