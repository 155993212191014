import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button, { BUTTON_VARIANT } from "components/Button/Button";
import SelectInput from "./SelectInput";
import * as sequenceActions from "redux/Sequence/actions";
import Spinner from "components/Spinner";

const SequenceOptions = () => {
    const dispatch = useDispatch();
    const empty = useSelector((state) => state.sequence.empty);
    const stepsToUpdate = useSelector((state) => state.sequence.stepsToUpdate);
    const subject = useSelector((state) => state.sequence.subject);
    const modifyTo = useSelector((state) => state.sequence.modifyTo);
    const [loading, setLoading] = useState(false);

    const allSequenceOptions = [
        {
            label: "Step:",
            mode: "single",
            state: stepsToUpdate,
            setState: (state) => dispatch(sequenceActions.changeStepsToUpdate(state)),
        },
        {
            label: "Subject:",
            mode: "single",
            state: subject,
            setState: (state) => dispatch(sequenceActions.changeSubject(state)),
        },
        {
            label: "Modify to:",
            mode: "single",
            state: modifyTo,
            setState: (state) => dispatch(sequenceActions.changeModifyTo(state)),
        },
    ];
    return (
        <>
            <div style={{ width: "100%", float: "left", display: "flex", gap: "24px", fontSize: "14px" }}>
                {allSequenceOptions.map(({ label, mode, state, setState }, i) => {
                    return <SelectInput key={label} label={label} mode={mode} state={state} setState={setState} />;
                })}
                {empty || (
                    <Button
                        variant={BUTTON_VARIANT.PRIMARY}
                        style={{ marginLeft: "20px", display: loading ? "hidden" : "box" }}
                        isDisabled={loading}
                        onClick={() => {
                            setLoading(true);
                            dispatch(sequenceActions.generateSequence(() => setLoading(false)));
                        }}
                    >
                        {loading ? <Spinner width="30" /> : "Generate"}
                    </Button>
                )}
            </div>
            <div style={{ border: "1px solid #CBCBCB", width: "100%" }} />
        </>
    );
};

export default SequenceOptions;
