import React, { useState } from "react";
import Button, { BUTTON_VARIANT } from "../Button/Button";
import "./ButtonGroup.css";

const ButtonGroup = ({ buttons, setIsEmail }) => {
    const [clickedId, setClickedId] = useState(1);

    const handleClick = (event, id) => {
        console.log(id);
        setClickedId(id);
        id === 1 ? setIsEmail(true) : setIsEmail(false);
    };
    return (
        <>
            <div className="button-group-container">
                {buttons.map((buttonLabel, i) => (
                    <Button
                        key={i}
                        name={buttonLabel}
                        onClick={(event) => handleClick(event, i)}
                        className={i === clickedId ? "customButton primary" : "customButton"}
                        variant={i === clickedId ? BUTTON_VARIANT.PRIMARY : "customButton"}
                    >
                        {buttonLabel}
                    </Button>
                ))}
            </div>
        </>
    );
};

export default ButtonGroup;
