import React, { useEffect, useState } from "react";
import Tooltip, { TOOLTIP_POSITION } from "../Tooltip/Tooltip";
import { DEFAULT_CAMPAIGN_TITLE } from "utils/constants";
// import Icon from 'react-web-vector-icons';

import "./EditableText.css";

const EditableText = (props) => {
    const { refValue, initValue, updateValue, isEditing, editClassName, showTooltip, editable } = props;
    const [value, setValue] = useState(initValue);
    const [backup, setBackup] = useState(initValue);
    const [editMode, setEditMode] = useState(isEditing);

    useEffect(() => {
        setValue(initValue);
        setBackup(initValue);
    }, [initValue]);

    const ReadOnlyElement = () => (
        <div
            ref={refValue}
            className={editClassName}
            onClick={() => {
                editable && setEditMode(!editMode);
            }}
        >
            <span style={{ paddingLeft: "6px" }}>{value}</span>
        </div>
    );

    const renderReadOnly = () => {
        return showTooltip ? (
            <Tooltip message={value} position={TOOLTIP_POSITION.TOP}>
                <ReadOnlyElement />
            </Tooltip>
        ) : (
            <ReadOnlyElement />
        );
    };

    return editMode ? (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div className={`editable-text ${editClassName}`}>
                <input
                    type="text"
                    value={value}
                    className={editClassName + " editable-text"}
                    autoFocus
                    onFocus={(event) => {
                        if (value === DEFAULT_CAMPAIGN_TITLE) {
                            event.target.select();
                        }
                        // const value = event.target.value;
                        // event.target.value = "";
                        // event.target.value = value;
                        // setBackup(value);
                        // console.log("!", value)
                        // if(value === DEFAULT_CAMPAIGN_TITLE) {
                        // 	console.log("!!")
                        // 	setTimeout(() => {
                        // 		console.log("!!!")
                        // 		event.target.focus();
                        // 		event.target.select();
                        // 	}, 1000);
                        // }
                    }}
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                    onBlur={() => {
                        setEditMode(false);
                    }}
                    onKeyUp={(event) => {
                        if (event.key === "Escape") {
                            setValue(backup);
                            setEditMode(false);
                        } else if (event.key === "Enter") {
                            setEditMode(false);
                            updateValue(value);
                        }
                    }}
                />
            </div>
            {/*{*/}
            {/*value !== backup &&*/}
            {/*<div onClick={() => updateValue(value)}>*/}
            {/*<Icon*/}
            {/*name='save'*/}
            {/*font='Feather'*/}
            {/*color="#7B31C6"*/}
            {/*size={50}*/}
            {/*style={{marginLeft: '20px', cursor: 'pointer'}}*/}
            {/*/>*/}
            {/*</div>*/}
            {/*}*/}
        </div>
    ) : (
        renderReadOnly()
    );
};

export default EditableText;
