import "./CreateCampaignButton.css";
import Icon from "components/Icon/Icon";
import { CAMPAIGN_TYPE } from "utils/constants";
import Button, { BUTTON_VARIANT } from "components/Button/Button";

const CreateCampaignButton = ({ type, onClick, version }) => {
    const description =
        type === CAMPAIGN_TYPE.MANUAL_DISCOVERY
            ? `Please upload a list of prospects
            to create instant personalizations`
            : `Upload a list of prospects and Samplead will monitor their activity daily and let you know once it find out the perfect moment to reach out.
            `;
    if (version === "upload more") {
        return (
            <div>
                <div className="lead-button-container">
                    <Button onClick={onClick} variant={BUTTON_VARIANT.SECONDARY} className="lead-button">
                        {type === CAMPAIGN_TYPE.MANUAL_DISCOVERY ? "Upload more" : "Upload more"}
                    </Button>
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    margin: "0 auto",
                }}
            >
                <div className="lead-button-container">
                    <Icon type={type} />
                    <p
                        style={{
                            maxWidth: "300px",
                        }}
                        className="samplead-body  text-center"
                    >
                        {description}
                    </p>
                    <Button onClick={onClick} variant={BUTTON_VARIANT.SECONDARY} className="lead-button">
                        {type === CAMPAIGN_TYPE.MANUAL_DISCOVERY ? "Upload list" : "Create campaign"}
                    </Button>
                </div>
            </div>
        );
    }
};

export default CreateCampaignButton;
