import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { toggleSnackbarClose } from "redux/Snackbar/actions";
import { FiX } from "react-icons/fi";

const Snackbar = () => {
    const dispatch = useDispatch();

    // select the UI states from the redux store
    const SHOW = useSelector((state) => state.snackbar.toggleSnackbar);
    const MESSAGE = useSelector((state) => state.snackbar.snackbarMessage);
    const timeout = useSelector((state) => state.snackbar.snackbarTimeout);
    const variant = useSelector((state) => state.snackbar.snackbarVariant);

    // convert the timeout prop to pass into the styled component
    let TIME = (timeout - 500) / 1000 + "s";

    let TIMER;
    function handleTimeout() {
        TIMER = setTimeout(() => {
            dispatch(toggleSnackbarClose());
        }, timeout);
    }

    function handleClose() {
        clearTimeout(TIMER);
        dispatch(toggleSnackbarClose());
    }

    useEffect(() => {
        if (SHOW && timeout !== -1) {
            handleTimeout();
        }
        return () => {
            clearTimeout(TIMER);
        };
    }, [SHOW, TIMER]);

    return SHOW ? (
        <Container time={TIME} variant={variant}>
            <Message>{MESSAGE}</Message>
            <Button onClick={handleClose}>
                <FiX />
            </Button>
        </Container>
    ) : null;
};

const fadein = keyframes`
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 2rem;
      opacity: 1;
    }
`;

const fadeout = keyframes`
    from {
      bottom: 2rem;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
`;

const Container = styled.div`
    position: fixed;
    z-index: 1000;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    padding: 0.625rem 1rem;
    border-radius: 0.75rem;
    border: transparent;
    background-color: ${(props) =>
        props.variant === "success" ? "#4caf50" : props.variant === "error" ? "#f44336" : props.variant === "warning" ? "#ff9800" : "#7b31c6"};
    color: ${(props) =>
        props.variant === "success" ? "#ffffff" : props.variant === "error" ? "#ffffff" : props.variant === "warning" ? "#ffffff" : "#ffffff"};
    color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    display: flex;
    justify-content: center;
    align-items: center;

    animation: ${fadein} 0.5s, ${fadeout} 0.5s ${(props) => props.time};
`;

const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.875rem;
    padding: 0;
    margin-left: 1rem;
    height: 1.75rem;
    width: 1.75rem;
    text-align: center;

    border: none;
    border-radius: 50%;
    background-color: transparent;
    color: white;
    cursor: pointer;

    &:hover {
        background-color: #ecdbff;
    }
`;

const Message = styled.p`
    font-size: 1rem;
`;

export default Snackbar;
