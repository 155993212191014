import * as actionTypes from "./actionTypes";
import * as fileActionTypes from "../File/actionTypes";
import * as settingsService from "./service";

const log = require("loglevel").getLogger("SettingsActions");
log.setLevel("debug");

export const getSettings = (callback) => {
    return async (dispatch) => {
        log.debug("SettingsActions -> getSettings -> Enter");
        const settings = await settingsService.getSettings();
        if(!settings) return callback && callback();

        await dispatch({
            type: actionTypes.GET_SETTINGS,
            data: settings,
        });

        await dispatch({
            type: fileActionTypes.UPDATE_FILES,
            data: settings.marketing_materials,
        });
        callback && callback();
    };
};

export const updateSingleSetting = (updated_settings) => {
    return async (dispatch) => {
        log.debug("SettingsActions -> updateSetting -> Enter");
        await settingsService.editSingleSetting(updated_settings);
        await dispatch({
            type: actionTypes.UPDATE_SINGLE_SETTING,
            data: updated_settings,
        });
    };
};

export const updateArraySetting = (updated_settings) => {
    return async (dispatch) => {
        log.debug("SettingsActions -> updateArraySetting -> Enter");
        await settingsService.editArraySetting(updated_settings);
        await dispatch({
            type: actionTypes.UPDATE_ARRAY_SETTING,
            data: updated_settings,
        });
    };
};

export const updateMultipleSettings = (updated_settings) => {
    return async (dispatch) => {
        log.debug("SettingsActions -> updateMultipleSettings -> Enter");
        await settingsService.editMultipleSettings(updated_settings);
        await dispatch({
            type: actionTypes.UPDATE_MULTIPLE_SETTINGS,
            data: updated_settings,
        });
    };
};
