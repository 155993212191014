import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

import { PATH_NEW_CAMPAIGN, PATH_SEQUENCE_PAGE } from "utils/constants";
import Button, { BUTTON_VARIANT } from "components/Button/Button";

const DashboardHeader = ({ userName }) => {
    const navigate = useNavigate();

    const handleCampaignClick = () => {
        navigate(PATH_NEW_CAMPAIGN);
        ReactGA.event({
            category: "New Campaign",
            action: `user ${userName} created a new campaign`,
            label: "campaign",
        });
    };

    const handleSequenceClick = () => {
        navigate(PATH_SEQUENCE_PAGE);
        ReactGA.event({
            category: "New Sequence",
            action: `user ${userName} created a new sequence`,
            label: "sequence",
        });
    };

    return (
        <div className={"dashboard__header"}>
            <div className={"dashboard__header--text"}>
                <h1 className={"samplead-title-1"}>Hi {userName}!</h1>
                <p className={"samplead-body--large"}>Let's start tailoring messages to your target audience.</p>
            </div>
            <div className={"dashboard__header--buttons"}>
                <Button variant={BUTTON_VARIANT.SECONDARY} onClick={handleSequenceClick}>
                    Create a sequence
                </Button>
                <Button variant={BUTTON_VARIANT.PRIMARY} onClick={handleCampaignClick}>
                    Create new campaign
                </Button>
            </div>
        </div>
    );
};

export default DashboardHeader;
