import * as actionTypes from "./actionTypes";

const log = require("loglevel").getLogger("sequenceReducer");
log.setLevel("debug");

const AMOUNT_OF_STEPS = 2;

const initialState = JSON.stringify({
    empty: true,
    currentlyEditing: "1",
    unsavedChanges: false,
    cursorPosition: 0,
    emailOrLinkedIn: "linkedin",
    originalSteps: Object.fromEntries(
        [...Array(AMOUNT_OF_STEPS)].map((_, i) => {
            return [`${i + 1}`, { subject: "", message: "" }];
        })
    ),
    generatedSteps: Object.fromEntries(
        [...Array(AMOUNT_OF_STEPS)].map((_, i) => {
            return [`${i + 1}`, { subject: "", message: "" }];
        })
    ),
    stepsToUpdate: {
        All: true,
        ...Object.fromEntries(
            [...Array(AMOUNT_OF_STEPS)].map((_, i) => {
                return [`Step ${i + 1}`, false];
            })
        ),
    },
    subject: {
        Both: true,
        Subject: false,
        Body: false,
    },
    modifyTo: {
        "Fit to title": true,
        "Be casual": false,
        "Free text: Make it rhyme": false,
    },
    show: {
        originalSteps: Object.fromEntries(
            [...Array(AMOUNT_OF_STEPS)].map((_, i) => {
                return [`${i + 1}`, true];
            })
        ),
        generatedSteps: Object.fromEntries(
            [...Array(AMOUNT_OF_STEPS)].map((_, i) => {
                return [`${i + 1}`, true];
            })
        ),
    },
});

function sequenceReducer(state = JSON.parse(initialState), action) {
    switch (action.type) {
        case actionTypes.LOAD_SEQUENCE: {
            const { steps } = action.data;

            return {
                ...state,
                originalSteps: steps,
                generatedSteps: Object.fromEntries(
                    [...Array(Object.keys(steps).length)].map((_, i) => {
                        return [`${i + 1}`, { subject: "", message: "" }];
                    })
                ),
                empty: false,
            };
        }
        case actionTypes.RESET_SEQUENCE: {
            return JSON.parse(initialState);
        }
        case actionTypes.GENERATE_SEQUENCE: {
            const { generatedSteps } = action.data;
            if (Object.keys(generatedSteps).length) {
                return {
                    ...state,
                    generatedSteps,
                };
            }
            return state;
        }
        case actionTypes.ADD_STEP: {
            const { originalSteps, generatedSteps, stepsToUpdate, show } = state;
            const nextStep = Object.keys(generatedSteps).length + 1;

            let newOriginalSteps = { ...originalSteps };
            let newGeneratedSteps = { ...generatedSteps };
            let newStepsToUpdate = { ...stepsToUpdate };
            let newShow = { ...show, generatedSteps: { ...show.generatedSteps } }; // create a copy of the nested object as well

            newOriginalSteps[`${nextStep}`] = { id: nextStep, data: "new step data" };
            newGeneratedSteps[`${nextStep}`] = { id: nextStep, data: "new step data" };
            newStepsToUpdate[`Step ${nextStep}`] = { id: nextStep, data: "new step data" };
            newShow.originalSteps[`${nextStep}`] = { id: nextStep, data: "new step data" };
            newShow.generatedSteps[`${nextStep}`] = { id: nextStep, data: "new step data" };

            return {
                ...state,
                originalSteps: newOriginalSteps,
                generatedSteps: newGeneratedSteps,
                stepsToUpdate: newStepsToUpdate,
                show: newShow,
                unsavedChanges: true,
            };
        }

        case actionTypes.REMOVE_STEP: {
            const { originalSteps, generatedSteps, stepsToUpdate, show } = state;
            const lastStep = Object.keys(originalSteps).length;

            const newOriginalSteps = { ...originalSteps };
            const newGeneratedSteps = { ...generatedSteps };
            const newStepsToUpdate = { ...stepsToUpdate };
            const newShow = { ...show, generatedSteps: { ...show.generatedSteps } }; // create a copy of the nested object as well

            delete newOriginalSteps[lastStep];
            delete newGeneratedSteps[lastStep];
            delete newStepsToUpdate[`Step ${lastStep}`];
            delete newShow.originalSteps[lastStep];
            delete newShow.generatedSteps[lastStep];

            return {
                ...state,
                originalSteps: newOriginalSteps,
                generatedSteps: newGeneratedSteps,
                stepsToUpdate: newStepsToUpdate,
                show: newShow,
                unsavedChanges: true,
            };
        }

        case actionTypes.UPDATE_STEP: {
            const { stepId, subject, message, original, messageIndex } = action.data;
            const steps = original ? "originalSteps" : "generatedSteps";

            let updatedMessage;

            if (Array.isArray(state[steps][stepId]["message"]) && messageIndex !== undefined) {
                // If message is an array and messageIndex is defined, update the element at messageIndex
                updatedMessage = [...state[steps][stepId]["message"]];
                updatedMessage[messageIndex] = message;
            } else {
                // error
                updatedMessage = message;
            }

            return {
                ...state,
                [steps]: {
                    ...state[steps],
                    [stepId]: {
                        // retain previous values
                        ...state[steps][stepId],
                        subject: subject,
                        message: updatedMessage,
                    },
                },
            };
        }

        case actionTypes.CHANGE_STEPS_TO_UPDATE: {
            const { stepsToUpdate } = action.data;
            return { ...state, stepsToUpdate };
        }
        case actionTypes.CHANGE_SUBJECT: {
            const { subject } = action.data;
            return { ...state, subject };
        }
        case actionTypes.CHANGE_MODIFY_TO: {
            const { modifyTo } = action.data;
            return { ...state, modifyTo };
        }
        case actionTypes.CHANGE_FREE_TEXT: {
            const { freeText } = action.data;
            const { modifyTo } = state;
            const newModifyTo = Object.fromEntries(
                Object.entries(modifyTo).map(([key, value]) => {
                    if (key.includes("Free text: ")) {
                        return [`Free text: ${freeText}`, value];
                    }
                    return [key, value];
                })
            );
            return {
                ...state,
                modifyTo: {
                    ...newModifyTo,
                },
            };
        }
        case actionTypes.REPHRASE_STEP: {
            const { stepId, newStep } = action.data;
            return {
                ...state,
                generatedSteps: {
                    ...state.generatedSteps,
                    [stepId]: { ...state.generatedSteps[stepId], newStep },
                },
            };
        }
        case actionTypes.TOGGLE_SHOW_STEP: {
            const { stepId, original } = action.data;
            const steps = original ? "originalSteps" : "generatedSteps";
            return {
                ...state,
                show: {
                    ...state.show,
                    [steps]: {
                        ...state.show[steps],
                        [stepId]: !state.show[steps][stepId],
                    },
                },
            };
        }
        case actionTypes.TOGGLE_SHOW_ALL_STEPS: {
            const { original, toShow } = action.data;
            const steps = original ? "originalSteps" : "generatedSteps";
            const show = Object.fromEntries(
                Object.entries(state.show[steps]).map(([key, value]) => {
                    return [key, toShow];
                })
            );
            return {
                ...state,
                show: {
                    ...state.show,
                    [steps]: {
                        ...show,
                    },
                },
            };
        }
        case actionTypes.SET_CURSOR_POSITION: {
            return {
                ...state,
                cursorPosition: action.data,
            };
        }
        case actionTypes.SET_CURRENTLY_EDITING: {
            return {
                ...state,
                currentlyEditing: action.data.currentlyEditing,
            };
        }
        case actionTypes.SET_STEPS: {
            const { steps } = action.data;
            //update generated steps to be same as steps
            return {
                ...state,
                generatedSteps: steps,
            };
        }
        case actionTypes.LOAD_GENERATED_SEQUENCE:
            return {
                ...state,
                generatedSteps: action.data.steps,
            };
        case actionTypes.SET_UNSAVED_CHANGES:
            return {
                ...state,
                unsavedChanges: action.data.unsavedChanges,
            };
        case actionTypes.SET_STEP_TIME_DELAY:
            const { stepId, timeDelay } = action.data;
            const steps = "generatedSteps";
            return {
                ...state,
                [steps]: {
                    ...state[steps],
                    [stepId]: { ...state[steps][stepId], timeDelay },
                },
            };
        case actionTypes.GET_REPHRASED_STEP:
            return {
                ...state,
                generatedSteps: {
                    ...state.generatedSteps,
                    [action.data.stepId]: { message: action.data.newStep?.rephrasedStep },
                },
            };
        case actionTypes.CHANGE_MESSAGE_INDEX_FOR_STEP:
            const { step, index } = action.data;
            return {
                ...state,
                generatedSteps: {
                    ...state.generatedSteps,
                    [step]: {
                        ...state.generatedSteps[step],
                        messageIndex: index,
                    },
                },
            };
        case actionTypes.REPHRASE_WITH_CUSTOM_TEXT:
            const { id, campaignId, newStep } = action.data;

            // Avoiding direct state mutation by creating a new copy of state.generatedSteps
            let generatedStepsCopy = { ...state.generatedSteps };

            // Checking if message array exists and creating a copy of it
            let currentMessageArray = generatedStepsCopy[id]?.message ? [...generatedStepsCopy[id].message] : [];

            if (currentMessageArray.length >= 5) {
                currentMessageArray[4] = newStep;
            } else {
                while (currentMessageArray.length < 4) {
                    currentMessageArray.push(null); // Or your default message
                }
                currentMessageArray.push(newStep);
            }

            // Updating the message array for the specific id in the generatedStepsCopy
            generatedStepsCopy[id] = {
                ...generatedStepsCopy[id],
                message: currentMessageArray,
            };

            return {
                ...state,
                generatedSteps: generatedStepsCopy,
            };
        case actionTypes.SET_EMAIL_OR_LINKEDIN:
            return {
                ...state,
                emailOrLinkedIn: action.data.emailOrLinkedIn,
            };

        default:
            return state;
    }
}

export default sequenceReducer;
