import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import * as campaignActions from "../../redux/Campaign/reducer";
import Tabs from "../../components/Tabs/Tabs";
import { LeadStatus, PATH_DASHBOARD } from "../../utils/constants";
import CampaignHeader from "../Campaign/inner-components/CampaignHeader";
import { SuggestedLeadsMenu } from "../Campaign/inner-components/SuggestedLeadsMenu";
import { ActiveLeads } from "../Campaign/CampaignContent/tabs/ActiveLeads";
import { RemovedLeads } from "../Campaign/CampaignContent/tabs/RemovedLeads";
import Popup from "../../components/Popup";
import { BUTTON_VARIANT } from "../../components/Button/Button";
import SourceList from "../Campaign/CampaignContent/SourceList";
import Steps from "../Campaign/CampaignContent/tabs/Steps2";
import { BarLoader } from "react-spinners";
import { set } from "date-fns";

const MagicMoments = () => {
    const [isLoadingCampaign, setIsLoadingCampaign] = useState(false);
    const [isSuggestedLeadsMenuOpen, setIsSuggestionMenuOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch();
    const [checkTokens, setCheckTokens] = useState(0);
    const [showSourceListModal, setShowSourceListModal] = useState(false);

    const updated = useSelector((state) => state.campaign.lastUpdated);
    const id = "all";
    const campaign = useSelector((state) => state.campaign.campaigns.find((c) => c.id === id));
    const tabCounts = useSelector((state) => state.campaign.tabCounts);
    const campaignFilterId = useSelector((state) => state.campaign.campaigns.find((c) => c.id === "all").campaignFilterId);
    const [campaignFilterTitle, setCampaignFilterTitle] = useState("All Campaigns");
    const campaignPaginationAndSearch = useSelector((state) => state.campaign.campaignPaginationAndSearch["all"]);
    const page = campaignPaginationAndSearch?.pageNumber || 1;
    const pageSize = campaignPaginationAndSearch?.pageSize || 10;
    const searchText = campaignPaginationAndSearch?.searchValue || "";
    const searchedColumn = campaignPaginationAndSearch?.searchKey || "";
    const currentTab = campaignPaginationAndSearch?.currentTab || "active";
    const sortKey = campaignPaginationAndSearch?.sortKey || "";
    const sortOrder = campaignPaginationAndSearch?.sortOrder || "ASC";
    const searchResultsCount = campaign?.searchResultCount || 0;

    const filterByCampaignId = (campaignId) => {
        dispatch(campaignActions.setCampaignFilterId(campaignId));
        console.log("campaignFilterId changed: ", campaignFilterId);
        setIsLoadingCampaign(true);
        // reset pagination and search
        dispatch(
            campaignActions.updatePaginationAndSearch({
                campaignId: "all",
                pageNumber: 1,
                pageSize: 10,
                searchValue: "",
                searchKey: "",
                currentTab: currentTab || "active",
            })
        );
        if (campaignId === "all") {
            setCampaignFilterTitle("All Campaigns");
            dispatch(
                campaignActions.getAllMagicMoments({
                    campaignId: "all",
                    pageNumber: 1,
                    pageSize: 10,
                    searchValue: "",
                    searchKey: "",
                    currentTab: currentTab,
                    sortKey: sortKey,
                    sortOrder: sortOrder,
                    callback: () => setIsLoadingCampaign(false),
                })
            );
            return;
        }
        console.log("getting campaign by id: ", campaignId);
        dispatch(
            campaignActions.getAllMagicMoments({
                campaignId: "all",
                pageNumber: page,
                pageSize,
                searchValue: searchText,
                searchKey: searchedColumn,
                currentTab: currentTab,
                sortKey: sortKey,
                sortOrder: sortOrder,
                filterByCampaignId: campaignFilterId,
                callback: () => setIsLoadingCampaign(false),
            })
        );
    };

    useEffect(() => {
        let currentTab;
        switch (activeTab) {
            case 0:
                currentTab = "active";
                break;
            case 1:
                currentTab = "exported";
                break;
            case 2:
                currentTab = "removed";
                break;
            default:
                currentTab = "active";
        }

        dispatch(
            campaignActions.updatePaginationAndSearch({
                campaignId: id,
                currentTab: currentTab,
            })
        );
    }, [activeTab]);

    useEffect(() => {
        // console.log("checking campaign");
        // console.log(campaign);
        // if (!campaign) return;
        // const timeout = checkForCampaignUpdate();
        // return () => {
        //     timeout && clearTimeout(timeout);
        // };
    }, [campaign]);

    useEffect(() => {
        const load = async () => {
            if (id) {
                console.log("(REDUX DEBUG) LOAD CAMPAIGN");
                dispatch(
                    campaignActions.getAllMagicMoments({
                        campaignId: "all",
                        pageNumber: 1,
                        pageSize: 10,
                        searchValue: "",
                        searchKey: "",
                        currentTab: currentTab || "active",
                    })
                );
                dispatch(
                    campaignActions.getAllMagicMomentsUnseen({
                        id: id,
                    })
                );
                setIsLoadingCampaign(false);
            }
        };
        load();
    }, [id]);

    if (!campaign) {
        // navigate(`${PATH_DASHBOARD}`);

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100vh",
                    color: "#a53aff",
                    fontWeight: "bold",
                }}
            >
                <h3>Loading Magic Moments</h3>
                <div style={{ margin: "20px" }}>
                    <BarLoader color={"#a53aff"} loading={true} />
                </div>
            </div>
        );
    }

    const rephraseLead = async (lead) => {
        dispatch(campaignActions.rephraseLead(lead.id));
    };

    const editLeadMessage = (lead) => {
        dispatch(campaignActions.editLeadMessage(lead));
    };

    const exportLeads = (leadIds) => {
        dispatch(campaignActions.exportLeads({ campaignId: id, leadIds: leadIds }));
    };

    const removeLeads = (leadIds) => {
        dispatch(campaignActions.removeLeads({ campaignId: id, leadIds: leadIds }));
    };

    const restoreLeads = (leadIds) => {
        dispatch(campaignActions.restoreLeads({ campaignId: id, leadIds: leadIds }));
    };

    const changeLeadStatus = (lead, status) => {
        dispatch(campaignActions.changeLeadStatus({ leadId: lead.id, status: status }));
    };

    const loadCampaign = (id) => {
        console.log("(REDUX DEBUG)  checkForCampaignUpdate");

        dispatch(
            campaignActions.getAllMagicMoments({
                campaignId: "all",
                pageNumber: 1,
                pageSize: 10,
                searchValue: "",
                searchKey: "",
                currentTab: currentTab || "active",
            })
        );
        dispatch(
            campaignActions.getAllMagicMomentsUnseen({
                id: "all",
            })
        );
    };

    // if (isLoadingCampaign) {
    //     return "Loading campaign...";
    // }

    const approveLead = async (lead) => {
        dispatch(campaignActions.changeLeadStatus({ leadId: lead.id, status: LeadStatus.Active }));
    };

    const denyLead = async (lead, reasons) => {
        dispatch(campaignActions.denyLead({ leadId: lead.id, reasons: reasons.join(",") }));
        // console.log("DENY: ", lead);
    };

    const onTabChange = (index) => {
        setActiveTab(index);
        dispatch(campaignActions.clearExpandedLeadIds());
    };

    const onTitleUpdate = (name) => {
        dispatch(campaignActions.renameCampaign({ campaignId: id, name: name }));
    };

    const onToggleRows = (expandCollapseAction, leads) => {
        if (expandCollapseAction === "Expand All") {
            const unreadLeadIds = leads.filter((l) => l.unread === 1).map((l) => l.id);

            if (unreadLeadIds.length > 0) {
                dispatch(campaignActions.markLeadsAsRead({ campaignId: id, leadIds: unreadLeadIds }));
            }

            dispatch(campaignActions.updateExpandedLeadIds(leads.map((l) => l.id)));
        } else {
            dispatch(campaignActions.updateExpandedLeadIds([]));
        }
    };

    //
    function checkForCampaignUpdate() {
        console.log(campaign);
        if (campaign) {
            const amountOfLeads = campaign.leads.length;
            let doneGeneratingMessages = campaign.leads.filter((lead) => lead.message !== null || lead.messageStatus === 4);
            console.log(amountOfLeads);
            console.log(doneGeneratingMessages.length);

            if (doneGeneratingMessages.length !== amountOfLeads) {
                return setTimeout(async () => {
                    loadCampaign(campaign.id);
                    setCheckTokens(checkTokens + 1);
                    console.log(checkTokens);
                }, 30000);
            } else {
                console.log("done.");
            }
        }
    }
    //

    console.log("CAMPAIGN LEADS: ", campaign.leads);

    const suggestedLeads = campaign.leads ? campaign.leads.filter((c) => c.status === LeadStatus.Unseen) : [];
    const activeLeads = campaign.leads ? campaign.leads.filter((lead) => lead.status === LeadStatus.Active && !lead.exported) : [];
    const exportedLeads = campaign.leads ? campaign.leads.filter((lead) => lead.status === LeadStatus.Active && lead.exported) : [];
    const removedLeads = campaign.leads ? campaign.leads.filter((x) => x.status === LeadStatus.Removed) : [];

    const tabsCaptions = [
        `Active Leads (${tabCounts?.["active"] || 0})`,
        `Exported Leads (${tabCounts?.["exported"] || 0})`,
        `Removed Leads (${tabCounts?.["removed"] || 0})`,
    ];

    const reasons = [
        { type: "bad_territory", title: "Not in my defined territory" },
        { type: "bad_title", title: "Good company, but not a good title " },
        { type: "bad_company", title: "Good title, company not a fit" },
        { type: "bad_title_bad_company", title: "Not a good title and company not a fit" },
        { type: "competitor", title: "Competitor" },
        { type: "ecosystem", title: "Customer Vendor Ecosystem" },
    ];

    return (
        <div key={updated}>
            <Popup
                title="Deny Reason"
                onClose={() => setShowPopup(false)}
                visible={showPopup}
                buttons={[{ title: "Ok", type: BUTTON_VARIANT.PRIMARY, action: () => setShowPopup(false) }]}
            >
                <div>
                    {reasons.map((reason, i) => {
                        return (
                            <div key={i} style={{ paddingTop: "5px" }}>
                                <input type="radio" name="deny_reason" value={reason.type} />
                                <label htmlFor={reason.type}>&nbsp;&nbsp;&nbsp;{reason.title}.</label>
                            </div>
                        );
                    })}
                </div>
            </Popup>
            <CampaignHeader
                campaign={campaign}
                onTitleUpdate={onTitleUpdate}
                suggestedLeadsCount={suggestedLeads.length}
                setShowSourceListModal={setShowSourceListModal}
                onSuggestionsClick={() => setIsSuggestionMenuOpen(true)}
            />
            {campaign.type === "auto-discovery" && showSourceListModal && (
                <SourceList
                    setShowSourceListModal={setShowSourceListModal}
                    relevantLeads={campaign.leads.filter((lead) => lead.origin !== "suggested" && lead.status !== LeadStatus.Removed)}
                />
            )}
            <Tabs activeTabIndex={activeTab} captions={tabsCaptions} onActiveTabChange={onTabChange}>
                <ActiveLeads
                    campaign={campaign}
                    isActiveExported={false}
                    relevantLeads={activeLeads?.reverse()}
                    onUpdate={loadCampaign}
                    onRephrase={rephraseLead}
                    onToggleRows={onToggleRows}
                    onEditMessage={editLeadMessage}
                    onChangeStatus={changeLeadStatus}
                    onExport={exportLeads}
                    onRemove={removeLeads}
                    filterByCampaignId={filterByCampaignId}
                    isLoadingCampaign={isLoadingCampaign}
                />

                <ActiveLeads
                    campaign={campaign}
                    isActiveExported={true}
                    relevantLeads={exportedLeads?.reverse()}
                    onUpdate={loadCampaign}
                    onRephrase={rephraseLead}
                    onToggleRows={onToggleRows}
                    onEditMessage={editLeadMessage}
                    onChangeStatus={changeLeadStatus}
                    onExport={exportLeads}
                    onRemove={removeLeads}
                    filterByCampaignId={filterByCampaignId}
                    isLoadingCampaign={isLoadingCampaign}
                />

                <ActiveLeads //removed leads
                    campaign={campaign}
                    isActiveExported={true}
                    relevantLeads={removedLeads?.reverse()}
                    onUpdate={loadCampaign}
                    onRephrase={rephraseLead}
                    onToggleRows={onToggleRows}
                    onEditMessage={editLeadMessage}
                    onChangeStatus={changeLeadStatus}
                    onExport={exportLeads}
                    onRemove={restoreLeads}
                    filterByCampaignId={filterByCampaignId}
                    isLoadingCampaign={isLoadingCampaign}
                    removedLeads={true}
                />

                <Steps campaign={campaign} />
            </Tabs>

            <SuggestedLeadsMenu
                campaignId={"all"}
                isOpen={isSuggestedLeadsMenuOpen}
                suggestedLeads={suggestedLeads}
                onApproveLead={approveLead}
                onDenyLead={denyLead}
                onClose={() => setIsSuggestionMenuOpen(false)}
            />
        </div>
    );
};

export default MagicMoments;
