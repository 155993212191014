import React, { useState, useRef, useEffect } from "react";
import { getSuggestions } from "utils/services";

import "./Autocomplete.css";

const Autocomplete = ({
    inputIndex = 0,
    inputKey,
    inputValue,
    setInputValue,
    handleEnterKey,
    setCurrentFocus = () => {},
    isFocused,
    placeholder,
    className,
}) => {
    const debounce_timer = 1000; // time to wait after typing stops before sending request to server
    const [active, setActive] = useState(0);
    const [filtered, setFiltered] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const [input, setInput] = useState(inputValue || "");
    const [typingStarted, setTypingStarted] = useState(false);
    const [enterClicked, setEnterClicked] = useState(false);
    const [changedFocus, setChangedFocus] = useState(false);
    const isFocusedRef = useRef(isFocused);
    isFocusedRef.current = isFocused;

    useEffect(() => {
        setTypingStarted(true);

        if (input === undefined) return;
        setInputValue(input, inputIndex);
        if (!typingStarted) return; // avoid sending request on first render
        if (enterClicked || changedFocus) {
            // avoid sending request when enter is clicked or focus is changed) {
            setEnterClicked(false);
            setChangedFocus(false);
            return;
        }

        if (!isFocused) {
            // avoid sending request when input is not focused
            setIsShow(false);
            return;
        }
        setInput(input);
        const delayDebounceFn = setTimeout(async () => {
            let data = {
                key: inputKey,
                value: input.toLowerCase(),
            };
            if (!isFocusedRef.current) return;
            let suggestions = await getSuggestions("settings", data);

            const newFilteredSuggestions = suggestions.filter((suggestion) => suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1);

            setActive(0);
            setIsShow(true);
            setFiltered(newFilteredSuggestions);
        }, debounce_timer);

        return () => clearTimeout(delayDebounceFn);
    }, [input]);

    const handle_on_blur = () => {};

    useEffect(() => {
        if (isFocused) {
            setIsShow(true);
        } else {
            setIsShow(false);
        }
    }, [isFocused]);

    const handle_on_focus = () => {
        console.log("handle_on_focus");
        setCurrentFocus(inputIndex);
    };

    const onClick = (e) => {
        console.log("on click", e.target.innerText);
        setActive(0);
        setFiltered([]);
        setIsShow(false);
        setInput(e.currentTarget.innerText);
        setInputValue(e.currentTarget.innerText, inputIndex);
        setEnterClicked(true);
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && (!isShow || filtered.length === 0)) {
            setInput(filtered[active]);
            setInputValue(filtered[active], inputIndex);
            if (handleEnterKey !== null) {
                handleEnterKey("next");
            }
        } else if (e.keyCode === 13) {
            // enter key
            setActive(0);
            setIsShow(false);
            setInput(filtered[active]);
            setInputValue(filtered[active], inputIndex);
            setEnterClicked(true);
        } else if (e.keyCode === 38) {
            // up arrow
            return active === 0 ? null : setActive(active - 1);
        } else if (e.keyCode === 40) {
            // down arrow
            return active - 1 === filtered.length ? null : setActive(active + 1);
        } else if (e.keyCode === 9) {
            // tab key
            setActive(0);
            setIsShow(false);
            setChangedFocus(true);
        }
    };

    const renderAutocomplete = () => {
        if (isShow && input) {
            if (filtered.length) {
                return (
                    <ul className="autocomplete">
                        {filtered.map((suggestion, index) => {
                            let className;
                            if (index === active) {
                                className = "active";
                            }
                            return (
                                <li className={className} key={suggestion} onClick={onClick}>
                                    {suggestion}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                /*   return (
                      <div className="no-autocomplete">
                          <em>Not found</em>
                      </div>
                  ); */
            }
        }
        return <></>;
    };
    return (
        <>
            <input
                autoFocus={inputIndex === 0}
                autoComplete="off"
                type="text"
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={onKeyDown}
                onFocus={() => handle_on_focus(inputIndex)}
                onBlur={() => handle_on_blur()}
                value={input}
                className={`autocomplete_input ${className}`}
                placeholder={placeholder}
            />
            {renderAutocomplete()}
        </>
    );
};

export default Autocomplete;
