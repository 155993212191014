import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Icon from "../Icon/Icon";
import SuggestionCard from "../SuggestionCard";

import "./SuggestionsList.css";
import { useSelector } from "react-redux";

const EmptySuggestionsList = () => (
    <div className="no-suggestions">
        <Icon type="activity-group" />
        <p className="samplead-menu-header">All done!</p>
        <p className="samplead-body text-center">We will notify you as soon as we have new prospects to offer you.</p>
    </div>
);

const SuggestionsList = ({ suggestedLeads, onCloseClick, onApproveLead, onDenyLead, campaignId }) => {
    const [suggestionsfilter, setSuggestionsfilter] = useState("all");
    const [suggestionsCampaignfilter, setSuggestionsCampaignfilter] = useState("all");
    const [suggestedLeadsList, setSuggestedLeadsList] = useState(suggestedLeads);
    const campaignTitles = useSelector((state) =>
        state.campaign.campaigns
            .map((c) => {
                if (c && c.type === "auto-discovery" && c.id !== "all") {
                    return c.title;
                }
            })
            .filter((t) => t)
    );

    console.log("suggestions campaign id", campaignId, campaignTitles);

    useEffect(() => {
        setSuggestedLeadsList(suggestedLeads);
    }, []);

    // re render the list when list changes on approval or deny
    useEffect(() => {
        setSuggestedLeadsList(suggestedLeads);
    }, [suggestedLeads]);

    console.log("SuggestionCampaignFilter", suggestionsCampaignfilter);

    useEffect(() => {
        let filteredLeads = suggestedLeads;

        if (suggestionsfilter !== "all") {
            if (suggestionsfilter === "your list") {
                filteredLeads = filteredLeads.filter((lead) => lead.origin === "your list");
            } else if (suggestionsfilter === "suggested") {
                filteredLeads = filteredLeads.filter((lead) => lead.origin === "suggested");
            }
        }

        if (suggestionsCampaignfilter !== "all") {
            filteredLeads = filteredLeads.filter((lead) => lead.campaignTitle === suggestionsCampaignfilter);
        }

        setSuggestedLeadsList(filteredLeads);
    }, [suggestionsfilter, suggestionsCampaignfilter, suggestedLeads]);

    return (
        <div className="suggestions-list-wrapper">
            <div className="suggestions-list-header">
                <div className="suggestions-list-header-close">
                    <Icon type="close" onClick={onCloseClick} />
                </div>
                <div className="suggestions-list-header-title">
                    <span>New Magic moments</span>
                    <span>{suggestedLeads.length}</span>
                </div>
                <h6 className="samplead-body--large">Sales triggers to your prospects.</h6>
                {suggestedLeads.length > 0 && (
                    <div className="filter-suggestions">
                        <label>Filter by:</label>
                        {campaignId === "all" ? (
                            <select onChange={(e) => setSuggestionsCampaignfilter(e.target.value)}>
                                <option value="all">All</option>
                                {campaignTitles?.map((title) => (
                                    <option value={title}>{title}</option>
                                ))}
                            </select>
                        ) : (
                            <select onChange={(e) => setSuggestionsfilter(e.target.value)}>
                                <option value="all">All</option>
                                <option value="your list">Your List</option>
                                <option value="suggested">Suggested</option>
                            </select>
                        )}
                    </div>
                )}
            </div>
            <div className="suggestions-list__content">
                {!suggestedLeads.length ? (
                    <EmptySuggestionsList />
                ) : (
                    suggestedLeadsList &&
                    suggestedLeadsList
                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                        .map((lead) => (
                            <SuggestionCard
                                key={lead.id}
                                lead={lead}
                                onApproveClick={() => onApproveLead(lead)}
                                onDenyClick={(reasons) => onDenyLead(lead, reasons)}
                            />
                        ))
                )}
            </div>
        </div>
    );
};

export default SuggestionsList;
