import React, { useEffect, useState, lazy, Suspense } from "react";
import Button, { BUTTON_VARIANT } from "../Button/Button";
import Badge from "../Badge";
import Text from "../Text";
import { isNullOrEmptyArray } from "utils/general";

import "./LeadRow.css";
import HotnessIcon from "../HotnessIcon/index.jsx";
import { LeadStatus } from "utils/constants";
import { useSelector, useDispatch } from "react-redux";
import { toggleSnackbarOpen } from "redux/Snackbar/actions";
import OriginChip from "../OriginChip";
import * as campaignActions from "redux/Campaign/reducer";
import { Card, Segmented, Tabs, Tag, Tooltip } from "antd";
import BadgeLink from "components/BadgeLink";
import FriendlyIcon from "../../assets/svgs/friendly.svg";
import CasualIcon from "../../assets/svgs/casual.svg";
import FormalIcon from "../../assets/svgs/formal.svg";
import OriginalIcon from "../../assets/svgs/our_choice.svg";
import { fixUrl } from "utils/general";

const LazyLeadImageComponent = lazy(() => import("../LeadImageComponent"));

const doneMessageStatus = [3, 4];

const LeadMessage = ({ lead, onMessageEdit, setIndicateError, setLoadingMessages, loadingMessages, handleMessageEdit }) => {
    const [ready, setReady] = useState(false);
    const [messageIndex, setMessageIndex] = useState(0);
    const dispatch = useDispatch();

    const message = lead?.message
        ? lead.message
        : doneMessageStatus.includes(lead.messageStatus)
        ? lead.messagesList?.[messageIndex]
        : "Generating message...";

    const changeMessageToSelected = () => {
        if (lead && lead.messagesList && lead.messagesList.length > 0) {
            const localMessage = lead.messagesList[messageIndex];

            console.log("localMessage", localMessage);

            if (lead.message !== localMessage) {
                dispatch(campaignActions.editLeadMessage({ ...lead, message: localMessage }));
            }
        }
        // edit lead messageIndex
    };

    useEffect(() => {
        changeMessageToSelected();
    }, [messageIndex]);

    useEffect(() => {
        //covert summary
        // message
        if (message !== "Generating message...") {
            setReady(true);
        }
        if (
            lead.messageStatus === 4 ||
            lead.message === "" ||
            lead.summary.includes("Oops! 😲😲😲We couldn't find a message that is creative enough to send...")
        ) {
            setIndicateError(true);
        }
    }, [message]);

    // TODO: change to enum
    const handleChange = (e) => {
        // TODO ajax call after a setTimeOut
        // Update global
        let localMessage = e.currentTarget.innerText;

        if (message !== localMessage) onMessageEdit(lead, localMessage);
    };

    if (lead?.messagesList && lead?.messagesList?.length > 0) {
        return (
            <>
                <Segmented
                    onChange={(e) => {
                        setMessageIndex(e);
                    }}
                    block
                    options={[
                        {
                            value: 0,
                            icon: (
                                <Tooltip title="✨Our choice✨">
                                    <img className="rephrase-emoji" src={OriginalIcon} alt="Original" />
                                </Tooltip>
                            ),
                        },
                        {
                            value: 1,
                            icon: (
                                <Tooltip title="Formal">
                                    <img className="rephrase-emoji" src={FormalIcon} alt="Formal" />
                                </Tooltip>
                            ),
                        },
                        {
                            value: 2,
                            icon: (
                                <Tooltip title="Casual">
                                    <img className="rephrase-emoji" src={CasualIcon} alt="Casual" />
                                </Tooltip>
                            ),
                        },
                        {
                            value: 3,
                            icon: (
                                <Tooltip title="Friendly">
                                    <img className="rephrase-emoji" src={FriendlyIcon} alt="Friendly" />
                                </Tooltip>
                            ),
                        },
                    ]}
                />

                <p
                    style={{ marginTop: "10px", height: "auto" }}
                    contentEditable={lead.status !== LeadStatus.Removed}
                    suppressContentEditableWarning={true}
                    onBlur={handleChange}
                    className={`samplead-body lrbc--message ${ready !== false ? "ready--message" : ""}`}
                >
                    {messageIndex === "original" ? message : lead?.messagesList[messageIndex]}
                </p>
            </>
        );
    }

    return (
        <p
            contentEditable={lead.status !== LeadStatus.Removed}
            suppressContentEditableWarning={true}
            onBlur={handleChange}
            className={`samplead-body lrbc--message ${ready !== false ? "ready--message" : ""}`}
        >
            {message}
        </p>
    );
};
const LeadValuePropositionMessage = ({ lead, onMessageEdit, indicateError, setIndicateError, setLoadingMessages }) => {
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);

    const message = doneMessageStatus.includes(lead.messageStatus) ? lead.message_2 : "Generating message...";

    useEffect(() => {
        //covert summary
        // message
        if (message !== "Generating message...") {
            setReady(true);
        }
        if (
            lead.messageStatus === 4 ||
            lead.message_2 === "" ||
            lead.summary.includes("Oops! 😲😲😲We couldn't find a message that is creative enough to send...")
        ) {
            setIndicateError(true);
        }
    }, [message]);

    // TODO: change to enum
    const handleChange = async (e) => {
        // TODO ajax call after a setTimeOut
        // Update global
        let localMessage_2 = e.currentTarget.innerText;

        if (lead && message !== localMessage_2) {
            setLoadingMessages(true);
            await dispatch(campaignActions.editLeadValuePropositionMessage({ lead, message: localMessage_2 }));
            setLoadingMessages(false);
        }
    };

    return (
        <p
            contentEditable={lead.status !== LeadStatus.Removed}
            suppressContentEditableWarning={true}
            onBlur={handleChange}
            className={`samplead-body lrbc--message ${ready !== false ? "ready--message" : ""}`}
        >
            {message}
        </p>
    );
};

const IndicateErrorIcon = ({ lead }) => {
    const [show, setShow] = useState(false);
    const [errorIndicatorMessage, setErrorIndicatorMessage] = useState(
        `Sadly, we were unable to come up
		with a creative message to send`
    );

    const handleMouseOver = () => {
        setShow(true);
    };

    const handleMouseOut = () => {
        setShow(false);
        setErrorIndicatorMessage(`Sadly, we were unable to come up
		with a creative message to send`);
    };

    const handleClick = () => {
        setErrorIndicatorMessage(
            `Sadly, we were unable to come up with a creative message to send.. This is due to the limited information we could track for this prospect . Click on the Tailored message box to edit yourself ✌✌✌`
        );
    };

    const MessageErrorIcon = () => {
        return (
            <>
                <div className="message-on-hover">{errorIndicatorMessage}</div>
                <div className="chat-bubble"></div>
            </>
        );
    };

    return (
        <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut} onClick={handleClick} className="indicate-error-icon-container">
            <svg className="indicate-error-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
                <path
                    fill="#ffb522"
                    d="M16 32c8.836 0 16-7.164 16-16S24.836 0 16 0 0 7.164 0 16s7.164 16 16 16zm2-14a2 2 0 0 1-4 0V8a2 2 0 0 1 4 0v10zm-2 3.968a2 2 0 1 1-.001 4.001A2 2 0 0 1 16 21.968z"
                />
            </svg>
            {show && <MessageErrorIcon />}
            {/* <p>Something Went Wrong...</p> */}
        </div>
    );
};

const LeadRow = ({
    lead,
    campaign,
    isExpanded,
    handleExpandCollapse,
    onRestoreClick,
    onRemoveClick,
    onRephraseClick,
    onEditLeadMessageEvent,
    setSpecificExports,
    specificExports,
}) => {
    const dispatch = useDispatch();
    // Gets the image if it is already in the redux store
    const userTmpImage = useSelector((state) => state.campaign.userTmpImages[lead.id]);

    const [readMode, setReadMode] = useState(false);
    const [leadImage, setLeadImage] = useState(userTmpImage);
    const [visible, setVisible] = useState(true);
    const [hoverTitleXY, setHoverTitleXY] = useState([0, 0]);
    const [indicateError, setIndicateError] = useState(false);
    const [selected, setSelected] = useState(false);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [errText, setErrText] = useState("");

    useEffect(() => {
        console.log("loadingMessages", loadingMessages);
    }, [loadingMessages]);

    const displayConnections = lead.connections == null || lead.connections > 499 ? "500+ connections" : `${lead.connections} connections`;

    useEffect(() => {
        if (lead.messageStatus === 4) {
            setIndicateError(true);
        }
        const message = lead.messageStatus === 3 ? lead.message : "Generating message...";
    }, []);

    useEffect(() => {
        if (lead.summary.includes("Oops!") || lead.messageStatus === 4 || lead.message === "") {
            setIndicateError(true);
        }
    }, [lead.messageStatus, lead.message, lead.summary]);

    let email = lead.email || lead.mail;
    const handleExpandClick = () => {
        handleExpandCollapse(lead.id);
    };

    const handleMessageEdit = (lead, message) => {
        dispatch(campaignActions.setLead({ lead: { ...lead, message: message } }));
        onEditLeadMessageEvent({ ...lead, message: message });
    };

    const HoverText = ({ lead }) => {
        return (
            <div className="hover-title" style={{ left: hoverTitleXY[0], top: hoverTitleXY[1] }}>
                {lead.title}
            </div>
        );
    };

    const handleMouseMove = (e) => {
        let x = e.pageX + "px";
        let y = e.pageY + "px";
        if (hoverTitleXY[0] === 0) {
            setHoverTitleXY([x, y]);
        }
    };

    const handleMouseOver = () => {
        setVisible(true);
    };

    const handleMouseOut = () => {
        setVisible(false);
        setHoverTitleXY([0, 0]);
    };
    console.log(campaign?.type);

    const unreadClass = lead.unread ? "unread" : "";

    const handleSelect = () => {
        if (!specificExports?.includes(lead)) {
            setSpecificExports([...specificExports, lead]);
            setSelected(true);
        } else {
            const filtered = specificExports.filter((specific_lead) => specific_lead.id !== lead.id);
            setSpecificExports(filtered);
            setSelected(false);
        }
    };

    const getRephrase = (leadId) => {
        console.log("get rephrase");
        if (campaign?.type === "auto-discovery") {
            if (!lead?.message) {
                // return error message
                setErrText("No message provided");
                return;
            }
            if (lead?.messagesList) {
                // return error message
                setErrText("Message already rephrased for this lead");
                return;
            }
            try {
                dispatch(campaignActions.rephraseLeadLambda({ leadId, setLoadingMessages }));
            } catch (error) {
                console.log(error);
            }
        } else {
            onRephraseClick(leadId, lead.message, lead.messageStatus, lead.messageStatus === 3 ? lead.message : "Generating message...");
        }
    };

    const messagesTabs = [
        {
            key: "1",
            label: `1st Message`,
            children: (
                <Card loading={loadingMessages} title="Ice Breaker:">
                    <LeadMessage
                        lead={lead}
                        handleMessageEdit={handleMessageEdit}
                        indicateError={indicateError}
                        setIndicateError={setIndicateError}
                        onMessageEdit={handleMessageEdit}
                        setLoadingMessages={setLoadingMessages}
                    />
                </Card>
            ),
        },
        {
            key: "2",
            label: `2nd Message`,
            children: (
                <Card loading={loadingMessages} title="Value Proposition:">
                    <LeadValuePropositionMessage
                        lead={lead}
                        indicateError={indicateError}
                        setIndicateError={setIndicateError}
                        onMessageEdit={handleMessageEdit}
                        setLoadingMessages={setLoadingMessages}
                    />
                </Card>
            ),
        },
    ];

    return (
        <div className={`lead-row-container ${isExpanded && "show-message"} samplead-body`}>
            <div className={`lead-row-message--divider`} />
            <div className={`lead-row-bottom-wrapper`}>
                <div className={`lead-row-bottom`}>
                    <div className={`lead-row-bottom__left`}>
                        {lead?.dots_summary ? (
                            <ul
                                className={`samplead-body`}
                                style={{
                                    listStylePosition: "outside",
                                    padding: "0px 30px 30px 30px",
                                }}
                            >
                                {lead?.dots_summary?.split("\n")?.map((item, key) => {
                                    let style = { minHeight: "1em" };
                                    if (item?.includes("•")) {
                                        item = item?.replace("• ", "");
                                        style.listStyleType = "circle";
                                    } else {
                                        style.listStyleType = "none";
                                        style.marginLeft = "-1.5em";
                                    }
                                    if (!item) {
                                        item = " ";
                                    }
                                    return (
                                        <li key={key} style={style}>
                                            {item}
                                        </li>
                                    );
                                }) || lead.summary}
                            </ul>
                        ) : (
                            lead.summary && (
                                <div className={`lead-row-bottom--content`}>
                                    {lead.summary.length < 700 || readMode ? (
                                        <ul className={`samplead-body`} style={{ listStylePosition: "outside" }}>
                                            {lead?.summary?.split("\n")?.map((item, key) => {
                                                let style = { minHeight: "1em" };
                                                if (item?.includes("•")) {
                                                    item = item?.replace("• ", "");
                                                    style.listStyleType = "circle";
                                                } else {
                                                    style.listStyleType = "none";
                                                    style.marginLeft = "-1.5em";
                                                }
                                                if (!item) {
                                                    item = " ";
                                                }
                                                return (
                                                    <li key={key} style={style}>
                                                        {item}
                                                    </li>
                                                );
                                            }) || lead.summary}
                                            {lead?.summary?.length > 700 && (
                                                <Button variant={BUTTON_VARIANT.LINK} onClick={() => setReadMode(false)}>
                                                    Read less
                                                </Button>
                                            )}
                                        </ul>
                                    ) : (
                                        <ul className={`samplead-body`} style={{ listStylePosition: "outside" }}>
                                            {lead.summary?.slice(0, 700)}
                                            ...&nbsp;&nbsp;
                                            <Button variant={BUTTON_VARIANT.LINK} onClick={() => setReadMode(true)} inline>
                                                Read more
                                            </Button>
                                        </ul>
                                    )}
                                </div>
                            )
                        )}
                        <footer className={`lead-row-bottom__left--footer`}>
                            <p className={`samplead-body`}>{lead.country}</p>
                            <Button variant={BUTTON_VARIANT.LINK} onClick={() => window.open(fixUrl(lead.url), "_blank")}>
                                Go To Profile
                            </Button>
                        </footer>
                    </div>

                    <div className={`lead-row-bottom__right`}>
                        {lead.status !== LeadStatus.Monitoring && (
                            <div className={`lead-row-bottom--content`}>
                                {!lead.message_2 ? (
                                    <Card loading={loadingMessages} title="Tailored message:">
                                        <LeadMessage
                                            lead={lead}
                                            indicateError={indicateError}
                                            setIndicateError={setIndicateError}
                                            onMessageEdit={handleMessageEdit}
                                        />
                                    </Card>
                                ) : (
                                    <Tabs defaultActiveKey="1" items={messagesTabs} />
                                )}
                            </div>
                        )}
                        <p
                            style={{
                                color: "red",
                                fontSize: "12px",
                                margin: "0",
                            }}
                        >
                            {errText}
                        </p>
                        <div className={`lead-row-bottom__right--tags`}>
                            <h6 className="samplead-body">Data sources:</h6>
                            <div className={"lead-row-bottom__right--badges"}>
                                <OriginChip origin={lead.origin} badgesVersion={true} />
                                {!isNullOrEmptyArray(lead.badges) &&
                                    lead.badges.map((badge, i) => {
                                        return <BadgeLink badge={badge} key={i} />;
                                    })}
                            </div>
                        </div>
                        <footer className={`lead-row-bottom__right--footer`}>
                            {campaign && lead?.message && (
                                <>
                                    <Button
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        onClick={() => {
                                            dispatch(
                                                toggleSnackbarOpen({ message: "Message copied to clipboard", timeout: 2000, variant: "success" })
                                            );
                                            navigator.clipboard.writeText(lead.message);
                                        }}
                                    >
                                        Copy
                                    </Button>
                                    {onRephraseClick && campaign && lead?.message && (
                                        <Button variant={BUTTON_VARIANT.SECONDARY} onClick={() => getRephrase(lead.id)} borderless>
                                            Rephrase
                                        </Button>
                                    )}
                                </>
                            )}
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeadRow;
export { LeadMessage };
