import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import avatar from "assets/images/avatar.png";
import Modal from "components/Modal";
import { getAllActiveUsers, getAllUsers } from "redux/User/service";
import * as userActions from "redux/User/actions";
import Button from "components/Button/Button";
import Highlighter from "react-highlight-words";
import Select from "react-select";
import { SearchOutlined } from "@ant-design/icons";
import { Table, Pagination, Input, Space } from "antd";

const MirrorModeModal = (props) => {
    const adminUser = useSelector((state) => state.user.adminUser);
    const mirrorMode = useSelector((state) => state.user.mirrorMode);
    const users = useSelector((state) => state.user.users);

    const [accounts, setAccounts] = useState([]);
    const [accountManagers, setAccountManagers] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const dispatch = useDispatch();

    const changeUser = async (user) => {
        dispatch(userActions.setLoggedInUser(user, adminUser));
        window.location.reload();
    };

    useEffect(() => {
        if (!adminUser?.admin) return;
        if (users.length) return;
        getAllActiveUsers().then((users) => {
            dispatch(userActions.setUsers(users));
        });
    }, [adminUser]);

    useEffect(() => {
        if (!users.length) return;
        const accounts = users.reduce((acc, user) => {
            if (acc.has(user.account)) return acc;
            acc.add(user.account);
            return acc;
        }, new Set());
        const accountManagers = users.reduce((acc, user) => {
            if (acc.has(user.account_manager)) return acc;
            acc.add(user.account_manager);
            return acc;
        }, new Set());

        setAccounts([...accounts]);
        setAccountManagers([...accountManagers]);
    }, [users]);

    const getColumnSearchProps = (dataIndex, select = false) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
            return (
                <div
                    style={{
                        padding: 8,
                        position: "relative",
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                >
                    {!select ? (
                        <Input
                            ref={searchInput}
                            placeholder={`Search ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            onKeyDown={(e) => {
                                e.stopPropagation();

                                if (e.key === "Enter") {
                                    handleSearch(selectedKeys, confirm, dataIndex);
                                }

                                if (e.key === "Escape") {
                                    clearFilters && handleReset(clearFilters);
                                    setSearchText("");
                                    setSelectedKeys([""]);
                                    confirm({
                                        closeDropdown: false,
                                    });
                                }
                            }}
                            style={{
                                marginBottom: 8,
                                display: "block",
                            }}
                        />
                    ) : (
                        <Select
                            ref={searchInput}
                            onChange={(e) => {
                                setSelectedKeys(e.value ? [e.value] : []);
                            }}
                            onKeyDown={(e) => {
                                e.stopPropagation();

                                if (e.key === "Enter") {
                                    handleSearch(selectedKeys, confirm, dataIndex);
                                }

                                if (e.key === "Escape") {
                                    clearFilters && handleReset(clearFilters);
                                    setSearchText("");
                                    setSelectedKeys([""]);
                                    confirm({
                                        closeDropdown: false,
                                    });
                                }
                            }}
                            style={{
                                marginBottom: 8,
                            }}
                            defaultValue={{
                                label: "All",
                                value: "",
                            }}
                            options={
                                dataIndex === "account"
                                    ? [
                                          {
                                              label: "All",
                                              value: "",
                                          },
                                          ...accounts.map((account) => ({
                                              label: account,
                                              value: account,
                                          })),
                                      ]
                                    : [
                                          {
                                              label: "All",
                                              value: "",
                                          },
                                          ...accountManagers.map((accountManager) => ({
                                              label: accountManager,
                                              value: accountManager,
                                          })),
                                      ]
                            }
                        />
                    )}
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters && handleReset(clearFilters);
                                setSearchText("");
                                setSelectedKeys([""]);
                                confirm();
                                searchInput.current.setValue("");
                            }}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                confirm({
                                    closeDropdown: false,
                                });
                                setSearchText(selectedKeys[0]);
                                setSearchedColumn(dataIndex);
                            }}
                        >
                            Filter
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                close();
                            }}
                        >
                            close
                        </Button>
                    </Space>
                </div>
            );
        },
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                    fontSize: "15px",
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => !select && searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "Image",
            dataIndex: "image_url",
            key: "image_url",
            render: (image) => <img src={image ? image : avatar} alt="user" className="source-lead-row-image" />,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps("name"),
        },
        {
            title: "Account",
            dataIndex: "account",
            key: "account",
            sorter: (a, b) => a.account.localeCompare(b.account),
            ...getColumnSearchProps("account", true),
        },
        {
            title: "Account Manager",
            dataIndex: "account_manager",
            key: "account_manager",
            sorter: (a, b) => a.account_manager.localeCompare(b.account_manager),
            ...getColumnSearchProps("account_manager", true),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
            ...getColumnSearchProps("email"),
        },
        {
            title: "Change User",
            dataIndex: "token",
            key: "token",
            render: (token, user) => (
                <Button
                    variant="primary"
                    onClick={() => {
                        changeUser(user);
                        props.closeModal();
                    }}
                >
                    Change User
                </Button>
            ),
        },
    ];

    // create table with all users, and a button to change to that user
    // if the user is the admin, show the admin user as well on top
    // have a column for the user's image, account, account_managet, name, email, and a button to change to that user
    // also add input to search for a user by name, email, account or account manager
    return (
        <Modal {...props}>
            <div className="flex justify-center">
                <h1 className="samplead-title-2">User Mirroring</h1>
            </div>
            {mirrorMode && (
                <div className="flex justify-center items-center gap-4">
                    <h2 className="samplead-title-3">Currently viewing as {adminUser.name}</h2>
                    <Button variant="primary" onClick={() => changeUser(adminUser)}>
                        Change Back
                    </Button>
                </div>
            )}
            <Table
                dataSource={users}
                columns={columns}
                loading={!users?.length}
                style={{
                    background: "#fbfbff",
                    width: "100%",
                    height: "100%",
                    border: "px solid #e8e8e8",
                    borderRadius: "10px",
                    overflow: "auto",
                }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50", "100"],
                    position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
            />
        </Modal>
    );
};

export default MirrorModeModal;
