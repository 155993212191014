import React, { useState } from "react";
import { useSelector } from "react-redux";

import { FormInput } from "components/Form/Form";
import NavButtons from "components/NavButtons/NavButtons";
import { DISABLE_ERRORS } from "utils/constants";
import "../onboarding.css";

const PerfectCustomersPage = ({
    inputHeader,
    inputKey,
    handleNavClick,
    addText,
    placeholder,
    input_limit,
    subheaderText = null,
    autocomplete = false,
    min_blanks,
}) => {
    const settings = useSelector((state) => state.settings);
    const getInputValue = () => {
        let value =
            settings[inputKey].length < min_blanks
                ? [...settings[inputKey], ...Array(min_blanks - settings[inputKey].length).fill("")]
                : settings[inputKey];
        return value.map((item) => {
            try {
                return JSON.parse(item);
            } catch (e) {
                return [item || "", ""];
            }
        });
    };
    const [inputValue, setInputValue] = useState(() => getInputValue());
    const [errorMsg, setErrorMsg] = useState("");

    const update_input_value = (value, index, arrayIndex) => {
        setInputValue((prev) => {
            prev[index][arrayIndex] = value;
            return [...prev];
        });
    };

    const next_clicked = (action) => {
        setErrorMsg(false);
        let hasError = false;
        // validate input
        if (action === "next") {
            if (inputValue.length === 0 || inputValue.every((val) => val === "")) {
                setErrorMsg("Please enter at least 1 value");
                hasError = true;
            }
        }

        if (hasError && !DISABLE_ERRORS) return;
        let response_obj = [
            {
                key: inputKey,
                value: inputValue.map((item) => JSON.stringify(item)).filter((item) => item !== '["",""]'),
            },
        ];
        console.log(response_obj);
        handleNavClick(action, response_obj, "array");
    };

    const addExtraField = () => {
        setErrorMsg(false);
        if (inputValue.length < input_limit) {
            setInputValue([...inputValue, ["", ""]]);
        }
    };

    return (
        <>
            <div className="form-container">
                <div className="input-header">
                    <div className="input-header-text">
                        <p>{inputHeader}</p>
                    </div>
                </div>
                {subheaderText && (
                    <div className="subheader margin-y-low centered fit-to-width">
                        <div className="subheader-text">
                            <p>{subheaderText}</p>
                        </div>
                    </div>
                )}
                <div style={{ gap: 30, justifyContent: "space-evenly", alignItems: "center", display: "flex" }}>
                    <div className={`input-bar centered ${autocomplete ? "autocomplete_wrapper" : ""}`} style={{ flex: "1 1 0", width: 0 }}>
                        Company Name
                    </div>
                    <div className={`input-bar centered ${autocomplete ? "autocomplete_wrapper" : ""}`} style={{ flex: "1 1 0", width: 0 }}>
                        Why are they perfect?
                    </div>
                </div>
                {inputValue.map(([customer, explanation], index) => {
                    return (
                        <div className="centered" key={index} style={{ gap: 30 }}>
                            <div className={`input-bar centered ${autocomplete ? "autocomplete_wrapper" : ""}`}>
                                <FormInput
                                    type="text"
                                    inputValue={customer}
                                    inputName={inputKey}
                                    placeholder={placeholder}
                                    onChange={(value) => update_input_value(value, index, 0)}
                                />
                            </div>
                            <div className={`input-bar centered ${autocomplete ? "autocomplete_wrapper" : ""}`}>
                                <FormInput
                                    type="text"
                                    inputValue={explanation}
                                    inputName={inputKey}
                                    placeholder={placeholder}
                                    onChange={(value) => update_input_value(value, index, 1)}
                                />
                            </div>
                        </div>
                    );
                })}

                {inputValue.length < input_limit && (
                    <div className="add-custom-option">
                        <p className="add-custom-option-text link" onClick={() => addExtraField()}>
                            {addText}
                        </p>
                    </div>
                )}

                <div className="input-error-msg">{errorMsg && <div className="error-msg">{errorMsg}</div>}</div>
            </div>
            <NavButtons handleNavClick={next_clicked} />
        </>
    );
};

export default PerfectCustomersPage;
