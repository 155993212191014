import axios from "axios";
import env from "config/site.config";

export const LINKEDIN_STATE = "samplead_req_gen";
export const LINKEDIN_CALLBACK = env.REACT_APP_LINKED_IN_CALLBACK_URL; //https://dev-4ued6kzz.us.auth0.com/login/callback';
const LINKEDIN_SCOPE = "r_liteprofile r_emailaddress";
const LINKEDIN_CLIENT_ID = "776949dxw9amgf";

const getURLWithQueryParams = (base, params) => {
    const query = Object.entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

    return `${base}?${query}`;
};

export const LINKEDIN_URL = getURLWithQueryParams("https://www.linkedin.com/oauth/v2/authorization", {
    response_type: "code",
    client_id: LINKEDIN_CLIENT_ID,
    state: LINKEDIN_STATE,
    scope: LINKEDIN_SCOPE,
    redirect_uri: LINKEDIN_CALLBACK,
});

export const queryToObject = (queryString) => {
    const pairsString = queryString[0] === "?" ? queryString.slice(1) : queryString;
    const pairs = pairsString.split("&").map((str) => str.split("=").map(decodeURIComponent));
    return pairs.reduce((acc, [key, value]) => (key ? { ...acc, [key]: value } : acc), {});
};

export const setAccessToken = (token, adminToken = "") => {
    axios.defaults.headers.common["Samplead-Access"] = token;
    if (adminToken) {
        axios.defaults.headers.common["Samplead-Admin-Access"] = adminToken;
    }
};
