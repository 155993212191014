import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button, { BUTTON_VARIANT } from "components/Button/Button";
import { PATH_CLUSTERING_TITLES, PATH_NEW_CAMPAIGN } from "utils/constants";
import CampaignCard from "components/CampaignCard";
import Icon from "components/Icon/Icon";
import * as campaignActions from "redux/Campaign/reducer";
import * as campaignApi from "redux/Campaign/api";
import { isNullOrEmptyArray } from "utils/general";
import Spinner from "components/Spinner";
import "./EmbeddedStatistics.css";
import { Card, Tabs } from "antd";
import ClusterTitles from "routes/ClusterTitles";
import logger from "redux-logger";

const NoCampaigns = () => {
    return (
        <main className="all-campaigns-main no-campaigns">
            <Icon type="activity-event" />
            <p className="samplead-body text-center">
                You don't have any Campaigns yet. To create a Campaign - click on the “Create new campaign” button above.
            </p>
        </main>
    );
};

const CampaignList = ({ campaigns, onDeleteCampaign }) => {
    const [currentTab, setCurrentTab] = useState(false);
    const [salesforceTab, setSalesforceTab] = useState(false);

    const dispatch = useDispatch();
    //re render the componenet when the campaigns are loaded
    useEffect(() => {
        if (!currentTab && campaigns?.length > 0) {
            setCurrentTab(campaigns[0].id);
        }
    }, [campaigns]);

    const settings = useSelector((state) => state.settings);
    console.log("settings", settings);

    useEffect(() => {
        // Check settings for salesforce integration
        console.log(
            "get sf",
            settings?.salesforce_integration &&
                settings?.salesforce_instance_url &&
                settings?.salesforce_refresh_token &&
                settings?.salesforce_user_id
        );
        if (
            settings &&
            settings?.salesforce_integration &&
            settings?.salesforce_instance_url &&
            settings?.salesforce_refresh_token &&
            settings?.salesforce_user_id
        ) {
            setSalesforceTab(true);
        }
    }, [settings]);

    useEffect(() => {
        // reset redux for page pageSize and page number
        console.log("watching current tab", currentTab);
    }, [currentTab]);

    if (isNullOrEmptyArray(campaigns)) {
        return <NoCampaigns />;
    }

    let tabItems = [...campaigns]
        .filter((c) => c.type === "auto-discovery")
        .map((campaign, i) => {
            return {
                // also remove the "’s" from the end of the title
                label: `${campaign?.title.split(" ")[0].replace("’s", "").replace("'s", "")}`,
                key: campaign.id,
                weaviate_class: "Campaigns",
            };
        });

    // If Salesforce attributes are truthy, add Salesforce tab to the list
    if (salesforceTab) {
        tabItems.push({
            label: "Salesforce",
            key: settings.salesforce_instance_url,
            weaviate_class: "SalesForceCampaigns",
        });
    }

    return (
        <main className="all-campaigns-main">
            <Tabs
                defaultActiveKey="1"
                centered
                onChange={(key) => {
                    console.log(key);
                    setCurrentTab(key);
                }}
                items={tabItems}
            />
            {currentTab && (
                <CampaignEmbeddingCard weaviateClass={tabItems.find((item) => item.key === currentTab).weaviate_class} campaignId={currentTab} />
            )}
        </main>
    );
};

const CampaignEmbeddingCard = ({ campaignId, weaviateClass }) => {
    const navigate = useNavigate();
    return (
        <div>
            <ClusterTitles key={campaignId} campaignId={campaignId} weaviateClass={weaviateClass} />
        </div>
    );
};

const EmbeddedStatistics = () => {
    const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(true);
    const navigate = useNavigate();
    const campaigns = useSelector((state) => state.campaign.campaigns.filter((campaign) => campaign.type === "auto-discovery"));
    const updated = useSelector((state) => state.campaign.lastUpdated);
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(campaignActions.getCampaigns()).then(() => {
            console.log("campaigns loaded");
            setIsLoadingCampaigns(false);
        });
    }, [user]);

    const deleteCampaign = (campaignId) => {
        campaignApi.deleteCampaign(campaignId).then((res) => {
            dispatch(campaignActions.getCampaigns());
        });
    };

    if (isLoadingCampaigns) {
        return <Spinner />;
    }

    return <div>{<CampaignList campaigns={campaigns} onDeleteCampaign={deleteCampaign} />}</div>;
};

export default EmbeddedStatistics;
