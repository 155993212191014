import "./Text.css";
import { createRef, useLayoutEffect, useState } from "react";
import Tooltip, { TOOLTIP_POSITION } from "../Tooltip/Tooltip";

const Text = ({ className, children }) => {
    const textRef = createRef();
    const [tooltipEnabled, setTooltipEnabled] = useState(false);

    useLayoutEffect(() => {
        if (textRef.current) {
            setTooltipEnabled(textRef.current.offsetWidth < textRef.current.scrollWidth);
        }
    }, [textRef]);

    return tooltipEnabled ? (
        <Tooltip message={children} position={TOOLTIP_POSITION.TOP}>
            <p ref={textRef} className={`${className} overflow-ellipsis`}>
                {children}
            </p>
        </Tooltip>
    ) : (
        <p ref={textRef} className={`${className} overflow-ellipsis`}>
            {children}
        </p>
    );
};

export default Text;
