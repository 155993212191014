import * as actionTypes from "./actionTypes";

const log = require("loglevel").getLogger("settingsReducer");
log.setLevel("debug");

const initialState = JSON.stringify({
    full_name: null,
    company_name: null,
    work_email: null,
    company_website: null,
    tracking_targets: [], // checkboxes
    geographical_targets: [],
    specific_territories: [],
    excluded_cities: [],
    target_company_headcount: [],
    target_industries: [],
    target_titles: ["", "", ""],
    excluded_titles: [""],
    seniority_level: [],
    target_role_function: [],
    target_persona_description: null,
    competitors: ["", "", ""],
    linkedin_profiles: ["", "", ""],
    linkedin_groups: ["", "", ""],
    perfect_customers: ["", "", ""],
    influencers: ["", "", ""],
    keywords: ["", "", "", "", ""],
    hashtags: ["", "", "", "", ""],
    marketing_materials: [],
});

function settingsReducer(state = JSON.parse(initialState), action) {
    switch (action.type) {
        case actionTypes.GET_SETTINGS: {
            return {
                ...state,
                ...action.data,
            };
        }
        case actionTypes.UPDATE_SINGLE_SETTING: {
            return {
                ...state,
                [action.data[0].key]: action.data[0].value,
            };
        }
        case actionTypes.UPDATE_ARRAY_SETTING: {
            let updated_settings = { ...state };
            action.data.forEach((setting) => {
                updated_settings[setting.key] = setting.value;
            });

            return updated_settings;
        }
        case actionTypes.UPDATE_MULTIPLE_SETTINGS: {
            let updated_settings = { ...state };
            action.data.forEach((setting) => {
                updated_settings[setting.key] = setting.value;
            });

            return updated_settings;
        }
        default:
            return state;
    }
}

export default settingsReducer;
