import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import * as userActions from "redux/User/actions";
import { LINKEDIN_STATE, LINKEDIN_URL } from "utils/auth";
import SignUp from "components/SignUp";
import SignIn from "components/SignIn";
import "./Login.css";
import { useNavigate, useParams } from "react-router-dom";
import { getStorageItem } from "utils/storageUtils";
import { PATH_ONBOARDING_PAGE } from "utils/constants";
import env from "config/site.config";

let loginPopup;

const Login = () => {
    const [signUp, setSignUp] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const { checkoutId } = useParams();

    useEffect(() => {
        if (checkoutId) {
            console.log("checkoutId", checkoutId);
            localStorage.setItem("checkoutId", checkoutId);
        }
    }, [checkoutId]);

    useEffect(() => {
        const receiveLinkedInMessage = ({ origin, data: { state, code, error, ...rest } }) => {
            if (origin !== window.location.origin || state !== LINKEDIN_STATE) {
                return;
            }
            dispatch(userActions.signIn(code));
            loginPopup && loginPopup.close();
        };
        window.addEventListener("message", receiveLinkedInMessage);
        return () => {
            window.removeEventListener("message", receiveLinkedInMessage);
            loginPopup && loginPopup.close();
        };
    }, []);

    const signInWithLinkedin = () => {
        loginPopup = window.open(LINKEDIN_URL, "_blank", `width=600,height=600,top=150,left=${(window.innerWidth - 300) / 2}`);
    };

    const handleChangeToCredentials = (credential, value) => {
        if (credential === "email") {
            setEmail(value);
        } else {
            setPassword(value);
        }
    };

    const handleCreateNewAccount = async () => {
        console.log(email, password);
        const { data } = await axios.post(env.REACT_APP_BACKEND_URL + "/user/signup", {
            email,
            password,
        });
        if (data.status === "ok") {
            dispatch(userActions.signIn({ email, password, isSignUp: true }));
        }
    };

    const handleSignIn = async () => {
        dispatch(userActions.signIn({ email, password }));
    };

    return (
        <>
            {signUp ? (
                <SignUp
                    handleCreateNewAccount={handleCreateNewAccount}
                    handleChangeToCredentials={handleChangeToCredentials}
                    signInWithLinkedin={signInWithLinkedin}
                    setSignUp={setSignUp}
                />
            ) : (
                <SignIn
                    handleChangeToCredentials={handleChangeToCredentials}
                    signInWithLinkedin={signInWithLinkedin}
                    setSignUp={setSignUp}
                    handleSignIn={handleSignIn}
                />
            )}
        </>
    );
};

export default Login;
