import Button, { BUTTON_VARIANT } from "components/Button/Button";
import Tooltip, { TOOLTIP_POSITION } from "components/Tooltip/Tooltip";

export const NewSuggestionsButton = ({ suggestedLeadsCount, onClick }) => {
    const tooltipMessage = !suggestedLeadsCount ? "You dont have new suggestions yet" : `You have ${suggestedLeadsCount} new suggestions`;

    return (
        <Tooltip message={tooltipMessage} position={TOOLTIP_POSITION.TOP}>
            <Button
                variant={BUTTON_VARIANT.PRIMARY}
                isDisabled={suggestedLeadsCount === 0}
                notification={suggestedLeadsCount || undefined}
                onClick={onClick}
            >
                New suggestions
            </Button>
        </Tooltip>
    );
};
