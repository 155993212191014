import axios from "axios";
import env from "config/site.config";

export const getAccounts = async (accessToken) => {
    try {
        const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/outreach/get_accounts`, { accessToken });
        console.log(data);
        return data;
    } catch (error) {
        return error;
    }
};

export const getAccountsByName = async (accessToken, leads) => {
    const results = [];

    const promises = leads.map(async (lead) => {
        const account_name = lead.user_data.company_name;
        return axios.post(`${env.REACT_APP_BACKEND_URL}/outreach/get_account_by_name`, {
            accessToken,
            account_name,
        });
    });

    await Promise.all(promises)
        .then((values) => {
            values.forEach((value) => {
                results.push(value.data);
            });
            console.log(values);
        })
        .catch((error) => {
            return error;
        });

    return results;
};
export const createAccounts = async (accessToken, leads) => {
    const results = [];

    const promises = leads.map(async (lead) => {
        const account_name = lead.user_data.company_name;
        return axios.post(`${env.REACT_APP_BACKEND_URL}/outreach/create_account`, {
            accessToken,
            account_name,
        });
    });

    await Promise.all(promises)
        .then((values) => {
            values.forEach((value) => {
                results.push(value.data);
            });
            console.log(values);
        })
        .catch((error) => {
            return error;
        });

    return results;
};

export const createProspects = async (accessToken, leads) => {
    const results = [];

    const promises = leads.map(async (lead) => {
        console.log(lead);
        if (lead) {
            return axios.post(`${env.REACT_APP_BACKEND_URL}/outreach/create_prospects`, {
                accessToken,
                accountID: lead.accountID,
                ...lead,
            });
        }
    });

    await Promise.all(promises)
        .then((values) => {
            // console.log(values);
            // const filteredValues = values.filter((value) => value !== undefined || !value.message);
            values.forEach((value) => {
                results.push(value.data);
            });
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
    console.log(results);
    return results;
};

export const getProspectsByEmail = async (accessToken, leads) => {
    const results = [];

    const promises = leads.map(async (lead) => {
        const email = lead.lead.email;
        console.log(email);
        return axios.post(`${env.REACT_APP_BACKEND_URL}/outreach/get_prospects_by_email`, {
            accessToken,
            email,
        });
    });

    await Promise.all(promises)
        .then((values) => {
            values.forEach((value) => {
                results.push(value.data);
            });
            console.log(values);
        })
        .catch((error) => {
            return error;
        });

    return results;
};

export const updateProspectsMessage = async (accessToken, prospects) => {
    const results = [];

    const promises = prospects.map(async (prospect) => {
        console.log(prospect);
        return axios.post(`${env.REACT_APP_BACKEND_URL}/outreach/patch_prospect`, {
            accessToken,
            prospectID: prospect.prospectID,
            message: prospect.message,
        });
    });

    await Promise.all(promises)
        .then((values) => {
            values.forEach((value) => {
                results.push(value.data);
            });
            console.log(values);
        })
        .catch((error) => {
            return error;
        });

    return results;
};

export const pushProspects = async (accessToken, sequenceID, prospects, mailboxID) => {
    const results = [];

    const filteredProspects = prospects.filter((prospect) => prospect && !prospect.message);

    const failedProspects = prospects.filter((prospect) => prospect.message);

    const promises = filteredProspects.map(async (prospect) => {
        console.log(prospect, sequenceID);
        return axios.post(`${env.REACT_APP_BACKEND_URL}/outreach/push_to_sequence`, {
            accessToken,
            sequenceID,
            prospectID: prospect.data.id,
            mailboxID,
        });
    });

    await Promise.all(promises)
        .then((values) => {
            values.forEach((value) => {
                results.push(value.data);
            });
        })
        .catch((error) => {
            return error;
        });

    return results;
};

export const getSequences = async (accessToken, accountID, leads) => {
    try {
        const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/outreach/get_sequences`, {
            accessToken,
        });
        console.log(data);
        return data;
    } catch (error) {
        return error;
    }
};

export const getSequenceByName = async (accessToken, sequenceName) => {
    try {
        const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/outreach/get_sequence_by_name`, {
            accessToken,
            sequenceName,
        });
        console.log(data);
        return data;
    } catch (error) {
        return error;
    }
};

export const handleOutreachRefreshToken = async (refreshToken) => {
    if (!refreshToken) return;
    try {
        const now = Math.floor(Date.now() / 1000);
        const refresh_token = localStorage.getItem("outreach_refresh_token");
        const token_expire_timestamp = localStorage.getItem("outreach_access_token_expire");
        if (refresh_token !== undefined && token_expire_timestamp !== undefined && now > token_expire_timestamp) {
            console.log("refreshing token");
            const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/outreach/refresh_token`, { refresh_token: refresh_token });
            console.log(data);
            if (!data.message) {
                localStorage.setItem("outreach_access_token", data.access_token);
                localStorage.setItem("outreach_refresh_token", data.refresh_token);
                localStorage.setItem("outreach_access_token_expire", data.created_at + data.expires_in);
            } else {
                throw new Error(data.message);
            }
        } else {
            console.log("token is still valid");
        }
    } catch (error) {
        console.log(error);
        // return error
    }
};

export const handleSalesforceRefreshToken = async (refreshToken) => {
    if (!refreshToken) return;
    try {
        const now = Math.floor(Date.now() / 1000);
        const refresh_token = localStorage.getItem("salesforce_refresh_token");

        const token_expire_timestamp = localStorage.getItem("salesforce_access_token_expire");
        if (refresh_token !== undefined && token_expire_timestamp !== undefined && now > token_expire_timestamp) {
            console.log("refreshing token");
            const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/salesforce/refresh_token`, { refresh_token: refresh_token });
            console.log(data);
            if (!data.message) {
                localStorage.setItem("salesforce_access_token", data.access_token);
                const expireTime = +data.issued_at / 1000 + 7200;
                localStorage.setItem("salesforce_access_token_expire", expireTime);
            } else {
                throw new Error(data.message);
            }
        } else {
            console.log("token is still valid");
        }
    } catch (error) {
        console.log(error);
        throw new Error(error);
    }
};

export const getIntegrationSettings = async (user) => {
    try {
        const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/settings/get_integrations`, { user });
        console.log(data);
        return data;
    } catch (error) {
        return error;
    }
};

export const exportToZapier = async (leads) => {
    try {
        const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/zapier/prospect_pushed/trigger`, { leads });
        console.log(data);
        return data;
    } catch (error) {
        return error;
    }
};
