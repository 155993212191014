import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "redux/store";
import "./index.css";

import "styles/index.scss";
import "react-dropdown/style.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import "react-web-vector-icons/fonts";
import "react-tooltip/dist/react-tooltip.css";

import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
