const SignIn = ({ signInWithLinkedin, setSignUp, handleChangeToCredentials, handleSignIn }) => {
    const handleChangeToLogin = () => {
        setSignUp(true);
    };

    return (
        <>
            <div className="signup-cont">
                <div>Log in to</div>
                <div className="nav-logo"></div>
                <button className="linkedin_btn" onClick={signInWithLinkedin}>
                    <div>
                        <img src="https://img.icons8.com/color/48/000000/linkedin.png" width="30px" alt="linkedin" />
                        <h4>Log in with Linkedin</h4>
                    </div>
                </button>
                <div style={{ marginTop: "10px" }}>or use your email</div>
                <label>Email</label>
                <input onChange={(e) => handleChangeToCredentials("email", e.target.value)} className="signup_input" placeholder="Email" />
                <label>Password</label>
                <input onChange={(e) => handleChangeToCredentials("password", e.target.value)} className="signup_input" placeholder="Password" />
                <div className="forgot-pass">Forgot password?</div>
                <button className="create_btn" onClick={handleSignIn}>
                    Log in
                </button>
                <div className="is-member-box">
                    <p className="login-text">Not a member? </p>
                    <p className="login-text" style={{ color: "#A54CFF" }} onClick={handleChangeToLogin}>
                        Sign Up
                    </p>
                </div>
            </div>
        </>
    );
};

export default SignIn;
