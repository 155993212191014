import axios from "axios";
import env from "config/site.config";

export const getExpandiCampaignsV1 = async (whitelabel) => {
    return await axios
        .get(`${env.REACT_APP_BACKEND_URL}/expandi/list/${whitelabel ? "true" : "false"}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const createExpandiCampaign = async ({ campaign_name, whitelabel_flag, campaign_url }) => {
    return await axios
        .post(`${env.REACT_APP_BACKEND_URL}/expandi/create`, {
            campaign_name,
            whitelabel_flag,
            campaign_url,
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const updateExpandiCampaign = async ({ campaign_name, whitelabel_flag, campaign_url, id }) => {
    return await axios
        .post(`${env.REACT_APP_BACKEND_URL}/expandi/update`, {
            campaign_name,
            whitelabel_flag,
            campaign_url,
            id,
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const deleteExpandiCampaign = async (id) => {
    return await axios
        .post(`${env.REACT_APP_BACKEND_URL}/expandi/delete`, {
            id,
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const exportLeadsToExpandiV1 = async ({ expandi_campaign_id, leads, leadIds }) => {
    return await axios
        .post(`${env.REACT_APP_BACKEND_URL}/expandi/leads`, {
            expandi_campaign_id,
            leads,
            leadIds,
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const setExpandiUserKeySecret = async ({ id, username, url, whitelabel_flag }) => {
    return await axios
        .post(`${env.REACT_APP_BACKEND_URL}/expandi/user/key_secret`, {
            id,
            username,
            url,
            whitelabel_flag,
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const removeExpandiUserKeySecret = async ({ id, whitelabel_flag }) => {
    return await axios
        .delete(`${env.REACT_APP_BACKEND_URL}/expandi/user/key_secret`, {
            data: {
                id,
                whitelabel_flag,
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getExpandiCampaigns = async ({ whitelabel, expandiUserId }) => {
    return await axios
        .get(`${env.REACT_APP_BACKEND_URL}/expandi/campaigns/${whitelabel ? "true" : "false"}/${expandiUserId}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const exportLeadsToExpandi = async ({ expandi_campaign_id, leads, leadIds, whitelabel_flag, expandi_user_id }) => {
    return await axios
        .post(`${env.REACT_APP_BACKEND_URL}/expandi/push_leads`, {
            expandi_campaign_id,
            leads,
            leadIds,
            whitelabel_flag,
            expandi_user_id,
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getExpandiUsers = async (whitelabel) => {
    return await axios
        .get(`${env.REACT_APP_BACKEND_URL}/expandi/users/${whitelabel ? "true" : "false"}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        });
};
