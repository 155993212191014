import { useEffect } from "react";
import { useState } from "react";
import Button, { BUTTON_VARIANT } from "../Button/Button";

const PageSelect = ({ buttons, setPage }) => {
    const [clickedId, setClickedId] = useState(0);

    useEffect(() => {
        console.log("buttons", buttons);
    }, [buttons]);

    const handleClick = (event, id) => {
        if (id === "<") {
            if (clickedId > 0) {
                setClickedId(clickedId - 1);
                setPage(clickedId - 1);
            }
            return;
        }
        if (id === ">") {
            if (clickedId < buttons.length - 1) {
                setClickedId(clickedId + 1);
                setPage(clickedId + 1);
            }
            return;
        }
        setClickedId(id);
        setPage(id);
    };

    return (
        <>
            <div style={{ display: "flex" }}>
                <Button onClick={(event) => handleClick(event, "<")} className={"customButton"} variant={"customButton"}>
                    {"<"}
                </Button>
                {buttons &&
                    buttons.map((buttonLabel, i) => {
                        if (i < clickedId - 2 || i > clickedId + 2) {
                            return null;
                        }
                        return (
                            <Button
                                key={i}
                                name={buttonLabel}
                                onClick={(event) => handleClick(event, i)}
                                className={i === clickedId ? "customButton primary" : "customButton"}
                                variant={i === clickedId ? BUTTON_VARIANT.PRIMARY : "page-button"}
                            >
                                {buttonLabel}
                            </Button>
                        );
                    })}
                {
                    // return button of "..." and the last page unless the last page is already shown
                    buttons.length > 5 && clickedId != buttons[buttons.length - 2] && (
                        <>
                            <Button className={"customButton"} variant={"customButton"}>
                                {"..."}
                            </Button>
                            <Button
                                key={buttons.length - 1}
                                name={buttons.length}
                                onClick={(event) => handleClick(event, buttons.length - 1)}
                                className={buttons.length - 1 === clickedId ? "customButton primary" : "customButton"}
                                variant={buttons.length - 1 === clickedId ? BUTTON_VARIANT.PRIMARY : "page-button"}
                            >
                                {buttons.length}
                            </Button>
                        </>
                    )
                }
                <Button onClick={(event) => handleClick(event, ">")} className={"customButton"} variant={"customButton"}>
                    {">"}
                </Button>
            </div>
        </>
    );
};

export default PageSelect;
