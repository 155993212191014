import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { PATH_HOME, PATH_TO_SAMPLEAD_AUTOMATION_SITE, PATH_TO_EXPANDI_SITE } from "utils/constants";
import Button, { BUTTON_VARIANT } from "components/Button/Button";
import sampleadLogo from "assets/images/logo.png";
import sampleadBigLogo from "assets/images/logo200.png";
import expandiLogo from "assets/images/integrations/expandi-logo.png";
import sampleadAutomationIcon from "assets/images/icons/samplead-automation-icon.png";
import sampleadAutomationInfoPhoto from "assets/images/integrations/samplead-automation-info-photo.png";
import expandiIcon from "assets/images/integrations/expandi-integration-image.png";
import expandiInfoPhoto from "assets/images/integrations/expandi-info-photo.png";
import { SideArrow } from "components/DropdownMenu";
import { updateSingleSetting } from "redux/Settings/actions";
import Spinner from "components/Spinner";
import { getExpandiUsers, getUsersKey, addExpandiUser, removeExpandiUser } from "redux/Expandi/reducer";
import { toggleSnackbarOpen } from "redux/Snackbar/actions";

const IntegrateExpandi = ({ whitelabelFlag }) => {
    const integration = whitelabelFlag ? "samplead_automation_integration" : "expandi_integration";
    const [loading, setLoading] = useState(true);
    const [confirmPopUp, setConfirmPopUp] = useState(false);
    const user = useSelector((state) => state.user.user);
    const installed = useSelector((state) => state?.settings?.[integration]);

    const handleInstallClick = () => {
        setConfirmPopUp(true);
    };

    useEffect(() => {
        if ([0, 1].includes(installed)) {
            setLoading(false);
        }
    }, [installed]);

    return (
        <div className="all-integrations-content-container">
            {confirmPopUp && (
                <IntegrateExpandiConfirm
                    setConfirmPopUp={setConfirmPopUp}
                    installed={installed}
                    integration={integration}
                    whitelabelFlag={whitelabelFlag}
                />
            )}
            <div className="integrations-container" style={{ margin: "50px 20px 20px 20px" }}>
                <Link to={PATH_HOME} style={{ textDecoration: "none" }} className="samplead-body--bold">
                    {`< Back to Home`}
                </Link>
                <div className="integrations-header">
                    <img src={whitelabelFlag ? sampleadBigLogo : expandiLogo} alt="Outreach" className="integrations-image" />
                    {!whitelabelFlag && <h1 style={{ fontWeight: "700", fontSize: "40px" }}>Expandi</h1>}
                </div>
                {installed ? (
                    <span>
                        See a detailed “How to” in{" "}
                        {whitelabelFlag ? (
                            <>
                                this link <span style={{ color: "#808080" }}>(coming soon)</span>
                            </>
                        ) : (
                            <a
                                href={whitelabelFlag ? "samplead-automation" : "https://help.expandi.io/en/articles/5405654-reversed-webhooks-api"}
                                target="_blank"
                                rel="noreferrer"
                            >
                                this link
                            </a>
                        )}
                    </span>
                ) : (
                    <p>{whitelabelFlag ? "Seamlessly send personalised messages via Linkedin" : "The #1 LinkedIn automation tool."}</p>
                )}
            </div>

            <div className="integrations-main-content-container">
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <MainContent installed={installed} whitelabelFlag={whitelabelFlag} />
                        <Sidebar
                            handleInstallClick={handleInstallClick}
                            installed={installed}
                            user={user}
                            integration={integration}
                            whitelabelFlag={whitelabelFlag}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

const ExpandiUserRow = ({ username = "", url = "", id = "", whitelabelFlag }) => {
    const isNewUser = id === "";
    const dispatch = useDispatch();
    const [urlValue, setUrlValue] = useState(url);
    const [usernameValue, setUsernameValue] = useState(username);
    const [loading, setLoading] = useState(false);

    return (
        <div className="flex justify-between relative">
            <input
                type={"text"}
                style={{
                    border: "1px solid #000000",
                    borderRadius: "16px",
                    padding: "16px",
                    fontSize: "15px",
                    overflowWrap: "break-word",
                    width: "25%",
                }}
                placeholder={"Username"}
                value={usernameValue}
                onChange={(e) => {
                    setUsernameValue(e.target.value);
                }}
            />
            <input
                type={"text"}
                style={{
                    border: "1px solid #000000",
                    borderRadius: "16px",
                    padding: "16px",
                    fontSize: "15px",
                    overflowWrap: "break-word",
                    width: "72%",
                }}
                placeholder={isNewUser ? "URL to use" : "URL to update"}
                value={urlValue}
                onChange={(e) => {
                    setUrlValue(e.target.value);
                }}
            />
            <div
                style={{
                    position: "absolute",
                    left: "100%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    paddingLeft: "15px",
                }}
            >
                <Button
                    disabled={loading}
                    variant={BUTTON_VARIANT.PRIMARY}
                    onClick={async (e) => {
                        setLoading(true);

                        const regex =
                            /^https:\/\/api\.liaufa\.com\/api\/v1\/open-api\/campaign-instance\/\d+\/assign\/\?key=\w{8}-\w{4}-\w{4}-\w{4}-\w{12}&secret=\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/;
                        if (!regex.test(urlValue)) {
                            dispatch(toggleSnackbarOpen({ message: "Please enter a valid URL", variant: "warning" }));
                            setLoading(false);
                            return;
                        }
                        if (usernameValue === "") {
                            dispatch(toggleSnackbarOpen({ message: "Please enter a username", variant: "warning" }));
                            setLoading(false);
                            return;
                        }

                        id = id || "a" + uuidv4().toString().replace(/-/g, "");
                        console.log("id", id);
                        dispatch(
                            addExpandiUser({
                                id,
                                username: usernameValue,
                                url: urlValue,
                                whitelabel_flag: whitelabelFlag,
                                successCallback: () => {
                                    if (isNewUser) {
                                        setUsernameValue("");
                                        setUrlValue("");
                                    }
                                },
                                finallyCallback: () => {
                                    setLoading(false);
                                },
                            })
                        );
                    }}
                >
                    {loading ? <Spinner width="30px" color="#ffffff" /> : "Save"}
                </Button>
                {!isNewUser && (
                    <Button
                        disabled={loading}
                        variant={BUTTON_VARIANT.DANGER}
                        onClick={async (e) => {
                            setLoading(true);
                            dispatch(
                                removeExpandiUser({
                                    id,
                                    whitelabel_flag: whitelabelFlag,
                                    successCallback: () => {
                                        if (isNewUser) {
                                            setUsernameValue("");
                                            setUrlValue("");
                                        }
                                    },
                                    finallyCallback: () => {
                                        setLoading(false);
                                    },
                                })
                            );
                        }}
                    >
                        {loading ? <Spinner width="30px" color="#ffffff" /> : "Delete"}
                    </Button>
                )}
            </div>
        </div>
    );
};

const MainContent = ({ installed, whitelabelFlag }) => {
    const users = useSelector((state) => state.expandi[getUsersKey(whitelabelFlag)]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (installed) {
            dispatch(getExpandiUsers(whitelabelFlag));
        }
    }, [installed]);

    return (
        <div className="integrations-main-content" style={{ width: "85%" }}>
            {!installed ? (
                <>
                    <img src={whitelabelFlag ? sampleadAutomationInfoPhoto : expandiInfoPhoto} alt="info" />
                    <p>Contact your prospects on LinkedIn and by Email via smart automated sequences.</p>
                    <p>
                        Installing this FREE integration will add an option to export your approved prospects and messages directly in to your
                        LinkedIn campaign
                    </p>
                </>
            ) : (
                <>
                    {[
                        <span>
                            Login to your {whitelabelFlag ? "Samplead automation" : "Expandi"} account:{" "}
                            <a href={whitelabelFlag ? PATH_TO_SAMPLEAD_AUTOMATION_SITE : PATH_TO_EXPANDI_SITE} target="_blank" rel="noreferrer">
                                {whitelabelFlag ? PATH_TO_SAMPLEAD_AUTOMATION_SITE : PATH_TO_EXPANDI_SITE}
                            </a>
                        </span>,
                        "Open a campaign",
                        "Go to the integration tab inside the campaign",
                        "Copy and paste the URL to use to the “URL to use” column",
                        "Click on “Save“",
                        "You are all set up",
                    ].map((ele, i) => {
                        return (
                            <p key={i}>
                                {i + 1}. {ele}
                            </p>
                        );
                    })}
                    <div className="w-3/4 gap-4">
                        <div className="flex justify-between">
                            <h4
                                style={{
                                    background: "#D9D9D9",
                                    borderRadius: "16px",
                                    padding: "16px",
                                    margin: "16px 0px",
                                    fontWeight: "700",
                                    fontSize: "15px",
                                    width: "25%",
                                }}
                            >
                                Username
                            </h4>
                            <h4
                                style={{
                                    background: "#D9D9D9",
                                    borderRadius: "16px",
                                    padding: "16px",
                                    margin: "16px 0px",
                                    fontWeight: "700",
                                    fontSize: "15px",
                                    width: "72%",
                                }}
                            >
                                URL to use
                            </h4>
                        </div>
                        {Object.keys(users)?.length
                            ? Object.entries(users).map(([id, { username, url }], i) => (
                                  <ExpandiUserRow id={id} username={username} url={url} whitelabelFlag={whitelabelFlag} key={i} />
                              ))
                            : null}
                        <ExpandiUserRow whitelabelFlag={whitelabelFlag} />
                    </div>
                </>
            )}
        </div>
    );
};

const Sidebar = ({ installed, handleInstallClick, user, integration, whitelabelFlag }) => {
    const [uninstallPopUp, setUninstallPopUp] = useState(false);

    return (
        <div className="integrations-side-actions">
            <button className={installed ? "installed" : "install-button"} onClick={handleInstallClick}>
                {installed ? "Connected" : "Connect"}
            </button>
            <ul>
                <li>
                    {installed ? (
                        <>
                            <h4>Installed by {user.email}</h4>
                            <span>
                                <span>
                                    <div className="info-box">
                                        <InfoSVG />
                                        <p>This integration requires further information, please add campaign name and URL to set up</p>
                                    </div>
                                </span>
                                <div className="external-button-wrapper">
                                    <button onClick={handleInstallClick} className="external-app-settings-button">
                                        External App Settings
                                    </button>
                                    <SideArrow />
                                </div>
                            </span>
                        </>
                    ) : (
                        <p>Next: Confirm authorization and complete installation</p>
                    )}
                </li>
                <li>
                    <p>Categories</p>
                    <p>
                        <label>LinkedIn automation</label>
                    </p>
                </li>
                <li>
                    <p>Developers links</p>
                    <div>Samplead & {whitelabelFlag ? "Samplead LI automation" : "Expandi"}</div>
                </li>
            </ul>
            {installed ? (
                <div className="uninstall-container">
                    <Button onClick={() => setUninstallPopUp(true)} variant={BUTTON_VARIANT.SECONDARY} className="uninstall">
                        Uninstall
                    </Button>
                    <p>Requires confirmation</p>
                    {uninstallPopUp && (
                        <UninstallModal setUninstallPopUp={setUninstallPopUp} integration={integration} whitelabelFlag={whitelabelFlag} />
                    )}
                </div>
            ) : null}
        </div>
    );
};

const IntegrateExpandiConfirm = ({ setConfirmPopUp, installed, integration, whitelabelFlag }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleOutsideClick = () => {
        setConfirmPopUp(false);
    };

    const connectClick = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch(updateSingleSetting([{ key: integration, value: 1 }]))
            .then(() => {
                setConfirmPopUp(false);
            })
            .catch((err) => {
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className="confirm-popup-outside" onClick={handleOutsideClick}></div>
            <div className="pop-up">
                <div className="joined-logos">
                    <img src={sampleadLogo} alt="Samplead" className="samplead-image" />
                    <p>+</p>
                    <img
                        src={whitelabelFlag ? sampleadAutomationIcon : expandiIcon}
                        style={{ width: "150px" }}
                        alt="Outreach"
                        className="integrations-image"
                    />
                </div>
                <div className="info-container">
                    <h4>Thank you for adding {whitelabelFlag ? "Samplead LI" : "Expandi"} to Samplead</h4>
                    <p>
                        Your users can now export Samplead's personalized messages directly to {whitelabelFlag ? "Samplead LI" : "Expandi"} campaigns.
                    </p>
                    <h4>This integration will give Samplead access to the following {whitelabelFlag ? "Samplead LI" : "Expandi"} data:</h4>
                </div>
                <ul className="access-point-list">
                    {[
                        "Add place holder field",
                        "Add search",
                        "Assign prospects to campaigns",
                        "Choose uploaded prospects",
                        "Pause/resume campaign",
                    ].map((accessPoint) => {
                        return (
                            <li className="access-point" key={accessPoint}>
                                <CheckmarkSVG />
                                <p>{accessPoint}</p>
                            </li>
                        );
                    })}
                    <div className="buttons">
                        <button onClick={connectClick} className={"install-btn"} disabled={installed}>
                            {loading ? <Spinner width="30px" /> : installed ? "Installed" : "Confirm"}
                        </button>
                        <button onClick={() => setConfirmPopUp(false)} className="cancel-btn">
                            Cancel
                        </button>
                    </div>
                    {error !== "" && <p className="error">{error}</p>}
                </ul>
            </div>
        </>
    );
};

const CheckmarkSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
                d="M7.00016 13.6667C3.31816 13.6667 0.333496 10.682 0.333496 7C0.333496 3.318 3.31816 0.333332 7.00016 0.333332C10.6822 0.333332 13.6668 3.318 13.6668 7C13.6668 10.682 10.6822 13.6667 7.00016 13.6667ZM7.00016 12.3333C8.41465 12.3333 9.7712 11.7714 10.7714 10.7712C11.7716 9.77104 12.3335 8.41449 12.3335 7C12.3335 5.58551 11.7716 4.22896 10.7714 3.22876C9.7712 2.22857 8.41465 1.66667 7.00016 1.66667C5.58567 1.66667 4.22912 2.22857 3.22893 3.22876C2.22873 4.22896 1.66683 5.58551 1.66683 7C1.66683 8.41449 2.22873 9.77104 3.22893 10.7712C4.22912 11.7714 5.58567 12.3333 7.00016 12.3333ZM6.3355 9.66667L3.50683 6.838L4.4495 5.89533L6.3355 7.78133L10.1062 4.01L11.0495 4.95266L6.3355 9.66667Z"
                fill="#2ED47A"
            />
            <path
                d="M7.00016 13.6667C3.31816 13.6667 0.333496 10.682 0.333496 7C0.333496 3.318 3.31816 0.333332 7.00016 0.333332C10.6822 0.333332 13.6668 3.318 13.6668 7C13.6668 10.682 10.6822 13.6667 7.00016 13.6667ZM7.00016 12.3333C8.41465 12.3333 9.7712 11.7714 10.7714 10.7712C11.7716 9.77104 12.3335 8.41449 12.3335 7C12.3335 5.58551 11.7716 4.22896 10.7714 3.22876C9.7712 2.22857 8.41465 1.66667 7.00016 1.66667C5.58567 1.66667 4.22912 2.22857 3.22893 3.22876C2.22873 4.22896 1.66683 5.58551 1.66683 7C1.66683 8.41449 2.22873 9.77104 3.22893 10.7712C4.22912 11.7714 5.58567 12.3333 7.00016 12.3333ZM6.3355 9.66667L3.50683 6.838L4.4495 5.89533L6.3355 7.78133L10.1062 4.01L11.0495 4.95266L6.3355 9.66667Z"
                fill="#007D39"
            />
        </svg>
    );
};
const InfoSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 5H11V7H9V5ZM9 9H11V15H9V9Z"
                fill="#7A7A7A"
            />
        </svg>
    );
};

const UninstallModal = ({ integration, setUninstallPopUp, whitelabelFlag }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const unInstallClick = async () => {
        setLoading(true);
        dispatch(updateSingleSetting([{ key: integration, value: 0 }]))
            .then(() => {
                setUninstallPopUp(false);
            })
            .catch((err) => {
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleOutsideClick = () => {
        setUninstallPopUp(false);
    };

    return (
        <>
            <div className="confirm-popup-outside" onClick={handleOutsideClick}></div>
            <div className="pop-up">
                <div className="joined-logos">
                    <img src={sampleadLogo} alt="Samplead" className="samplead-image" />
                    <p>+</p>
                    <img
                        src={whitelabelFlag ? sampleadAutomationIcon : expandiIcon}
                        style={{ width: "150px" }}
                        alt="Outreach"
                        className="integrations-image"
                    />
                </div>
                <div className="info-container">
                    <h4>This integration currently premite access to the following {whitelabelFlag ? "Samplead automation" : "Expandi"} data:</h4>
                </div>
                <ul className="access-point-list">
                    {[
                        "Add place holder field",
                        "Add search",
                        "Assign prospects to campaigns",
                        "Choose uploaded prospects",
                        "Pause/resume campaign",
                    ].map((accessPoint) => {
                        return (
                            <li className="access-point" key={accessPoint}>
                                <CheckmarkSVG />
                                <p>{accessPoint}</p>
                            </li>
                        );
                    })}
                    <div className="buttons">
                        <button className="uninstall-button" onClick={unInstallClick}>
                            {loading ? <Spinner width="30px" /> : "Confirm uninstall"}
                        </button>
                        <button onClick={() => setUninstallPopUp(false)} className="cancel-btn">
                            Cancel
                        </button>
                    </div>
                    {error !== "" && <p className="error">{error}</p>}
                </ul>
            </div>
        </>
    );
};

export default IntegrateExpandi;
