import React, { useEffect, useState, lazy, Suspense } from "react";
import Button, { BUTTON_VARIANT } from "../Button/Button";
import Text from "../Text";
import moment from "moment";
import anonymous from "assets/images/anonymous.png";
import { isNullOrEmptyArray } from "utils/general";
import Badge from "../Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft as FaArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import OriginChip from "../OriginChip";

import "./SuggestionCard.css";
import HotnessIcon from "../HotnessIcon/index.jsx";
import axios from "axios";
import { useSelector } from "react-redux";
import env from "config/site.config";
import Icon from "../Icon/Icon";
import { CAMPAIGN_TYPE } from "utils/constants";
import BadgeLink from "components/BadgeLink";
import { Card } from "antd";
import { fixUrl } from "utils/general";

import MagicMomentsGrey from "../../assets/svgs/magic_moments_grey.svg";

const LazyLeadImageComponent = lazy(() => import("components/LeadImageComponent"));

const SuggestionCard = ({ lead, onApproveClick, onDenyClick }) => {
    const [areActionsDisabled, setAreActionsDisabled] = useState(false);
    const [showDenyReason, setShowDenyReason] = useState(false);
    const [readMode, setReadMode] = useState(false);
    const [userInsertedReason, setUserInsertedReason] = useState("");
    const [showInsertedReason, setShowInsertedReason] = useState(false);
    const user = useSelector((state) => state.user.user);

    useEffect(() => {
        // function checkForDiscoveryInsertedData() {
        //     if (lead.summary.includes("inner_summary")) {
        //         lead.company_data = JSON.parse(lead.summary);
        //         lead.summary = JSON.parse(lead.summary).inner_summary;
        //     }
        // }
        // checkForDiscoveryInsertedData();
    }, []);

    const reasons = [
        { type: "bad_territory", title: "Not my territory" },
        { type: "bad_title", title: "Title" },
        { type: "bad_company", title: "Company" },
        { type: "bad_title_bad_company", title: "Partner" },
        { type: "competitor", title: "Competitor" },
        { type: "ecosystem", title: "Customer" },
        { type: "bad_Industry", title: "Industry" },
        { type: `other:${userInsertedReason}`, title: "Other" },
    ];

    const onSubmitDeny = () => {
        sendGA("denied a suggestion");
        const checkedReasons = [];

        reasons.forEach((reason) => {
            if (document.getElementById(reason.type).checked) {
                checkedReasons.push(document.getElementById(reason.type).value);
            }
        });
        onDenyClick(checkedReasons);
    };

    const disableActions = (cb) => async () => {
        setAreActionsDisabled(true);

        try {
            await cb();
        } finally {
            setAreActionsDisabled(false);
        }
    };

    const addUrlPrefix = (url) => {
        if (url.startsWith("www")) {
            return "https://" + url;
        }
        return url;
    };

    const sendGA = (event) => {
        ReactGA.event({
            category: "New Campaign",
            action: `${user.name} ${event}`,
            label: "approve/deny",
        });
    };

    const sendGAapproved = () => {
        sendGA("approved a suggestion");
    };

    const showReasonInputIfSelected = (value) => {
        if (value === "Other") {
            setShowInsertedReason(!showInsertedReason);
        }
    };

    function emphasizeWords(str) {
        const removedQuotes = str.replace(/^"(.+(?="$))"$/, "$1");
        const words = removedQuotes.split(/(\s+|\*.*?\*)/);
        const emphasizedWords = words.map((word, index) => {
            if (word.startsWith("*") && word.endsWith("*")) {
                const emphasizedWord = word.slice(1, -1);
                return (
                    <span
                        style={{
                            display: "inline-block",
                            padding: "0 12px",
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#666",
                            backgroundColor: "#f2f2f2",
                            border: "1px solid #d9d9d9",
                            borderRadius: "6px",
                            lineHeight: "28px",
                            verticalAlign: "middle",
                            margin: "2px",
                        }}
                        key={index}
                    >
                        {emphasizedWord}
                    </span>
                );
            }
            return <span key={index}>{word}</span>;
        });
        return <>{emphasizedWords}</>;
    }

    return (
        <div className="suggestion-card-wrapper">
            <header className="flex flex-col">
                <div className="suggestion-card__header max-w-full">
                    <div className="absolute left-5">
                        <Suspense fallback={<div>Loading...</div>}>
                            <LazyLeadImageComponent lead={lead} />
                        </Suspense>
                    </div>
                    <div className="suggestion-card__header-content w-9/12">
                        <div className="suggestion-card-name--text w-full" id={lead.id}>
                            <Text className="samplead-title-2 ">{lead.name}</Text>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="fire-icon h-full">
                            <HotnessIcon lead={lead} />
                        </div>
                        <div className="flex items-center h-full">
                            <OriginChip origin={lead.origin} />
                        </div>
                    </div>
                </div>
                <div className="w-10/12">
                    <div className={"suggestion-card__title"}>
                        <Text className="suggestion-card__title">{lead.title}</Text>
                    </div>
                    <div className="suggestion-card__employees">
                        {lead?.company_name || lead?.user_data?.company_name} | {lead.industry || lead?.user_data?.company_data?.industry || "n/a"} |{" "}
                        {lead?.company_size ||
                            lead?.user_data?.company_data?.company_size_on_linkedin?.toString()?.replace(" employees", "") ||
                            "n/a"}{" "}
                        employees | {lead?.country || "n/a"}
                    </div>
                </div>
            </header>
            <main className={"suggestion-card-main"}>
                {lead.summary && (
                    <div className="samplead-body w-11/12">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            {lead.summary_short}
                        </div>
                        <div className={`samplead-body`}>
                            {lead.dots_summary ? (
                                readMode && (
                                    <Card
                                        style={{
                                            margin: "10px 0",
                                            padding: "0 10px",
                                        }}
                                    >
                                        <ul className={`samplead-body`} style={{ listStylePosition: "outside", padding: "10px" }}>
                                            {lead?.dots_summary?.split("\n").map((item, key) => {
                                                let style = { minHeight: "1em" };
                                                if (item.includes("•")) {
                                                    item = item.replace("• ", "");
                                                    style.listStyleType = "circle";
                                                } else {
                                                    style.listStyleType = "none";
                                                    style.marginLeft = "-1.5em";
                                                }
                                                if (!item) {
                                                    item = " ";
                                                }
                                                return (
                                                    <li key={key} style={style}>
                                                        {item}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </Card>
                                )
                            ) : readMode ? (
                                <Card style={{ margin: "10px 0", padding: "0 10px" }}>
                                    <div className={`samplead-body`} style={{ padding: "10px" }}>
                                        {lead.summary}
                                    </div>
                                </Card>
                            ) : null}
                        </div>
                        {readMode ? (
                            <Button variant={BUTTON_VARIANT.LINK} onClick={() => setReadMode(false)}>
                                Read less
                            </Button>
                        ) : (
                            <div className={`samplead-body`}>
                                <Button variant={BUTTON_VARIANT.LINK} onClick={() => setReadMode(true)} inline>
                                    Read more
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </main>

            <div className={`suggestion-badges`}>
                <h6 className="samplead-body">Magic Moment type:&nbsp;&nbsp;&nbsp;</h6>
                <div className={"lead-row-bottom__right--badges"} style={{ display: "flex", columnGap: "10px" }}>
                    {!isNullOrEmptyArray(lead.badges) &&
                        lead.badges.map((badge, i) => {
                            return <BadgeLink key={i} badge={badge} />;
                        })}
                </div>
            </div>

            <div
                style={{
                    marginRight: "auto",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        direction: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    <Button
                        onClick={() => {
                            window.open(fixUrl(lead.url), "_blank");
                        }}
                        variant={BUTTON_VARIANT.LINK}
                    >
                        Go To Profile
                    </Button>
                    {lead?.user_data?.company_url && (
                        <Button
                            onClick={() => {
                                window.open(fixUrl(lead?.user_data?.company_url), "_blank");
                            }}
                            variant={BUTTON_VARIANT.LINK}
                        >
                            Go To Company
                        </Button>
                    )}
                </div>
            </div>

            {showDenyReason && <div className="deny-reason-overlay" onClick={() => setShowDenyReason(false)} />}

            <footer className={"suggestion-card__footer"}>
                <div style={{ position: "relative" }}>
                    {showDenyReason && (
                        <div className="deny-reason-wrapper">
                            <div className="deny-reason-box">
                                <div style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
                                    {/* <img src={`../../images/warning.png`} style={{height: '20px', paddingRight: '3px'}} alt="" /> */}
                                    Help Samplead find better prospects for you! Why did you choose to deny?
                                    <br />
                                </div>
                                {/* <div style={{padding: '5px', fontSize: '15px'}}>Tell us why did you choose to deny<br/></div> */}

                                <div className="deny-reason-buttons-box">
                                    {reasons.map((reason, i) => {
                                        return (
                                            <div key={i} className="deny-reason-grid-item">
                                                <div className="deny-reason-text-box">
                                                    <input
                                                        className="deny-checkbox"
                                                        onChange={(e) => showReasonInputIfSelected(reason.title)}
                                                        type="checkbox"
                                                        id={reason.type}
                                                        name={reason.type}
                                                        value={reason.type}
                                                    />
                                                    <label className="deny-reason-text" htmlFor={reason.title}>
                                                        {reason.title === "Other" && showInsertedReason ? (
                                                            <input
                                                                placeholder="Please tell us more..."
                                                                onChange={(e) => setUserInsertedReason(e.target.value)}
                                                            />
                                                        ) : (
                                                            <div> &nbsp;&nbsp;&nbsp;{reason.title}</div>
                                                        )}
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                {/* {showInsertedReason && 
								<label>
								  <input placeholder='Please tell us more...' onChange={(e)=>setUserInsertedReason(e.target.value)}/>
								</label>
								} */}
                                <div className="skip_btn" style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <div className="skip_btn" style={{ display: "flex", justifyContent: "flex-end", padding: "10px 0" }}>
                                        <Button
                                            variant={BUTTON_VARIANT.LINK}
                                            onClick={disableActions(() => onSubmitDeny())}
                                            isDisabled={areActionsDisabled}
                                        >
                                            Skip
                                        </Button>
                                    </div>
                                    <div className="skip_btn" style={{ display: "flex", justifyContent: "flex-end", padding: "10px 0" }}>
                                        <Button
                                            variant={BUTTON_VARIANT.PRIMARY}
                                            onClick={disableActions(() => onSubmitDeny())}
                                            isDisabled={areActionsDisabled}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        justifyContent: "space-between",
                    }}
                >
                    {lead?.campaignTitle && (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <img src={MagicMomentsGrey} style={{ height: "20px", paddingRight: "3px" }} alt="" />
                            <h3>{lead?.campaignTitle}</h3>
                        </div>
                    )}
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginLeft: "auto",
                        }}
                    >
                        <Button
                            style={{ marginRight: "10px" }}
                            variant={BUTTON_VARIANT.DANGER}
                            onClick={disableActions(() => {
                                sendGA("viewed deny options");
                                setShowDenyReason(true);
                            })}
                            isDisabled={areActionsDisabled}
                        >
                            Deny
                        </Button>
                        <Button
                            className="suggestion-card__footer-approve-btn"
                            variant={BUTTON_VARIANT.PRIMARY}
                            onClick={disableActions(onApproveClick)}
                            onSubmit={sendGAapproved()}
                            isDisabled={areActionsDisabled}
                        >
                            Approve
                        </Button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default SuggestionCard;
