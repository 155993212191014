import React, { useEffect, lazy, Suspense, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import LeadRow from "components/LeadRow";
import * as campaignActions from "redux/Campaign/reducer";
import { isNullOrEmptyArray } from "utils/general";
import { useState } from "react";
import Button, { BUTTON_VARIANT } from "components/Button/Button";
import env from "config/site.config";
import { Table, Pagination, Input, Space, Tooltip } from "antd";
import { PlusOutlined, MinusOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { LeadStatus } from "utils/constants";
import OriginChip from "components/OriginChip";
import HotnessIcon from "components/HotnessIcon";
import moment from "moment";
import { updatePaginationAndSearch } from "../../../redux/Campaign/reducer";
import { ca } from "date-fns/locale";
import { setFocusedLead } from "redux/Replies/reducer";

const LazyLeadImageComponent = lazy(() => import("components/LeadImageComponent"));

export const LeadList = ({
    campaignId,
    leads,
    type,
    onRephraseLead,
    onRemoveLead,
    onRestoreLead,
    onExpandCollapse,
    onEditLeadMessage,
    emptyListComponent: EmptyListComponent,
    exported,
    setSpecificExports,
    specificExports,
    isActiveExported,
    isLoadingCampaign,
}) => {
    const dispatch = useDispatch();
    const [unscrapedLeads, setUnscrapedLeads] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const tabCounts = useSelector((state) => state.campaign.tabCounts);
    const campaign = useSelector((state) => state.campaign.campaigns.find((c) => c.id === campaignId));

    const searchInput = useRef(null);
    const campaignPaginationAndSearch = useSelector((state) => state.campaign.campaignPaginationAndSearch[campaignId]);
    const page = campaignPaginationAndSearch?.pageNumber || 1;
    const pageSize = campaignPaginationAndSearch?.pageSize || 10;
    const searchText = campaignPaginationAndSearch?.searchValue || "";
    const searchedColumn = campaignPaginationAndSearch?.searchKey || "";
    const currentTab = campaignPaginationAndSearch?.currentTab || "active";
    const sortKey = campaignPaginationAndSearch?.sortKey || "";
    const sortOrder = campaignPaginationAndSearch?.sortOrder || "ASC";
    const searchResultsCount = campaign?.searchResultCount || 0;
    const campaignFilterId = useSelector((state) => state.campaign.campaigns.find((c) => c.id === "all").campaignFilterId);

    const [tableLoading, setTableLoading] = useState(false);
    const [totalLeads, setTotalLeads] = useState(0);

    useEffect(() => {
        console.log("camp", campaign);
        console.log("search count:", searchResultsCount);
        if (searchText) {
            setTotalLeads(searchResultsCount);
        } else {
            setTotalLeads(tabCounts?.[currentTab] || 0);
        }
    }, [searchResultsCount, searchText, tabCounts, currentTab]);

    const searched = Boolean(searchText);
    const sorted = Boolean(sortKey && sortOrder);

    useEffect(() => {
        if (!campaignId) return;
        console.log("getting new page with campaign id", campaignId);
        if (campaignId === "all") {
            setTableLoading(true);
            dispatch(
                campaignActions.getAllMagicMoments({
                    pageNumber: page,
                    pageSize,
                    searchValue: searchText,
                    searchKey: searchedColumn,
                    currentTab: currentTab,
                    sortKey: sortKey,
                    sortOrder: sortOrder,
                    filterByCampaignId: campaignFilterId === "all" ? null : campaignFilterId,
                    callback: () => {
                        setTableLoading(false);
                    },
                })
            );
            return;
        }

        setTableLoading(true);
        console.log("(REDUX DEBUG)  LEAD LIST USE EFFECT");
        dispatch(
            campaignActions.getCampaignById({
                campaignId: campaignId,
                pageNumber: page,
                pageSize,
                searchValue: searchText,
                searchKey: searchedColumn,
                currentTab: currentTab,
                sortKey: sortKey,
                sortOrder: sortOrder,
                searched: searched, // add searched parameter
                sorted: sorted, // add sorted parameter
                callback: () => {
                    setTableLoading(false);
                },
            })
        );
    }, [page, pageSize, searchText, searchedColumn, currentTab, sortKey, sortOrder, campaignId, dispatch]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        dispatch(
            updatePaginationAndSearch({
                campaignId,
                pageNumber: page,
                pageSize,
                searchValue: selectedKeys[0],
                searchKey: dataIndex,
                currentTab: currentTab,
                sortKey: sortKey,
                sortOrder: sortOrder,
            })
        );
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        dispatch(
            updatePaginationAndSearch({
                campaignId,
                pageNumber: page,
                pageSize,
                searchValue: "",
                searchKey: "",
                currentTab: currentTab,
                sortKey: sortKey,
                sortOrder: sortOrder,
            })
        );
    };

    const getUpdatedSortValues = (sorter) => {
        if (!sorter.order) {
            return {
                updatedSortKey: "",
                updatedSortOrder: "",
            };
        }
        const updatedSortKey = sorter.field === "createdAt" ? "created_at" : sorter.field;
        const updatedSortOrder = sorter.order === "ascend" ? "ASC" : "DESC";
        return {
            updatedSortKey,
            updatedSortOrder,
        };
    };

    const handleTableChange = (pagination, filters, sorter) => {
        console.log("Sorter:", sorter);

        const { updatedSortKey, updatedSortOrder } = getUpdatedSortValues(sorter);

        dispatch(
            updatePaginationAndSearch({
                campaignId,
                pageNumber: pagination.current,
                pageSize: pagination.pageSize,
                searchValue: searchText,
                searchKey: searchedColumn,
                currentTab: currentTab,
                sortKey: updatedSortKey,
                sortOrder: updatedSortOrder,
            })
        );
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            dispatch(
                                updatePaginationAndSearch({
                                    campaignId,
                                    pageNumber: page,
                                    pageSize,
                                    searchValue: selectedKeys[0],
                                    searchKey: dataIndex,
                                    currentTab: currentTab,
                                })
                            );
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                    fontSize: "15px",
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    // get expanded rows from redux
    const initialExpanded = useSelector((state) => state.campaign.expandedLeadIds);
    const [expandedRows, setExpandedRows] = useState(initialExpanded);

    // save to redux when expanded rows change
    useEffect(() => {
        dispatch(campaignActions.updateExpandedLeadIds(expandedRows));
    }, [expandedRows]);

    useEffect(() => {
        const tempDataSource = [];

        for (let i = 0; i < leads.length; i++) {
            tempDataSource.push({
                key: leads[i]?.id,
                name: leads[i]?.name,
                title: leads[i]?.title,
                email: leads[i]?.email,
                company_name: leads[i]?.user_data?.company_name,
                createdAt: moment(leads[i]?.createdAt).format("MMM DD,YYYY"),
                action: leads[i]?.id,
                avatar: leads[i],
                country: leads[i]?.country,
                lead: leads[i],
            });
        }
        console.log("Table data source: ", tempDataSource);

        setDataSource(tempDataSource);
    }, [leads]);

    useEffect(() => {
        handleUnscrapedMessage(leads);

        const leadIds = leads?.map((lead) => lead.id);
        const campaignId = leads[0]?.campaignId;
        const properties = [];
        if (leads.find((l) => l.email)) {
            properties.push("email");
        }
        if (leads.find((l) => l.phone)) {
            properties.push("phone");
        }

        dispatch(campaignActions.normalizeLeadsProperties({ leadIds, campaignId, properties }));
    }, []);

    const handleUnscrapedMessage = (leads) => {
        const TIMESTAMP_BY_TIMEZONE = env.REACT_APP_ENV_NAME === "local" ? Math.floor(Date.now() / 1000) - 7230 : Math.floor(Date.now() / 1000) - 30;

        const foundUnscraped = leads.filter(
            (l) => l.createdAt && Math.floor(Date.parse(l.createdAt) / 1000) > TIMESTAMP_BY_TIMEZONE && l.title === ""
        );
        if (foundUnscraped !== undefined) {
            setUnscrapedLeads(foundUnscraped);
        }
    };
    const handleUnscrapedOK = () => {
        setUnscrapedLeads([]);
    };

    const expandedLeadIds = useSelector((state) => state.campaign.expandedLeadIds);
    const campaigns = useSelector((state) => state.campaign.campaigns);

    useEffect(() => {
        dispatch(campaignActions.updateExpandedLeadIds(expandedRows));
    }, [expandedRows]);

    if (!tableLoading && !leads.length && !searchText) {
        return <EmptyListComponent type={type} leads={leads} isActiveExported={isActiveExported} />;
    }

    const toggleExpand = (lead) => {
        let updated;
        const leadId = lead.id;
        if (expandedLeadIds.includes(leadId)) {
            updated = expandedLeadIds.filter((l) => l !== leadId);
        } else {
            updated = [...expandedLeadIds, leadId];

            if (lead.unread) {
                //this causes problems with the Lead Image
                dispatch(campaignActions.markLeadsAsRead({ campaignId: lead.campaignId, leadIds: [lead.id] }));
            }
        }

        dispatch(campaignActions.updateExpandedLeadIds(updated));
        // onExpandCollapse(expandedLeadIds.length === leads.length);
    };

    const columns = [
        Table.SELECTION_COLUMN,
        {
            title: "",
            dataIndex: "",
            key: "x",
            width: 100,
            render: (record) => (
                <Suspense fallback={<div>Loading...</div>}>
                    <LazyLeadImageComponent lead={record?.lead} />
                </Suspense>
            ),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: true,
            ...getColumnSearchProps("name"),
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            sorter: true,
            ...getColumnSearchProps("title"),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: true,
            ...getColumnSearchProps("email"),
        },
        {
            title: "Company",
            dataIndex: "company_name",
            key: "company_name",
            sorter: true,
            ...getColumnSearchProps("company_name"),
            render: (record) => {
                const lead = dataSource.find((l) => l.company_name === record);
                return (
                    <Tooltip placement="topLeft" title={lead?.lead?.summary_short}>
                        <div
                            style={{
                                curser: lead?.lead?.summary_short ? "pointer" : "default",
                                textDecoration: lead?.lead?.summary_short ? "underline" : "none",
                                textDecorationColor: lead?.lead?.summary_short ? "#a53aff" : "none",
                            }}
                        >
                            {record}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            sorter: true,
            ...getColumnSearchProps("country"),
        },
        // add date column
        {
            title: "Created",
            dataIndex: "createdAt",
            key: "createdAt",
            // width: 150,
            sorter: true,
            // ...getColumnSearchProps("createdAt"),
        },
        {
            title: (record) => (
                <Button style={{ margin: "auto" }} variant={BUTTON_VARIANT.SECONDARY} onClick={expandAll}>
                    {
                        {
                            true: "Collapse",
                            false: "Expand",
                        }[expandedRows?.length === dataSource?.length]
                    }{" "}
                </Button>
            ),
            dataIndex: "",
            render: (record) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "20px",
                    }}
                >
                    <div className="origin-chip-container">{type === "auto-discovery" && <HotnessIcon lead={record?.lead} />}</div>{" "}
                    <div style={{ display: "flex" }}>
                        <div className="origin-chip-container">
                            <OriginChip origin={record?.lead?.origin} />
                        </div>
                    </div>
                    {
                        // check if the current row is expanded
                        expandedRows?.includes(record?.key) ? (
                            <MinusOutlined
                                style={{ fontSize: "25px", marginTop: "5px", color: "#b219d8" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setExpandedRows(expandedRows?.filter((row) => row !== record.key));
                                }}
                            />
                        ) : (
                            <PlusOutlined
                                style={{ fontSize: "25px", marginTop: "5px", color: "#b219d8" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setExpandedRows([...expandedRows, record?.key]);
                                }}
                            />
                        )
                    }
                </div>
            ),
        },
    ];

    const expandAll = () => {
        // check if all rows are expanded
        const isAllRowsExpanded = expandedRows?.length === dataSource?.length;
        // if they are, collapse them all
        if (isAllRowsExpanded) {
            setExpandedRows([]);
            return;
        }
        // set the expanded rows to the keys of the data source
        setExpandedRows(dataSource?.map((row) => row.key));
    };

    return (
        <main className={`campaigns__main show-lead-list`}>
            <Table
                onChange={handleTableChange}
                dataSource={dataSource}
                columns={columns}
                loading={tableLoading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            if (record?.lead?.repliesData) {
                                console.log("clicked row", record?.lead);
                                dispatch(setFocusedLead(record?.lead));
                            } else {
                                console.log("clicked row no reply data");
                            }
                        },
                    };
                }}
                rowSelection={{
                    onChange: (selectedRowKeys, selectedRows) => {
                        if (selectedRows) {
                            const selectedLeads = selectedRows.map((row) => {
                                return row?.lead;
                            });
                            if (selectedLeads?.length > 0) console.log("selectedLeads", selectedLeads);
                            setSpecificExports(selectedLeads);
                        }
                    },
                }}
                expandable={{
                    expandedRowKeys: expandedRows,
                    onExpand: (expanded, record) => {
                        if (expanded) {
                            setExpandedRows([...expandedRows, record.key]);
                        } else {
                            setExpandedRows(expandedRows.filter((key) => key !== record.key));
                        }
                    },

                    expandIcon: ({ expanded, onExpand, record }) => {
                        return null;
                    },

                    expandedRowRender: (record) => {
                        const lead = record.lead;
                        return (
                            <div>
                                <LeadRow
                                    key={lead.id}
                                    lead={lead}
                                    campaign={isNullOrEmptyArray(campaigns) ? null : campaigns.find((c) => c.id === lead.campaignId)}
                                    isExpanded={expandedLeadIds.includes(lead.id)}
                                    handleExpandCollapse={() => toggleExpand(lead)}
                                    onRemoveClick={onRemoveLead && (() => onRemoveLead(lead))}
                                    onRestoreClick={onRestoreLead && (() => onRestoreLead(lead))}
                                    onRephraseClick={onRephraseLead && (() => onRephraseLead(lead))}
                                    onEditLeadMessageEvent={(lead) => onEditLeadMessage(lead)}
                                    setSpecificExports={setSpecificExports}
                                    specificExports={specificExports}
                                />
                            </div>
                        );
                    },
                }}
                style={{
                    background: "#fbfbff",
                    width: "100%",
                    height: "100%",
                    border: "px solid #e8e8e8",
                    borderRadius: "10px",
                }}
                pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: totalLeads,

                    onChange: (page, pageSize) => {
                        dispatch(
                            updatePaginationAndSearch({
                                campaignId,
                                pageNumber: page,
                                pageSize,
                                searchValue: searchText,
                                searchKey: searchedColumn,
                                currentTab: currentTab,
                            })
                        );
                    },
                    onShowSizeChange: (current, size) => {
                        dispatch(
                            updatePaginationAndSearch({
                                campaignId,
                                pageNumber: current,
                                pageSize: size,
                                searchValue: searchText,
                                searchKey: searchedColumn,
                                currentTab: currentTab,
                            })
                        );
                    },

                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20", "30", "40", "50"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
            />
        </main>
    );
};
