import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

import Icon from "components/Icon/Icon";
import Button, { BUTTON_VARIANT } from "components/Button/Button";
import * as sequenceActions from "redux/Sequence/actions";
import useStyles from "../styles";
import Spinner from "components/Spinner";

const LeadMessage = ({ value = "", messageSubject, minRows, maxLength, step, original }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const textAreaRef = useRef(null);

    console.log("flag-", step, messageSubject, value);

    return (
        <TextareaAutosize
            type={"text"}
            ref={textAreaRef}
            style={{ ...classes.sequenceStep }}
            minRows={minRows}
            onChange={(e) => {
                dispatch(sequenceActions.updateStep(step, { [messageSubject]: e.target.value, messageIndex: step?.messageIndex }, original));
            }}
            defaultValue={value}
            maxLength={maxLength}
            placeholder={messageSubject.charAt(0).toUpperCase() + messageSubject.slice(1)}
        />
    );
};

const SequenceStep = ({ step, subject, message, children, original, buttonCallback }) => {
    const [loading, setLoading] = useState(false);
    const show = useSelector((state) => {
        if (original) {
            return state.sequence.show.originalSteps[step];
        } else {
            return state.sequence.show.generatedSteps[step];
        }
    });
    const dispatch = useDispatch();
    const setShow = () => {
        dispatch(sequenceActions.toggleShowStep(step, original));
    };
    const classes = useStyles();
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "8px", backgroundColor: "#F2F2FF", padding: "16px", borderRadius: "8px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }} onClick={() => setShow((prev) => !prev)}>
                <div style={classes.sequenceStepSubjects}>Step {step}</div>
                <Icon type={show ? "up_arrow" : "down_arrow"} style={{ float: "right", cursor: "pointer" }} />
            </div>
            {show && (
                <>
                    <LeadMessage value={subject} messageSubject="subject" minRows="1" maxLength={120} step={step} original={original} />
                    <LeadMessage value={message} messageSubject="message" minRows="10" maxLength={2200} step={step} original={original} />
                    {buttonCallback && (
                        <Button
                            variant={BUTTON_VARIANT.SECONDARY}
                            borderless={true}
                            isDisabled={loading}
                            style={{ right: "0", top: "20px", position: "absolute" }}
                            onClick={(e) => {
                                setLoading(true);
                                buttonCallback(() => setLoading(false));
                            }}
                        >
                            {loading ? <Spinner width="30" /> : "Rephrase"}
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};

const FullSequence = ({ steps, title, original }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const empty = useSelector((state) => state.sequence.empty);

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "50px", lastChild: { gap: "0px" }, width: "40%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1 style={classes.sequenceStepTitle}>{title}</h1>
                <div style={{ display: "flex", gap: "10px" }}>
                    <Button
                        variant={BUTTON_VARIANT.SECONDARY}
                        onClick={() => {
                            dispatch(sequenceActions.toggleShowAllSteps(original, true));
                        }}
                    >
                        Expand all
                    </Button>
                    <Button
                        variant={BUTTON_VARIANT.SECONDARY}
                        onClick={() => {
                            dispatch(sequenceActions.toggleShowAllSteps(original, false));
                        }}
                    >
                        Shrink all
                    </Button>
                </div>
            </div>
            {original || !empty ? (
                <>
                    {Object.entries(steps).map(([step, { subject, message }], index) => {
                        return (
                            <SequenceStep
                                step={step}
                                subject={empty ? "" : subject}
                                message={empty ? "" : message}
                                original={original}
                                key={index}
                                buttonCallback={
                                    original
                                        ? null
                                        : (callback) => {
                                              dispatch(sequenceActions.rephraseStep(step, callback));
                                          }
                                }
                            ></SequenceStep>
                        );
                    })}
                    {original && (
                        <div>
                            <Button
                                variant={BUTTON_VARIANT.SECONDARY}
                                borderless={true}
                                style={{ float: "left" }}
                                onClick={(e) => {
                                    dispatch(sequenceActions.addStep());
                                }}
                            >
                                Add step
                            </Button>
                            <Button
                                variant={BUTTON_VARIANT.SECONDARY}
                                borderless={true}
                                style={{ float: "right" }}
                                onClick={(e) => {
                                    dispatch(sequenceActions.removeStep());
                                }}
                            >
                                Remove step
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div style={{ marginTop: "30px" }}>
                        <div>Start creating AI powered sequences:</div>
                        <div>1. Name your sequence</div>
                        <div>2. Choose if it is an email or a linkedin sequence</div>
                        <div>3. Upload your version of the sequence steps</div>
                        <div>4. Generate by using the drop downs</div>
                        <Icon
                            type={"linkedin"}
                            style={{ marginTop: "15px", cursor: "pointer" }}
                            onClick={() => {
                                dispatch(sequenceActions.getSequence());
                            }}
                        />
                    </div>
                </>
            )}

            <div />
        </div>
    );
};

const SequenceContent = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(sequenceActions.getSequence());
    }, []);

    const originalSteps = useSelector((state) => state.sequence.originalSteps);
    const generatedSteps = useSelector((state) => state.sequence.generatedSteps);

    return (
        <div style={{ display: "flex", gap: "70px", justifyContent: "space-between", height: "100%", width: "100%", marginTop: "20px" }}>
            <FullSequence steps={originalSteps} title={"Original sequence"} original={true} />
            <div style={{ minHeight: "100%", border: "1px solid #CDCDCD", display: "block" }}></div>
            <FullSequence
                steps={generatedSteps}
                title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon type={"samplead_icon"} style={{ marginRight: "10px" }} />
                        Sequence
                    </div>
                }
                original={false}
            />
        </div>
    );
};

export default SequenceContent;
