import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import avatar from "assets/images/avatar.png";
import Modal from "components/Modal";
import { getAccountUsers, updateAccountFilteredUser } from "redux/User/actions";
import Button from "components/Button/Button";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { Table, Input, Space, Switch } from "antd";
// import spinner
import Spinner from "components/Spinner";

const AccountUsersModal = (props) => {
    const user = useSelector((state) => state.user.user);
    const accountUsers = useSelector((state) => state.user.accountUsers);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (user?.role !== "2") return;
        if (accountUsers?.length) return;
        dispatch(getAccountUsers());
    }, [user]);

    const getColumnSearchProps = (dataIndex, select = false) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
            return (
                <div
                    style={{
                        padding: 8,
                        position: "relative",
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                >
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        onKeyDown={(e) => {
                            e.stopPropagation();

                            if (e.key === "Enter") {
                                handleSearch(selectedKeys, confirm, dataIndex);
                            }

                            if (e.key === "Escape") {
                                clearFilters && handleReset(clearFilters);
                                setSearchText("");
                                setSelectedKeys([""]);
                                confirm({
                                    closeDropdown: false,
                                });
                            }
                        }}
                        style={{
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters && handleReset(clearFilters);
                                setSearchText("");
                                setSelectedKeys([""]);
                                confirm();
                                searchInput.current.setValue("");
                            }}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                confirm({
                                    closeDropdown: false,
                                });
                                setSearchText(selectedKeys[0]);
                                setSearchedColumn(dataIndex);
                            }}
                        >
                            Filter
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                close();
                            }}
                        >
                            close
                        </Button>
                    </Space>
                </div>
            );
        },
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                    fontSize: "15px",
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => !select && searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "Image",
            dataIndex: "image_url",
            key: "image_url",
            render: (image) => <img src={image ? image : avatar} alt="user" className="source-lead-row-image" />,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps("name"),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
            ...getColumnSearchProps("email"),
        },
        {
            title: "Shown User",
            dataIndex: "is_shown",
            key: "is_shown",
            render: (is_shown, user) => {
                return (
                    <>
                        {user?.is_loading ? (
                            <Spinner width="30px" contHeight="30px" />
                        ) : (
                            <Switch
                                checked={is_shown}
                                onChange={(e) => {
                                    dispatch(updateAccountFilteredUser(user.owner_id, e));
                                }}
                            />
                        )}
                    </>
                );
            },
        },
    ];

    // create table with all users, and a button to change to that user
    // if the user is the admin, show the admin user as well on top
    // have a column for the user's image, account, account_managet, name, email, and a button to change to that user
    // also add input to search for a user by name, email, account or account manager
    return (
        <Modal {...props} style={{ padding: "100px" }}>
            <div className="flex justify-center">
                <h1 className="samplead-title-2">User Filtering</h1>
            </div>
            <Table
                dataSource={accountUsers}
                columns={columns}
                loading={!accountUsers?.length}
                style={{
                    background: "#fbfbff",
                    width: "100%",
                    height: "100%",
                    border: "px solid #e8e8e8",
                    borderRadius: "10px",
                    overflow: "auto",
                }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50", "100"],
                    position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
            />
        </Modal>
    );
};

export default AccountUsersModal;
