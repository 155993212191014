import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";

import ProgressBar from "components/ProgressBar";
import Header from "components/Header";
import { pages } from "./PageData";
import SingleInputTemplate from "./templates/SingleInputTemplate";
import MultipleInputTemplate from "./templates/MultipleInputTemplate";
import CheckboxInputTemplate from "./templates/CheckboxInputTemplate";
import WelcomePage from "./pages/WelcomePage";
import GeoLocationsPage from "./pages/GeoLocationsPage";
import TargetTitlesPage from "./pages/TargetTitlesPage";
import MarketingMaterialsPage from "./pages/MarketingMaterialsPage";
import PerfectCustomersPage from "./pages/PerfectCustomersPage";
import CompanyWebsiteLoader from "./pages/CompanyWebsiteLoader";
import * as settingsActions from "redux/Settings/actions";
import { setNewUserToOld } from "redux/User/actions";
import { sendBoardingSummary } from "redux/Settings/service";
import { PATH_DASHBOARD, PATH_PRODUCT_OPTIONS, PAYWALL_LINK } from "utils/constants";
import NavButtons from "components/NavButtons/NavButtons";
import "./onboarding.css";
import env from "config/site.config";

const OnboardingForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);

    const [currentPage, setCurrentPage] = useState(0);
    const [progress, setProgress] = useState(0);
    const [responseObjToSend, setResponseObjToSend] = useState([]);

    useEffect(() => {
        console.log(`%cOnboarding Page`, "background-color: blue; color");
        dispatch(settingsActions.getSettings());
    }, []);

    const handleNavigateToPaywall = () => {
        const checkoutId = localStorage.getItem("checkoutId");
        if (user?.active_subscription) {
            navigate(PATH_DASHBOARD);
            return;
        }
        if (checkoutId) {
            navigate(PATH_DASHBOARD);
            return;
        }
        navigate(PATH_PRODUCT_OPTIONS);
    };

    useEffect(() => {
        if (user.is_new_user) {
            return;
        }
        // if user came from paywall, return
        const cameFromPaywall = localStorage.getItem("cameFromPaywall");
        if (cameFromPaywall) {
            return;
        }
        if (!user.active_subscription) {
            handleNavigateToPaywall();
            return;
        }
    }, [user]);

    useEffect(() => {
        setProgress(((currentPage + 1) * 100) / pages.length - 1);
    }, [currentPage]);

    const nextPage = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const previousPage = () => {
        setCurrentPage((prev) => prev - 1);
    };

    const handleNavClick = async (action = "", response_obj_array = "", update_type = "") => {
        // This is where you send the HTTP request to the server
        console.log("current page", currentPage);
        console.log("action:", action, " / response", response_obj_array);
        if (currentPage === 0) return nextPage();
        if (action === "previous") {
            if (currentPage === 6) {
                return setCurrentPage(4);
            }
            return previousPage();
        }
        if (action === "next") {
            if (response_obj_array[0].value && response_obj_array[0].value.length > 0) {
                setResponseObjToSend([...responseObjToSend, ...response_obj_array]);
                if (update_type === "single") {
                    dispatch(settingsActions.updateSingleSetting(response_obj_array));
                } else if (update_type === "array") {
                    dispatch(settingsActions.updateArraySetting(response_obj_array));
                }
                console.log(responseObjToSend);
            }
        }
        if (action === "skip_onboarding") {
            sendBoardingSummary(responseObjToSend);
            dispatch(setNewUserToOld());
            if (!user.active_subscription) {
                handleNavigateToPaywall();
            } else {
                navigate(`${PATH_DASHBOARD}?onboarding=complete`);
            }
        }

        // Check if its the last page and if so, redirect to dashboard
        if (currentPage + 1 === pages.length) {
            // send request to server to update onboarding_complete
            dispatch(settingsActions.updateSingleSetting([{ key: "onboarding_complete", value: 1 }]));
            sendBoardingSummary(responseObjToSend);
            // update local storage to remove is_new_user flag
            dispatch(setNewUserToOld());
            if (!user.active_subscription) {
                handleNavigateToPaywall();
            } else {
                navigate(`${PATH_DASHBOARD}?onboarding=complete`);
            }

            return;
        } else {
            nextPage();
        }
    };

    const get_current_page = (currentPage) => {
        const page = pages[currentPage];
        switch (page.template) {
            case "WelcomePage":
                return (
                    <WelcomePage
                        key={currentPage}
                        inputHeader={page.inputHeader}
                        subheaderText={page.subheaderText}
                        handleNavClick={handleNavClick}
                    />
                );
            case "SingleInputTemplate":
                return (
                    <SingleInputTemplate
                        key={currentPage}
                        inputHeader={page.inputHeader}
                        inputKey={page.inputKey}
                        subheaderText={page.subheaderText}
                        handleNavClick={handleNavClick}
                        autocomplete={page.autocomplete}
                        textarea={page.textarea}
                        placeholder={page.placeholder}
                    />
                );
            case "MultipleInputTemplate":
                return (
                    <MultipleInputTemplate
                        key={currentPage}
                        inputHeader={page.inputHeader}
                        inputKey={page.inputKey}
                        subheaderText={page.subheaderText}
                        addText={page.addText}
                        input_limit={page.input_limit}
                        placeholder={page.placeholder}
                        handleNavClick={handleNavClick}
                        autocomplete={page.autocomplete}
                        min_blanks={page.min_blanks}
                    />
                );
            case "CheckboxInputTemplate":
                return (
                    <CheckboxInputTemplate
                        key={currentPage}
                        inputHeader={page.inputHeader}
                        inputKey={page.inputKey}
                        options={page.options}
                        customOptions={page.custom_options}
                        customInputLimit={page.customInputLimit}
                        placeholder={page.placeholder}
                        checkboxWidth={page.checkboxWidth}
                        columns={page.columns}
                        handleNavClick={handleNavClick}
                    />
                );
            case "GeolocationTemplate":
                return (
                    <GeoLocationsPage
                        key={currentPage}
                        options={page.options}
                        checkboxWidth={page.checkboxWidth}
                        columns={page.columns}
                        handleNavClick={handleNavClick}
                    />
                );
            case "TargetTitlesPage":
                return <TargetTitlesPage key={currentPage} input_limit={page.input_limit} handleNavClick={handleNavClick} />;
            case "MarketingMaterialsPage":
                return <MarketingMaterialsPage key={currentPage} handleNavClick={handleNavClick} />;
            case "PerfectCustomersPage":
                return (
                    <PerfectCustomersPage
                        key={currentPage}
                        inputHeader={page.inputHeader}
                        inputKey={page.inputKey}
                        subheaderText={page.subheaderText}
                        addText={page.addText}
                        input_limit={page.input_limit}
                        placeholder={page.placeholder}
                        handleNavClick={handleNavClick}
                        autocomplete={page.autocomplete}
                        min_blanks={page.min_blanks}
                    />
                );
            case "CompanyWebsiteLoader":
                return <CompanyWebsiteLoader key={currentPage} handleNavClick={handleNavClick} />;
            default:
                return <div>Page not found</div>;
        }
    };

    return (
        <>
            <LoadingBar color="#5F2A94" progress={progress} height={3} transitionTime={100} onLoaderFinished={() => setProgress(0)} />
            <Header logoOnly={true} />
            <div className="page-container">
                <div className="page-header-onboarding">
                    <div className="progress-bar-container">
                        <div className="page-header-progress">
                            {currentPage !== 0 && currentPage !== pages.length && currentPage !== 5 && (
                                <div className="page-header-progress-bar">
                                    <ProgressBar label={pages[currentPage].progressBarText} progress={pages[currentPage].progressBarFill} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/*  {visiblePages} */}

                <div className="flex justify-center items-start mt-14 h-3/5">{get_current_page(currentPage)}</div>
            </div>
        </>
    );
};
export default OnboardingForm;
