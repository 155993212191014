import axios from "axios";
import env from "config/site.config";

const log = require("loglevel").getLogger("FileService");
log.setLevel("error");

export const uploadFileToS3 = async (file_data, upload_url) => {
    log.debug("FileService -> uploadFileToS3 -> Enter");

    const myHeaders = new Headers({ "Content-Type": file_data.type });

    return fetch(upload_url, {
        method: "PUT",
        headers: myHeaders,
        body: file_data,
    })
        .then(async (response) => {
            const res = response.data;
            log.debug("FileService -> uploadFileToS3 -> response: " + JSON.stringify(res));
            return res;
        })
        .catch((response) => {
            log.debug("FileService -> uploadFileToS3 -> error: ");
            log.debug(response);
            return null;
        });
};

export const getPresignedUploadUrl = async (fileName, fileType) => {
    log.debug("S3Service -> getPresignedUploadUrl -> Enter");

    const headers = new Headers({ "Content-Type": "application/json" });
    const options = {
        headers: headers,
        data: { fileName, fileType },
    };
    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/s3/presignedurl/upload`, options)
        .then(async (response) => {
            const res = response.data;
            log.debug("S3Service -> getPresignedUploadUrl -> response: " + JSON.stringify(res));
            return res.signedUrl;
        })
        .catch((response) => {
            log.debug("S3Service -> getPresignedUploadUrl -> error: ");
            log.debug(response);
            return null;
        });
};

export const getPresignedDownloadUrl = async (fileName) => {
    log.debug("S3Service -> getPresignedDownloadUrl -> Enter");
    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/s3/presignedurl/download`, { data: { fileName } })
        .then(async (response) => {
            const res = response.data;
            log.debug("S3Service -> getPresignedDownloadUrl -> response: " + JSON.stringify(res));
            return res.signedUrl;
        })
        .catch((response) => {
            log.debug("S3Service -> getPresignedDownloadUrl -> error: ");
            log.debug(response);
            return null;
        });
};

export const deleteFileFromS3 = async (fileName) => {
    log.debug("S3Service -> deleteFileFromS3 -> Enter");
    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/s3/deleteUserFile`, { data: { fileName } })
        .then(async (response) => {
            const res = response.data;
            log.debug("S3Service -> deleteFileFromS3 -> response: " + JSON.stringify(res));
            return res;
        })
        .catch((response) => {
            log.debug("S3Service -> deleteFileFromS3 -> error: ");
            log.debug(response);
            return null;
        });
};

/* export const listUserFilesFromS3 = async () => {
	log.debug("S3Service -> listUserFilesFromS3 -> Enter");
	return axios.get(`${env.REACT_APP_BACKEND_URL}/s3/listUserFiles`)
		.then(async response => {
			const res = response.data;
			log.debug("S3Service -> listUserFilesFromS3 -> response: " + JSON.stringify(res));
			return res.files;
		})
		.catch(response => {
			log.debug("S3Service -> listUserFilesFromS3 -> error: ");
			log.debug(response);
			return null;
		})
}
 */
