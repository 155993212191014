import React from "react";

import "./Toggle.css";

const Toggle = ({ id, name, value, update_checkboxes, checked, inputKey }) => {
    const handleClick = () => {
        update_checkboxes(name);
    };
    return (
        <div>
            <label className="switch">
                <input type="checkbox" id={id} name={name} value={value} onClick={() => handleClick()} checked={checked} />
                <span className="slider round"></span>
            </label>
        </div>
    );
};

export default Toggle;
