import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/Button/Button";
import { FormInput } from "components/Form/Form";
import { checkboxes } from "./SettingsData";
import * as settingsActions from "redux/Settings/actions";
import * as fileActions from "redux/File/actions";
import Toggle from "components/Toggle";
import { formatDate, formatTime, formatBytes, formatInputLabel, formatInputValue } from "utils/utils";
import { FileUploader } from "react-drag-drop-files";
import { ALLOWED_FILE_TYPES, PATH_ACCOUNT_SETTING } from "utils/constants";
import "./Settings.css";
import { useNavigate } from "react-router-dom";
import { PATH_ONBOARDING_PAGE } from "utils/constants";

export default function SettingsPage() {
    const settings = useSelector((state) => state.settings);
    const fileData = useSelector((state) => state.file.files);
    const [userData, setUserData] = useState(settings);
    const [files, setFiles] = useState([]);
    const [isLoadingSettings, setIsLoadingSettings] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [uploadErrorMsg, setUploadErrorMsg] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Load settings when page loads
    useEffect(() => {
        dispatch(settingsActions.getSettings()).then(() => {
            setIsLoadingSettings(false);
        });
    }, []);

    // when settings in redux store updates, set userData to the new store values
    useEffect(() => {
        setUserData(settings);
        console.log("filedata changed", fileData);
        setFiles(fileData);
        console.log({ userData });
    }, [settings, fileData]);

    const changePage = () => {
        let page = currentPage === 1 ? 2 : 1;
        setCurrentPage(page);
    };

    const update_checkboxes = (name) => {
        let newTrackingTargets;
        // Check if the name is in the tracking_targets array
        if (userData.tracking_targets.includes(name)) {
            // If it is, remove it
            newTrackingTargets = userData.tracking_targets.filter((target) => target !== name);
        } else {
            // If it isn't, add it
            newTrackingTargets = [...userData.tracking_targets, name];
        }
        setUserData({
            ...userData,
            tracking_targets: newTrackingTargets,
        });
    };

    const update_field_value = (value, key) => {
        setUserData({
            ...userData,
            [key]: value,
        });
    };

    const handleUploadFile = async (file) => {
        try {
            const file_data = Array.from(file);
            console.log("file_data", file_data);
            let new_signedUploadUrl = await fileActions.getPresignedUploadUrl(file_data[0].name, file_data[0].type);
            // PUT file to S3
            dispatch(fileActions.uploadFileToS3(file_data[0], new_signedUploadUrl));

            let new_file_obj = {
                Key: file_data[0].name,
                LastModified: new Date(),
                Size: file_data[0].size,
            };
            setFiles([...files, new_file_obj]);
        } catch (error) {
            console.log(error);
            setUploadErrorMsg("Error uploading file");
        }
    };

    const handleSaveSettings = () => {
        let single_entry = ["full_name", "company_name", "work_email", "company_website", "product_description", "target_persona_description"];
        // convert all strings to arrays
        let data = [];
        // check fields that changed by comparing settings and userData
        for (let key in userData) {
            if (userData[key] !== settings[key]) {
                data.push({
                    key: key,
                    value: single_entry.includes(key) ? userData[key] : userData[key].split(","),
                });
            }
        }
        console.log("dto", data);

        // dispatch data
        dispatch(settingsActions.updateMultipleSettings(data));
    };

    const handleDownloadFile = async (file_name) => {
        console.log("handleDownloadFile", file_name);
        try {
            let signedDownloadUrl = await fileActions.getPresignedDownloadUrl(file_name);
            window.open(signedDownloadUrl);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteFile = async (file_name) => {
        console.log("handleDeleteFile", file_name);
        // prompt user to confirm delete
        if (window.confirm("Are you sure you want to delete this file?")) {
            try {
                dispatch(fileActions.deleteFileFromS3(file_name));
                setFiles(files.filter((file) => file.Key !== file_name));
            } catch (error) {
                console.log(error);
            }
        } else {
            return;
        }
    };

    if (isLoadingSettings) {
        return (
            <div className="loading">
                <div className="loading__spinner"></div>
            </div>
        );
    }

    return (
        <div className="main-content">
            <div className="header-container">
                <div className="page-title">Settings</div>
            </div>

            <div className="fields-container">
                <div className={currentPage === 1 ? "edit-field-container" : "edit-field-container-2"}>
                    {Object.entries(userData).map(([key, value], index) => {
                        if (((currentPage === 1 && index <= 14) || (currentPage === 2 && index > 14)) && key !== "tracking_targets") {
                            if (key === "marketing_materials") {
                                return (
                                    <>
                                        <div className="upload-file-container" key={index}>
                                            <label className="samplead-caption" htmlFor="file">
                                                Marketing Materials
                                            </label>
                                            <FileUploader
                                                multiple={true}
                                                handleChange={handleUploadFile}
                                                name="file"
                                                types={ALLOWED_FILE_TYPES}
                                                label="Click to browse or drag and drop your files"
                                                classes="file-uploader-box-dark"
                                                // children={file_upload_box_child}
                                            />
                                            <div className="input-error-msg">
                                                {uploadErrorMsg && <div className="error-msg">{uploadErrorMsg}</div>}
                                            </div>
                                            <div className="file-list-container">
                                                {files.map((file_data, file_index) => {
                                                    return (
                                                        <div className="uploaded_file_item" key={file_index}>
                                                            <span
                                                                className="uploaded_file_item__name"
                                                                onClick={() => handleDownloadFile(file_data.Key)}
                                                            >
                                                                {file_data.Key} ({formatBytes(file_data.Size)})
                                                            </span>
                                                            <span className="uploaded_date_text">
                                                                &nbsp; &nbsp; &nbsp;{formatDate(new Date(file_data.LastModified), true)}{" "}
                                                                {formatTime(new Date(file_data.LastModified))}
                                                                <span className="delete-file-button" onClick={() => handleDeleteFile(file_data.Key)}>
                                                                    X
                                                                </span>
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </>
                                );
                            } else {
                                return (
                                    <FormInput
                                        key={index}
                                        type="text"
                                        inputId={key}
                                        title={formatInputLabel(key)}
                                        inputValue={formatInputValue(value)}
                                        onChange={(value) => update_field_value(value, key)}
                                    />
                                );
                            }
                        } else {
                            return null;
                        }
                    })}
                </div>

                {currentPage === 2 && (
                    <div className="checkbox-container">
                        <div className="checkbox-header-wrapper">
                            <p className="checkbox-header-wrapper-text">What would you like us to track?</p>
                        </div>
                        <div className="checkbox-input-container">
                            {checkboxes.map((checkbox_name, index) => {
                                return (
                                    <div className="checkbox-input-wrapper" key={index}>
                                        <Toggle
                                            id={checkbox_name}
                                            name={checkbox_name}
                                            value={userData.tracking_targets.includes(checkbox_name)}
                                            update_checkboxes={update_checkboxes}
                                            inputKey="tracking_targets"
                                            checked={userData.tracking_targets.includes(checkbox_name)}
                                        />
                                        <label htmlFor={checkbox_name}>{checkbox_name}</label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            <div className="pagify-container">
                <p>
                    Page {currentPage} of 2
                    <span onClick={() => changePage()}>
                        &nbsp;&nbsp;
                        {currentPage === 1 && <span className="pagify-arrows">&gt;</span>} &nbsp;
                        {currentPage === 2 && <span className="pagify-arrows">&lt;</span>}
                    </span>
                </p>
            </div>
            <div className="settings-button-container">
                <div className="flex" style={{ gap: "10px" }}>
                    <Button variant="primary" onClick={() => handleSaveSettings()} children="Save settings" />
                    <Button variant="link" onClick={() => navigate(PATH_ACCOUNT_SETTING)} children="Account settings" />
                </div>
                <Button variant="secondary" onClick={() => navigate(PATH_ONBOARDING_PAGE)} children="Onboarding Menu" />
            </div>
        </div>
    );
}
