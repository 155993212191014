import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";

import { PATH_HOME } from "utils/constants";
import Button, { BUTTON_VARIANT } from "components/Button/Button";
import sampleadLogo from "assets/images/logo.png";
import outreachFullLogo from "assets/images/integrations/outreach-integration-image.png";
import outreachLogo from "assets/images/integrations/outreach-logo.png";
import infoPhoto from "assets/images/integrations/outreach-info-photo.png";
import { SideArrow } from "components/DropdownMenu";
import env from "config/site.config";
import { ClipLoader } from "react-spinners";

const IntegrateOutreach = () => {
    const [installed, setInstalled] = useState(false);
    const [confirmPopUp, setConfirmPopUp] = useState(false);
    const [uninstallPopUp, setUninstallPopUp] = useState(false);
    const user = useSelector((state) => state.user.user);

    useEffect(() => {
        const access_token = localStorage.getItem("outreach_access_token");
        const refresh_token = localStorage.getItem("outreach_refresh_token");
        const access_token_expire = localStorage.getItem("outreach_access_token_expire");

        if (!access_token || !refresh_token || !access_token_expire) {
            setInstalled(false);
        }
    }, []);

    useEffect(() => {
        const getIntegrations = async () => {
            try {
                const url = `${env.REACT_APP_BACKEND_URL}/settings/get_integrations`;
                const { data } = await axios.post(url, user);
                if (!data.message && data.settings) {
                    // outreach integration is 0 or 1
                    if (data.settings.outreach_integration) {
                        setInstalled(true);
                    } else {
                        setInstalled(false);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        getIntegrations();
    }, []);

    const handleInstallClick = () => {
        setConfirmPopUp(true);
    };

    useEffect(() => {
        const access_token = localStorage.getItem("outreach_access_token");

        if (access_token) {
            setInstalled(true);
        }
    }, []);

    function timeConverter(UNIX_timestamp) {
        if (!UNIX_timestamp) return;
        var a = new Date(UNIX_timestamp * 1000);
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var time = date + " " + month + " " + year + " " + hour + ":" + min;
        return time;
    }

    const checkRefreshTokenExpire = () => {
        const refresh_token_expire = localStorage.getItem("outreach_access_token_expire") + 7 * 24 * 60 * 60; //two weeks

        console.log(refresh_token_expire);
        console.log(Math.floor(Date.now() / 1000));
        if (refresh_token_expire < Math.floor(Date.now() / 1000)) {
            return true;
            //expired or null
        } else {
            return false;
        }
    };

    return (
        <div className="all-integrations-content-container">
            {confirmPopUp && <IntegrateOutreachConfirm setConfirmPopUp={setConfirmPopUp} installed={installed} />}
            <div className="integrations-container">
                <Link to={PATH_HOME} style={{ textDecoration: "none" }} className="samplead-body--bold">
                    {`< Back to Home`}
                </Link>
                <div className="integrations-header">
                    <img src={outreachLogo} alt="Outreach" className="integrations-image" />
                    <h1 style={{ fontWeight: "700", fontSize: "40px" }}>Outreach</h1>
                </div>
            </div>

            <div className="integrations-main-content-container">
                <div className="integrations-main-content">
                    <p>
                        Generate more pipeline and win more deals with less effort. The Outreach Sales Execution Platform helps you grow sales,
                        consolidate tools, and lower sales execution risk.
                    </p>
                    <img src={infoPhoto} alt="info" />
                    <p>
                        Installing this FREE integration will add an option to export your approved prospects and messages directly in to your
                        outreach.io sequence
                    </p>
                </div>
                <div className="integrations-side-actions">
                    <button
                        className={installed && checkRefreshTokenExpire() ? "failed-install-button" : installed ? "installed" : "install-button"}
                        onClick={handleInstallClick}
                    >
                        {installed && checkRefreshTokenExpire() ? "Try again" : installed ? "Connected" : "Connect"}
                    </button>
                    <ul>
                        <li>
                            {installed && (
                                <h4>
                                    Installed by {user.email} on {timeConverter(localStorage.getItem("outreach_access_token_expire"))}
                                </h4>
                            )}
                            {installed && checkRefreshTokenExpire() ? (
                                <span>
                                    <span>
                                        <div className="info-box">
                                            <InfoSVG />
                                            <p>
                                                We failed to authenticate your Outreach – Please{" "}
                                                <a className="link-to-outreach" href="https://accounts.outreach.io/users/sign_in">
                                                    click Here
                                                </a>{" "}
                                                to sign in to your Outreach account and then try connecting again.
                                            </p>
                                        </div>
                                    </span>
                                    <div className="external-button-wrapper">
                                        <button onClick={handleInstallClick} className="external-app-settings-button">
                                            External App Settings
                                        </button>
                                        <SideArrow />
                                    </div>
                                </span>
                            ) : (
                                <p>Next: Confirm authorization and complete installation</p>
                            )}
                        </li>
                        <li>
                            <p>Categories</p>
                            <p>
                                <label>Sales enablement</label>
                            </p>
                        </li>
                        <li>
                            <p>Developers links</p>
                            <div>Samplead & outreach</div>
                        </li>
                    </ul>
                    {installed && (
                        <div className="uninstall-container">
                            <Button onClick={() => setUninstallPopUp(true)} variant={BUTTON_VARIANT.SECONDARY} className="uninstall">
                                Disconnect
                            </Button>
                            <p>Requires confirmation</p>
                            {uninstallPopUp && <UninstallModal setUninstallPopUp={setUninstallPopUp} user={user} />}
                        </div>
                    )}
                    {installed && !checkRefreshTokenExpire() && <OutreachMailboxSelect />}
                </div>
            </div>
        </div>
    );
};

const IntegrateOutreachConfirm = ({ setConfirmPopUp, installed }) => {
    const [externalPopup, setExternalPopup] = useState(null);
    const [error, setError] = useState("");

    const user = useSelector((state) => state.user.user);

    console.log(installed);

    useEffect(() => {
        if (!externalPopup) {
            return;
        }

        const timer = setInterval(() => {
            if (!externalPopup) {
                timer && clearInterval(timer);
                return;
            }
            const currentUrl = externalPopup.location.href;
            if (!currentUrl) {
                return;
            }
            const searchParams = new URL(currentUrl).searchParams;
            const code = searchParams.get("code");
            if (code) {
                console.log("got code");
                getAuthToken(code);
                changeSettingsToOutreachInstalled();
                externalPopup.close();
            } else {
                console.log("no code");
            }
        }, 50);
    }, [externalPopup]);

    const changeSettingsToOutreachInstalled = async () => {
        try {
            const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/settings/update/single`, [
                {
                    key: "outreach_integration",
                    value: 1,
                    user: user,
                },
            ]);
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleOutsideClick = () => {
        setConfirmPopUp(false);
    };

    const getAuthToken = async (code) => {
        try {
            const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/outreach/token`, { code });
            console.log(data);
            if (data.access_token) {
                const new_access_token_expire = data.created_at + data.expires_in;
                localStorage.setItem("outreach_access_token", data.access_token);
                localStorage.setItem("outreach_refresh_token", data.refresh_token);
                localStorage.setItem("outreach_access_token_expire", new_access_token_expire);
                window.location.reload(false);
            } else {
                setError("Something went wrong. Please try again.");
                console.log(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const connectClick = (e) => {
        const left = window.screenX + (window.outerWidth - 500) / 2;
        const top = window.screenY + (window.outerHeight - 400) / 2.5;
        const title = `WINDOW TITLE`;
        const url = env.REACT_APP_OUTREACH_AUTH_URL;
        const popup = window.open(url, title, `popup=${"true"},width=${500},height=${400},left=${left},top=${top}`);
        setExternalPopup(popup);
    };

    return (
        <>
            <div className="confirm-popup-outside" onClick={handleOutsideClick}></div>
            <div className="pop-up">
                <div className="joined-logos">
                    <img src={sampleadLogo} alt="Samplead" className="samplead-image" />
                    <p>+</p>
                    <img src={outreachFullLogo} alt="Outreach" className="integrations-image" />
                </div>
                <div className="info-container">
                    <h4>Thank you for adding Outreach to Samplead</h4>
                    <p>
                        Your users can now export Samplead's personalized messages directly to <strong>Outreach</strong> sequences.
                    </p>
                    <h4>This integration will give Samplead access to the following Outreach data:</h4>
                </div>
                <ul className="access-point-list">
                    {["Add custom field", "Bulk create", "Bulk import", "Choose uploaded prospects", "Assign prospect to sequence"].map(
                        (accessPoint) => {
                            return (
                                <li className="access-point" key={accessPoint}>
                                    <CheckmarkSVG />
                                    <p>{accessPoint}</p>
                                </li>
                            );
                        }
                    )}
                    <div className="buttons">
                        <button onClick={connectClick} className={installed ? "retry-integration" : "install-btn"}>
                            {installed ? "Try again" : "Confirm"}
                        </button>
                        <button onClick={() => setConfirmPopUp(false)} className="cancel-btn">
                            Cancel
                        </button>
                    </div>
                    {error !== "" && <p className="error">{error}</p>}
                </ul>
            </div>
        </>
    );
};

const CheckmarkSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
                d="M7.00016 13.6667C3.31816 13.6667 0.333496 10.682 0.333496 7C0.333496 3.318 3.31816 0.333332 7.00016 0.333332C10.6822 0.333332 13.6668 3.318 13.6668 7C13.6668 10.682 10.6822 13.6667 7.00016 13.6667ZM7.00016 12.3333C8.41465 12.3333 9.7712 11.7714 10.7714 10.7712C11.7716 9.77104 12.3335 8.41449 12.3335 7C12.3335 5.58551 11.7716 4.22896 10.7714 3.22876C9.7712 2.22857 8.41465 1.66667 7.00016 1.66667C5.58567 1.66667 4.22912 2.22857 3.22893 3.22876C2.22873 4.22896 1.66683 5.58551 1.66683 7C1.66683 8.41449 2.22873 9.77104 3.22893 10.7712C4.22912 11.7714 5.58567 12.3333 7.00016 12.3333ZM6.3355 9.66667L3.50683 6.838L4.4495 5.89533L6.3355 7.78133L10.1062 4.01L11.0495 4.95266L6.3355 9.66667Z"
                fill="#2ED47A"
            />
            <path
                d="M7.00016 13.6667C3.31816 13.6667 0.333496 10.682 0.333496 7C0.333496 3.318 3.31816 0.333332 7.00016 0.333332C10.6822 0.333332 13.6668 3.318 13.6668 7C13.6668 10.682 10.6822 13.6667 7.00016 13.6667ZM7.00016 12.3333C8.41465 12.3333 9.7712 11.7714 10.7714 10.7712C11.7716 9.77104 12.3335 8.41449 12.3335 7C12.3335 5.58551 11.7716 4.22896 10.7714 3.22876C9.7712 2.22857 8.41465 1.66667 7.00016 1.66667C5.58567 1.66667 4.22912 2.22857 3.22893 3.22876C2.22873 4.22896 1.66683 5.58551 1.66683 7C1.66683 8.41449 2.22873 9.77104 3.22893 10.7712C4.22912 11.7714 5.58567 12.3333 7.00016 12.3333ZM6.3355 9.66667L3.50683 6.838L4.4495 5.89533L6.3355 7.78133L10.1062 4.01L11.0495 4.95266L6.3355 9.66667Z"
                fill="#007D39"
            />
        </svg>
    );
};
const InfoSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM9 5H11V7H9V5ZM9 9H11V15H9V9Z"
                fill="#7A7A7A"
            />
        </svg>
    );
};

const UninstallModal = ({ user, setUninstallPopUp }) => {
    const uninstallClick = async () => {
        try {
            const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/settings/update/single`, [
                {
                    key: "outreach_integration",
                    value: 0,
                    user: user,
                },
            ]);
            console.log(data);
            if (data.status === "ok") {
                localStorage.removeItem("outreach_access_token");
                localStorage.removeItem("outreach_refresh_token");
                localStorage.removeItem("outreach_access_token_expire");
            }
        } catch (error) {
            console.log(error);
        }
        setUninstallPopUp(false);
        window.location.reload(false);
    };

    return (
        <>
            <div className="confirm-popup-outside"></div>
            <div className="pop-up">
                <div className="joined-logos">
                    <img src={sampleadLogo} alt="Samplead" className="samplead-image" />
                    <p>+</p>
                    <img src={outreachFullLogo} alt="Outreach" className="integrations-image" />
                </div>
                <div className="info-container">
                    <h4>This integration currently permits access to the following Outreach data:</h4>
                </div>
                <ul className="access-point-list">
                    {["Add custom field", "Bulk create", "Bulk import", "Choose uploaded prospects", "Assign prospect to sequence"].map(
                        (accessPoint) => {
                            return (
                                <li className="access-point" key={accessPoint}>
                                    <CheckmarkSVG />
                                    <p>{accessPoint}</p>
                                </li>
                            );
                        }
                    )}
                    <div className="buttons">
                        <button className="uninstall-button" onClick={uninstallClick}>
                            Confirm uninstall
                        </button>
                        <button onClick={() => setUninstallPopUp(false)} className="cancel-btn">
                            Cancel
                        </button>
                    </div>
                </ul>
            </div>
        </>
    );
};

const OutreachMailboxSelect = () => {
    const [outreachMailboxes, setOutreachMailboxes] = useState([]);
    const [selectedMailbox, setSelectedMailbox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mailboxUpdated, setMailboxUpdated] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setLoading(true);
        getOutreachMailboxes();
    }, []);

    useEffect(() => {
        if (selectedMailbox) {
            console.log(selectedMailbox);
            updateChosenMailbox(selectedMailbox);
        }
    }, [selectedMailbox]);

    const getOutreachMailboxes = async () => {
        try {
            const accessToken = localStorage.getItem("outreach_access_token");
            if (!accessToken) return;
            const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/outreach/mailboxes`, {
                accessToken: accessToken,
            });
            console.log(data.data);
            if (data.data.message) {
                setError(true);
                setLoading(false);
                return;
            } else {
                console.log(data);
                setError(false);
                setOutreachMailboxes(data.data);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setError(true);
            setLoading(false);
        }
    };

    const updateChosenMailbox = async (selectedMailbox) => {
        try {
            setLoading(true);
            const { data } = await axios.post(`${env.REACT_APP_BACKEND_URL}/settings/update/single`, [
                {
                    key: "outreach_mailbox",
                    value: selectedMailbox,
                    user: localStorage.getItem("user_id"),
                },
            ]);
            console.log(data);
            if (data.status === "ok") {
                setMailboxUpdated(true);
                setLoading(false);
            } else {
                setMailboxUpdated(false);
                setError(true);
                setLoading(false);
            }
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    };

    return (
        <div className="integrations-mailbox-select-container">
            <h4>Select your Outreach mailbox</h4>
            <div className="select-container">
                <select
                    className="integrations-mailbox-select"
                    name="integrations-mailbox"
                    id="integrations-mailbox"
                    value={selectedMailbox}
                    onChange={(e) => setSelectedMailbox(e.target.value)}
                >
                    <option value="" disabled>
                        Select your Outreach mailbox
                    </option>
                    {outreachMailboxes &&
                        outreachMailboxes.map((mailbox) => {
                            return (
                                <option value={mailbox.id} key={mailbox.id}>
                                    {mailbox.attributes.email}
                                </option>
                            );
                        })}
                </select>
                {loading && <ClipLoader color="#a239ff" height={20} width={20} />}
            </div>
            {error && <p className="error-message">Something went wrong. Please try again.</p>}
            {mailboxUpdated && !loading && <p className="success-message">Mailbox updated successfully.</p>}
        </div>
    );
};

export default IntegrateOutreach;
