export const pages = [
    {
        template: "WelcomePage",
        inputHeader: "Welcome!",
        subheaderText: "",
    },
    {
        template: "SingleInputTemplate",
        inputHeader: "Full Name",
        inputKey: "full_name",
        progressBarText: "Personal Info 1/5",
        progressBarFill: 25,
    },
    {
        template: "SingleInputTemplate",
        inputHeader: "Company Name",
        inputKey: "company_name",
        progressBarText: "Personal Info 2/5",
        progressBarFill: 50,
        autocomplete: true,
    },
    {
        template: "SingleInputTemplate",
        inputHeader: "Work Email",
        inputKey: "work_email",
        progressBarText: "Personal Info 3/5",
        progressBarFill: 75,
    },
    {
        template: "SingleInputTemplate",
        inputHeader: "Company Website",
        inputKey: "company_website",
        progressBarText: "Personal Info 4/5",
        progressBarFill: 95,
    },
    {
        template: "CompanyWebsiteLoader",
    },
    {
        template: "SingleInputTemplate",
        inputHeader: "Product description",
        inputKey: "product_description",
        subheaderText: "Describe what you are selling.",
        progressBarText: "Personal Info 5/5",
        progressBarFill: 100,
        textarea: true,
        placeholder: "For example: 'We are selling a SaaS product that...'",
    },
    {
        // Targets to track
        template: "CheckboxInputTemplate",
        inputHeader: "Let's start with the fun part - what would you like us to track?",
        inputKey: "tracking_targets",
        custom_options: false,
        options: [
            "Job change",
            "News articles",
            "Linkedin groups",
            "Linkedin posts",
            "Linkedin events",
            "Medium activity",
            "Twitter tweets",
            "Funding event",
        ],
        checkboxWidth: "close",
        columns: 3,
        progressBarText: "Target 1/8",
        progressBarFill: 12,
    },
    {
        // geolocations
        template: "GeolocationTemplate",
        options: ["Africa", "Asia", "Europe", "North America", "APAC", "DACH", "MENA", "South America", "APJ", "EMEA", "Nordics", "All"],
        progressBarText: "Target 2/8",
        checkboxWidth: "wide",
        columns: 4,
        progressBarFill: 25,
    },
    {
        // companyHeadcount
        template: "CheckboxInputTemplate",
        inputHeader: "Company headcount of your target audience",
        inputKey: "target_company_headcount",
        custom_options: false,
        options: ["Self employed", "51-200", "1001-5000", "1-10", "201-500", "5001-10000", "11-50", "501-1000", "10000+"],
        checkboxWidth: "medium",
        columns: 3,
        progressBarText: "Target 3/8",
        progressBarFill: 37,
    },

    {
        // targetIndustries
        template: "CheckboxInputTemplate",
        inputHeader: "Industries you aim to target",
        inputKey: "target_industries",
        custom_options: true,
        customInputLimit: 5,
        placeholder: "Industry",
        options: ["Cyber Security", "Fintech", "B2B Hardware", "Medical", "Internet", "Marketing", "B2B SAAS", "B2B Software", "E-Commerce"],
        checkboxWidth: "medium",
        columns: 3,
        progressBarText: "Target 4/8",
        progressBarFill: 50,
    },

    {
        // targetTitles
        template: "TargetTitlesPage",
        inputHeader: "Target titles",
        inputKey: "target_titles",
        subheaderText: "For example: VP of Technology",
        addText: "+ add another target title",
        input_limit: 10,
        placeholder: "Title",
        progressBarText: "Target 5/8",
        progressBarFill: 62,
    },

    // seniorityLevel
    {
        template: "CheckboxInputTemplate",
        inputHeader: "Seniority Level",
        inputKey: "seniority_level",
        custom_options: false,
        options: ["Owner", "VP", "Senior", "Partner", "Director", "Entry", "CXO", "Manager", "Training"],
        checkboxWidth: "medium",
        columns: 3,
        progressBarText: "Target 6/8",
        progressBarFill: 75,
    },

    // functionOfTargetRole
    {
        template: "CheckboxInputTemplate",
        inputHeader: "Function (Role of the person you are targeting)",
        inputKey: "target_role_function",
        custom_options: false,
        options: [
            "Finance",
            "Engineering",
            "Media and Communication",
            "Entrepreneurship",
            "Marketing",
            "Operations",
            "Program Management",
            "Business Development",
            "Product",
            "Sales",
            "Project Management",
            "Information Technology",
            "Purchasing",
            "Education",
            "Human resources",
            "Arts and Design",
        ],
        checkboxWidth: "wide",
        columns: 4,
        progressBarText: "Target 7/8",
        progressBarFill: 87,
    },
    // targetPersonaDescription
    {
        template: "SingleInputTemplate",
        inputHeader: "Target persona description",
        inputKey: "target_persona_description",
        progressBarText: "Target 8/8",
        progressBarFill: 100,
        textarea: true,
        placeholder: 'For example: "We are targeting HR decision makers in the United States that manage employee experience..."',
    },

    {
        // competitors
        template: "MultipleInputTemplate",
        inputHeader: "Competitors",
        inputKey: "competitors",
        subheaderText: "List 3 competitors (Linkedin page URL)",
        addText: "+ add another competitor",
        input_limit: 10,
        placeholder: "Competitor",
        progressBarText: "Past Experience 1/8",
        progressBarFill: 12,
        autocomplete: true,
        min_blanks: 3,
    },
    {
        // linkedinProfiles
        template: "MultipleInputTemplate",
        inputHeader: "Linkedin profiles",
        inputKey: "linkedin_profiles",
        subheaderText: "Link to LinkedIn profile of existing customers who fit target audience",
        addText: "+ add another link",
        input_limit: 6,
        placeholder: "Link",
        progressBarText: "Past Experience 2/8",
        progressBarFill: 25,
        min_blanks: 3,
    },
    {
        // linkedinGroups
        template: "MultipleInputTemplate",
        inputHeader: "Linkedin groups",
        inputKey: "linkedin_groups",
        subheaderText: "",
        addText: "+ add another group",
        input_limit: 6,
        placeholder: "Link to group",
        progressBarText: "Past Experience 3/8",
        progressBarFill: 37,
        min_blanks: 3,
    },
    {
        // perfectCustomers
        template: "PerfectCustomersPage",
        inputHeader: '"Perfect" customers',
        inputKey: "perfect_customers",
        subheaderText: 'List 3 companies you define as your "perfect" customers',
        addText: "+ add another company",
        input_limit: 10,
        placeholder: "Company",
        progressBarText: "Past Experience 4/8",
        progressBarFill: 50,
        min_blanks: 3,
    },
    {
        // keyWords
        template: "MultipleInputTemplate",
        inputHeader: "Key words",
        inputKey: "keywords",
        subheaderText: "Your product's top 5 key words",
        addText: "+ add another keyword",
        input_limit: 5,
        placeholder: "Keyword",
        progressBarText: "Past Experience 5/8",
        progressBarFill: 62,
        min_blanks: 5,
    },
    {
        // influencers
        template: "MultipleInputTemplate",
        inputHeader: "Influencers",
        inputKey: "influencers",
        subheaderText: "List 3 influencers in your field",
        addText: "+ add another influencer",
        input_limit: 5,
        placeholder: "Influencer",
        progressBarText: "Past Experience 6/8",
        progressBarFill: 75,
        min_blanks: 3,
    },
    {
        // hashtags
        template: "MultipleInputTemplate",
        inputHeader: "#",
        inputKey: "hashtags",
        subheaderText: "Provide 5 related #hashtags for your product",
        addText: "+ add another hashtag",
        input_limit: 5,
        placeholder: "Hashtag",
        progressBarText: "Past Experience 7/8",
        progressBarFill: 88,
        autocomplete: true,
        min_blanks: 5,
    },
    {
        // marketingMaterials: file upload
        template: "MarketingMaterialsPage",
        progressBarText: "Past Experience 8/8",
        progressBarFill: 100,
    },
];
