import React from "react";
import { Route, Routes } from "react-router-dom";

import ErrorBoundary from "utils/ErrorBoundary";
import { NewCampaign } from "./NewCampaign";
import { CampaignContent } from "./CampaignContent";
import "./Campaign.css";

function Campaign() {
    return (
        <ErrorBoundary>
            <div className={`campaigns__container no-tabs`}>
                <Routes>
                    <Route path="new" element={<NewCampaign />} />
                    <Route path=":id" element={<CampaignContent />} />
                </Routes>
            </div>
        </ErrorBoundary>
    );
}

export default Campaign;
