import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as campaignApis from "redux/Campaign/api";
import { CAMPAIGN_TYPE, PATH_DASHBOARD } from "utils/constants";
import CampaignHeader from "../inner-components/CampaignHeader";
import { CampaignProgressBar } from "../inner-components/CampaignProgressBar";
import Modal from "components/Modal";
import ManualDiscoveryForm from "components/ManualDiscoveryForm";
import CreateCampaignButton from "../CreateCampaignButton/CreateCampaignButton";
import { BUTTON_VARIANT } from "components/Button/Button";
import ConfirmationBox from "components/ConfirmationBox";
import { updateCampaignLeads } from "redux/Campaign/api";
import * as campaignActions from "../../../redux/Campaign/reducer";
import { dispatch } from "d3";

export const CreateManualDiscoveryCampaign = ({ type, onCampaignCreate, onCampaignUpdate, version, newCampaignTitle }) => {
    const [isUploadCsvDialogOpen, setIsUploadCsvDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            <div>
                <CreateCampaignButton
                    version={version}
                    type={type === CAMPAIGN_TYPE.AUTO_DISCOVERY ? CAMPAIGN_TYPE.AUTO_DISCOVERY : CAMPAIGN_TYPE.MANUAL_DISCOVERY}
                    onClick={
                        CAMPAIGN_TYPE.AUTO_DISCOVERY
                            ? () =>
                                  dispatch(
                                      campaignActions.createDiscoveryCampaign({
                                          name: newCampaignTitle,
                                          callback: (result) => {
                                              if (result.status === "created") {
                                                  navigate(PATH_DASHBOARD);
                                              } else if (result.status === "exists") {
                                                  console.log(result.campaignId, "exists");
                                              }
                                          },
                                      })
                                  )
                            : () => setIsUploadCsvDialogOpen(true)
                    }
                />
                <Modal isModalOpen={isUploadCsvDialogOpen} closeModal={() => setIsUploadCsvDialogOpen(false)}>
                    <ManualDiscoveryForm onCampaignCreate={onCampaignCreate} onCampaignUpdate={onCampaignUpdate} maximized={false} />
                </Modal>
            </div>
        </>
    );
};

const CreateCampaignButtons = ({ type, isNewCampaign, onCampaignCreate, onCampaignUpdate, newCampaignTitle }) => {
    const [isUploadCsvDialogOpen, setIsUploadCsvDialogOpen] = useState(false);

    if (isNewCampaign) {
        return (
            <main className={"campaigns__main_empty no-tabs"}>
                <div
                    className="campaigns__main-content no-tabs"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            borderRight: "1px solid #d1d1d1",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <CreateManualDiscoveryCampaign
                            type={CAMPAIGN_TYPE.MANUAL_DISCOVERY}
                            onCampaignCreate={onCampaignCreate}
                            maximized={false}
                            newCampaignTitle={newCampaignTitle}
                        />
                    </div>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <CreateManualDiscoveryCampaign
                            type={CAMPAIGN_TYPE.AUTO_DISCOVERY}
                            onCampaignCreate={onCampaignCreate}
                            maximized={false}
                            newCampaignTitle={newCampaignTitle}
                        />
                    </div>
                </div>
            </main>
        );
    } else {
        return (
            <main className={"campaigns__main_empty no-tabs"}>
                <div className="campaigns__main-content no-tabs">
                    <p className={"text-center break-line samplead-body"}></p>
                    {type === CAMPAIGN_TYPE.AUTO_DISCOVERY ? (
                        <CreateManualDiscoveryCampaign
                            onCampaignUpdate={onCampaignUpdate}
                            type={CAMPAIGN_TYPE.AUTO_DISCOVERY}
                            onCampaignCreate={() => onCampaignCreate(CAMPAIGN_TYPE.AUTO_DISCOVERY)}
                        />
                    ) : (
                        <CreateManualDiscoveryCampaign
                            onCampaignUpdate={onCampaignUpdate}
                            onCampaignCreate={(file) => onCampaignCreate(CAMPAIGN_TYPE.MANUAL_DISCOVERY, file)}
                        />
                    )}
                </div>
            </main>
        );
    }
};

export const NewCampaign = ({ type, version }) => {
    const [showDiscoveryCampaignAlreadyExist, setShowDiscoveryCampaignAlreadyExist] = useState(false);
    const [uploadErrorMessage, setUploadErrorMessage] = useState("");
    const [uploadErrorAction, setUploadErrorAction] = useState(null);
    const [discoveryCampaignId, setDiscoveryCampaignId] = useState(null);
    const navigate = useNavigate();
    const isNewCampaign = useLocation().pathname.includes("new");
    const csvLengthForProgress = useSelector((state) => state.campaign.csvLengthForProgress);
    const [newCampaignTitle, setNewCampaignTitle] = useState("Untitled Campaign");

    const [campaign, setCampaign] = useState({});
    const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);

    const onTitleUpdate = (title) => {
        setCampaign((x) => ({ ...x, title }));
        console.log(title);
        setNewCampaignTitle(title);
    };

    const handleCampaignCreation = async (type, dataFiltered) => {
        console.log(dataFiltered);
        setIsCreatingCampaign(true);
        if (type === CAMPAIGN_TYPE.AUTO_DISCOVERY) {
            await campaignApis.createDiscoveryCampaign(campaign.title, createDiscoveryCampaignCallback);
        } else {
            await campaignApis.createManualCampaign(campaign.title, dataFiltered, manualCampaignCreated);
        }
    };

    const handleCampaignUpdate = async (campaignId, dataFiltered) => {
        setIsCreatingCampaign(true);
        // not using the updateCampaignCallback function because it is not working
        const result = await updateCampaignLeads(campaignId, dataFiltered, () => {});
        // reloading instead of navigating to the campaign id because the campaign id is already in the url
        console.log(result);
        if (result.status === "ok") {
            window.location.reload();
        } else {
            setUploadErrorMessage(result.message);
            setUploadErrorAction(null);
        }
        // window.location.reload();
    };

    const updateCampaignCallback = (result) => {
        console.log(result);
        setIsCreatingCampaign(false);
        if (result.status === "ok") {
            navigate(`../${result.campaignId}`);
        } else {
            setShowDiscoveryCampaignAlreadyExist(true);
            setDiscoveryCampaignId(result.campaignId);
        }
    };

    const createDiscoveryCampaignCallback = (result) => {
        setIsCreatingCampaign(false);
        if (result.status === "created") {
            navigate(`../${result.campaignId}`);
        } else {
            setShowDiscoveryCampaignAlreadyExist(true);
            setDiscoveryCampaignId(result.campaignId);
        }
    };

    const goToExistingCampaign = () => {
        setShowDiscoveryCampaignAlreadyExist(false);
        navigate(`../${discoveryCampaignId}`);
    };

    const manualCampaignCreated = (result) => {
        console.log(result);
        switch (result.status) {
            case "failed":
                setUploadErrorMessage(result.message);
                setUploadErrorAction(null);
                break;
            case "partial":
                setUploadErrorMessage(result.message);
                setUploadErrorAction({ type: "navigate", newCampaignId: result.newCampaignId });
                break;
            case "ok":
                navigate(`../${result.newCampaignId}`);
                break;
            default:
                break;
        }

        setIsCreatingCampaign(false);
    };

    const onApproveError = () => {
        if (uploadErrorAction && uploadErrorAction.type === "navigate") {
            navigate(`../${uploadErrorAction.newCampaignId}`);
        }
        setUploadErrorAction(null);
        setUploadErrorMessage("");
    };

    if (isCreatingCampaign) {
        return (
            <CampaignProgressBar
                progress={csvLengthForProgress || 0}
                uploadErrorMessage={uploadErrorMessage}
                setUploadErrorMessage={setUploadErrorMessage}
                setIsCreatingCampaign={setIsCreatingCampaign}
            />
        );
    }

    if (version === "upload more") {
        return (
            <>
                <CreateManualDiscoveryCampaign version={version} onCampaignUpdate={handleCampaignUpdate} />
            </>
        );
    } else {
        return (
            <>
                <ConfirmationBox
                    visible={uploadErrorMessage.length > 0}
                    onClose={() => setUploadErrorMessage("")}
                    title="Upload Limit Exceeded"
                    buttons={[{ title: "Ok", type: BUTTON_VARIANT.PRIMARY, action: onApproveError }]}
                    message={uploadErrorMessage}
                />
                {isNewCampaign && <CampaignHeader campaign={campaign} onTitleUpdate={onTitleUpdate} />}
                <CreateCampaignButtons
                    type={type}
                    isNewCampaign={isNewCampaign}
                    onCampaignCreate={handleCampaignCreation}
                    onCampaignUpdate={handleCampaignUpdate}
                    newCampaignTitle={newCampaignTitle}
                />
                <ConfirmationBox
                    visible={showDiscoveryCampaignAlreadyExist}
                    onClose={() => setShowDiscoveryCampaignAlreadyExist(false)}
                    title="Campaign Already Exist"
                    buttons={[
                        { title: "Cancel", type: BUTTON_VARIANT.PRIMARY_WHITE, action: () => setShowDiscoveryCampaignAlreadyExist(false) },
                        { title: "Yes", type: BUTTON_VARIANT.PRIMARY, action: goToExistingCampaign },
                    ]}
                    message={`Discovery campaign already exist on this account.\nWould you like to view it?`}
                />
            </>
        );
    }
};
