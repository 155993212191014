const useStyles = () => ({
    root: {
        position: "relative",
        paddingTop: "80px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        rowGap: "20px",
        width: "80%",
    },
    sequenceStep: {
        background: "#FFFFFF",
        border: "1px solid #7A7A7A",
        borderRadius: "6px",
        padding: "10px",
        width: "100%",
        height: "100%",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        whiteSpace: "pre-wrap",
        resize: "none",
        overflow: "hidden",
    },
    sequenceStepTitle: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "24px",
        lineHeight: "38px",
    },
    sequenceStepSubjects: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "20px",
        fontFeatureSettings: "'tnum' on, 'lnum' on",
        width: "100%",

        color: "#000000",
    },
});

export default useStyles;
