export const logger = (txt) => {
    if (process.env.NODE_ENV === "development") {
        console.log(txt);
    }
};

export const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        let char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
};

export const formatDate = (date, pad) => {
    try {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        if (pad) {
            if (day < 10) day = "0" + day;
            if (month < 10) month = "0" + month;
        }
        return `${day}/${month}/${year}`;
    } catch (e) {
        console.log(e);
    }
};

export const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;

    return `${hours}:${minutes}`;
};

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const formatInputLabel = (label) => {
    return label
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

export const formatInputValue = (value) => {
    if (value === null) {
        return "";
    }
    if (Array.isArray(value)) {
        return value.filter((el) => el !== "").join(", ");
    }
    return value;
};
