import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BarLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

import Button, { BUTTON_VARIANT } from "components/Button/Button";
import Icon from "components/Icon/Icon";
import ExportConfirmation from "components/ExportConfirmation";
import { LeadStatus } from "utils/constants";
import { LeadList } from "../../inner-components/LeadList";
import {
    createAccounts,
    createProspects,
    getAccounts,
    getAccountsByName,
    getIntegrationSettings,
    getProspectsByEmail,
    getSequences,
    pushProspects,
    updateProspectsMessage,
    handleSalesforceRefreshToken,
    exportToZapier,
} from "./integrationFunctions";
import DropdownMenu from "components/DropdownMenu";
import Modal from "components/Modal";
import { NewCampaign } from "../../NewCampaign";
import "./ActiveLeads.css";
import { setNoOutreachMailboxes } from "redux/NoOutreachMailbox/reducer";
import * as expandiActions from "redux/Expandi/reducer";
import { toggleSnackbarOpen } from "redux/Snackbar/actions";
import * as campaignActions from "redux/Campaign/reducer";
import axios from "axios";
import env from "config/site.config";
import { CampaignTitle } from "routes/Campaign/inner-components/CampaignTitle";

export const EmptyActiveLeadList = ({ type, leads, isActiveExported }) => {
    console.log(isActiveExported);
    return (
        <main className={`campaigns__main empty-tab`}>
            <div className={`campaigns__main-content empty-tab`}>
                {leads.length === 0 && !isActiveExported ? (
                    <NewCampaign type={type} />
                ) : (
                    <main className={`campaigns__main empty-tab`}>
                        <div className={`campaigns__main-content empty-tab`}>
                            <Icon type="activity-group" />
                            <p className="samplead-body text-center">Leads that you have downloaded/exported will appear here.</p>
                        </div>
                    </main>
                )}
            </div>
        </main>
    );
};

export const ActiveLeads = ({
    campaign,
    relevantLeads,
    onUpdate,
    onRephrase,
    onChangeStatus,
    onToggleRows,
    onExport,
    onRemove,
    onEditMessage,
    onExpandCollapse,
    isActiveExported,
    isLoadingCampaign,
    expandedRows,
    setExpandedRows,
    removedLeads,
    filterByCampaignId,
}) => {
    const [specificExports, setSpecificExports] = useState([]);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const leads = relevantLeads ? relevantLeads : [];
    const expandedLeadIds = useSelector((state) => state.campaign.expandedLeadIds);
    const expandCollapseAction = expandedLeadIds.length === leads.length ? "Collapse All" : "Expand All";
    const [exportOptionsMenu, setExportOptionsMenu] = useState(false);
    // outreach integration
    const [outreachSequences, setOutreachSequences] = useState([]);
    const [outreachSpinner, setOutreachSpinner] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [outreachError, setOutreachError] = useState("");
    const [leadsFilter, setLeadsFilter] = useState("all");
    const [leadsToExport, setLeadsToExport] = useState(leads);
    const dispatch = useDispatch();

    const usersCampaigns = useSelector((state) =>
        state.campaign.campaigns
            .map((c) => {
                if (c.type === "auto-discovery" && c.id !== "all") return c;
            })
            .filter((c) => c)
    );

    useEffect(() => {
        const outreach_access_token = localStorage.getItem("outreach_access_token");
        if (outreach_access_token) {
            getOutreachSequences(outreach_access_token);
        }
        console.log(leads);
        console.log(relevantLeads);
    }, []);

    const getOutreachSequences = async (accessToken) => {
        try {
            if (accessToken) {
                setOutreachSpinner(true);
                const accounts = await getAccounts(accessToken);
                console.log(accounts);
                if ("data" in accounts) {
                    setAccounts(accounts.data);
                } else {
                    setOutreachError("Cant Access Accounts");
                }

                const sequences = await getSequences(accessToken);
                console.log(sequences);
                if ("data" in sequences) {
                    setOutreachSequences(sequences.data.filter((seq) => seq.attributes.enabled === true));
                    // setOutreachSequences(sequences.data);
                    setOutreachSpinner(false);
                } else {
                    setOutreachSpinner(false);
                    setOutreachError("Cant Access Sequences");
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const rephraseLeadMessage = (lead) => {
        onRephrase(lead);
    };

    const removeLead = (lead) => {
        onChangeStatus(lead, LeadStatus.Removed);
    };

    const editLeadMessage = (lead) => {
        onEditMessage(lead);
    };

    const hasEmail = leads.filter((l) => l.email || l.mail).length > 0;
    const hasPhone = leads.filter((l) => l.phone).length > 0;

    const handleListDownload = (leads) => {
        const leadIds = specificExports.length > 0 ? specificExports.map((lead) => lead.id) : leads.map((lead) => lead.id);
        onExport(leadIds);

        setIsExportModalOpen(false);
    };

    const handleActiveLeadsFiltering = (value) => {
        setLeadsFilter(value);
    };

    return (
        <>
            <NoMailboxModal />
            {leads.length > 0 && (
                <div className="campaigns__main-top">
                    <div className="campaigns__main-top-options">
                        {campaign.type === "auto-discovery" && campaign?.id !== "all" ? (
                            <span>
                                <select onChange={(e) => handleActiveLeadsFiltering(e.target.value)}>
                                    <option value="all">All</option>
                                    <option value="your list">Your List</option>
                                    <option value="suggested">Suggested</option>
                                </select>
                            </span>
                        ) : null}
                        {campaign?.id === "all" && (
                            <select onChange={(e) => filterByCampaignId(e.target.value)}>
                                <option value="all">All</option>
                                {usersCampaigns?.map((campaign) => (
                                    <option value={campaign.id}>{campaign.title}</option>
                                ))}
                            </select>
                        )}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                flexDirection: "row-reverse",
                                gap: "10px",
                                position: "relative",
                            }}
                        >
                            <Button
                                className="export-button"
                                variant={BUTTON_VARIANT.PRIMARY}
                                //  onClick={() => setIsExportModalOpen(true)}
                                onClick={() => {
                                    setExportOptionsMenu((prevState) => !prevState);
                                }}
                            >
                                {specificExports.length ? `Export Selected (${specificExports.length})` : "Export"}
                            </Button>
                            {specificExports.length > 0 && (
                                <Button
                                    variant={BUTTON_VARIANT.DANGER}
                                    onClick={() => {
                                        setSpecificExports([]);
                                        onRemove(specificExports);
                                    }}
                                >
                                    {removedLeads ? "Restore" : "Delete"} {`(${specificExports.length})`}
                                </Button>
                            )}
                            {exportOptionsMenu && (
                                <ExportedOptionsMenu
                                    campaign={campaign}
                                    leads={specificExports.length ? specificExports : leads}
                                    outreachSpinner={outreachSpinner}
                                    setIsExportModalOpen={setIsExportModalOpen}
                                    outreachSequences={outreachSequences}
                                    accounts={accounts}
                                    onExport={onExport}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}

            {isLoadingCampaign ? (
                <div
                    className="campaigns__main-content"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "10px",
                        width: "1200px",
                        height: "100%",
                    }}
                >
                    <div className="spinner">
                        <BarLoader color="#a239ff" />
                    </div>
                </div>
            ) : (
                <LeadList
                    campaignId={campaign.id}
                    leads={leadsFilter === "all" ? leads : leads.filter((lead) => lead.origin === leadsFilter)}
                    isActiveExported={isActiveExported}
                    type={campaign.type}
                    onRephraseLead={rephraseLeadMessage}
                    onRemoveLead={removeLead}
                    onExpandCollapse={onExpandCollapse}
                    onEditLeadMessage={editLeadMessage}
                    emptyListComponent={EmptyActiveLeadList}
                    setSpecificExports={setSpecificExports}
                    specificExports={specificExports}
                    isLoadingCampaign={isLoadingCampaign}
                    expandedRows={expandedRows}
                    setExpandedRows={setExpandedRows}
                />
            )}

            <ExportConfirmation
                visible={isExportModalOpen}
                onClose={() => setIsExportModalOpen(false)}
                campaignName={campaign.title}
                leads={specificExports.length > 0 ? specificExports : leads}
                onDownload={handleListDownload}
            />
        </>
    );
};

export const ExportedOptionsMenu = ({
    setIsExportModalOpen,
    outreachSequences,
    setOutreachSequences,
    outreachSpinner,
    leads,
    accounts,
    onExport,
    campaign,
}) => {
    const [showPushToSequenceModal, setShowPushToSequenceModal] = useState(false);
    const [chosenSequence, setChosenSequence] = useState({});
    const outreachIntegration = useSelector((state) => state.settings.outreach_integration);
    const outreachMailbox = useSelector((state) => state.settings.outreach_mailbox);

    const dispatch = useDispatch();

    const handleSelectedOption = (optionLabel) => {
        if (optionLabel === "CSV") {
            setIsExportModalOpen(true);
            console.log("CSV");
        }
    };

    const handleSelectedSequence = (sequence) => {
        console.log("outreachMailbox", outreachMailbox);
        console.log("outreachIntegration", outreachIntegration);
        if (outreachIntegration && !outreachMailbox) {
            dispatch(setNoOutreachMailboxes(true));
        }
        console.log(sequence);
        setChosenSequence(sequence);
        setShowPushToSequenceModal(true);
    };

    const handleSelectedExpandiCampaign = (expandi_campaign_id, expandi_user_id, whitelabel_flag, callback) => {
        console.log("expandi_campaign_id", expandi_campaign_id);
        console.log("expandi_user_id", expandi_user_id);
        dispatch(
            expandiActions.exportCampaignLeads({
                expandi_campaign_id,
                expandi_user_id,
                leadIds: leads.map((lead) => lead.id),
                leads: leads.map((lead) => {
                    console.log("lead", lead);
                    return {
                        profile_link: lead?.url,
                        first_name: lead?.name?.split(" ")[0],
                        last_name: lead?.name?.split(" ")?.slice(1)?.join(" ") || "",
                        company_name: lead?.user_data?.company_name,
                        email: lead?.email,
                        "Tailored Message": lead?.message,
                        "Tailored Message 2": lead?.message_2,
                        campaign_id: lead?.campaignId,
                    };
                }),
                whitelabel_flag,
                campaignId: campaign.id,
            })
        ).then(() => {
            callback();
        });
    };

    const handleSalesforceExport = async (campaignId = undefined) => {
        const refreshToken = localStorage.getItem("salesforce_refresh_token");
        await handleSalesforceRefreshToken(refreshToken);
        const accessToken = localStorage.getItem("salesforce_access_token");

        if (!accessToken) {
            dispatch(toggleSnackbarOpen("Please connect your Salesforce account first", "error"));
            return;
        }

        function getNumberOfEmployees(company_size_on_linkedin) {
            // it can be a string like "20256 employees" or number like 20256
            if (typeof company_size_on_linkedin === "string") {
                return +company_size_on_linkedin?.split(" ")[0] || 0;
            }
            return company_size_on_linkedin;
        }

        const salesforceAccounts = {};
        const salesforceContacts = {};
        leads.forEach((lead, i) => {
            const company_name = lead?.user_data?.company_name;
            if (!(company_name in salesforceAccounts)) {
                salesforceAccounts[company_name] = {
                    Name: company_name,
                    Source__c: "LI recommendations",
                    Account_Status__c: "Outreach - Gold",
                    NumberOfEmployees: getNumberOfEmployees(lead?.user_data?.company_data?.company_size_on_linkedin),
                    Company_Linkedin__c: lead?.user_data?.company_url,
                    Website: lead?.user_data?.company_data?.website,
                    Industry: lead?.user_data?.company_data?.industry,
                };
                if (campaignId) salesforceAccounts[company_name].First_Campaign_Name__c = campaignId;
            }
            if (!company_name) return;

            const first_name = lead?.name?.split(" ")[0];
            const last_name = lead?.name?.split(" ")?.slice(1)?.join(" ") || "";

            if (!(lead.id in salesforceContacts)) {
                salesforceContacts[lead.id] = {
                    id: lead.id,
                    company: company_name,
                    message: lead?.message,
                    Lead_source_name__c: "LI recommendations",
                    UTM_Source__c: "LI recommendations",
                    FirstName: first_name,
                    LastName: last_name,
                    Phone: lead?.phone,
                    Title: lead?.user_data?.title,
                    Linkedin_Profile_URL__c: lead?.url,
                    Private_Email__c: lead?.email,
                };
                if (campaignId) salesforceContacts[lead.id].First_Campaign_Name__c = campaignId;
            }
        });

        const response = await axios.post(
            `${env.REACT_APP_BACKEND_URL}/salesforce/push_accounts`,
            {
                accounts: Object.values(salesforceAccounts),
                contacts: Object.values(salesforceContacts),
                salesforce_access_token: accessToken,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        console.log("response", response);
        console.log("response?.data?.success", response?.data?.success);
        if (response?.data?.success) {
            dispatch(toggleSnackbarOpen({ message: "All leads exported successfully" }));
        } else {
            console.log("message", response?.data?.message);
            dispatch(
                toggleSnackbarOpen({
                    message: response?.data?.message,
                    variant: "error",
                    timeout: -1,
                })
            );
        }
    };

    const handleZapierExport = async () => {
        const data = await exportToZapier(leads);
        console.log("data", data);
        if (data?.success) {
            console.log("campaign.id", campaign.id);
            dispatch(campaignActions.setCampaignUpdated());
            dispatch(toggleSnackbarOpen({ message: "All leads exported successfully" }));
        } else {
            dispatch(
                toggleSnackbarOpen({
                    message: data?.message,
                    variant: "error",
                    timeout: -1,
                })
            );
        }
    };

    return (
        <div className="exported-options-popup">
            <DropdownMenu
                handleSelectedOption={handleSelectedOption}
                outreachSequences={outreachSequences}
                setOutreachSequences={setOutreachSequences}
                outreachSpinner={outreachSpinner}
                handleSelectedSequence={handleSelectedSequence}
                handleSelectedExpandiCampaign={handleSelectedExpandiCampaign}
                handleSalesforceExport={handleSalesforceExport}
                handleZapierExport={handleZapierExport}
            />
            <PushToSequenceModal
                sequence={chosenSequence}
                leads={leads}
                accounts={accounts}
                showPushToSequenceModal={showPushToSequenceModal}
                setShowPushToSequenceModal={setShowPushToSequenceModal}
                onExport={onExport}
            />
        </div>
    );
};

const PushToSequenceModal = ({ sequence, accounts, leads, showPushToSequenceModal, setShowPushToSequenceModal, onExport }) => {
    const [progress, setProgress] = useState("Preparing Export");
    const [error, setError] = useState("");
    const [mailboxID, setMailboxID] = useState(0);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (sequence !== undefined) {
            PushToSequence();
        }
    }, [sequence]);

    const PushToSequence = async () => {
        setError("");

        const accessToken = localStorage.getItem("outreach_access_token");
        updateProgress([], "Preparing Export");
        if (!accessToken) {
            setError("Session Expired");
            return;
        }
        //wait for all messages to be generated
        const stillGenerating = leads.filter((lead) => (lead && lead.messageStatus === 2) || lead.messageStatus === 1);

        if (stillGenerating.length > 0) {
            setError("Please wait for all messages to be generated and then try again");
            return;
        }

        const validLeads = leads.filter((lead) => lead && lead.user_data && lead.title !== "");
        // dont export leads with no email

        const leadsWithEmail = validLeads.filter(
            (lead) =>
                lead &&
                lead.email &&
                lead.email.match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
        );

        if (leadsWithEmail.length === 0) {
            setError(`Leads with no email and cannot be exported to Outreach, please add an email to these leads and try again`);
            return;
        }

        try {
            console.log("getting integration settings", user);
            const settingObj = await getIntegrationSettings(user);
            if (settingObj.settings) {
                const mailboxID = settingObj.settings.outreach_mailbox;
                console.log("mailboxID", mailboxID);
                setMailboxID(mailboxID);
            }
        } catch (error) {
            console.log(error);
            setError("Error getting integration settings");
            return;
        }
        console.log(validLeads);

        const leadsWithCompanyName = leadsWithEmail.filter((lead) => lead && lead.user_data.company_name);

        const getExistingAccounts = await getAccountsByName(accessToken, leadsWithCompanyName);

        updateProgress(getExistingAccounts, "Managing Accounts");

        const existingAccounts = getExistingAccounts.map((res, i) => {
            if (res.data) {
                return { account: res.data[0], lead: leadsWithCompanyName[i] };
            }
            return null;
        });
        const companyNameButNoAccount = existingAccounts.filter((res) => res && !res.account);
        const companyNameButNoAccountLeads = companyNameButNoAccount.map((res) => res.lead);

        const existingAccountsFiltered = existingAccounts.filter((res) => res && res.account);

        const createdAccounts = await createAccounts(accessToken, companyNameButNoAccountLeads);

        const createdAccountsFiltered = createdAccounts.map((res, i) => {
            return { account: res.data, lead: companyNameButNoAccountLeads[i] };
        });

        updateProgress(createdAccountsFiltered, "Setting up your Accounts");

        const AllAccountLeadObjects = [...existingAccountsFiltered, ...createdAccountsFiltered];

        const leadsExtractedAccountIDs = AllAccountLeadObjects.map((res, i) => {
            return { accountID: res.account.id, lead: res.lead };
        });
        console.log(leadsExtractedAccountIDs);

        updateProgress(createdAccounts, "Creating Prospects");

        console.log(leadsExtractedAccountIDs);

        const prospects = await createProspects(accessToken, leadsExtractedAccountIDs);

        const receivedProspectsByEmails = await getProspectsByEmail(accessToken, AllAccountLeadObjects);

        updateProgress(receivedProspectsByEmails, "Getting New Prospects");

        const prospectsToUpdate = receivedProspectsByEmails.map((res, i) => {
            if (res.data) {
                const prospect = res.data[0];
                if (prospect && prospect.attributes && prospect.attributes.emails) {
                    const matchingLead = leads.find((lead) => lead.email === prospect.attributes.emails[0]);

                    return { prospectID: prospect.id, message: matchingLead.message, leadID: matchingLead.id };
                }
            }
            return null;
        });

        const filteredProspectsToUpdate = prospectsToUpdate.filter((res) => res && res.message);

        console.log(prospectsToUpdate);
        console.log(filteredProspectsToUpdate);

        updateProgress(filteredProspectsToUpdate, "Updating Prospects");

        const updatedProspects = await updateProspectsMessage(accessToken, filteredProspectsToUpdate);

        console.log(updatedProspects);

        const successProspects = updatedProspects.filter((res) => res && res.data);

        updateProgress(successProspects, "Adding Prospects to Sequence");

        console.log(sequence.id);
        if (sequence.id) {
            const prospectsAddedToSequence = await pushProspects(accessToken, sequence.id, successProspects, mailboxID);

            updateProgress(
                prospectsAddedToSequence,
                "Confirming",
                `${
                    prospects.length - prospectsAddedToSequence.length > 0
                        ? `,${prospects.length - prospectsAddedToSequence.length} Failed because they didn't have an email or Something went Wrong.`
                        : ""
                }`
            );

            console.log(prospectsAddedToSequence);

            updateProgress(
                prospectsAddedToSequence,
                `${prospectsAddedToSequence.length} were Successfully Added to Sequence ${
                    prospects.length - prospectsAddedToSequence.length > 0
                        ? `,${prospects.length - prospectsAddedToSequence.length} Failed because they didn't have an email or Something went Wrong.`
                        : ""
                }`
            );

            exportSucceededLeads(filteredProspectsToUpdate, prospectsAddedToSequence);
        } else {
            console.log("no sequence id");
        }
    };

    const updateProgress = (progress, phrase, extra) => {
        setProgress(phrase);

        if (phrase === "Confirming") {
            const alreadyInSequence = progress.filter((res) => res && res.message);

            if (alreadyInSequence.length > 0) {
                setError(
                    `${alreadyInSequence.length} are already in the Sequence and their Tailored message have been updated, ${
                        progress.length - alreadyInSequence.length
                    } were Added` + extra
                );
            }
        }
    };

    const closeModal = () => {
        setShowPushToSequenceModal(false);
    };

    const exportSucceededLeads = (successProspects, sequenceStateOfAddedToSequence) => {
        //find matching leadID with succeeded prospect
        const succeededLeads = sequenceStateOfAddedToSequence.map((res, i) => {
            console.log(res);
            if (res && res.data && !res.message) {
                const matchingLead = successProspects.find((prospect) => prospect.prospectID === res.data.relationships.prospect.data.id);
                if (matchingLead && matchingLead.leadID) {
                    return matchingLead.leadID;
                }
            }
            return null;
        });

        const filteredSucceededLeads = succeededLeads.filter((res) => res);

        onExport(filteredSucceededLeads);
    };

    return (
        <div className="push-to-sequence-modal">
            {setShowPushToSequenceModal && (
                <Modal isModalOpen={showPushToSequenceModal}>
                    <div className={progress.includes("Successfully Added") ? "export-confirmation" : "export-before"}>
                        {/* <div className="export-confirmation"> */}
                        <div className="export-confirmation__content">
                            <h2 className={"samplead-body--large"}>Export to {"attributes" in sequence ? sequence.attributes.name : "Sequence"}</h2>
                            <p className={"samplead-body text-center break-line"}>{error === "" ? progress : error}</p>
                            {!error && <div className="spinner">{!progress.includes("Successfully Added") && <BarLoader color="#a239ff" />}</div>}
                            <Button onClick={() => closeModal()} variant={BUTTON_VARIANT.PRIMARY}>
                                {progress.includes("Successfully Added") ? "Ok!" : "Cancel"}
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

const NoMailboxModal = ({ setShowNoMailboxModal }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const noMailbox = useSelector((state) => state.noMailbox.noOutreachMailboxes);

    return (
        <div className="no-mailbox-modal">
            <Modal isModalOpen={noMailbox}>
                <div className="no-mailbox__content">
                    <h2 className={"samplead-body--large"}>No Mailbox</h2>
                    <p className={"samplead-body text-center break-line text-row"}>
                        You need to connect a Mailbox to your account to export to <p style={{ color: "#5952ff" }}> Outreach</p>.
                    </p>
                    <Button onClick={() => navigate("/integrations/outreach")} variant={BUTTON_VARIANT.LINK}>
                        Click here to connect a Mailbox.
                    </Button>
                    <Button onClick={() => dispatch(setNoOutreachMailboxes(false))} variant={BUTTON_VARIANT.PRIMARY}>
                        Ok!
                    </Button>
                </div>
            </Modal>
        </div>
    );
};
