export const checkboxes = [
    "Job change",
    "News articles",
    "LinkedIn groups",
    "Linkedin posts",
    "Linkedin events",
    "Medium activity",
    "Twitter tweets",
    "Funding event",
];
