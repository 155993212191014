import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ReactGA from "react-ga4";

import Header from "components/Header";
import Sidebar from "components/Sidebar";
import AppRoutes from "./routes/AppRoutes";
import Snackbar from "components/Snackbar";
import { logger } from "utils/utils";
import { getStorageItem } from "utils/storageUtils";
import { handleOutreachRefreshToken, handleSalesforceRefreshToken } from "./routes/Campaign/CampaignContent/tabs/integrationFunctions";
import * as userActions from "redux/User/actions";
import * as settingsActions from "redux/Settings/actions";
import { PATH_ONBOARDING_PAGE, PATH_PRODUCT_OPTIONS } from "utils/constants";
import "./App.css";
import env from "./config/site.config";

const AppLayout = ({ children }) => {
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const location = useLocation();
    axios.interceptors.response.use(
        (response) => {
            console.log("Axios interceptors");
            return response;
        },
        async (err) => {
            console.log("Axios error: ", err);
            dispatch(userActions.signOut());
            return Promise.reject(err);
        }
    );

    /* Added class for main container */
    let app_container_class = location.pathname === PATH_ONBOARDING_PAGE ? "onboarding__container" : "app__container";

    return (
        <>
            <Header />
            {user && <Sidebar side="left" />}
            <div className={app_container_class}>{children}</div>
            <Snackbar />
        </>
    );
};

const App = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    ReactGA.initialize(env.REACT_APP_GOOGLE_ANALYTICS_ID);

    useEffect(() => {
        ReactGA.send("pageview");

        if (env.REACT_APP_ENV_NAME !== "production") {
            document.title = `${document.title} - ${env.REACT_APP_ENV_NAME}`;
        }
    }, []);

    logger("App is Up");

    useEffect(() => {
        if (!user) {
            const storageUser = getStorageItem(env.REACT_APP_STORAGE_SIGNED_USER_KEY, true);
            const storageAdminUser = getStorageItem(env.REACT_APP_STORAGE_SIGNED_ADMIN_KEY, true);

            if (storageUser?.active_subscription || storageUser?.admin) {
                dispatch(userActions.setLoggedInUser(storageUser, storageAdminUser));
            }
        } else {
            // check if user has expired token, time stamp of expired, and refresh token
            const outreachRefreshToken = localStorage.getItem("outreach_refresh_token");
            const salesforceRefreshToken = localStorage.getItem("salesforce_refresh_token");

            outreachRefreshToken && handleOutreachRefreshToken(outreachRefreshToken);
            salesforceRefreshToken && handleSalesforceRefreshToken(salesforceRefreshToken);
            dispatch(settingsActions.getSettings());
        }
    }, [user]);

    useEffect(() => {
        const checkoutId = localStorage.getItem("checkoutId");
        if (checkoutId) {
            if (user && !user?.active_subscription && !user?.is_new_user) {
                reProcessCheckout(checkoutId);
            }
        }
    }, [user]);

    const reProcessCheckout = async (checkoutId) => {
        try {
            const response = await axios.post(`${env.REACT_APP_BACKEND_URL}/stripe/validate_checkout_session`, {
                session_id: checkoutId,
            });
            if (response.status === 200) {
                dispatch(userActions.setUserSubscribed());
                localStorage.removeItem("checkoutId");
            }
        } catch (error) {
            console.log("Error validating checkout session: ", error);
        }
    };

    return (
        <Router>
            <AppLayout>
                <AppRoutes />
            </AppLayout>
        </Router>
    );
};

export default App;
