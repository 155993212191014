import * as actionTypes from "./actionTypes";

const log = require("loglevel").getLogger("fileReducer");
log.setLevel("debug");

/* file obj: {
    Key
    LastModified
    Size
    DownloadUrl
}
*/

const initialState = JSON.stringify({
    files: [],
});

function fileReducer(state = JSON.parse(initialState), action) {
    log.debug("fileReducer -> Enter");
    switch (action.type) {
        case actionTypes.GET_PRESIGNED_UPLOAD_URL: {
            return {
                ...state,
                presigned_url: action.data.presigned_url,
            };
        }
        case actionTypes.UPLOAD_FILE: {
            log.debug("fileReducer -> UPLOAD_FILE -> Enter", action.data);
            let file_data = [action.data];
            console.log("reducer file data", file_data);
            let new_file = {
                Key: file_data[0].name,
                name: file_data[0].name,
                LastModified: file_data[0].lastModified,
                Size: file_data[0].size,
            };
            return {
                ...state,
                files: [...state.files, new_file],
            };
        }
        case actionTypes.UPDATE_FILES: {
            return {
                ...state,
                files: action.data,
            };
        }
        case actionTypes.DELETE_FILE: {
            return {
                ...state,
                files: state.files.filter((file) => file.Key !== action.data.fileName),
            };
        }

        default:
            return state;
    }
}

export default fileReducer;
