import Button, { BUTTON_VARIANT } from "components/Button/Button";
import Modal from "components/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { cancelUserSubscription } from "redux/User/actions";

import "./AccountSettings.css";
import "./../Settings.css"

const AccountSettings = () => {
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelRes, setCancelRes] = useState(null); 
  const [cancelLoading, setCancelLoading] = useState(false);
  const dispatch = useDispatch();


  const handleSubscriptionCancel = () => {
    dispatch(cancelUserSubscription(handleRes));
  };

  const handleRes = (res) => {
    console.log(res);
    if(res?.subscription?.status === "canceled"){
      setCancelRes("Subscription canceled");
      console.log("Subscription canceled");
    } else {
      setCancelRes("No Active Subscription, please try again later or contact support");
      console.log("Subscription not canceled");
    }

  };

 return (
   <div className="account_settings_container">
      <Modal 
       isModalOpen={cancelModal}
       children={<CancelModal handleSubscriptionCancel={handleSubscriptionCancel} setCancelModal={setCancelModal} cancelRes={cancelRes}/>}/>
      <div className="header-container">
        <div className="page-title">Account Settings</div>
        </div>
        <div className="account_settings">
          <div className="account_settings_item">
            <div className="account_settings_item_title">Subscription Plan</div>
            <div className="account_settings_item_value">Basic</div>
          </div>
      <Button onClick={()=>setCancelModal(true)} variant={BUTTON_VARIANT.DANGER}>Cancel Subscription</Button>
        </div>
    </div>
  );
}

const CancelModal = ({setCancelModal, handleSubscriptionCancel, cancelRes}) => {
  return (
    <>
    {
     !cancelRes ?
      <div className="cancel_modal">
        <div className="header-container">
        <div className="page-title">Account Settings</div>
        </div>
      <p>Are you sure you want to cancel your subscription?</p>
      <div className="cancel_modal_buttons">
        <Button onClick={()=>handleSubscriptionCancel()} variant={BUTTON_VARIANT.DANGER}>Yes</Button>
        <Button onClick={()=>setCancelModal(false)} variant={BUTTON_VARIANT.PRIMARY}>No</Button>
      </div>
      </div>
      :
      <div className="cancel_modal container">
      <h2>Subscription</h2>
      <p>{cancelRes}</p>
      <Button onClick={()=>setCancelModal(false)} variant={BUTTON_VARIANT.PRIMARY}>Close</Button>
      </div>
    }
    </>
  );
};

export default AccountSettings;