import axios from "axios";
import env from "config/site.config";
import originalStepsJson from "routes/Sequence/data/originalSteps.json";
import generatedStepsJson from "routes/Sequence/data/generatedSteps.json";

const stepsFromJson = (stepsJson, noMessage = false) => {
    const steps = {};
    for (const step in stepsJson) {
        if (!steps[step]) {
            steps[step] = {};
        }
        steps[step].subject = stepsJson[step].subject;
        steps[step].message = noMessage ? "" : stepsJson[step].message.join("\n");
    }
    return steps;
};

export const getSequence = async () => {
    return stepsFromJson(originalStepsJson);
};

export const generateSequence = async (originalSteps, generatedSteps, stepsToUpdate, subject, modifyTo, freeText = "") => {
    let stepsToUpdateArray = [];
    if (stepsToUpdate.All) {
        const { All, ...allSteps } = stepsToUpdate;
        stepsToUpdateArray = Object.keys(allSteps).map((stepId) => stepId.slice(5));
    } else {
        stepsToUpdateArray = Object.keys(stepsToUpdate)
            .filter((stepId) => stepsToUpdate[stepId])
            .map((stepId) => stepId.slice(5));
    }
    console.log("stepsToUpdateArray", stepsToUpdateArray);
    const stepsToUpdatePromises = stepsToUpdateArray.map((stepId) => {
        return axios.post(`${env.REACT_APP_BACKEND_URL}/sequence/step`, {
            subjectBody: subject,
            modifyTo: modifyTo,
            subject: originalSteps[stepId].subject,
            message: originalSteps[stepId].message,
            freeText: freeText,
        });
    });
    try {
        const stepsToUpdateResponses = await Promise.all(stepsToUpdatePromises);
        const generatedSequence = {};
        stepsToUpdateResponses.forEach((response, index) => {
            console.log(response);
            const stepId = stepsToUpdateArray[index];
            generatedSequence[stepId] = {
                subject: response.data.subject,
                message: response.data.message,
            };
        });

        return { ...generatedSteps, ...generatedSequence };
    } catch (error) {
        console.log(error);
    }
};

// router.get("/rephrase/:campaignId/:stepId", async (req, res, next) => {

export const getRephrasedStep = async (stepId, campaignId, currentMessage) => {
    const newStep = await axios.post(`${env.REACT_APP_BACKEND_URL}/sequence/rephrase/${campaignId}/${stepId}`, {
        currentMessage: currentMessage,
    });
    return newStep;
};

export const rephraseWithCustomText = async (stepId, campaignId, customText, currentMessage) => {
    const response = await axios.post(`${env.REACT_APP_BACKEND_URL}/sequence/rephrase/${campaignId}/${stepId}`, {
        customText: customText,
        currentMessage: currentMessage,
    });
    return response;
};

export const saveSequence = async (steps) => {
    const response = await axios.post(`${env.REACT_APP_BACKEND_URL}/sequence/save`, {
        steps: steps,
    });
    return response;
};

export const getSequenceByCampaignId = async (campaignId) => {
    const response = await axios.get(`${env.REACT_APP_BACKEND_URL}/sequence/get/${campaignId}`);
    return response;
};
