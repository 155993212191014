import React from "react";
import Button from "components/Button/Button";
import Modal from "../Modal";

import "./Popup.css";

const Popup = (props) => {
    const { title, children, buttons, visible, onClose } = props;
    return (
        <Modal isModalOpen={visible} closeModal={onClose}>
            <div className="popup">
                <div className="popup__title">
                    <h2>{title}</h2>
                </div>
                <div className="popup__content">{children}</div>

                {buttons && (
                    <div className="popup__buttons">
                        {buttons.map((button, i) => {
                            return (
                                <div key={i} style={{ marginLeft: "10px" }}>
                                    <Button variant={button.type} onClick={button.action}>
                                        {button.title}
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default Popup;
