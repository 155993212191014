import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as expandiApi from "redux/Expandi/api";
import * as campaignActions from "redux/Campaign/reducer";
import { toggleSnackbarOpen } from "redux/Snackbar/actions";

const initialState = {
    expandiUsers: {},
    sampleadAutomationUsers: {},
    expandiCampaigns: [],
    sampleadAutomationCampaigns: [],
};

function getCampaignKey(whitelabel) {
    return whitelabel ? "sampleadAutomationCampaigns" : "expandiCampaigns";
}

function getUsersKey(whitelabel) {
    return whitelabel ? "sampleadAutomationUsers" : "expandiUsers";
}

const expandiSlice = createSlice({
    name: "expandi",
    initialState: initialState,
    reducers: {
        setCampaigns: (state, action) => {
            const { whitelabel, campaigns } = action.payload;

            return {
                ...state,
                [getCampaignKey(whitelabel)]: campaigns,
            };
        },
        resetCampaigns: (state, action) => {
            return {
                ...state,
                expandiCampaigns: [],
                sampleadAutomationCampaigns: [],
            };
        },
        setUsers: (state, action) => {
            const { whitelabel, users } = action.payload;

            return {
                ...state,
                [getUsersKey(whitelabel)]: users,
            };
        },
        addUser: (state, action) => {
            const { whitelabel, user } = action.payload;

            return {
                ...state,
                [getUsersKey(whitelabel)]: {
                    ...state[getUsersKey(whitelabel)],
                    [user.id]: user,
                },
            };
        },
        removeUser: (state, action) => {
            const { whitelabel, id } = action.payload;

            const users = { ...state[getUsersKey(whitelabel)] };
            delete users[id];

            return {
                ...state,
                [getUsersKey(whitelabel)]: users,
            };
        },
    },
});

const getExpandiCampaigns = createAsyncThunk("expandi/getExpandiCampaigns", async ({ whitelabel, expandiUserId }, thunkAPI) => {
    const { dispatch } = thunkAPI;

    if (typeof whitelabel === "undefined") {
        const response = await expandiApi.getExpandiCampaigns({ whitelabel: true, expandiUserId });
        if (response.success) {
            dispatch(expandiSlice.actions.setCampaigns({ whitelabel: true, campaigns: response?.campaigns }));
        }
    }
    const response = await expandiApi.getExpandiCampaigns({ whitelabel, expandiUserId });
    if (response.success) {
        dispatch(expandiSlice.actions.setCampaigns({ whitelabel, campaigns: response?.campaigns }));
    }
});

const getExpandiUsers = createAsyncThunk("expandi/getExpandiUsers", async (whitelabel, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { expandi } = getState();
    if (expandi?.[getUsersKey(whitelabel)].length > 0) {
        return;
    }

    if (typeof whitelabel === "undefined") {
        const response = await expandiApi.getExpandiUsers(true);
        if (response.success) {
            dispatch(
                expandiSlice.actions.setUsers({
                    whitelabel: true,
                    users: response?.users || {},
                })
            );
        }
    }

    const response = await expandiApi.getExpandiUsers(whitelabel);
    if (response.success) {
        dispatch(
            expandiSlice.actions.setUsers({
                whitelabel,
                users: response?.users || {},
            })
        );
    }
});

const addExpandiUser = createAsyncThunk("expandi/addExpandiUser", async (payload, thunkAPI) => {
    const { id, username, url, whitelabel_flag, successCallback = () => {}, finallyCallback = () => {} } = payload;
    const { dispatch } = thunkAPI;

    try {
        const res = await expandiApi.setExpandiUserKeySecret({ id, username, url, whitelabel_flag });

        if (res.success) {
            dispatch(toggleSnackbarOpen({ message: "URL updated successfully" }));
            dispatch(
                expandiSlice.actions.addUser({
                    whitelabel: whitelabel_flag,
                    user: { id: res.id, username, url },
                })
            );
            successCallback();
        } else {
            dispatch(toggleSnackbarOpen({ message: "Something went wrong", variant: "error" }));
        }
    } catch (err) {
        dispatch(toggleSnackbarOpen({ message: "Something went wrong", variant: "error" }));
    }
    finallyCallback();
});

const removeExpandiUser = createAsyncThunk("expandi/removeExpandiUser", async (payload, thunkAPI) => {
    const { id, whitelabel_flag, successCallback = () => {}, finallyCallback = () => {} } = payload;
    const { dispatch } = thunkAPI;

    try {
        const res = await expandiApi.removeExpandiUserKeySecret({ id, whitelabel_flag });

        if (res.success) {
            dispatch(toggleSnackbarOpen({ message: "URL removed successfully" }));
            dispatch(
                expandiSlice.actions.removeUser({
                    whitelabel: whitelabel_flag,
                    id,
                })
            );
            successCallback();
        } else {
            dispatch(toggleSnackbarOpen({ message: "Something went wrong", variant: "error" }));
        }
    } catch (err) {
        dispatch(toggleSnackbarOpen({ message: "Something went wrong", variant: "error" }));
    }
    finallyCallback();
});

const exportCampaignLeads = createAsyncThunk("expandi/exportCampaignLeads", async (payload, thunkAPI) => {
    const { expandi_campaign_id, leads, leadIds, campaignId, whitelabel_flag, expandi_user_id } = payload;
    const { dispatch, getState } = thunkAPI;
    try {
        const response = await expandiApi.exportLeadsToExpandi({ expandi_campaign_id, leads, leadIds, whitelabel_flag, expandi_user_id });
        if (response.success) {
            dispatch(toggleSnackbarOpen({ message: "All leads exported successfully" }));
        } else {
            dispatch(
                toggleSnackbarOpen({
                    message: response?.message,
                    variant: "error",
                    timeout: -1,
                })
            );
        }
        const parameters = { ...getState().campaign?.campaignPaginationAndSearch?.[campaignId], campaignId };
        dispatch(campaignActions.getCampaignById(parameters));
    } catch (err) {
        dispatch(
            toggleSnackbarOpen({
                message: "Something went wrong while exporting leads",
                variant: "error",
                timeout: -1,
            })
        );
    }
});

export const { setCampaigns, resetCampaigns, setUsers, addUser } = expandiSlice.actions;
export { getExpandiCampaigns, exportCampaignLeads, getExpandiUsers, addExpandiUser, removeExpandiUser, getCampaignKey, getUsersKey };
export default expandiSlice.reducer;
