import axios from "axios";
import env from "config/site.config";

const log = require("loglevel").getLogger("UserService");
log.setLevel("debug");

axios.interceptors.request.use((request) => {
    log.info("Starting Request", request.url);
    return request;
});

axios.interceptors.response.use((response) => {
    // console.log('Response:', response)
    return response;
});

export const signIn = async (credentials) => {
    log.debug("UserService -> signIn -> Enter");

    let data = {};
    let route;
    if (typeof credentials === "string") {
        data = {
            code: credentials,
        };
        route = "verify";
    } else {
        data = credentials;
        route = "password";
    }

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/user/login/${route}`, { data })
        .then(async (response) => {
            console.log("response.data:", response.data);
            const user = response.data.user;

            if (!user.admin && !response.data.active_subscription && response.data.redirect_url) {
                console.log("redirecting to: ", response.data.redirect_url);
                window.location.href = response.data.redirect_url;
                return false;
            }

            const res = { user: response.data.user };
            log.debug("UserService -> signIn -> response: " + JSON.stringify(res));
            return res;
        })
        .catch((response) => {
            log.debug("userService -> signIn -> error: ");
            log.debug(response);
            return null;
        });
};

export const signOut = () => {
    try {
    } catch (e) {}
};

export const getUserStats = async (sd, ed) => {
    log.debug("UserService -> getUserStats -> Enter");

    const data = {
        sd,
        ed,
    };

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/user/stats`, { data })
        .then(async (response) => {
            const res = response.data;
            log.debug("UserService -> getUserStats -> response: " + JSON.stringify(res));
            return res;
        })
        .catch((response) => {
            log.debug("userService -> getUserStats -> error: ");
            log.debug(response);
            return null;
        });
};

export const getAllUsers = async () => {
    log.debug("UserService -> getAllUsers -> Enter");

    return axios
        .get(`${env.REACT_APP_BACKEND_URL}/user/get_all`)
        .then(async (response) => {
            const res = response.data;
            log.debug("UserService -> getAllUsers -> response: ", res);
            return res;
        })
        .catch((response) => {
            log.debug("userService -> getAllUsers -> error: ");
            log.debug(response);
            return null;
        });
};

export const getAllActiveUsers = async () => {
    log.debug("UserService -> getAllActiveUsers -> Enter");

    return axios
        .get(`${env.REACT_APP_BACKEND_URL}/user/get_all_active`)
        .then(async (response) => {
            const res = response.data;
            log.debug("UserService -> getAllUsers -> response: ", res);
            return res;
        })
        .catch((response) => {
            log.debug("userService -> getAllUsers -> error: ");
            log.debug(response);
            return null;
        });
};

export const getAccountUsers = async () => {
    log.debug("UserService -> getAccountUsers -> Enter");

    return axios
        .get(`${env.REACT_APP_BACKEND_URL}/user/account_users`)
        .then(async (response) => {
            const res = response.data;
            log.debug("UserService -> getAllUsers -> response: ", res);
            return res;
        })
        .catch((response) => {
            log.debug("userService -> getAllUsers -> error: ");
            log.debug(response);
            return null;
        });
};

export const cancelUserSubscription = async () => {
    log.debug("UserService -> cancelUserSubscription -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/stripe/cancel_subscription`)
        .then(async (response) => {
            const res = response.data;
            log.debug("UserService -> cancelUserSubscription -> response: " + JSON.stringify(res));
            return res;
        })
        .catch((response) => {
            log.debug("userService -> cancelUserSubscription -> error: ");
            log.debug(response);
            return null;
        });
};

export const updateAccountFilteredUser = async (filteredOwnerId, isShown) => {
    log.debug("UserService -> updateAccountFilteredUser -> Enter");

    return axios
        .post(`${env.REACT_APP_BACKEND_URL}/user/update_user_account_filter`, {
            filteredOwnerId,
            isShown,
        })
        .then(async (response) => {
            const res = response.data;
            log.debug("UserService -> updateAccountFilteredUser -> response: " + JSON.stringify(res));
            return res;
        })
        .catch((response) => {
            log.debug("userService -> updateAccountFilteredUser -> error: ");
            log.debug(response);
            return null;
        });
};
